import React, { useMemo } from 'react'
import moment from 'moment'

import EventCalendar from './EventCalendar'
import Loading from 'components/Loading'
import useEventsCollection from './hooks/useEventsCollection'
import { CalendarNavigationBar } from './CalendarNavigationBar'
import { useCalendarPeriod } from './hooks/useCalendarPeriod'
import { viewTypes } from './viewTypes'

export const WeekView = () => {
  const {
    firstOfWeek,
    setFirstOfWeek,
    gotoPrevWeek,
    gotoNextWeek,
    gotoCurrentWeek,
  } = useCalendarPeriod(viewTypes.WEEK)

  const { date, month, year } = useMemo(() => {
      return {
        date: firstOfWeek.date(),
        month: firstOfWeek.month(),
        year: firstOfWeek.year(),
      }
  }, [firstOfWeek])

  const handleChangeMonth = (newValue: any) => setFirstOfWeek(moment(newValue).startOf('month') )

  const [{ events, loading }] = useEventsCollection({
    startDate: firstOfWeek.clone().startOf('week').subtract(1, 'day').format('MM-DD-YYYY'),
    endDate: firstOfWeek.clone().endOf('week').add(1, 'day').format('MM-DD-YYYY'),
  })

  return (
    <div>
      <CalendarNavigationBar
        date={firstOfWeek}
        onTodayClick={gotoCurrentWeek}
        onNextClick={gotoNextWeek}
        onPrevClick={gotoPrevWeek}
        onMonthChange={handleChangeMonth}
      />

      {
        loading ?
          <Loading />
          :
          <EventCalendar
            date={date}
            month={month}
            year={year}
            // @ts-ignore
            events={events}
            viewType={viewTypes.WEEK}
          />
      }
    </div>
  )
}
