import React, { useState } from 'react'
import { Dialog, DialogContent } from '@material-ui/core'
import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import useCredential from '../hooks/useCredential'
import {
  CredentialContentType,
  CredentialExpirationOption,
  CreateOrUpdateCredentialParam,
} from 'credential/models'
import Loading from 'components/Loading'
import { useCurrentOrg } from 'auth/hooks'
import { useEffect } from 'react'
import CredentialForm from './CredentialForm'
import moment from 'moment-timezone'
import useCreateOrUpdateCredential from 'credential/hooks/useCreateOrUpdateCredential'
import useOrganization from '../../volunteering/organizations/hooks/useOrganization'

interface Props {
  credentialId?: number
  showing: boolean
  close: any
  cancel: any
  complete: any
  disableDraft?: boolean
}

const CreateOrUpdateCredentialPrompt = (props: Props) => {
  const { showing, close, complete, disableDraft, credentialId } = props
  const isUpdate = !!props.credentialId
  const [initiallyLoading, setInitiallyLoaded] = useState(!isUpdate)
  const { result, loading: credentialsLoading } = useCredential(props.credentialId)
  const { id: organizationId } = useCurrentOrg()
  const { result: organization, loading: organizationLoading } = useOrganization(organizationId)
  const loading = credentialsLoading || organizationLoading
  const [credential, setCredential] = useState({})
  const confirm = useConfirm()
  const createOrUpdateCredential = useCreateOrUpdateCredential()
  useEffect(() => {
    if (isUpdate && !!result && !loading) {
      setCredential({
        title: result.version.title,
        description: result.version.description,
        contentType: result.version.contentType || CredentialContentType.Text,
        content: result.version.content,
        url: result.version.url,
        waiverStatement: result.version.waiverStatement,
        expirationOption: result.credential.expirationOption || CredentialExpirationOption.Month,
        expirationOptionLength: result.credential.expirationOptionLength || 1,
        expirationDate: result.credential.expirationDate
          ? moment(result.credential.expirationDate)
              .tz(organization.timeZone || 'America/New_York')
              .format('MM/DD')
          : '12/31',
      })
      setInitiallyLoaded(true)
    }
  }, [result, loading, isUpdate, setInitiallyLoaded])

  const handleSave = async (values: CreateOrUpdateCredentialParam, isDraft?: boolean) => {
    const waiver = await createOrUpdateCredential(values, credentialId, isDraft)
    complete(waiver)
  }

  const handleCancel = async (values: CreateOrUpdateCredentialParam) => {
    if (isUpdate || disableDraft || !values.title) {
      close()
      return
    }
    const confirmed = await confirm(`Would you like to save the changes you have made so far?`, {
      title: 'Warning',
      confirmText: 'Save Draft',
      rejectText: 'Discard',
    })

    if (confirmed) {
      await handleSave(values, true)
    } else {
      close()
    }
  }
  return (
    <Dialog fullScreen open={showing}>
      <DialogContent>
        {(loading || !initiallyLoading) ? (
          <Loading />
        ) : (
          <CredentialForm
            organization={organization}
            credential={credential as CreateOrUpdateCredentialParam}
            handleSave={handleSave}
            handleCancel={handleCancel}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default CreateOrUpdateCredentialPrompt
