import { Geofencing } from 'civic-champs-shared/core/location/utils'
import { EventGeofencing } from 'utils/event'

export interface Role {
  id: number
  name: string
  available: number
  opportunitu_timeshift_id: number
  checked: boolean
}

export interface Shift {
  id: number
  name: string
  opportunity_id: number | null
  time_start: string
  time_end: string
  created_at: Date
  deleted_at: Date
  roles: Role[]
}

export interface ShiftModelDesc {
  timeshift_id: number | null
  role_id: number | null
}

export class ShiftModel implements ShiftModelDesc {
  constructor(timeshift_id?: number, role_id?: number) {
    this.timeshift_id = timeshift_id || null
    this.role_id = role_id || null
  }

  public timeshift_id: number | null
  public role_id: number | null
}

export interface ShiftOption {
  label: string
  value?: Shift
  shiftError: string
  roleError: string
}

export interface FormData {
  user_id?: number
  person_id?: number
  details: ShiftModel[]
}

export interface Opportunity {
  address?: string
  city?: string
  comment?: string
  config: object
  contact_email?: string
  contact_name?: string
  contact_phone_number?: string
  createdAt: Date
  deletedAt?: Date
  description?: string
  endsAt?: Date
  geofencing: Geofencing
  id: number
  occurrenceId: number
  opportunityId: number
  instructions?: string
  is_recurring?: boolean
  name: string
  organization: any
  private: boolean
  published?: boolean
  slots?: OpportunityVolunteerSlots
  startsAt: Date
  state?: string
  timeshifts?: any
  totalCheckIns?: number
  totalVolunteerHours?: number
  visibility: OpportunityVisibility
  zip?: string
}

export interface OpportunityApiResponse {
  address?: string
  city?: string
  comment?: string
  config: object
  contact_email?: string
  contact_name?: string
  contact_phone_number?: string
  createdAt: Date
  deletedAt?: Date
  description?: string
  endsAt?: Date
  geofencing: EventGeofencing
  id: number
  occurrenceId: number
  opportunityId: number
  instructions?: string
  is_recurring?: boolean
  name: string
  organization: any
  private: boolean
  published?: boolean
  slots?: OpportunityVolunteerSlots
  startsAt: Date
  state?: string
  timeshifts?: any
  totalCheckIns?: number
  totalVolunteerHours?: number
  visibility: OpportunityVisibility
  zip?: string
}

export interface OpportunityVolunteerSlots {
  available: number
  total: number
}

export interface Volunteer {
  email: string
  givenName: string
  familyName: string
  emailError: string
  givenNameError: string
  familyNameError: string
}

export enum OpportunityVisibility {
  PUBLIC = 'public',
  PRIVATE = 'private',
  SELECT_GROUPS_ONLY = 'select-groups-only',
}

export const opportunityVisibilityToLabel: { [key: string]: string } = {
  [OpportunityVisibility.PUBLIC]: 'Public',
  [OpportunityVisibility.PRIVATE]: 'Private',
  [OpportunityVisibility.SELECT_GROUPS_ONLY]: 'Selected Groups Only',
}

export const opportunityVisibilityToDescription: { [key: string]: string } = {
  [OpportunityVisibility.PUBLIC]: 'Everyone can see and register for your event',
  [OpportunityVisibility.PRIVATE]: 'Only people with the direct link can see or register for your event',
  [OpportunityVisibility.SELECT_GROUPS_ONLY]:
    'Only the selected groups below can see this event on your calendar, register for this event, and check in for this event when they arrive at the event',
}

export const getVisibilityDescription = (visibility: OpportunityVisibility): string =>
  opportunityVisibilityToDescription[visibility]

export const getVisibilityLabel = (visibility: OpportunityVisibility): string =>
  opportunityVisibilityToLabel[visibility]
