import { useCallback } from "react"

import ChangeRolePrompt from "../components/ChangeRolePrompt"
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import useChangeRole from './useChangeRole'

const useChangeRolePrompt = (eagerReplace) => {
    const changeRole = useChangeRole(eagerReplace)
    const prompt = usePrompt(ChangeRolePrompt)

    return useCallback( member => {
        if(prompt.showing) throw new Error('already showing prompt!')

        //TODO this is awkward...
        let unlistens = [
            prompt.onComplete( newRole => changeRole(member, newRole) ),
            prompt.onHide( () => unlistens.forEach( fn => fn() ) ),
        ]

        prompt.show({ member })
    }, [changeRole, prompt])
}

export default useChangeRolePrompt
