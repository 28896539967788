import React from 'react'
import PropTypes from 'prop-types'
import { ConnectedRouter } from 'connected-react-router'
import { Redirect, Route, Switch } from 'react-router'
import CssBaseline from '@material-ui/core/CssBaseline'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { RequireNewPassword, withAuthenticator } from 'aws-amplify-react'
import { SnackbarProvider } from 'notistack'
import { useTitle } from 'react-use'

import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'

import DrawerBar from '../components/Drawer'
import Notifications from '../notifications/Notifications'
import StandaloneKiosk from '../kiosk/StandaloneKiosk'
import CustomSignIn from '../auth/components/CustomSignIn'
import CustomForgotPassword from '../auth/components/CustomForgotPassword'
import ErrorBoundary from 'core/ErrorBoundary'
import ModalPromptProvider from 'civic-champs-shared/core/modal/ModalPromptProvider'
import FeaturesLoadedGate from 'core/feature/components/FeaturesLoadedGate'
import AuthInitializedGate from 'auth/components/AuthInitializedGate'
import { UISettingsProvider } from 'contexts/UISettingsProvider'
import useGoogleAnalytics from 'civic-champs-shared/utils/useGoogleAnalytics'
import useIntercomWidget from 'civic-champs-shared/utils/useIntercomWidget'

import useRoutes from './hooks/useRoutes'
import { OverlayContextProvider } from 'components/overlay/OverlayContext'

const { REACT_APP_ENV } = process.env
const env = REACT_APP_ENV || 'development'
const documentTitle = env === 'production' ? `${document.title}` : `${env.toUpperCase()} - ${document.title}`

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  content: {
    overflowX: 'auto',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: `24px 90px`,
    [theme.breakpoints.down('sm')]: {
      padding: '24px 90px 24px 30px',
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    position: 'relative',
  },
}))

const ErrorFallback = ({ error }) => (
  <>
    <Typography variant="h4">An Error Occurred. Please Refresh This Page.</Typography>
    {error && <Typography color="error">{error}</Typography>}
  </>
)

const App = ({ isKioskMode, history, currentProfile }) => {
  const classes = useStyles()
  const { routes, defaultRoute } = useRoutes()
  useGoogleAnalytics()
  useIntercomWidget(currentProfile?.user)

  useTitle(documentTitle)

  return (
    <ConnectedRouter history={history}>
      <UISettingsProvider>
        <SnackbarProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Notifications />
            <ModalPromptProvider>
              <OverlayContextProvider>
                {isKioskMode ? (
                  <StandaloneKiosk />
                ) : (
                  <>
                    <div className={classes.root}>
                      <CssBaseline />
                      <DrawerBar history={history} />
                      <main className={classes.content}>
                        <ErrorBoundary fallback={ErrorFallback}>
                          <AuthInitializedGate>
                            <FeaturesLoadedGate>
                              <Switch>
                                {routes.map((props, index) => (
                                  <Route {...props} key={index} />
                                ))}
                                <Redirect to={defaultRoute} />
                              </Switch>
                            </FeaturesLoadedGate>
                          </AuthInitializedGate>
                        </ErrorBoundary>
                      </main>
                    </div>
                  </>
                )}
              </OverlayContextProvider>
            </ModalPromptProvider>
          </MuiPickersUtilsProvider>
        </SnackbarProvider>
      </UISettingsProvider>
    </ConnectedRouter>
  )
}
/*
 */
App.propTypes = {
  history: PropTypes.object,
}

export default withAuthenticator(App, false, [<CustomSignIn />, <CustomForgotPassword />, <RequireNewPassword />])
