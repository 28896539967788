import React from 'react';

import { DateCell } from 'core/table/cells';
import { createDateValueSort, createContactSort, createRoleSort } from 'components/table/sort'
import { humanize } from 'civic-champs-shared/auth/utils/permissions';
import { ContactCell } from 'core/table/cells'
import { contactAccessor } from 'core/table/utils'

const TEAM_COLUMNS = [
    { id: 'fistName', Header: 'First Name', accessor: 'person.givenName' },
    { id: 'lastName', Header: 'Last Name', accessor: 'person.familyName' },
    {
        id: 'email',
        Header: 'Email',
        sortType: createContactSort('email'),
        accessor: contactAccessor({
            valuePath: 'person.email',
            verificationStatusPath: 'user.emailVerified',
            type: 'email',
        }),
        Cell: ContactCell,
    },
    {
        id: 'joinedDate',
        Header: 'Joined Date',
        accessor: 'startedAt',
        Cell: DateCell,
        sortType: createDateValueSort('startedAt')
    }, {
        Header: 'Role',
        accessor: 'role',
        Cell: ({ cell }) => {
            const role = humanize(cell.value) || ''
            const { displayName, code } = cell.row.original.status
            const isFullMember = code === 'member'
            return isFullMember ? role : role ? `${role} (${displayName})` : displayName
        },
        sortType: createRoleSort('role')
    }
];

const useTeamColumns = () => TEAM_COLUMNS

export default useTeamColumns;
