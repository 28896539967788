import React, { useState, useCallback } from 'react'
import { makeStyles } from '@material-ui/core'
import { QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import InputComponent from 'civic-champs-shared/core/input'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'

interface Props {
  showing: boolean
  cancel: any
  close: any
  complete: (duplicatedQuestionSet: QuestionSetResponse) => any
  questionSet: QuestionSetResponse
}

const useClasses = makeStyles({
  container: {
    padding: '8px 18px',
  },
})

export const DuplicateQuestionSetPrompt = (props: Props) => {
  const { showing, complete, close, questionSet } = props
  const [name, setName] = useState(`${questionSet.name} (1)`)
  const classes = useClasses()

  const disabled = !name || name === questionSet.name

  const handleSubmit = useCallback(async () => {
    complete({
      ...questionSet,
      name,
    })
  }, [complete, name, questionSet])

  return (
    <ConfirmDialog
      showing={showing}
      close={close}
      complete={handleSubmit}
      title="Rename Duplicate"
      text="Please enter a new name for the duplicate questionnaire"
      cancelText="Cancel"
      confirmText="Duplicate"
      confirmDisabled={disabled}
    >
      <div className={classes.container}>
        <InputComponent
          value={name}
          onChange={setName}
          required={true}
          label={'Questionnaire name'}
          placeholder={'Enter new name for duplicate question set'}
        />
      </div>
    </ConfirmDialog>
  )
}

export default DuplicateQuestionSetPrompt
