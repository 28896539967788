import { useCallback, useEffect } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'

const emptyArray: never[] = []

export default function useCredential(credentialId?: number) {
  // @ts-ignore
  const [request, { loading, error, result, called }] = useFetch()

  const getCredential = useCallback(() => {
    if (!credentialId) {
      return
    }
    // @ts-ignore
    request({ method: 'get', url: `/credentials/${credentialId}` })
  }, [request, credentialId])

  useEffect(() => {
    getCredential()
  }, [getCredential])
  return {
    result: result || emptyArray,
    loading: credentialId && (loading || !called),
    error,
    refresh: getCredential,
  }
}
