import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { Tag } from 'new-mentorship/types'

export interface CreateTagPayload {
  name: string
  color: string
}

export const useTagManagement = (): {
  getOrgTags: () => Promise<Tag[]>
  createTag: (body: CreateTagPayload) => Promise<any>
  editTag: (tagId: number, body: Partial<CreateTagPayload>) => Promise<any>
  deleteTag: (tagId: number) => Promise<any>
  linkTagToMatch: (tagId: number, matchId: number) => Promise<any>
  unlinkTagFromMatch: (tagId: number, matchId: number) => Promise<any>
  linkTagsToMatch: (tagIds: number[], matchId: number) => Promise<any>
  unlinkTagsFromMatch: (tagIds: number[], matchId: number) => Promise<any>
  status: Status<any>
} => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<any>()

  const getOrgTags = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/mentorship-tags`,
    })
  }, [organization, request])

  const createTag = useCallback(
    body => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/mentorship-tags`,
        config: {
          body,
        },
      })
    },
    [organization, request],
  )

  const editTag = useCallback(
    (tagId, body) => {
      return request({
        method: 'put',
        url: `/organizations/${organization.id}/mentorship-tags/${tagId}`,
        config: {
          body,
        },
      })
    },
    [organization, request],
  )

  const deleteTag = useCallback(
    tagId => {
      return request({
        method: 'del',
        url: `/organizations/${organization.id}/mentorship-tags/${tagId}`,
      })
    },
    [organization, request],
  )

  const linkTagToMatch = useCallback(
    (tagId, matchId) => {
      return request({
        method: 'put',
        url: `/mentorship-matches/${matchId}/mentorship-tags/${tagId}`,
      })
    },
    [request],
  )

  const unlinkTagFromMatch = useCallback(
    (tagId, matchId) => {
      return request({
        method: 'del',
        url: `/mentorship-matches/${matchId}/mentorship-tags/${tagId}`,
      })
    },
    [request],
  )

  const linkTagsToMatch = useCallback(
    (tagIds, matchId) => {
      return request({
        method: 'post',
        url: `/mentorship-matches/${matchId}/mentorship-tags`,
        config: {
          body: tagIds,
        },
      })
    },
    [request],
  )

  const unlinkTagsFromMatch = useCallback(
    (tagIds, matchId) => {
      return request({
        method: 'patch',
        url: `/mentorship-matches/${matchId}/mentorship-tags`,
        config: {
          body: tagIds,
        },
      })
    },
    [request],
  )

  return {
    getOrgTags,
    createTag,
    status,
    editTag,
    deleteTag,
    linkTagToMatch,
    unlinkTagFromMatch,
    linkTagsToMatch,
    unlinkTagsFromMatch,
  }
}

export default useTagManagement
