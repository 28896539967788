import { useCallback, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'

export const useFetchOrgUserProfileQuestionSets = onResultUpdate => {
  const currentOrg = useCurrentOrg()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const [request, status] = useFetch(
    `Error Fetching User Profile Questionnaires for ${currentOrg.name}.  Please refresh the page and try again`,
  )
  const fetchOrgUserQuestionSets = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: `/organizations/${currentOrg.id}/${
        isQuestionnaireAsSurveyEnabled ? 'user-profile-surveys' : 'user-profile-question-sets'
      }`,
    })
  }, [currentOrg.id, isQuestionnaireAsSurveyEnabled, onResultUpdate, request])

  return useMemo(() => [fetchOrgUserQuestionSets, status], [fetchOrgUserQuestionSets, status])
}
