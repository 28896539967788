import { useCurrentOrg } from 'auth/hooks'
import { NotificationBlast } from './schema'
import usePost from 'civic-champs-shared/api/hooks/usePost'

const useMessageRecipients = (): [
  (props: NotificationBlast) => Promise<void>,
  {
    loading: boolean
    called: boolean
    error?: Error | null | undefined
    result?: void | null
  },
] => {
  const organization = useCurrentOrg()
  const [post, status] = usePost<void, NotificationBlast>({
    errorMessage: 'Error messaging recipients',
    successMessage: 'Successfully sent message',
    configMapper: (body: NotificationBlast) => ({ url: `/organizations/${organization.id}/notification-blasts`, config: { body } })
  })
  return [post, status]
}

export default useMessageRecipients
