import { map } from 'lodash'
import React, { useEffect } from 'react'
import classNames from 'classnames'
import { makeStyles, Button} from '@material-ui/core'
import cn from 'classnames'
import ThankYouPrompt from './ThankYouPrompt'
import DonatePromptPhone from './DonatePromptPhone'
import moment from 'moment';
import './sumarry.scss'
import useGetAffiliatedOpportunities from '../hooks/useGetAffiliatedOpportunties'

const useStyles = makeStyles(theme => ({ 
  previewTitle: {
    fontSize: '24px',
    color: '#3D3D3D',
  },
  appearance: {
    fontSize: '15px',
    color: '#3D3D3D',
    marginTop: '9px',
    marginBottom: '14px',
  },
  phoneContainer: {
    width: '303px',
    height: '620px',
    border: '4px solid #000000',
    borderRadius: '28px',
    paddingTop: '22px',
    paddingLeft: '28px',
    paddingRight: '29px',
    paddingBottom: '22px',
  },
  phoneTitle: {
    fontSize: '28px',
    color: '#3D3D3D',
    marginTop: '22px',
    marginBottom: '9px',
  },
  thankYouText: {
    fontSize: '15px',
    color: '#3D3D3D',
  },
  image: {
    width: '242px',
    height: '181px',
    marginTop: '9px',
    marginBottom: '16px',
  },
  button: {
    width: '240px',
    height: '44px',
    backgroundColor: '#3D67B5',
    borderRadius: '4px',
    marginTop: '16px',
    marginBottom: '22px',
  },
  buttonTwo: {
    marginTop: 0,
  },
  buttonText: {
    color: 'white',
    fontSize: '12px',
  },
  text: {
    marginTop: '20px',
    maxLines: 2,
  },
  subTitle: {
    marginTop: '32px',
  }
}));


const SummaryInfoDonation = (props) => {
  const { currentStep, data, campaignId } = props

  const [affiliatedOpps, setAffiliatedOpps] = React.useState();
  const [getAffiliateOpportunities, ] = useGetAffiliatedOpportunities()

  useEffect(() => { 
    (async () => {
        if(currentStep.stepIndex === 4 ){
        const affiliatedOpps = await getAffiliateOpportunities(campaignId) 
        setAffiliatedOpps(affiliatedOpps)
      }
    })()
}, [currentStep, campaignId, getAffiliateOpportunities])

  const sidebarClassNames = classNames('sidebar-title', { opacity: currentStep.index !== 4})

  const classes = useStyles()

  return (
    <div className="summary-info-sidebar">
      {(currentStep.stepIndex === 0 || currentStep.stepIndex === 1 || currentStep.stepIndex === 4) &&<h3 className={sidebarClassNames}>Summary</h3>}
      {(currentStep.stepIndex === 0 || currentStep.stepIndex === 1) && <span className="sidebar-placeholder-image" />}
      {currentStep.stepIndex === 2 &&
      <div>
        <div className={classes.previewTitle}><b>Preview</b></div>
        <div className={classes.appearance}>Appearance may vary</div>
        <ThankYouPrompt data={currentStep.thankYouPrompt}/> 
      </div> 
      }
      {currentStep.stepIndex === 3 &&
      <div>
        <div className={classes.previewTitle}><b>Preview</b></div>
        <div className={classes.appearance}>Appearance may vary</div> 
        <DonatePromptPhone data={currentStep.donatePrompt}/> 
      </div> 
      }
      {currentStep.stepIndex === 4 &&
      <div>
           <div className={classes.thankYouText}>{currentStep.name}</div>
           <p className={cn([classes.thankYouText, classes.text])}>{moment(currentStep.startsAt).format("MM/DD/YYYY")} - {moment(currentStep.endsAt).format("MM/DD/YYYY")}</p>
           {affiliatedOpps!=null && affiliatedOpps.length>0 && <p className={classes.subTitle}><b>Affiliated Events</b></p>} 
           {map(affiliatedOpps, (opp) => (  
           <p  key={opp.id} className={cn([classes.thankYouText, classes.text])}>{opp.name}</p>
           ))}
           <p className={classes.subTitle}><b>Thank you prompt</b></p>  
           <p className={cn([classes.thankYouText, classes.text])}
            dangerouslySetInnerHTML={{
              __html: currentStep.thankYouPrompt.firstParagraph }}
           ></p>
           <p className={classes.subTitle}><b>Donate prompt</b></p> 
           <p className={cn([classes.thankYouText, classes.text])} 
           dangerouslySetInnerHTML={{
            __html: currentStep.donatePrompt.firstParagraph }}
          ></p>  
        </div> 
      }
    </div>
  )
}

export default SummaryInfoDonation