import { isEmpty, upperFirst, trim } from 'lodash'
import * as moment from 'moment'
import React, { useCallback } from 'react'
import { Button, TextField, Typography, Switch, FormControlLabel } from '@material-ui/core'
import { Formik } from 'formik'
import InputMask from 'react-input-mask'
import * as yup from 'yup'
import useStyles from '../hooks/useMyUserStyles'
import cn from 'classnames'

const formSchema = yup.object({
  givenName: yup
    .string()
    .label('first name')
    .required(),
  familyName: yup
    .string()
    .label('last name')
    .required(),
  email: yup.string().email(),
  phone: yup.string().matches(/^\+1\d{10}$/, 'phone number is invalid'),
  home: yup.string().matches(/^\+1\d{10}$/, 'phone number is invalid'),
  birthday: yup.string().matches(/^\d{2}\/\d{2}\/\d{4}$/, '${path} must be DD/MM/YYYY'), // eslint-disable-line no-template-curly-in-string
})

export default function CreateNewUserStep(props) {
  const classes = useStyles()

  const { userLabel = 'Volunteer', provided, initialValues, onBack, onCreate } = props

  const handleFormikSubmit = useCallback(
    (values, { setSubmitting }) => {
      const email = !isEmpty(trim(values.email)) ? values.email : null
      const phoneNumber = !isEmpty(values.phone) ? trim(values.phone) : null
      const homePhoneNumber = !isEmpty(values.home) ? trim(values.home) : null
      const userData = {
        givenName: trim(values.givenName),
        familyName: trim(values.familyName),
        email,
        phoneNumber,
        homePhoneNumber,
        birthday: moment(values.birthday || '', 'MM/DD/YYYY').format('YYYY-MM-DD'),
        sendInvite: values.sendInvite
      }

      onCreate(userData)
      setSubmitting(false)
    },
    [onCreate],
  )

  return (
    <>
      <Typography>
        Please provide the {userLabel}'s full name and any additional information that is available.
      </Typography>
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleFormikSubmit}>
        {({ values, submitCount, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
          const getError = field => submitCount > 0 && !!errors[field]
          const getErrorText = field => (getError(field) ? errors[field] : undefined)
          const formatAsNameOnBlur = field => event => {
            setFieldValue(field, upperFirst(trim(values[field])))
            handleBlur(event)
          }
          const formatEmptyPhoneOnBlur = field => event => {
            let phone = trim(values[field]).replace(/[^\d]/g, '')
            // 'empty' phone number will still have the prepended '1' from formatting
            if (phone === '1') {
              setFieldValue(field, '')
            }
            handleBlur(event)
          }

          return (
            <form onSubmit={handleSubmit} noValidate>
              <TextField
                name="givenName"
                label="First Name"
                variant="outlined"
                value={values.givenName}
                onChange={handleChange}
                onBlur={formatAsNameOnBlur('givenName')}
                error={getError('givenName')}
                helperText={getErrorText('givenName')}
                autoFocus
                autoComplete="fname"
                required
                fullWidth
                className={classes.inputFieldOutlined}
                autoCapitalize='none'

              />
              <TextField
                name="familyName"
                label="Last Name"
                variant="outlined"
                value={values.familyName}
                onChange={handleChange}
                onBlur={formatAsNameOnBlur('familyName')}
                error={getError('familyName')}
                helperText={getErrorText('familyName')}
                autoComplete="lname"
                autoCapitalize='none'
                required
                fullWidth
                className={classes.inputFieldOutlined}
              />
              <TextField
                name="email"
                label="Email"
                variant={!!provided.email ? 'filled' : 'outlined'}
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError('email')}
                helperText={getErrorText('email')}
                disabled={!!provided.email}
                autoComplete="email"
                fullWidth
                className={classes.inputFieldOutlined}
              />
              <InputMask
                name="phone"
                label="Mobile Phone"
                variant={!!provided.phone ? 'filled' : 'outlined'}
                disabled={!!provided.phone}
                mask="+1 (999) 999-9999"
                maskChar=" "
                alwaysShowMask={false}
                value={values.phone}
                onChange={e => setFieldValue('phone', '+' + (e.target.value || '').replace(/[^\d]/g, ''))}
                onBlur={formatEmptyPhoneOnBlur('phone')}
                error={getError('phone')}
                helperText={getErrorText('phone')}
                fullWidth
                className={classes.inputFieldOutlined}
              >
                {inputProps => <TextField {...inputProps} />}
              </InputMask>
              <InputMask
                name="home"
                label="Home Phone"
                variant={!!provided.home ? 'filled' : 'outlined'}
                disabled={!!provided.home}
                mask="+1 (999) 999-9999"
                maskChar=" "
                alwaysShowMask={false}
                value={values.home}
                onChange={e => setFieldValue('home', '+' + (e.target.value || '').replace(/[^\d]/g, ''))}
                onBlur={formatEmptyPhoneOnBlur('home')}
                error={getError('home')}
                helperText={getErrorText('home')}
                fullWidth
                className={classes.inputFieldOutlined}
              >
                {inputProps => <TextField {...inputProps} />}
              </InputMask>
              <TextField
                name="birthday"
                label="Birthday"
                variant="outlined"
                placeholder={'MM/DD/YYYY'}
                value={values.birthday}
                onChange={handleChange}
                onBlur={handleBlur}
                error={getError('birthday')}
                helperText={getErrorText('birthday')}
                fullWidth
                className={classes.inputFieldOutlined}
              />
              <FormControlLabel
                control={<Switch name="sendInvite" checked={values.sendInvite} onChange={handleChange}/>}
                label="Send Invitation"
              />
              <div>
                <Button onClick={onBack} className={classes.actionButton}>
                  Back
                </Button>
                <Button type="submit" variant="contained" color="secondary" className={cn(classes.actionButton)}>
                  Create New {userLabel}
                </Button>
              </div>
            </form>
          )
        }}
      </Formik>
    </>
  )
}
