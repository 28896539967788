import { useCallback } from 'react'
import _ from 'lodash'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { volunteerSummary } from './utils'

const useArchiveVolunteer = (eagerRemoveMany: any) => {
  const [request, ] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(async(volunteers) => {
    const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
    const text = onlyOneVolunteer
      ? `Archiving ${volunteerName} from volunteers...`
      : `Archiving ${numVolunteers} volunteers...`
    const undo = eagerRemoveMany(volunteers)
    const notification = createNotification(text)
    try {
      // @ts-ignore
      await request({
        method: 'post',
        url: '/volunteers/archive',
        config: {
          body: { ids: _.map(volunteers, item => item.id) },
        },
      })

      notification.onSuccess('Volunteer archived!')
    } catch (error) {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Could not archive ${volunteerName} from volunteers`
        : `Could not archive ${numVolunteers} volunteers`
      notification.onError(text, error)
      undo()
    }
  }, [createNotification, eagerRemoveMany, request])
}

export default useArchiveVolunteer
