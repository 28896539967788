import { useMemo } from 'react'
import { Row } from 'react-table'
import reduce from 'lodash/reduce'

import { ProgramMatch } from '../types'

export const useMentorshipMatchesSummary = (rows: Row<ProgramMatch>[]) =>
  useMemo(() => {
    const { meetingCount, momentCount, totalMeetingTime, positiveFeedbackCount, negativeFeedbackCount } = reduce(
      rows,
      (
        data,
        {
          original: { meetingCount, momentCount, averageMeetingDuration, positiveFeedbackCount, negativeFeedbackCount },
        }: Row<ProgramMatch>,
      ) => ({
        meetingCount: data.meetingCount + meetingCount,
        momentCount: data.momentCount + momentCount,
        totalMeetingTime: data.totalMeetingTime + averageMeetingDuration * meetingCount,
        positiveFeedbackCount: data.positiveFeedbackCount + positiveFeedbackCount,
        negativeFeedbackCount: data.negativeFeedbackCount + negativeFeedbackCount,
      }),
      {
        meetingCount: 0,
        momentCount: 0,
        totalMeetingTime: 0,
        positiveFeedbackCount: 0,
        negativeFeedbackCount: 0,
      },
    )
    const feedbackCount = positiveFeedbackCount + negativeFeedbackCount
    return {
      meetingCount,
      momentCount,
      averageMeetingTime: meetingCount ? Math.round(totalMeetingTime / meetingCount / 60) : 0,
      positiveFeedbackPercent:
        (feedbackCount ? Math.round((positiveFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
      negativeFeedbackPercent:
        (feedbackCount ? Math.round((negativeFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
    }
  }, [rows])
