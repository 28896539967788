import React, { useMemo } from 'react'
import { DateCell, PersonNameCell, CapitalizeCell, ViewCell } from 'core/table/cells'
import format from 'utils/format'

export const useColumns = () => {
  return useMemo(
    () => [
      {
        Header: '',
        id: 'view',
        Cell: ViewCell(id => `/messages/${id}`),
      },
      {
        id: 'createdAt',
        Header: 'Date',
        accessor: 'createdAt',
        Cell: DateCell,
        filter: 'dateRange',
      },
      {
        Header: 'Method',
        accessor: 'method',
        Cell: CapitalizeCell,
      },
      {
        Header: 'Type',
        accessor: 'templateVersion.description'
      },
      {
        Header: 'Subject',
        accessor: 'userEnteredSubject',
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <div>{original.userEnteredSubject || original.subject}</div>,
      },
      {
        Header: 'Recipient',
        accessor: 'sentTo',
        Cell: PersonNameCell
      },
      {
        Header: 'Recipient Contact',
        accessor: 'recipientContact',
        Cell: ({ cell }) => cell.value.includes('@') ? cell.value : format.phoneNumber(cell.value)
      },
      {
        Header: 'Opportunity/Event',
        accessor: 'opportunity.name'
      },
      {
        Header: 'Custom Message',
        accessor: 'isCustom',
        Cell: ({ cell }) => cell.value ? 'Yes' : ''
      },
      {
        Header: 'Withheld',
        accessor: 'withheld',
        Cell: ({ cell }) => cell.value ? 'Yes' : ''
      },
    ],
    [],
  )
}
