import React from 'react'
import { Box, Grid, makeStyles, Typography } from '@material-ui/core'
import { MailOutline as MailOutlineIcon, Phone as PhoneIcon } from '@material-ui/icons'
import format from 'utils/format'
import cn from 'classnames'

const useStyles = makeStyles(theme => ({
  bigText: {
    lineHeight: '2rem',
    display: 'flex',
    alignItems: 'center',
  },
  smallText: {
    lineHeight: '1rem',
    fontSize: '.75rem',
    display: 'flex',
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  fixedWidth: {
    width: '200px',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
    fontWeight: 'bold',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  actionButton: {
    marginLeft: 'auto',
  },
}))

interface PersonInfoProps {
  title?: any
  person?: any
  actionButton?: any
  emptyContent?: any
  inline?: any
  hidePhone?: any
}

export const PersonInfo: React.FC<PersonInfoProps> = props => {
  const { title, person, actionButton, emptyContent, inline, hidePhone } = props
  const classes = useStyles()

  if (!person && !emptyContent && !actionButton) {
    return null
  }

  const contactTextClass = inline ? classes.smallText : classes.bigText
  return (
    <>
      <Box className={classes.header}>
        {title && <Typography className={classes.title}>{title}</Typography>}
        {actionButton && <Box className={classes.actionButton}>{actionButton}</Box>}
      </Box>
      <Grid container direction={inline ? 'row' : 'column'}>
        {!!person ? (
          <>
            <Grid item xs={inline ? 4 : 12}>
              <Typography className={cn(classes.bigText, { [classes.fixedWidth]: inline })}>
                {person.givenName || ''} {person.familyName || ''}
              </Typography>
            </Grid>
            <Grid item xs={inline ? 4 : 12}>
              <Typography className={contactTextClass}>
                <PhoneIcon />
                {hidePhone ? '' : format.phoneNumber(person.phoneNumber) || '(xxx) xxx-xxxx'}
              </Typography>
            </Grid>
            <Grid item xs={inline ? 4 : 12}>
              <Typography className={contactTextClass}>
                <MailOutlineIcon />
                {person.email ? <a href={`mailto:${person.email}`}>{person.email}</a> : 'No email listed'}
              </Typography>
            </Grid>
          </>
        ) : (
          <Grid item xs={inline ? 4 : 12}>
            {emptyContent}
          </Grid>
        )}
      </Grid>
    </>
  )
}
