import React from 'react'
import cn from 'classnames'
import { size } from 'lodash'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import { Button, Grid, InputLabel, makeStyles, Box } from '@material-ui/core'

import { useCurrentOrg } from 'auth/hooks'
import { notificationBlastSchema, StepThreeProps } from './schema'
import { getSubjectText } from './utils'
import { EmailPreview, SMSPreview } from '../MessagePreviews'
import CKEditorField from 'civic-champs-shared/formik/components/CkEditorField'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'

const useStyles = makeStyles((theme) => ({
  marginLeft50: {
    marginLeft: 50,
  },
  labelContainer: {
    position: 'relative',
    left: -15,
    textAlign: 'right',
    whiteSpace: 'nowrap',
},
  label: {
    fontSize: 16,
  },
  textarea: {
    fontSize: 16,
    padding: '18.5px 14px',
    borderColor: 'rgba(0, 0, 0, 0.23)',
    transition: 'box-shadow 0.2s',
    boxShadow: '0 0 0 2px transparent',

    '&:hover': {
      // @ts-ignore
      borderColor: theme.palette.neutral.black,
    },
    '&:focus': {
      borderColor: 'transparent',
      // @ts-ignore
      boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,

      '&$error': {
        borderColor: 'transparent',
        // @ts-ignore
        boxShadow: `0 0 0 2px ${theme.palette.borders.error}`,
      },
    }
  },
  error: {
    borderColor: 'transparent !important',
    // @ts-ignore
    boxShadow: `0 0 0 1px ${theme.palette.borders.error}`,
  },
  button: {
    borderColor: 'currentColor',
    textTransform: 'initial',
    fontWeight: 'bold',
    minWidth: 100,
  },
  sendTestButton: {
    backgroundColor: '#B4D6F0',
    color: theme.palette.secondary.main,
  },
  cancelButton: {
    // @ts-ignore
    color: theme.palette.danger.main,
  },
  input: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0 !important',
    },

    '& .MuiFormHelperText-root.Mui-error': {
      display: 'none !important',
    },
  },
  inputLabel: {
    marginTop: 5,
    // @ts-ignore
    color: theme.palette.danger.main,
    fontSize: 12,
  },
  row: {
    flex: 1,
    marginBottom: 15,
  },
  marginLeft: {
    marginLeft: 10,
  },
  buttons: {
    alignItems: 'flex-end',
    marginTop: 10,
  }
}))

export default function StepThree(props: StepThreeProps) {
  const { onSubmit, smsPersonIds = [], emailPersonIds = [], cancel, back } = props
  const numRecipients = size(smsPersonIds) + size(emailPersonIds)
  const classes = useStyles()
  const organization = useCurrentOrg()
  const schema = notificationBlastSchema(!!emailPersonIds.length)

  return (
    <Formik
      initialValues={{ body: '', subject: '' }}
      validationSchema={notificationBlastSchema}
      onSubmit={values => onSubmit(schema.cast(values))}
    >
      {formikProps => {
        const { handleSubmit, isSubmitting, isValid, dirty, values, errors } = formikProps

        return (
          <Form
            className="fullheight"
            onSubmit={handleSubmit}
          >
            <Grid
              container
              className="fullheight"
              direction="column"
              wrap="nowrap"
            >
              <Grid direction="row" item xs={12} container className={classes.row}>
                <Grid item xs={2} className={classes.labelContainer}>
                  <b className={classes.label}>Message Subject</b>
                </Grid>
                <Grid item xs={10}>
                  <Field variant="outlined" component={TextField} name="subject" className={classes.input} />
                  <InputLabel className={classes.inputLabel}>Required</InputLabel>
                </Grid>
              </Grid>

              <Grid direction="row" item xs={12} container className={classes.row}>
                <Grid item xs={2} className={classes.labelContainer}>
                  <b className={classes.label}>Message</b>
                </Grid>
                <Grid item xs={10}>
                  <Field
                      component={CKEditorField}
                      editor={ClassicEditor}
                      config={{
                        toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Blockquote', 'Link', 'Unlink']
                      }}
                      name="body"
                      style={{ marginTop: 10 }}
                      className={cn("fullwidth", classes.textarea, {
                        [classes.error]: errors.body,
                      })}
                  />
                  <InputLabel className={classes.inputLabel}>Required</InputLabel>
                </Grid>
              </Grid>

              <Grid direction="row" item xs={12} container className={classes.row}>
                <Grid item xs={2} className={classes.labelContainer}>
                  <b className={classes.label}>Previews</b>
                </Grid>
                {!!emailPersonIds.length && (
                  <Grid item xs={smsPersonIds.length ? 4 : 8}>
                    <EmailPreview
                      subject={values.subject}
                      body={<>
                        <Box fontSize={12} fontStyle="italic">{organization.name} has sent you a message:</Box>
                        <Box className="truncated-multiline-3" mt={2} dangerouslySetInnerHTML={{__html: values.body}}/>
                      </>}
                      emailCount={emailPersonIds.length}
                    />
                  </Grid>
                )}

                {!!smsPersonIds.length && (
                  <Grid
                    item
                    xs={emailPersonIds.length ? 4 : 8}
                    className={cn({ [classes.marginLeft50]: emailPersonIds.length })}
                  >
                    <SMSPreview
                      subject={getSubjectText({ body: values.body, subject: values.subject, organization })}
                      body={values.body}
                      smsCount={smsPersonIds.length}
                    />
                  </Grid>
                )}
              </Grid>

              <Grid xs={12} item container direction="row" justify="space-between" className={classes.buttons}>
                <Grid item xs={6}>
                  <Button
                    onClick={back}
                    variant="outlined"
                    color="secondary"
                    disabled={isSubmitting}
                    className={classes.button}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid direction="row" item xs={6} container justify="flex-end">
                  <Grid>
                    <Button
                      onClick={cancel}
                      variant="outlined"
                      disabled={isSubmitting}
                      className={cn(classes.button, classes.cancelButton)}
                    >
                      Cancel
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={cn("hidden", classes.marginLeft, classes.button, classes.sendTestButton)}
                      disabled={isSubmitting || !isValid || !dirty || !numRecipients}
                    >
                      Send Test
                    </Button>
                  </Grid>
                  <Grid>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      className={cn(classes.marginLeft, classes.button)}
                      disabled={isSubmitting || !isValid || !dirty || !numRecipients}
                    >
                      Send Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}
