import Typography from '@material-ui/core/Typography'
import { Checkbox, FormControlLabel, Modal } from '@material-ui/core'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBoxIcon from '@material-ui/icons/CheckBox'
import React, { useCallback, useMemo, useState } from 'react'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import { muiTheme } from 'theme'

const useStyles = makeStyles(theme => ({
  modalBody: {
    padding: '12px',
    background: '#F8FAFF',
    borderRadius: '4px',
    border: '0px',
    position: 'fixed',
    width: '384px',
    left: 'calc(50% - 187px)',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#74777F',
  },
  subtitle: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#74777F',
    paddingBottom: '12px',
    marginTop: '4px',
  },
  label: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  checkbox: {
    // @ts-ignore
    color: theme.palette.primary.main,
    fontSize: '18px',
  },
  checkboxRoot: {
    padding: '6px 8px',
  },
  item: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'grab',
  },
  icon: {
    fontSize: '20px',
  },
  dropContainer: {
    display: 'block',
    width: '100%',
    position: 'relative',
    marginBottom: '16px',
  },
  buttons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
}))

const getItemStyle = (isDraggingOver: boolean, index: number, style: any) =>
  isDraggingOver
    ? {
        ...style,
        background: isDraggingOver ? 'lightblue' : 'none',
        left: '16px',
        top: `${92 + index * 30}px`,
      }
    : style

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

export const ColumnSelectorModalComponent = (props: any) => {
  const { showing, complete, table } = props
  const classes = useStyles()
  const currentColumnState = useMemo(
    () =>
      table.allColumns.slice(1).map(({ id, Header, isVisible, hideInSelector }: any) => ({
        id,
        Header,
        checked: isVisible,
        hidden: hideInSelector,
      })),
    [table],
  )
  const initialColumnState = useMemo(
    () =>
      table.columns.map(({ id, Header }: any) => ({
        id,
        Header,
        checked: !table?.initialState?.hiddenColumns?.includes(id),
      })),
    [table],
  )
  const [columns, setColumns] = useState(currentColumnState)
  const setColumnsVisible = useCallback(
    (id, checked) =>
      setColumns((columns: any) => columns.map((column: any) => (column.id === id ? { ...column, checked } : column))),
    [setColumns],
  )
  const onDragEnd = useCallback(
    (result: any) => {
      // dropped outside the list
      if (!result.destination) {
        return
      }
      setColumns(reorder(columns, result.source.index, result.destination.index))
    },
    [columns],
  )

  return (
    <Modal open={showing}>
      <div className={classes.modalBody}>
        <Typography className={classes.title}>Adjust Column Display</Typography>
        <Typography className={classes.subtitle}>
          Choose columns to show or hide, and drag an individual column to adjust the order they display.
        </Typography>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {provided => (
              <div className={classes.dropContainer} {...provided.droppableProps} ref={provided.innerRef}>
                {columns
                  .filter(({ hidden }: any) => !hidden)
                  .map((column: any, index: number) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            key={column.id}
                            className={classes.item}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(snapshot.isDragging, index, provided.draggableProps.style)}
                          >
                            <FormControlLabel
                              classes={{ label: classes.label }}
                              control={
                                <Checkbox
                                  classes={{ root: classes.checkboxRoot }}
                                  icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} fontSize="small" />}
                                  checkedIcon={<CheckBoxIcon className={classes.checkbox} fontSize="small" />}
                                  checked={column.checked}
                                  onChange={(_, checked) => setColumnsVisible(column.id, checked)}
                                />
                              }
                              label={column.Header}
                            />
                            <DragHandleIcon className={classes.icon} />
                          </div>
                        )
                      }}
                    </Draggable>
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className={classes.buttons}>
          <OutlinedButton borderless onClick={() => setColumns(initialColumnState)}>
            Restore Defaults
          </OutlinedButton>
          <div>
            <OutlinedButton borderless onClick={() => complete()}>
              Cancel
            </OutlinedButton>
            <OutlinedButton borderless onClick={() => complete(columns)}>
              Save
            </OutlinedButton>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export const ColumnSelectorModal = (props: any) => (
  <ThemeProvider theme={muiTheme}>
    <ColumnSelectorModalComponent {...props} />
  </ThemeProvider>
)
