import React, { useEffect, useMemo, useState } from 'react'
import { useColumnOrder, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable } from 'react-table'
import { RouteComponentProps } from 'react-router'
import Grid from '@material-ui/core/Grid'
import MessageOutlinedIcon from '@material-ui/icons/MessageOutlined'

import { useCurrentOrg } from 'auth/hooks'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import { useRegistrantColumns, useStyles } from 'registrant/hooks/useRegistrantColumns'
import { useRegistrants } from 'volunteering/volunteers/hooks'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import Loading from 'components/Loading'
import DEFAULT_FILTERS from 'core/table/filters'
import { TableMenuButton } from 'civic-champs-shared/core/TableMenuButton'
import ExportRegistrantsButton from 'registrant/pages/ExportRegistrantsButton'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { DayNavigationBar } from '../components/DayNavigationBar'
import PageHeader from 'App/components/PageHeader'
import { useGetSearchableContactsFromVolunteersArray } from 'messages/hooks/useGetSearchableContactsFromVolunteersArray'

const tableName = 'registrants'

export const Registrants = (props: RouteComponentProps) => (
  <PageHeader title="Registrations" subTitle="View all of the day’s volunteer registrations">
    <RegistrantsComponent {...props} />
  </PageHeader>
)

export const RegistrantsComponent = ({ location, history }: RouteComponentProps) => {
  const [dayDiff, setDayDiff] = useState(0)

  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()

  const [{ registrants, loading, initiallyLoaded }] = useRegistrants(dayDiff) as any
  const columns = useRegistrantColumns()
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const organization = useCurrentOrg()

  const getSearchableContacts = useGetSearchableContactsFromVolunteersArray(registrants)

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'email', 'mobile', 'homePhoneNumber', 'groups'],
        // @ts-ignore
        globalFilter: '',
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data: registrants,
      columns,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, selectedRowsCount] = useMemo(
    () => [table.selectedFlatRows, table.selectedFlatRows.length],
    [table.selectedFlatRows],
  )

  const onMessageVolunteers = () => {
    const recipientPersonIds = selectedRows.map((row: any) => row.original.user.id)
    const dayName = dayDiff === 0 ? 'Today' : dayDiff === -1 ? 'Yesterday' : 'Tomorrow'
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: `${dayName}'s Registrations`,
    })
  }

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <DayNavigationBar
          dayDiff={dayDiff}
          onPrevClick={() => setDayDiff(value => value - 1)}
          onNextClick={() => setDayDiff(value => value + 1)}
        />
      </Grid>
      {loading && (
        <Grid item xs={12}>
          <Loading />
        </Grid>
      )}
      <Grid item xs={12} style={{ display: loading ? 'none' : 'block' }}>
        <ExtendedPagedTable
          history={history}
          location={location}
          table={table}
          columns={columns}
          columnState={columnState}
          tableName={tableName}
          searchPlaceholder="Search Registrations"
          buttons={
            <>
              <TableMenuButton
                startIcon={<MessageOutlinedIcon />}
                onClick={onMessageVolunteers}
                disabled={!selectedRowsCount}
                rightMargin
              >
                Message
              </TableMenuButton>
              <ExportRegistrantsButton data={selectedRows} disabled={!selectedRowsCount} organization={organization} />
            </>
          }
          useGlobalSearch
        />
      </Grid>
    </Grid>
  )
}
