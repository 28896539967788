import React, { useCallback, useState } from 'react'
import format from 'utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { Button } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import useExportStyles from 'export/useExportStyles'

const filename = 'champions.csv'
const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Phone', key: 'phoneNumber' },
  { label: 'Home Phone', key: 'homePhoneNumber' },
  { label: 'Hours', key: 'hours' },
  { label: 'Donations', key: 'donations' },
  { label: 'Last Activity', key: 'latestActivity' },
]
const formatData = champions =>
  champions.map(o => {
    return {
      firstName: o.user.givenName,
      lastName: o.user.familyName,
      email: o.user.email,
      phoneNumber: format.phoneNumber(o.user.phoneNumber),
      homePhoneNumber: format.phoneNumber(o.user.homePhoneNumber),
      hours: format.fixedPoint(o.volunteering.totalVolunteerHours, 1),
      donations: format.fixedPoint(o.donation.totalDonations / 100, 2),
      latestActivity: o.volunteering.latestActivity,
    }
  })

const ExportChampionsButtonOld = ({ data, disabled, includeDonations }) => {
  const filteredHeaders = includeDonations ? headers : headers.filter(header => header.key !== 'donations')
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const classes = useExportStyles()
  const [formattedData, setFormattedData] = useState([])
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  return (
    <Button variant="contained" color="secondary" disabled={disabled} onClick={createFormattedData}>
      <GetAppIcon style={{ marginRight: 5 }} />
      <CSVLink
        style={
          disabled
            ? { textDecoration: 'none', color: 'rgba(0, 0, 0, 0.26)' }
            : { textDecoration: 'none', color: '#fff' }
        }
        onClick={createFormattedData}
        filename={fileNameWithDate}
        headers={filteredHeaders}
        data={formattedData}
        className={classes.fill}
      />
      Export
    </Button>
  )
}

export default ExportChampionsButtonOld
