import React from 'react';
import format from 'utils/format';
import ExportCsvMenuItem from 'components/table/ExportCsvMenuItem';

const filename = "organizations.csv";
const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Created', key: 'createdAt' },
    { label: 'Members', key: 'totalMembers' },
    { label: 'Volunteers', key: 'totalVolunteers' },
    { label: 'Activities', key: 'totalActivities' },
    { label: 'Hours', key: 'totalHoursVolunteered' }
];
const useFormattedData = organizations => organizations.map(v => {
    v.totalHoursVolunteered = format.hours(v.totalHoursVolunteered, 2);
    return v;
})

const exportConfig = {
    filename,
    headers,
    useFormattedData
};

export default function ExportOrganizationsActionItem({ organizations }) {
    return (
        <ExportCsvMenuItem 
            data={organizations}
            config={exportConfig}
        />
    )
}