import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import ContactSupportOutlinedIcon from '@material-ui/icons/ContactSupportOutlined'
import { Grid, Tooltip } from '@material-ui/core'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { ContactCell, ExpandableCell, NameCell, contactSort } from 'core/table/cells/modern'
import { PhoneNumberCell, CellWithTooltip } from 'core/table/cells'
import { DYNAMIC_TEXT } from 'core/table/filters'
import { WaiversCell } from '../components/WaiversCell'
import { encodeOccurrenceFromEvent } from 'Event/helpers/encodeOccurrence'
import { useUISettings } from 'hooks/useUISettings'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  contactColumn: {
    '&>div': {
      width: '70px',
    },
  },
  emailColumn: {
    '&>div': {
      width: '210px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  eventNameColumn: {
    '&>div': {
      width: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  roleNameColumn: {
    '&>div': {
      width: '100px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  shiftNameColumn: {
    '&>div': {
      width: '200px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  statusColumn: {
    '&>div': {
      width: '100px',
    },
  },
  groupsColumn: {
    '&>div': {
      width: '310px',
    },
  },
  mobileColumn: {
    '&>div': {
      width: '150px',
    },
  },
  homeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  statusHeaderTooltip: {
    height: '16px',
    marginBottom: '-5px',
  },
})

const STATUS_TOOLTIP =
  'A registration’s status is determined by whether or not the volunteer checks into the event for which they registered. If a volunteer registers for an event, and then checks into a different event or opportunity, they will appear as Absent on this table.'

export function useRegistrantColumns() {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'user',
            Cell: ({ value: { id, givenName, familyName } }: any) => (
              <Link className={classes.link} to={`/champion/${id}`}>
                {givenName} {familyName}
              </Link>
            ),
            disableFilters: true,
            className: classes.nameColumn,
            sortType: contactSort,
          },
          {
            id: 'contact',
            Header: (
              <Grid container justify="space-between" style={{ fontSize: '20px', width: '40px' }}>
                <AlternateEmailIcon fontSize="inherit" htmlColor="#001B3F" />
                <PhoneIcon fontSize="inherit" htmlColor="#001B3F" />
              </Grid>
            ),
            accessor: 'user',
            Cell: ContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.contactColumn,
          },
          {
            id: 'firstName',
            Header: 'First Name',
            accessor: 'user.givenName',
            Cell: NameCell,
            className: classes.firstNameColumn,
            filter: DYNAMIC_TEXT,
          },
          {
            id: 'lastName',
            Header: 'Last Name',
            accessor: 'user.familyName',
            Cell: NameCell,
            className: classes.lastNameColumn,
            filter: DYNAMIC_TEXT,
          },
          {
            id: 'eventName',
            Header: 'Event',
            accessor: 'opportunity.name',
            Cell: ({ row }: any) => {
              const { occurence, organization } = row.original

              return (
                <Link
                  className={classes.link}
                  to={`/events/${encodeOccurrenceFromEvent(occurence)}/${organization.id}`}
                >
                  {occurence.name}
                </Link>
              )
            },
            filter: DYNAMIC_TEXT,
            className: classes.eventNameColumn,
          },
          {
            id: 'roleName',
            Header: 'Role',
            accessor: 'role.name',
            Cell: CellWithTooltip,
            filter: DYNAMIC_TEXT,
            className: classes.roleNameColumn,
          },
          {
            id: 'shiftName',
            Header: 'Shift',
            // @ts-ignore
            accessor: ({ timeshift: { name, timeStart, timeEnd } }: any) => `${name} (${timeStart} - ${timeEnd})`,
            filter: DYNAMIC_TEXT,
            className: classes.shiftNameColumn,
          },
          {
            id: 'status',
            Header: (
              <Grid container justify={'flex-start'}>
                <Tooltip title={STATUS_TOOLTIP} arrow>
                  <Grid item>
                    Status
                    <ContactSupportOutlinedIcon className={classes.statusHeaderTooltip} />
                  </Grid>
                </Tooltip>
              </Grid>
            ),
            accessor: 'status',
            disableFilters: true,
            className: classes.statusColumn,
          },
          {
            id: 'prerequisites',
            Header: 'Waivers',
            accessor: 'prerequisites',
            Cell: WaiversCell,
            disableFilters: true,
            className: classes.prerequisitesColumn,
          },
          {
            id: 'email',
            Header: 'Email',
            accessor: 'user.email',
            filter: DYNAMIC_TEXT,
            className: classes.emailColumn,
          },
          {
            id: 'mobile',
            Header: 'Mobile Phone',
            accessor: 'user.phoneNumber',
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            className: classes.mobileColumn,
          },
          {
            id: 'homePhoneNumber',
            Header: 'Home Phone',
            accessor: 'user.homePhoneNumber',
            className: classes.homeColumn,
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
          },
          {
            id: 'groups',
            Header: 'Groups',
            accessor: ({ groups }: any) => (groups || []).map(({ name }: any) => name).join(', '),
            Cell: ExpandableCell,
            disableFilters: true,
            className: classes.groupsColumn,
          },
        ],
        {
          showAdminItems,
        },
      ),
    [
      classes.contactColumn,
      classes.emailColumn,
      classes.eventNameColumn,
      classes.firstNameColumn,
      classes.groupsColumn,
      classes.homeColumn,
      classes.lastNameColumn,
      classes.link,
      classes.mobileColumn,
      classes.nameColumn,
      classes.prerequisitesColumn,
      classes.roleNameColumn,
      classes.shiftNameColumn,
      classes.statusColumn,
      classes.statusHeaderTooltip,
      showAdminItems,
    ],
  )
}

export default useRegistrantColumns
