import { filter, map } from 'lodash'
import React, { useMemo } from 'react'
import { useFeatureEnabled } from 'core/feature/hooks'
import { makeStyles } from '@material-ui/core/styles'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import HomeIcon from '@material-ui/icons/Home'
import { Grid } from '@material-ui/core'
import cn from 'classnames'

import { IconComponent } from 'civic-champs-shared/core/icon'
import { INTERNAL_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

import { useHasRole } from 'auth/hooks'
import {
  ConsolidatedContactCell,
  ContactCell,
  contactSort,
  ExpandableCell,
  NameCell,
  PersonLinkCell,
} from 'core/table/cells/modern'
import { CurrencyCell, DateCell, FixedPointCell, PhoneNumberCell } from 'core/table/cells'
import { DYNAMIC_NUMBER, DYNAMIC_TEXT } from 'core/table/filters'
import { createStringSort } from 'core/table/utils'
import { FIRST_LAST_NAME, GROUPS } from 'champion/utils/filters'
import { useGroups } from 'group/hooks/useGroups'
import format from 'utils/format'

export const useStyles = makeStyles(theme => ({
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '160px',
    },
  },
  firstNameColumn: {
    '&>div': {
      width: '90px',
    },
  },
  lastNameColumn: {
    '&>div': {
      width: '110px',
    },
  },
  contactColumn: {
    '&>div': {
      width: '100px',
    },
  },
  contactColumnHeader: {
    fontSize: '20px',
    width: '100px',
    color: '#001B3F',
  },
  contactIcon: {
    fontSize: 'inherit',
    color: '#000',
    cursor: 'default',
  },
  consolidatedContactColumn: {
    '&>div': {
      width: '50px',
    },
  },
  emailColumn: {
    '&>div': {
      width: '210px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  mobileColumn: {
    '&>div': {
      width: '150px',
    },
  },
  homeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  groupsColumn: {
    '&>div': {
      width: '310px',
    },
  },
  hoursColumn: {
    '&>div': {
      width: '100px',
    },
  },
  donationsColumn: {
    '&>div': {
      width: '110px',
    },
  },
  activityColumn: {
    '&>div': {
      width: '110px',
    },
  },
  addressColumn: {
    '&>div': {
      width: '300px',
    },
  },
}))

export default function useChampionColumns() {
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const isDonationEnabled = useFeatureEnabled('Donations')
  const classes = useStyles()
  const { groups } = useGroups(undefined, false)

  return useMemo(
    () =>
      filter(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            hideColumn: !isInternalAdmin,
            className: classes.idColumn,
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'user',
            Cell: PersonLinkCell,
            filter: FIRST_LAST_NAME,
            className: classes.nameColumn,
            sortType: contactSort,
          },
          {
            id: 'firstName',
            Header: 'First Name',
            accessor: 'user.givenName',
            Cell: NameCell,
            filter: DYNAMIC_TEXT,
            className: classes.firstNameColumn,
            sortType: createStringSort('firstName'),
          },
          {
            id: 'lastName',
            Header: 'Last Name',
            accessor: 'user.familyName',
            Cell: NameCell,
            filter: DYNAMIC_TEXT,
            className: classes.lastNameColumn,
            sortType: createStringSort('lastName'),
          },
          {
            id: 'contact',
            Header: (
              <Grid container justify="space-between" className={classes.contactColumnHeader}>
                <Grid item>
                  <AlternateEmailIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <PhoneIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <HomeIcon fontSize="inherit" />
                </Grid>
                <Grid item>
                  <span className={cn('material-symbols-outlined', classes.contactIcon)}>emergency</span>
                </Grid>
              </Grid>
            ),
            accessor: 'user',
            Cell: ContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.contactColumn,
          },
          {
            id: 'consolidatedContact',
            Header: (
              <Grid container justify="center">
                <Grid item>
                  <IconComponent name="contact" customColor="#000000" height={18} width={18} />
                </Grid>
              </Grid>
            ),
            accessor: 'user',
            Cell: ConsolidatedContactCell,
            disableSortBy: true,
            disableFilters: true,
            className: classes.consolidatedContactColumn,
          },
          {
            id: 'email',
            Header: 'Email',
            accessor: 'user.email',
            filter: DYNAMIC_TEXT,
            className: classes.emailColumn,
            sortType: createStringSort('email'),
          },
          {
            id: 'mobile',
            Header: 'Mobile Phone',
            accessor: 'user.phoneNumber',
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            className: classes.mobileColumn,
            sortType: createStringSort('mobile'),
          },
          {
            id: 'homePhoneNumber',
            Header: 'Home Phone',
            accessor: 'user.homePhoneNumber',
            className: classes.homeColumn,
            Cell: PhoneNumberCell,
            filter: DYNAMIC_TEXT,
            sortType: createStringSort('homePhoneNumber'),
          },
          {
            id: 'groups',
            Header: 'Groups',
            accessor: 'groups',
            Cell: ExpandableCell,
            filter: GROUPS,
            className: classes.groupsColumn,
            sortType: createStringSort('groups'),
            getFilterAutocomplete: () => map(groups, 'name'),
          },
          {
            id: 'hours',
            Header: 'Hours',
            accessor: 'volunteering.totalVolunteerHours',
            Cell: FixedPointCell,
            filter: DYNAMIC_NUMBER,
            className: classes.hoursColumn,
          },
          {
            id: 'donations',
            Header: 'Donations',
            accessor: 'volunteer.donation.totalDonations',
            Cell: CurrencyCell,
            hideColumn: !isDonationEnabled || !isSuperAdmin,
            filter: DYNAMIC_NUMBER,
            className: classes.donationsColumn,
          },
          {
            id: 'latestActivity',
            Header: 'Latest Activity',
            accessor: 'volunteering.latestActivity',
            Cell: DateCell,
            disableFilters: true,
            className: classes.activityColumn,
          },
          {
            id: 'address',
            Header: 'Address',
            accessor: ({ user }: any) => format.address(user.address),
            Cell: ExpandableCell,
            disableFilters: true,
            className: classes.addressColumn,
          },
        ],
        column => !column.hideColumn,
      ),
    [
      classes.activityColumn,
      classes.addressColumn,
      classes.consolidatedContactColumn,
      classes.contactColumn,
      classes.contactColumnHeader,
      classes.contactIcon,
      classes.donationsColumn,
      classes.emailColumn,
      classes.firstNameColumn,
      classes.groupsColumn,
      classes.homeColumn,
      classes.hoursColumn,
      classes.idColumn,
      classes.lastNameColumn,
      classes.mobileColumn,
      classes.nameColumn,
      groups,
      isDonationEnabled,
      isInternalAdmin,
      isSuperAdmin,
    ],
  )
}
