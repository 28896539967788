import { useCallback } from "react"

import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'

//TODO this doesn't match how things are broken up in useChangeRolePrompt :-/
const useExpelAdmin = (eagerRemove) => {
    const [request,] = useFetch()
    const createApiNotification = useCreateApiNotification()

    return useCallback( async (membership) => {
        const memberName = `${membership.person.givenName} ${membership.person.familyName}`

        const undo = eagerRemove(membership)
        const notification = createApiNotification(`Ending ${memberName}'s membership...`)
        try {
            await request({
                method: 'del',
                url: `/admins/${membership.id}`
            })

            notification.onSuccess('Member removed!')
        } catch(error) {
            notification.onError(`Could not end ${memberName}'s membership!`, error)
            undo()
        }
    }, [createApiNotification, eagerRemove, request])
}

export default useExpelAdmin
