import React, { useEffect } from 'react'
import { StatusCodes } from 'http-status-codes'
import { useCurrentOrg } from 'auth/hooks'
import { Grid, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import Loading from 'components/Loading'
import * as yup from 'yup'
import { Remove, Add } from '@material-ui/icons'
import { TextField } from 'formik-material-ui'
import { IconButton, Button as MuiButton } from '@material-ui/core'
import { Field } from 'formik'
import Popover from 'core/popover/Popover'
import {
  useStyles,
  useGetRoles,
  useUpdateRoleAndMakeDefault,
  useCreateDefaultRole,
  useDeleteRoleAssignment,
} from './hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import * as _ from 'lodash'

const schema = yup.object().shape({
  name: yup.string().required('Required'),
})

export default function RoleEdit(props) {
  const [isAdding, setIsAdding] = React.useState(false)
  const styles = useStyles()
  const organization = useCurrentOrg()

  const [getRoles, { result: roles, loading }] = useGetRoles(organization.id)
  const [updateRole] = useUpdateRoleAndMakeDefault(organization.id)
  const [createRole] = useCreateDefaultRole(organization.id)
  const [deleteRole, { loading: deleteLoading }] = useDeleteRoleAssignment(organization.id)
  const errorMsg = useErrorNotification()
  const numRoles = _.size(roles)
  useEffect(() => {
    getRoles()
  }, [getRoles])

  const handleSubmit = async values => {
    try {
      await createRole(values)
    } catch (err) {
      // handle name already exists
      if (err.response.status === StatusCodes.CONFLICT) {
        await updateRole(values)
      } else {
        errorMsg('There was a problem adding the role. Please try again.', err.response.data.message)
      }
    }
    setIsAdding(false)
    getRoles()
  }

  const removeRole = async role => {
    try {
      await deleteRole(role.id)
    } catch (err) {
      console.error(err)
    }
    getRoles()
  }

  return (
    <>
      <Typography variant="h4">Organization Default Roles</Typography>
      {loading || deleteLoading ? (
        <Loading />
      ) : (
        <Grid container item xs={12} direction="column">
          {roles &&
            roles.map(role => (
              <Grid container item direction="row" alignItems="center" className={styles.role} key={role.name}>
                <Grid item xs={8} sm={6} md={4}>
                  <span>{role.name}</span>
                </Grid>
                <Grid item xs={4}>
                  <IconButton
                    className={styles.removeButton}
                    onClick={() => removeRole(role)}
                    disabled={deleteLoading || numRoles < 2}
                  >
                    <Remove />
                  </IconButton>
                </Grid>
                {numRoles < 2 && (
                  <Grid item xs={4}>
                    <Popover>
                      <h3>At least one default role required</h3>
                      <br />
                      <div>Each organization must have at least one default role.</div>
                      <div>If you wish to delete this role, you must add another default role first.</div>
                      <br />
                    </Popover>
                  </Grid>
                )}
              </Grid>
            ))}
          {isAdding ? (
            <Formik initialValues={{ name: '' }} onSubmit={handleSubmit} validationSchema={schema}>
              {({ submitForm, isSubmitting }) => {
                return (
                  <Form>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Field
                          className={styles.field}
                          name="name"
                          variant="outlined"
                          component={TextField}
                          placeholder="Role Name"
                          label="Role Name"
                          disabled={isSubmitting}
                        />
                      </Grid>
                      <Grid item>
                        <MuiButton variant="contained" color="primary" onClick={submitForm} disabled={isSubmitting}>
                          Add new role
                        </MuiButton>
                      </Grid>
                    </Grid>
                    <MuiButton
                      variant="text"
                      color="secondary"
                      onClick={() => setIsAdding(false)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </MuiButton>
                  </Form>
                )
              }}
            </Formik>
          ) : (
            <Grid item>
              <MuiButton variant="contained" color="secondary" onClick={() => setIsAdding(true)}>
                <Add />
                Add role
              </MuiButton>
            </Grid>
          )}
        </Grid>
      )}
    </>
  )
}
