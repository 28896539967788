import * as _ from 'lodash'
import {
  CreateEventFields,
  EditEventFields,
  OpportunityOccurrencePersonGroupPayloadLessOccurrenceId,
} from '../../../interfaces/interfaceCreateEditEvent'
import { OpportunityVisibility } from '../../../interfaces'

export const mapGroupsPayload = (
  event: CreateEventFields | EditEventFields,
): OpportunityOccurrencePersonGroupPayloadLessOccurrenceId[] => {
  const visibilityGroups =
    event.visibility === OpportunityVisibility.SELECT_GROUPS_ONLY ? event.visibilityGroups || [] : []
  const onboardingGroups =
    event.visibility !== OpportunityVisibility.SELECT_GROUPS_ONLY ? event.onboardingGroups || [] : []
  return [...visibilityGroups, ...onboardingGroups].filter(group => !group.lockedTag)
}

export const mapEventPayload = (event: CreateEventFields | EditEventFields) => {
  const groups = mapGroupsPayload(event)

  const eventPayload = _.cloneDeep({
    ...event,
    groups,
  })
  const { lng, lat } = eventPayload.geofencing.location.coordinates;
  _.set(eventPayload, 'geofencing.location.coordinates', [lng, lat]);
  return eventPayload
}
