import { size, map, isEmpty } from 'lodash'
import React, { Fragment, useLayoutEffect, useState, useCallback } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  Divider,
  Typography,
  Button,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import Alert from '@material-ui/lab/Alert'
import useStyles from '../hooks/useMyUserStyles'
import format from 'utils/format'
import getErrorMessage from 'utils/getErrorMessage'
import cn from 'classnames'

export default function SelectUserStep(props) {
  const classes = useStyles()

  let { userLabel = 'User', users, loading, error, providedContact, onBack, onSelectExisting, onSelectNew } = props

  const [selected, setSelected] = useState(null)
  const [sendInvite, setSendInvite] = useState(true)
  useLayoutEffect(() => {
    setSelected(size(users) === 1 ? users[0] : null)
  }, [users, setSelected])

  const handleClickSelect = useCallback(() => {
    if (selected && onSelectExisting) onSelectExisting(selected, sendInvite)
  }, [selected, onSelectExisting, sendInvite])

  if (loading) {
    return (
      <>
        <CircularProgress />
        <div>
          <Button onClick={onBack} className={classes.actionButton}>
            Back
          </Button>
        </div>
      </>
    )
  } else if (error) {
    return (
      <>
        <Alert severity="error" color="error">
          {getErrorMessage(error)}
        </Alert>
        <div>
          <Button onClick={onBack} className={classes.actionButton}>
            Back
          </Button>
        </div>
      </>
    )
  } else if (isEmpty(users)) {
    return (
      <>
        <Typography>No existing user was found with matching contact information.</Typography>
        <div>
          <Button onClick={onBack} className={classes.actionButton}>
            Back
          </Button>
          <Button variant="contained" color="secondary" onClick={onSelectNew} className={cn(classes.actionButton)}>
            Create New {userLabel}
          </Button>
        </div>
      </>
    )
  }

  return (
    <>
      <List className={classes.userList}>
        {map(users, (user, index) => {
          const name = `${user.givenName} ${user.familyName}`
          const email = !!providedContact.email && user.email === providedContact.email ? user.email : null
          const phone =
            !!providedContact.phone && user.phoneNumber === providedContact.phone
              ? format.phoneNumber(user.phoneNumber)
              : null

          return (
            <Fragment key={user.id}>
              <ListItem selected={user === selected} onClick={() => setSelected(user)}>
                <ListItemText
                  primary={name}
                  secondary={
                    <>
                      {email && <Typography>{email}</Typography>}
                      {phone && <Typography>{phone}</Typography>}
                    </>
                  }
                />
              </ListItem>
              {index < users.length - 1 && <Divider variant="inset" component="li" />}
            </Fragment>
          )
        })}
      </List>
      <div>
        {/* TODO: perhaps change the code */}
        <FormControlLabel
          control={<Switch name="sendInvite" checked={sendInvite} onChange={() => setSendInvite(!sendInvite)} />}
          label="Send Associated Questionnaires"
        />
      </div>
      <div>
        <Button onClick={onBack} className={classes.actionButton}>
          Back
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={selected == null}
          onClick={handleClickSelect}
          endIcon={<PersonAddIcon />}
          className={cn(classes.actionButton)}
        >
          Add {userLabel}
        </Button>
      </div>
    </>
  )
}
