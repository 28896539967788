import { ClickAwayListener, Grid, IconButton, makeStyles, Tooltip, Typography } from '@material-ui/core'
import React, { useState } from 'react'

import IconComponent from 'civic-champs-shared/core/icon'
import { getSatisfactionExplanation } from '../utils/satisfactions'

const useStyles = makeStyles(() => ({
  iconButton: {
    padding: 11,
  },
  tooltip: {
    backgroundColor: '#fff',
    color: '#000',
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '5px',
    border: '1px #E0E3E3 solid',
    padding: '8px',
    minWidth: '200px',
  },
  tooltipHeader: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  arrow: {
    color: '#fff',
  },
  tooltipGridItem: {
    padding: 10,
  },
  smileyGridContainer: {
    paddingLeft: 20,
    paddingRight: 20,
  },
  reflectionGridContainer: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
    padding: 5,
    fontWeight: 600,
  },
}))

const ReflectionIcon = ({ color }: { color: string }) => (
  <IconComponent name="reflection" customColor={color} height={18} width={18} />
)

interface SmileyIconProps {
  rating: number
  color?: string
  height?: number
  width?: number
}

export const SmileyIcon = ({ rating, color, height = 18, width = 18 }: SmileyIconProps) => {
  const size = { height, width }
  if (rating > 0.5) return <IconComponent name="emoticon_excited" customColor={color || '#4985DF'} {...size} />
  else if (rating >= 0) return <IconComponent name="emoticon_happy" customColor={color || '#00A568'} {...size} />
  else if (rating >= -0.5) return <IconComponent name="emoticon_sad" customColor={color || '#F4BF2C'} {...size} />

  return <IconComponent name="emoticon_frown" customColor={color || '#BA1B1B'} {...size} />
}

const SmileyExplanation = ({ rating }: { rating: number }) => {
  return <div>{getSatisfactionExplanation(rating)}</div>
}

export const getSmileyOptions = () => [
  { title: 'Very Satisfied', value: 1, avatar: <SmileyIcon rating={1} /> },
  { title: 'Satisfied', value: 0.5, avatar: <SmileyIcon rating={0.5} /> },
  { title: 'Neutral', value: -0.5, avatar: <SmileyIcon rating={-0.5} /> },
  { title: 'Dissatisfied', value: -1, avatar: <SmileyIcon rating={-1} /> },
]

const SatisfactionExplanation = ({ rating }: { rating: number }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={0} direction="column" alignItems="center" className={classes.smileyGridContainer}>
      <Grid item className={classes.tooltipGridItem}>
        <SmileyIcon rating={rating} width={45} height={45} />
      </Grid>
      <Grid item className={classes.tooltipGridItem}>
        <SmileyExplanation rating={rating} />
      </Grid>
      <Grid item className={classes.tooltipGridItem}>
        <Grid container spacing={2} direction="row">
          {[-1, -0.5, 0.5, 1].map(rating => (
            <Grid item key={rating} className={classes.tooltipGridItem}>
              <SmileyIcon rating={rating} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  )
}

const ReflectionPopup = ({ reflection, user }: { reflection: string; user: { id: number } }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={0} direction="column" justify="flex-start" className={classes.reflectionGridContainer}>
      <Grid item className={classes.tooltipGridItem}>
        <Typography className={classes.tooltipHeader}>Reflection:</Typography>
      </Grid>
      <Grid item className={classes.tooltipGridItem}>
        <Typography className={classes.tooltipHeader}>{reflection}</Typography>
      </Grid>
    </Grid>
  )
}

const TooltipPopup = ({ content, children }: { content: JSX.Element; children: JSX.Element }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <Tooltip
          classes={{ tooltip: classes.tooltip, arrow: classes.arrow }}
          open={open}
          onClose={handleTooltipClose}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={content}
          arrow
          placement="left-start"
        >
          <IconButton className={classes.iconButton} onClick={handleTooltipOpen}>
            {children}
          </IconButton>
        </Tooltip>
      </div>
    </ClickAwayListener>
  )
}

export const SatisfactionHeader = () => {
  const classes = useStyles()

  return (
    <Grid container justify="space-between">
      <Grid item>
        <IconButton className={classes.iconButton} disabled>
          <ReflectionIcon color="#000" />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton className={classes.iconButton} disabled>
          <SmileyIcon rating={1} color="#000" />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export const SatisfactionCell = ({ cell }: any) => {
  const activity = cell.row.original
  const { reflection, volunteerSatisfaction, user } = activity

  return (
    <Grid container justify="space-between">
      <Grid item>
        {reflection && (
          <TooltipPopup content={<ReflectionPopup reflection={reflection} user={user} />}>
            <ReflectionIcon color="#4985DF" />
          </TooltipPopup>
        )}
      </Grid>
      <Grid item>
        {volunteerSatisfaction && (
          <TooltipPopup content={<SatisfactionExplanation rating={volunteerSatisfaction} />}>
            <SmileyIcon rating={volunteerSatisfaction} />
          </TooltipPopup>
        )}
      </Grid>
    </Grid>
  )
}

export default SatisfactionCell
