import {CreateEventForm, EventFormKeys} from '../../interfaces/interfaceCreateEditEvent'
import { OpportunityVisibility } from 'Event/interfaces'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'

export enum ACTION_TYPES {
  CHANGE_STEP = 'CHANGE_STEP',
  CHANGE_EVENT_FIELD = 'CHANGE_EVENT_FIELD',
  CLEAR_EVENT_FORM = 'CLEAR_EVENT_FORM'
}

const {
  CHANGE_STEP,
  CHANGE_EVENT_FIELD,
  CLEAR_EVENT_FORM
} = ACTION_TYPES

const initialState: CreateEventForm = {
  currentStep: 1,
  event: {
    name: '',
    startsAt: '',
    endsAt: '',
    recurring_type: 5,
    is_recurring: false,
    dates: [],
    description: '',
    isTest: false,
    instructions: '',
    contact_name: '',
    contact_email: '',
    contact_phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    geofencing: DEFAULT_GEOFENCING,
    timeshifts: [
      {
        time_start: '9:00 AM',
        time_end: '10:00 AM',
        name: 'Shift 1',
        roles: [
          {
            name: '',
            available: null,
          }
        ]
      }
    ],
    requiredCredentials: [],
    visibility: OpportunityVisibility.PUBLIC,
    questionSets: [],
    visibilityGroups: [],
    onboardingGroups: [],
  }
}

export const changeStep = (step: number) => ({
  type: CHANGE_STEP,
  step,
})

export const setEventField = (key: EventFormKeys, val: boolean | string | number) => ({
  type: CHANGE_EVENT_FIELD,
  val,
  key
})

export const clearEventForm = () => ({
  type: CLEAR_EVENT_FORM
})

export default function reducer(state = initialState, action: any): CreateEventForm {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        currentStep: action.step,
        event: {...state.event}
      }
    case CHANGE_EVENT_FIELD:
      return {
        ...state,
        event: {
          ...state.event,
          [action.key]: action.val
        }
      }
    case CLEAR_EVENT_FORM:
      return {
        ...state,
        event: {
          ...initialState.event,
          timeshifts: [
            {
              time_start: '9:00 AM',
              time_end: '10:00 AM',
              name: 'Shift 1',
              roles: [
                {
                  name: '',
                  available: null,
                }
              ]
            }
          ]
        }
      }
    default:
      return state
  }
}
