import { useCallback } from 'react'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import MessageRecipientsPromptOld from '../components/MessageRecipientsPrompt.old'
import MessageRecipientsPrompt, { MessagingContact } from '../components/MessageRecipientsPrompt'
import { useFeatureEnabled } from 'core/feature/hooks'

const useMessageRecipientsPrompt = () => {
  const newMessagingDialogEnabled = useFeatureEnabled('NewMessagingDialog')
  const prompt = usePrompt(newMessagingDialogEnabled ? MessageRecipientsPrompt : MessageRecipientsPromptOld)
  return useCallback(
    ({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle,
    }: {
      recipientPersonIds: number[]
      getSearchableContacts: () => Promise<MessagingContact[]>
      autocompleteSectionTitle?: string
    }) => {
      // @ts-ignore
      prompt.show({ recipientPersonIds, getSearchableContacts, autocompleteSectionTitle })
    },
    [prompt],
  )
}

export default useMessageRecipientsPrompt
