import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import Loading from 'components/Loading'
import { Field, Form, Formik } from 'formik'
import { get } from 'lodash'
import React from 'react'
import yup from 'civic-champs-shared/utils/yup'
import { useUpdateTask } from '../hooks'
import TaskLocationField from './TaskLocationField'

const useStyles = makeStyles(theme => ({
    dialog: {
        zIndex: '1200 !important',
        textAlign: 'center',
    },
    content: {
        width: '400px'
    },
    submit: {
        margin: theme.spacing(2)
    },
}))

const locationSchema = yup.object({
    location: yup.object().required('Location is required')
})

export default function EditTaskFieldPrompt(props) {
    const classes = useStyles()
    const { task, showing, close, complete, initialValue } = props
    const [updateTaskDetails, updateLoading] = useUpdateTask()
    const showError = useErrorNotification()

    const handleSubmit = async (values, actions) => {
        try {
            await updateTaskDetails(task, { locationId: get(values.location, 'id') })
            complete()
        } catch(err) {
            showError('There was a problem updating the task', err)
        }
    }

    const loading = updateLoading

    return (
        <Dialog
            open={showing}
            onClose={close}
            className={classes.dialog}
        >
            <DialogTitle>
                Edit Task
            </DialogTitle>
            <DialogContent className={classes.content}>
            {
                loading ?
                <Loading />
                :
                <Formik initialValues={{ fieldValue: initialValue }} onSubmit={handleSubmit} validationSchema={locationSchema}>
                    {({ isSubmitting, isValid, values }) => (
                        <Form>
                            <Field
                                component={TaskLocationField}
                                name='location'
                                recipient={task.recipient}
                            />
                            <Button
                                variant='contained'
                                color='secondary'
                                type='submit'
                                className={classes.submit}
                                disabled={!isValid || isSubmitting}
                            >
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            }
            </DialogContent>
        </Dialog>
    )
}
