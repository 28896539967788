import { size } from 'lodash'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import DuplicateButton from 'civic-champs-shared/core/duplicate-button'

import SmallPlusButton from '../../../../../components/small-plus-button/index'
import InputComponent from '../../../../../components/input/index'
import RoleListItem from './role-item/index'
import Button from '../../../../../../components/button/index'
import { TimeRangePicker, validateTimeSelection } from '../../../../../components/time-range-picker'
import { INIT_VALIDATOR } from '../../../../../helpers/validation'
import { toCopyArray } from '../../../../../helpers/toCopyArray'

import './index.scss'

const emptyRoles = {
  name: '',
  available: null,
  is_editable: true
}

const defaultErrorMessages = {
  name: ''
}

const ShiftItem = props => {
  const {
    shift,
    onRemoveShift,
    onDuplicateShift,
    onChangeField,
    isClickedToNextStep,
    onChangeValidField,
    enableShiftDelete,
    timeshifts,
    isShiftEditingDisabled,
    editMode = false
  } = props

  const [roleState, setRoleState] = useState(shift.roles)
  const [roleStateWithValidField, setRoleStateWithValidField] = useState([])

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  useEffect(() => {
    onChangeField(toCopyArray(roleState), 'roles')
  }, [roleState])

  useEffect(() => {
    isClickedToNextStep !== null &&
    isClickedToNextStep &&
    !shift.name &&
    onChangeErrorMessage('name', 'The name field is required')
  }, [isClickedToNextStep])

  useEffect(() => {
    const checkAllShiftName = timeshifts.every(shift => shift.name)
    const timesValid = timeshifts.every(shift => !validateTimeSelection(shift.time_start, shift.time_end))
    onChangeValidField(checkAllShiftName && !errorMessages.name && !isHaveErrorAtRoles() && timesValid)
  }, [errorMessages.name, roleStateWithValidField, shift.roles, shift.time_start, shift.time_end])

  useEffect(() => {
    !shift.name && isHaveErrorAtRoles() && onChangeValidField(null)
  }, [])

  const handleAddNewRoles = () => {
    setRoleState([...roleState, { ...emptyRoles }])
  }

  const handleRemoveRoles = idx => () => {
    if (roleState.length === 1) {
      return
    }
    const roles = [...roleState]
    roles.splice(idx, 1)
    setRoleState([...roles])
  }

  const handleChangeRoles = idx => (value, type) => {
    const updatedRoles = [...roleState]
    updatedRoles[idx][type] = value
    setRoleState(updatedRoles)
  }

  const handleChangeNameField = val => {
    onChangeField(val, 'name')
  }

  const handleChangeTimeField = (startTime, endTime) => {
    onChangeField(endTime, 'time_end')
    onChangeField(startTime, 'time_start')
  }

  const handleChangeValidField = (idx) => value => {
    const updatedRoles = toCopyArray(roleState)
    updatedRoles[idx]['is_valid'] = value
    setRoleStateWithValidField(updatedRoles)
  }

  const isHaveErrorAtRoles = () => {
    const isHaveFalseVal = roleStateWithValidField.some(i => i.is_valid === false)
    const isHaveNullVal = roleStateWithValidField.some(i => i.is_valid === null)
    return isHaveFalseVal || isHaveNullVal
  }

  const toDisableRoleEditing = (role) => {
    if (!editMode) {
      return false
    }

    return !role.is_editable
  }

  const toEnableDeleteRole = (role) => {
    if (!role.is_editable) {
      return false
    }

    if (size(roleState) <= 1) {
      return false
    }

    return true
  }

  const shiftItem = classnames(
    'shift-list-item',
  )

  return (
    <div className={shiftItem}>
      <div className="shift-role-list-wrap">
        <div>
          <div className="shift-time-row">
            <div className="shift-time-row__item">
              <TimeRangePicker
                startTime={shift.time_start}
                endTime={shift.time_end}
                onChange={handleChangeTimeField}
                disabled={isShiftEditingDisabled}
              />
            </div>
            <div className="shift-time-row__item">
              <InputComponent
                label="Shift Name*"
                value={shift.name}
                onChange={handleChangeNameField}
                onBlur={onValidateField('name', { required: true, maxLength: 255 })}
                hasError={!!errorMessages.name}
                errorMessage={errorMessages.name}
                disabled={isShiftEditingDisabled}
              />
            </div>
          </div>
          <div className="shift-role-list">
            {roleState.map((role, idx) => (
              <RoleListItem
                editMode={editMode}
                timeshifts={timeshifts}
                key={idx}
                role={role}
                enableDelete={toEnableDeleteRole(role)}
                onChangeRoleField={handleChangeRoles(idx)}
                onRemoveRole={handleRemoveRoles(idx)}
                onChangeValidField={handleChangeValidField(idx)}
                isClickedToNextStep={isClickedToNextStep}
                disabled={toDisableRoleEditing(role)}
              />
            ))}
            <SmallPlusButton
              title='Add another Role'
              className="add-role-button-wrap"
              onClick={handleAddNewRoles}
            />
          </div>
        </div>
        <div className="shift-buttons-wrap">
          {enableShiftDelete && (
            <Button
              className="remove-shift-button"
              customTextColor="#FF6F3E"
              customBackgroundColor="#ffffff"
              onClick={onRemoveShift}
            >
              Remove
            </Button>
          )}
          <DuplicateButton onClick={onDuplicateShift} />
        </div>
      </div>
    </div>
  )
}

ShiftItem.propTypes = {
  shift: PropTypes.object,
  onRemoveShift: PropTypes.func,
  onDuplicateShift: PropTypes.func,
  onChangeField: PropTypes.func,
  isShiftEditingDisabled: PropTypes.bool,
  enableShiftDelete: PropTypes.bool,
  editMode: PropTypes.bool
}

export default ShiftItem
