import React, { FC, useCallback, useEffect, useState } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import isUndefined from 'lodash/isUndefined'
import { QuestionSetSurveyEditorProps } from 'question-sets/pages/QuestionSetSurveyEditor'
import useGetSurvey from 'surveys/hooks/useGetSurvey'
import { useSaveSurvey } from 'surveys/hooks'

export const SurveyEditor: FC<QuestionSetSurveyEditorProps> = ({
  match: {
    url,
    params: { id },
  },
}) => {
  const [redirectLink, setRedirectLink] = useState<string | undefined>(undefined)
  const isNew = isUndefined(id)
  const backLink = url.substr(0, url.length - (isNew ? 4 : 5))
  const surveyId = isNew ? undefined : parseInt(id as string)
  const [getSurvey, { loading, result: survey }] = useGetSurvey()
  useEffect(() => {
    surveyId && getSurvey(surveyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [{ loading: surveySaving }, saveSurvey] = useSaveSurvey()
  const onSubmit = useCallback(
    payload =>
      saveSurvey(payload, surveyId).then(response => {
        if (isNew) {
          setRedirectLink(`${backLink}/${response.id}`)
        }
        return response
      }),
    [backLink, isNew, saveSurvey, surveyId],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={surveySaving}
      onSubmit={onSubmit}
      questionSet={survey?.versions[0].questionSet}
      backLink={backLink}
      backText={isNew ? 'Back to Surveys' : `Back to ${survey?.versions[0].questionSet?.name}`}
      title={id ? 'Edit Survey' : 'New Survey'}
      redirectLink={redirectLink}
    />
  )
}

export default SurveyEditor
