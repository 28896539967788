import { useCallback, useMemo, useEffect } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import * as _ from 'lodash'

const empty = { messages: [], emailEvents: [], smsEvents: [] }

const SendMethod = {
  EMAIL: 'email',
  PHONE: 'phone',
}

const EmailEventType = {
  RENDERING_FAILURE: 'Rendering Failure',
  REJECT: 'Reject',
  BOUNCE: 'Bounce',
  SEND: 'Send',
  COMPLAINT: 'Complaint',
  DELIVERY: 'Delivery',
  OPEN: 'Open',
  CLICK: 'Click',
  DELIVERY_DELAY: 'DeliveryDelay',
}

export const isFailure = eventType =>
  [EmailEventType.RENDERING_FAILURE, EmailEventType.REJECT, EmailEventType.BOUNCE, EmailEventType.COMPLAINT].includes(
    eventType,
  )

const useMappedResults = result =>
  useMemo(() => {
    const { messages, emailEvents, smsEvents } = result
    const messagesGroupedByUUID = _.groupBy(messages, 'uuid')
    const emailEventsGroupedByMessageId = _.groupBy(emailEvents, 'message.id')
    const smsEventsGroupedByMessageId = _.groupBy(smsEvents, 'message.id')
    return Object.keys(messagesGroupedByUUID).map(uuid => {
      const messages = messagesGroupedByUUID[uuid]
      const { subject, body, createdAt, userEnteredSubject } = messages[0]
      const emailMessages = messages.filter(message => message.method === SendMethod.EMAIL)
      const smsMessages = messages.filter(message => message.method === SendMethod.PHONE)
      const { failedEmailEvents, emailsStillSending } = emailMessages.reduce(
        (acc, email) => {
          const emailEvents = emailEventsGroupedByMessageId[email.id] || []
          if (_.isEmpty(emailEvents)) {
            acc.emailsStillSending = true
          }
          const failedEvent = emailEvents.find(emailEvent => isFailure(emailEvent.eventType))
          if (failedEvent) {
            acc.failedEmailEvents.push(failedEvent)
          }
          return acc
        },
        { failedEmailEvents: [], emailsStillSending: false },
      )
      const { failedSMSEvents, smsStillSending } = smsMessages.reduce(
        (acc, sms) => {
          const smsEvents = smsEventsGroupedByMessageId[sms.id] || []
          if (_.isEmpty(smsEvents)) {
            // TODO: add back later, once we are sure that we are receiving all SMS events
            // acc.smsStillSending = true
          }
          const failedEvent = smsEvents.find(smsEvent => !smsEvent.succeeded)
          if (failedEvent) {
            acc.failedSMSEvents.push(failedEvent)
          }
          return acc
        },
        { failedSMSEvents: [], smsStillSending: false },
      )
      return {
        createdAt,
        subject,
        userEnteredSubject,
        body,
        emailCount: emailMessages.length,
        smsCount: smsMessages.length,
        failedEmailEvents,
        failedSMSEvents,
        emailsStillSending,
        smsStillSending,
      }
    })
  }, [result])

const useMessagesSummary = () => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching messages summary')
  const organization = useCurrentOrg()
  const getMessagesSummary = useCallback(async () => {
    return request({
      method: 'get',
      url: `/organizations/${organization.id}/notification-blasts`,
    })
  }, [request])

  useEffect(() => {
    getMessagesSummary()
  }, [])

  const mappedResults = useMappedResults(result || empty)

  return useMemo(() => ({ result: mappedResults, loading: loading || !called, called, error }), [
    mappedResults,
    loading,
    called,
    error,
  ])
}

export default useMessagesSummary
