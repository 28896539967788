import React, { useCallback, useState, createRef } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import format from 'utils/format'

const filename = 'questionnaires.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Created On', key: 'createdAt' },
  { label: 'Created By', key: 'createdBy' },
  { label: 'Status', key: 'status' },
]

const formatPerson = (person: any) => (person ? `${person.givenName} ${person.familyName}` : null)

const formatData = (questionnaires: any[]) =>
  questionnaires.map(({ original: { name, createdAt, createdBy, status } }) => ({
    name,
    createdAt: format.datetime(createdAt),
    createdBy: formatPerson(createdBy),
    status,
  }))

const ExportQuestionnairesButton = React.forwardRef(
  (props: { data: any[]; disabled?: boolean; children?: React.ReactNode }, ref) => {
    const { data, disabled } = props
    const fileNameWithDate = useCurrentDateWithFilename(filename)
    const [formattedData, setFormattedData] = useState<any[]>([])
    const createFormattedData = useCallback(() => {
      setFormattedData(formatData(data))
    }, [setFormattedData, data])

    const csvLinkRef = createRef()

    return (
      <TableMenuButton
        // @ts-ignore
        ref={ref}
        {...props}
        startIcon={<SaveAltOutlinedIcon />}
        rightMargin
        onClick={() => {
          // @ts-ignore
          csvLinkRef.current?.link.click()
        }}
      >
        <CSVLink
          // @ts-ignore
          ref={csvLinkRef}
          style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
          onClick={createFormattedData}
          filename={fileNameWithDate as string}
          headers={headers}
          data={formattedData}
        >
          Export
        </CSVLink>
      </TableMenuButton>
    )
  },
)

export default ExportQuestionnairesButton
