import { get } from 'lodash'
import React, { useState, useCallback } from 'react'
import { useLatest } from 'react-use'
import { Link } from 'react-router-dom'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

import ToggleButton from './ToggleButton'
import UserSearch from './UserSearch'
import useStyles from './useStyles'
import CheckOutDialog from './CheckOutDialog'
import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useMiniSearch from 'utils/useMiniSearch'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import { getEncodedOccurrence } from 'kiosk/selectors'
import { useSelector } from 'react-redux'

const timesheetSearchConfig = {
  fields: ['user.givenName', 'user.familyName', 'user.email', 'user.phoneNumber', 'user.homePhoneNumber'],
  extractField: get,
  processTerm: (term, field) => (field === 'user.phoneNumber' ? term.substring(2) : term.toLowerCase()),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true,
  },
}

//TODO better name
//TODO move to utilities?
const useRunningGuard = callback => {
  const [running, setRunning] = useState(false)
  const latestRunning = useLatest(running)

  const guardedCallback = useCallback(
    (...args) => {
      //TODO should it use useCallback()?
      if (latestRunning.current) return
      try {
        setRunning(true)
        return callback(...args)
      } finally {
        setRunning(false)
      }
    },
    [callback, latestRunning],
  )

  guardedCallback.running = running

  return guardedCallback
}

export default function KioskCheckIn(props) {
  const {
    organizationName,
    volunteeringRoles,
    timesheetMode,
    volunteers,
    setTimesheetMode,
    checkIn,
    checkOut,
  } = props

  const [selected, setSelected] = useState(null)
  const [showDialog, setShowDialog] = useState(false)
  const [query, setQuery] = useState('')
  const search = useMiniSearch(volunteers, timesheetSearchConfig)
  const encodedOccurrence = useSelector(getEncodedOccurrence)

  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const showError = useErrorNotification()

  const handleRequirements = async user => {
    try {
      const done = await fulfillRequirementsPrompt({
        person: user,
        filters: {
          personId: user.id,
          associatedEntity: { encodedOccurrence },
          action: PrerequisitesAction.EVENT_REGISTRATION,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        isAdmin: false
      })
      return done
    } catch (error) {
      showError('Failed to load waivers and questionnaires', error)
      // TODO: confirm we we want to still continue https://civicchamps.atlassian.net/browse/CCA-1369
      return false
    }
  }

  const classes = useStyles()

  const handleSetMode = mode => {
    if (mode !== timesheetMode) {
      setQuery('')
      setSelected(null)
    }

    setTimesheetMode(mode)
  }

  const handleSelect = volunteer => {
    setSelected(volunteer !== selected ? volunteer : null)
  }

  const handleSubmitUser = useRunningGuard(async () => {
    if (timesheetMode === 'check-in') {
      if (await handleRequirements(selected.user)) {
        checkIn(selected)
        setSelected(null)
        setQuery('')
      }
    } else {
      setShowDialog(true)
    }
  })

  const handleSubmitCheckOut = checkOutData => {
    checkOut(selected, checkOutData)
    setSelected(null)
    setQuery('')
    setShowDialog(false)
  }

  return (
    <>
      <Grid container justify="flex-end">
        <Typography className={classes.washYourHands}>
          Friendly Reminder: Please wash your hands for at least 20 seconds before volunteering.
        </Typography>
      </Grid>
      <Grid container justify="flex-end">
        <Link to="/kiosk/signUp" className={classes.actionButton}>
          Sign Up
        </Link>
      </Grid>
      <Container maxWidth="md" className={classes.containerWrapper}>
        <Grid container direction="column" justify="center" align="center" className={classes.containerHeader}>
          <Grid item xs>
            <Typography variant="h4" gutterBottom>
              {organizationName}
            </Typography>
            <Typography variant="subtitle1" gutterBottom className={classes.subTitle}>
              {`Type Name to ${timesheetMode === 'check-in' ? 'Check In' : 'Check Out'}`}
            </Typography>
          </Grid>
          <Grid item xs>
            <ToggleButton alignment={timesheetMode} setAlignment={handleSetMode} />
          </Grid>
        </Grid>
        <UserSearch
          timesheetMode={timesheetMode}
          query={query}
          selected={selected}
          onChange={setQuery}
          onSearch={search}
          onSelect={handleSelect}
          onSubmit={handleSubmitUser}
        />
      </Container>
      <CheckOutDialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        volunteer={selected}
        volunteeringRoles={volunteeringRoles}
        onSubmit={handleSubmitCheckOut}
      />
    </>
  )
}
