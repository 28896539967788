import { ArraySchema } from 'yup'

import yup from 'civic-champs-shared/utils/yup'
import { QuestionType } from 'civic-champs-shared/question-sets/types'
import { showOptions } from 'question-sets/components/QuestionItemEditor'

export const questionSchema = (isQuestionnaireAsSurveyEnabled: boolean) =>
  yup.object({
    questionType: yup
      .string()
      .oneOf([
        QuestionType.LONG_TEXT,
        QuestionType.SHORT_TEXT,
        QuestionType.SINGLE_CHOICE,
        QuestionType.MULTIPLE_CHOICE,
        QuestionType.DROPDOWN,
        QuestionType.NUMBER,
        ...(isQuestionnaireAsSurveyEnabled ? [QuestionType.HOME_ADDRESS, QuestionType.EMERGENCY_CONTACT] : []),
      ])
      .required(),
    question: yup.string().max(400).required('${label} is required').trim().label('question'),
    options: yup.array().when('questionType', {
      is: showOptions,
      then: (schema: ArraySchema<string>) =>
        schema.of(yup.string().min(1).max(400).required('${label} is required').label('answer')).min(2).required(),
      otherwise: (schema: ArraySchema<string>) => schema.notRequired(),
    }),
  })

export const questionSetSchema = (isQuestionnaireAsSurveyEnabled: boolean) =>
  yup.object({
    name: yup.string().trim().required(),
    questions: yup.array().of(questionSchema(isQuestionnaireAsSurveyEnabled)).min(1).required(),
  })
