import { Column, TableInstance } from 'react-table'
import { History } from 'history'

export interface FilterRow {
  id: string
  column: string
  operator: string
  value: any
}

export enum operatorTypes {
  CONTAINS = 'contains',
  STARTS_WITH = 'startsWith',
  ENDS_WITH = 'endsWith',
  EQUALS = '=',
  GREATER_OR_EQUAL = '>=',
  GREATER_THAN = '>',
  LESS_THAN = '<',
  LESS_OR_EQUAL = '<=',
  ANY_OF = 'anyOf',
  ALL_OF = 'allOf',
  NOT_ALL_OF = 'notAllOf',
  NOT_ANY_OF = 'notAnyOf',
  IS = 'is',
}

export interface OperatorOption {
  value: operatorTypes
  displayValue: string
  autocomplete?: { value: any; label: string }[]
}

export type GetOperatorOptionsType = (column: string) => OperatorOption[]

export const STRING_OPERATOR_OPTIONS = [
  { value: operatorTypes.STARTS_WITH, displayValue: 'Starts With' },
  { value: operatorTypes.ENDS_WITH, displayValue: 'Ends With' },
  { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
  { value: operatorTypes.EQUALS, displayValue: 'Is' },
]

export const NUMBER_OPERATOR_OPTIONS = [
  { value: operatorTypes.LESS_THAN, displayValue: 'Less than' },
  { value: operatorTypes.LESS_OR_EQUAL, displayValue: 'Less than or equal to' },
  { value: operatorTypes.EQUALS, displayValue: 'Equal to' },
  { value: operatorTypes.GREATER_OR_EQUAL, displayValue: 'Greater than or equal to' },
  { value: operatorTypes.GREATER_THAN, displayValue: 'Greater than' },
]

export const ARRAY_OPERATOR_OPTIONS = [
  { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
  { value: operatorTypes.ALL_OF, displayValue: 'Include all of' },
  { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
  { value: operatorTypes.NOT_ALL_OF, displayValue: 'Do not include all of' },
]

export interface BinaryFilter {
  name: string
  value: boolean
  onChange: (value: boolean) => void
}

export interface FilterModalProps {
  table: TableInstance
  columns: Column[]
  onClose: (...args: any) => void
  getOperatorOptions: GetOperatorOptionsType
  setFilterRows?: (rows: any) => void
  filterRows?: FilterRow[]
  history?: History
  binaryFilters?: BinaryFilter[]
  subHeader?: string
}
