import { makeStyles } from '@material-ui/core/styles'

export const FULL_HEIGHT = 336
export const HALF_HEIGHT = 168
export const ROW_HEIGHT = 28

export const useMessageRecipientSelectorStyles = makeStyles(theme => ({
  input: {
    width: '337px',
    padding: '16px !important',
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.5px',
    color: '#191C1D',
    cursor: 'pointer',
    background: '#FFFFFF',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    '&$open': {
      borderRadius: '4px 4px 0px 0px',
    },
  },
  open: {},
  expandCollapse: {
    marginRight: '2px',
  },
  text: {
    display: 'flex',
    '& > span': {
      color: theme.palette.primary.main,
    },
  },
  popper: {
    zIndex: 1301,
    width: '337px',
    // For some reason positioning is 1px
    marginLeft: '1px',
  },
  popperPaper: {
    width: '337px',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '0px 0px 4px 4px',
  },
  recipients: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `${FULL_HEIGHT}px`,
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#74777F',
      borderRadius: '4px',
      width: '8px',
    },
    '&$shortened': {
      maxHeight: `${HALF_HEIGHT}px`,
    },
  },
  shortened: {},
  placeholder: {
    flexShrink: 0,
    width: '100%',
  },
}))

export const usePersonEmbeddedAutocompleteStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '&$loading': {
      alignItems: 'center',
    },
  },
  loading: {},
  search: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    margin: '0 15px 0 21px',
  },
  adornment: {
    color: '#C4C6CF',
    fontSize: '16px',
  },
  results: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#74777F',
      borderRadius: '4px',
      width: '8px',
    },
  },
  row: {
    padding: '0 15px 0 21px',
    borderTop: '0.5px solid #EEF0FA',
    height: `${ROW_HEIGHT}px`,
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: `${ROW_HEIGHT}px`,
    letterSpacing: '0.4px',
    color: '#000',
    flexShrink: 0,
    background: 'white',
    transition: 'background 0.5s',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    overflow: 'hidden',
    gap: '8px',
    '&:hover': {
      // @ts-ignore
      background: theme.palette.background.lightGrey,
    },
    '&$titleRow': {
      cursor: 'default',
      fontWeight: 700,
      lineHeight: `${ROW_HEIGHT + 4}px`,
      height: `${ROW_HEIGHT + 4}px`,
      '&:hover': {
        background: 'white',
      },
    },
  },
  titleRow: {},
  placeholder: {
    flexShrink: 0,
    width: '100%',
  },
}))

export const useMessageRecipientRowStyles = makeStyles(theme => ({
  recipientRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px 0 20px',
    height: `${ROW_HEIGHT}px`,
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#191C1D',
    flexShrink: 0,
    background: 'white',
    transition: 'background 0.5s',
    '&:hover': {
      // @ts-ignore
      background: theme.palette.background.lightGrey,
      '& $closeIcon': {
        opacity: 1,
      },
    },
    '&$button': {
      cursor: 'pointer',
      marginBottom: '4px',
    },
  },
  button: {},
  rowIcon: {
    fontSize: '20px',
    color: theme.palette.primary.main,
  },
  error: {
    color: theme.palette.error.main,
  },
  recipientContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '10px',
  },
  recipientNameContainer: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    padding: 0,
    margin: 0,
    maxWidth: '240px',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: '2px',
  },
  recipientErrorInfo: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '9px',
    lineHeight: '9px',
    letterSpacing: '0.4px',
    color: theme.palette.error.main,
  },
  closeIcon: { fontSize: '18px', cursor: 'pointer', opacity: 0 },
}))

// @ts-ignore
export const useMessageRecipientsPromptStyles = makeStyles({
  title: {
    cursor: 'move',
    padding: '20px 0 0',
    fontFamily: 'Poppins',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    marginBottom: '20px',
  },
  content: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '& .ck.ck-toolbar.ck-toolbar_grouping': {
      background: 'none',
      transform: 'scale(0.75)',
      borderColor: 'transparent',
    },
    '& .ck.ck-content.ck-editor__editable.ck-rounded-corners': {
      borderRadius: '4px !important',
      fontFamily: 'Nunito, sans-serif !important',
      fontSize: '16px !important',
      lineHeight: '20px !important',
      borderColor: 'rgb(175, 188, 213) !important',
      padding: '10px',
      boxShadow: 'none !important',
      '&.ck-focused': {
        borderColor: 'rgba(0, 0, 0, 0.87) !important',
      },
      '& > p': {
        margin: 0,
      },
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  // @ts-ignore
  dialog: ({ failure }) => ({
    background: '#F8FAFF',
    padding: '0 20px 20px',
    position: 'relative',
    maxWidth: '100%',
    width: failure ? '600px' : '700px',
    '&::-webkit-scrollbar': {
      width: '8px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#74777F',
      borderRadius: '4px',
    },
    scrollbarColor: '#74777F transparent',
    scrollbarWidth: '8px',
  }),
  dialogCloseButton: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  dialogCloseIcon: {
    fontSize: '16px',
  },
  recipients: {
    display: 'flex',
    gap: '10px',
    '& > p': {
      padding: '10px 0',
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '0.5px',
      color: '#000000',
      margin: 0,
      alignSelf: 'flex-start',
    },
  },
  border: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '10px 20px',
    gap: '10px',
    background: '#FFFFFF',
    border: '1px solid #74777F',
    borderRadius: '4px',
    flex: 'none',
    flexGrow: 0,
    '& > p': {
      fontFamily: 'Nunito, sans-serif',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
      padding: 0,
      margin: 0,
    },
    '& > p$subtitle': {
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0.1px',
    },
  },
  sendChannel: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
  },
  subtitle: {},
  whiteBg: {
    background: '#fff',
  },
  editorContainer: {
    width: '100%',
    position: 'relative',
  },
  recipientsCenter: {
    display: 'flex',
    flexDirection: 'column',
  },
  recipientsInner: {
    display: 'flex',
    gap: '0 10px',
  },
  recipientCounters: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '2px',
    '& > p': {
      padding: 0,
      margin: 0,
      fontFamily: 'Nunito',
      fontWeight: 700,
      fontSize: '12px',
      lineHeight: '16px',
      letterSpacing: '0.5px',
      color: '#000000',
      '& > span': {
        padding: 0,
        margin: 0,
        fontWeight: 400,
      },
    },
  },
})
