import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import useDelete from 'civic-champs-shared/api/hooks/useDelete'
import { useCurrentOrg } from '../../auth/hooks'

export default function RemoveContactPrompt(props) {
  const { showing, close, complete, personContact } = props
  const classes = usePromptStyles()
  const currentOrg = useCurrentOrg()
  const [removeContact, { loading }] = useDelete({
    errorMessage: 'Error removing contact',
    successMessage: 'Successfully removed contact',
    configMapper: () => ({
      url: `/organizations/${currentOrg.id}/persons/${personContact.person.id}/person-contacts/${personContact.id}`,
      onSuccess: complete,
    }),
  })

  return (
    <Dialog className="full-screen" open={showing} onClose={close}>
      <DialogTitle>Remove Contact</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to remove the contact {personContact.contact.contact} from{' '}
          {personContact.person.givenName} {personContact.person.familyName}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button className={classes.closeButton} onClick={close} disabled={loading}>
          X
        </Button>
        <Button className={classes.modalButton} onClick={removeContact} disabled={loading}>
          REMOVE
        </Button>
      </DialogActions>
    </Dialog>
  )
}
