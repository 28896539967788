import React, { FC, useCallback, useEffect } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import isUndefined from 'lodash/isUndefined'
import { useFetchGroupQuestionSets } from 'group/hooks/useFetchGroupQuestionSets'
import { useSetGroupQuestionSets } from 'group/hooks/useSetGroupQuestionSets'
import map from 'lodash/map'
import useGetSurvey from 'surveys/hooks/useGetSurvey'
import { useSaveSurvey } from 'surveys/hooks'
import { GroupSurveyQuestionSetEditorProps } from 'group/pages/GroupSurveyQuestionSetEditor'

export const GroupSurveyEditor: FC<GroupSurveyQuestionSetEditorProps> = ({
  match: {
    url,
    params: { id: groupId, questionnaireId },
  },
}) => {
  const isNew = isUndefined(questionnaireId)
  const backLink = url.substr(0, isNew ? url.length - 4 : url.indexOf(`/${questionnaireId}/edit`))
  const [getSurvey, { loading, result: survey }] = useGetSurvey()
  const surveyId = isNew ? undefined : parseInt(questionnaireId as string)
  const [fetchGroupQuestionSets] = useFetchGroupQuestionSets()
  const [setGroupQuestionSets] = useSetGroupQuestionSets()
  useEffect(() => {
    surveyId && getSurvey(surveyId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [{ loading: surveySaving }, saveSurvey] = useSaveSurvey()
  const onSubmit = useCallback(
    async payload => {
      const result = await saveSurvey(payload, surveyId)

      if (isNew) {
        // @ts-ignore
        const existingQuestionSets = await fetchGroupQuestionSets(groupId)
        // @ts-ignore
        await setGroupQuestionSets(groupId, [
          ...map(existingQuestionSets, ({ survey: { id: surveyId }, required }) => ({
            surveyId,
            required,
          })),
          { surveyId: result.id, required: true },
        ])
      }
      return result
    },
    [fetchGroupQuestionSets, groupId, isNew, saveSurvey, setGroupQuestionSets, surveyId],
  )

  if (loading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={surveySaving}
      onSubmit={onSubmit}
      questionSet={survey?.versions[0].questionSet}
      backLink={backLink}
      backText="Back to Group Surveys"
      title={isNew ? 'New Survey' : 'Edit Survey'}
    />
  )
}

export default GroupSurveyEditor
