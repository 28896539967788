import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDateRangeFilter } from 'filtering/selectors';
import { setDateRangeFilter } from 'filtering/actions';

export const useDateRangeFilter = () => {
  const dateRange = useSelector(getDateRangeFilter);
  const dispatch = useDispatch();
  const setDateRange = useCallback(dateRange => dispatch( setDateRangeFilter(dateRange) ), [dispatch]);

  return [dateRange, setDateRange];
}