import React from 'react'
import { Dialog, DialogContent, Grid, Button, Typography, Box, makeStyles } from '@material-ui/core'

import { NotificationBlastSummary } from './schema'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { EmailPreview, SMSPreview } from 'messages/components/MessagePreviews'
import { DialogTitle } from '../DialogTitle';
import cn from 'classnames'
import DOMPurify from 'dompurify'

interface MessageRecipientsPromptProps {
  summary: NotificationBlastSummary
  close: () => void
  showing: boolean
}

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingLeft: '5em !important',
    paddingRight: '5em !important',
    paddingBottom: '2em !important',
    paddingTop: '2em !important',
  },
}))

export default function MessageSummaryPrompt(props: MessageRecipientsPromptProps) {
  const { summary, close, showing } = props
  const { subject, userEnteredSubject, body='', smsCount, emailCount, failedEmailEvents, failedSMSEvents } = summary
  const classes = usePromptStyles()
  const ownClasses = useStyles()

  return (
    <Dialog open={showing} onClose={close} fullWidth maxWidth='md'>
      <DialogTitle onClose={close} />
      <DialogContent className={cn(classes.contentContainer, ownClasses.contentContainer)}>
        <div className={classes.dialog}>
          <Grid direction="column" container spacing={3}>
            <Grid direction="row" item xs={12} container alignItems="flex-start">
              <Grid item xs={1}>
                <Box textAlign="right">
                  <Typography variant="subtitle1">
                    <b>Subject</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box pl={3} pt={0.6}>
                  {userEnteredSubject || subject}
                </Box>
              </Grid>
            </Grid>

            <Grid direction="row" item xs={12} container alignItems="flex-start">
              <Grid item xs={1}>
                <Box textAlign="right">
                  <Typography variant="subtitle1">
                    <b>Message</b>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={8} className="truncated-multiline">
                <Box pl={3} pt={0.6} dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(body)
                }}/>
              </Grid>
            </Grid>

            <Grid direction="row" item xs={12} container justify="space-between">
              {!!emailCount && (
                <Grid item xs={5}>
                  <Box mt={3}>
                    <EmailPreview
                      subject={userEnteredSubject || subject}
                      smsCount={smsCount}
                      emailCount={emailCount}
                      body={body}
                      failedEmailEvents={failedEmailEvents}
                    />
                  </Box>
                </Grid>
              )}

              {!!smsCount && (
                <Grid item xs={6}>
                  <Box mt={3}>
                    <SMSPreview
                      subject={subject}
                      smsCount={smsCount}
                      emailCount={emailCount}
                      failedSMSEvents={failedSMSEvents}
                    />
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid direction="row" item container justify="flex-end">
                <Button
                  variant="contained"
                  color="secondary"
                  className={classes.button}
                  onClick={close}
                >
                  Done
                </Button>
            </Grid>
          </Grid>
        </div>
      </DialogContent>
    </Dialog>
  )
}
