import React, { useState } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import useCreateOrUpdateCredentialPrompt from '../hooks/useCreateOrUpdateCredentialPrompt'
import TableToolbar from 'components/table/TableToolbarLight'
import { useTableSearch } from 'components/table/hooks'
import _ from 'lodash'
import DataTable from 'components/DataTable'
import Loading from 'components/Loading'
import useOrganizationCredentials from '../hooks/useOrganizationCredentials'
import CredentialActionMenu from 'credential/components/CredentialActionMenu'
import { CellProps } from 'react-table'
import { DateTimeCell } from 'core/table/cells'
import { Link } from 'react-router-dom'
import Search from 'core/table/components/Search'
import { AddButton } from 'civic-champs-shared/core/add-button'

const searchConfig = {
  fields: ['version.title'],
  processTerm: (term: string, field: string) => term.toLowerCase(),
  searchOptions: {
    processTerm: (term: string) => term.toLowerCase(),
    prefix: true,
  },
  extractField: _.get,
}

export const Credentials = (props: any) => {
  // @ts-ignore
  const { credentials, loading, refresh } = useOrganizationCredentials()
  const [ selected, setSelected ] = useState({})
  const onCreateOrUpdateCredential = useCreateOrUpdateCredentialPrompt(refresh, false)
  const selectedId = _.get(selected, ['credential', 'id'])
  const { query, setQuery, results: filteredCredentials } = useTableSearch(credentials, searchConfig)

  const columns = [
    { Header: 'Name',
      accessor: 'version.title',
      Cell: ({ cell: { value, row: { original } } }: CellProps<any>) => (
        <Link to={`/credentials/${original.id}`}>
          <div>{ original.version.title }</div>
        </Link>
      ) },
    {
      Header: 'Type',
      accessor: 'credential.type',
      Cell: ({ cell: { value, row: { original } } }: CellProps<any>) => (
        <div>{ _.get(original, 'credential.isPublished') ? value : `${value} (draft)` }</div>
      )
    },
    {
      Header: 'Events',
      accessor: 'stats.totalOpportunities',
      Cell: ({ cell: { value, row: { original } } }: CellProps<any>) => (
        <div>{ _.get(original, 'credential.isPublished') ? value : '-' }</div>
      )
    },
    {
      Header: 'Volunteers',
      accessor: 'stats.totalVolunteers',
      Cell: ({ cell: { value, row: { original } } }: CellProps<any>) => (
        <div>{ _.get(original, 'credential.isPublished') ? value : '-' }</div>
      )
    },
    { Header: 'Created', accessor: 'credential.createdAt', Cell: DateTimeCell },
    { Header: 'Last Modified', accessor: 'version.createdAt', Cell: DateTimeCell },
  ];

  if (loading) {
    return <Loading />
  }
  return <Grid container>
    <Grid container>
      <Grid container xs={12} item justify="space-between" direction="row">
      <Grid xs={6} container alignItems="center" item>
      <Typography variant='h4'>Waivers</Typography>
        <Grid item style={{ marginLeft: 10 }}>
          <AddButton onClick={onCreateOrUpdateCredential} />
        </Grid>
      </Grid>
      <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
        <Grid item sm={6}>
        <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
      </Grid>
      </Grid>
      </Grid>
    </Grid>
    <Grid md={12}>
      <Box mt={3}>
        <div>
          <TableToolbar
            query={query}
            onQueryChange={setQuery}
            actionMenu={(
              <CredentialActionMenu
                selectedId={selectedId}
                onCreateOrUpdateCredential={onCreateOrUpdateCredential}
              />
            )}
          />
          <DataTable
            columns={columns}
            data={filteredCredentials}
            onSelectedChanged={ setSelected }
          />
        </div>
      </Box>
    </Grid>
  </Grid>
}
