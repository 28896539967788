import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import useArchiveVolunteer from './useArchiveVolunteer'
import { useCallback } from 'react'
import { volunteerSummary } from './utils'

const useArchiveVolunteerPromptOld = (eagerRemoveMany: any) => {
  const confirm = useConfirm()
  const archiveVolunteer = useArchiveVolunteer(eagerRemoveMany)

  return useCallback(
    async volunteers => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Are you sure you want to archive ${volunteerName} from volunteers?  His/her activities will no longer appear in reports.`
        : `Are you sure you want to archive ${numVolunteers} volunteers?  Their activities will no longer appear in reports.`

      const confirmed = await confirm(text, {
        title: 'Archive Volunteer',
        confirmText: 'Archive Volunteer',
        rejectText: 'Cancel',
      })

      if (confirmed) {
        await archiveVolunteer(volunteers)
      }
    },
    [confirm, archiveVolunteer],
  )
}

export default useArchiveVolunteerPromptOld
