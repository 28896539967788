import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

export const useSetOrgUserProfileQuestionSets = (onResultUpdate) => {
  const [request, status] = useFetch('Error creating group')
  const showSuccess = useSuccessNotification()
  const organization = useCurrentOrg()
  const setUserProfileQuestionSets = useCallback(
    async (questionSets) => {
      return request({
        onSuccess: result => {
          showSuccess(`Successfully updated user profile questionnaires for ${organization.name}`)
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: `/organizations/${organization.id}/user-profile-question-sets`,
        config: {
          body: { questionSets },
        },
      })
    },
    [request],
  )

  return useMemo(() => ([
    setUserProfileQuestionSets,
    status
  ]), [setUserProfileQuestionSets, status])
}
