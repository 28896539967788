import _ from 'lodash'
import qs from 'query-string'
import format from 'utils/format'

const queryString = params => qs.stringify(params, { skipNull: true, skipEmptyString: true })

export const GET_ACTIVITIES = {
  request: ({ organization, dateRange }) => {
    return {
      url: `/organizations/${organization.id}/activities?${queryString({
        from: format.datetime(dateRange.startDate),
        to: format.datetime(dateRange.endDate),
      })}`,
    }
  },
  caching: {
    maxAge: '1 hour',
    staleAfter: '1 minute',
  },
  update: (_queryConfig, state, result) => ({
    ...state,
    activities: result,
  }),
  select: (_queryConfig, state) => state.activities,
}

export const DELETE_ACTIVITY = {
  request: ({ activity }) => ({
    method: 'del',
    url: `/activities/${activity.id}`,
  }),
  update: (queryConfig, state) => {
    let id = _.get(queryConfig, 'variables.activity.id')
    return {
      ...state,
      activities: _.filter(state.activities, a => a.id !== id),
    }
  },
}

export const DELETE_ACTIVITIES = {
  request: activities => ({
    method: 'del',
    url: `/activities?ids=${activities.map(({ id }) => id).join(',')}`,
  }),
  update: (queryConfig, state) => {
    const ids = queryConfig.variables.map(({ id }) => id)
    return {
      ...state,
      activities: _.filter(state.activities, ({ id }) => !ids.includes(id)),
    }
  },
}

export const CREATE_ACTIVITY = {
  request: variables => ({
    method: 'post',
    url: `/activities`,
    config: { body: variables.activityData },
  }),
  update: (_queryConfig, state, result) => ({
    ...state,
    activities: [result, ...state.activities],
  }),
}

export const UPDATE_ACTIVITY = {
  request: variables => ({
    method: 'put',
    url: `/activities/${variables.activityId}`,
    config: { body: variables.activityData },
  }),
  update: (_queryConfig, state, result) => ({
    ...state,
    activities: _.map(state.activities, a => (a.id === result.id ? result : a)),
  }),
}
