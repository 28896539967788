import moment from 'moment'
import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
   active: {
    fontWeight: 'bold',
    color: '#5F8FE8',
    fontSize: '22px' 
  },
   inactive: {
    color: 'grey',
    fontSize: '22px'
  },
  draft:{
    color: '#FF6F3E',
    fontSize: '22px'
  },
}))


export default function EventStatus(props) {
    const { title, className, icon, customBackgroundColor, customTextColor, color = '#5C8DE8' } = props
    const classes = useStyles()

    if(title === "Draft") return <div className={classes.draft}>Draft</div>
    
    return title === "Live"
        ? <span className={classes.active}>Live</span>
        : <span className={classes.inactive}>Passed</span>
}