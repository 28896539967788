import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import map from 'lodash/map'
import { Grid } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

import { NumberCell } from 'core/table/cells'
import DEFAULT_FILTERS from 'core/table/filters'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks'
import { NUMBER_OPERATOR_OPTIONS, operatorTypes } from 'core/table/interfaces/Filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'

import Loading from 'components/Loading'
import MatchFlowEditor from '../components/MatchFlowEditor'
import { ProgramMatch, Tag } from '../types'
import {
  useAddMatch,
  useFetchMatches,
  useTagManagement,
  useMentorshipMatchesColumns as useColumns,
  useMentorshipMatchesSummary,
} from '../hooks'

import PageHeader from 'App/components/PageHeader'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'tags':
      return [
        { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
        { value: operatorTypes.EQUALS, displayValue: 'Equals' },
      ]

    case 'meetingCount':
    case 'momentCount':
    case 'averageMeetingDuration':
      return NUMBER_OPERATOR_OPTIONS

    case 'programName':
    case 'mentorName':
    case 'menteeName':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]
    default:
      return []
  }
}

const cards = [
  {
    header: 'Matches',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    header: 'Meetings',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    header: 'Avg. Meeting Duration',
    accessor: 'averageMeetingTime',
    Cell: NumberCell,
  },
  {
    header: 'Positive Experiences',
    accessor: 'positiveFeedbackPercent',
  },
]

const csvHeaders = [
  { label: 'Program', key: 'programName' },
  { label: 'Mentor', key: 'mentorName' },
  { label: 'Mentee', key: 'menteeName' },
  { label: 'Matched Since', key: 'startedAt' },
  { label: '# Meetings', key: 'meetingCount' },
  { label: '# Moments', key: 'momentCount' },
  { label: 'Avg. Meeting Time', key: 'averageMeetingDuration' },
  { label: 'Tags', key: 'tags' },
]

const tableName = 'mentorshipMatches'

const NewMentorshipMatchesComponent = ({ location, history }: RouteComponentProps) => {
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()

  const [fetchMatches, { loading, result }] = useFetchMatches()
  useEffect(() => {
    fetchMatches()
  }, [fetchMatches])

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const matches = useMemo(
    () =>
      map(result, match => ({
        ...match,
        mentorName: `${match?.mentor?.givenName} ${match?.mentor?.familyName}`,
        menteeName: `${match?.mentee?.givenName} ${match?.mentee?.familyName}`,
        programName: match?.program?.name,
      })),
    [result],
  )

  const { addMatch } = useAddMatch()
  const { linkTagsToMatch } = useTagManagement()
  const showAddMatch = useShowPrompt(MatchFlowEditor)

  const columns = useColumns({ showAddMatch, fetchMatches })

  const table = useTable(
    {
      data: matches,
      columns,
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const summary = useMentorshipMatchesSummary(table.rows)

  const exportedData = useMemo(
    () =>
      table.selectedFlatRows.map(({ values }: any) => ({
        ...values,
        tags: values.tags.map(({ name }: Tag) => name).join(','),
      })),
    [table.selectedFlatRows],
  )

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Matches"
        filterSubHeader="Select match attributes to filter by:"
        buttons={
          <>
            <ExportButton data={exportedData} headers={csvHeaders} filename={'mentorship-matches.csv'} />
          </>
        }
        addButton={
          <ContainedButton
            startIcon={<AddIcon />}
            onClick={() =>
              showAddMatch({
                label: 'Add a New Match',
                submit: ({ tags, ...match }: Partial<ProgramMatch> & { tags: Tag[] }) =>
                  addMatch(match).then(async ({ id: matchId }: ProgramMatch) => {
                    if (tags.length) {
                      await linkTagsToMatch(
                        tags.map(({ id }) => id),
                        matchId,
                      )
                    }
                    return fetchMatches()
                  }),
              })
            }
          >
            Add Match
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
      />
    </Grid>
  )
}

export const NewMentorshipMatches = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Mentor Matches" subTitle="This is the mentorship matches table">
      <NewMentorshipMatchesComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipMatches
