import { isEmpty } from 'lodash'
import { useMemo } from 'react'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled, useOrganizationSettingsTabs } from 'core/feature/hooks'

export default function useSettingsEnabledForSuperAdmin() {
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const isOrgUserProfilesEnabled = useFeatureEnabled('OrgUserProfiles')
  const isGlobalUserProfilesEnabled = useFeatureEnabled('GlobalUserProfiles')
  const hasOrgSettingsTabs = !isEmpty(useOrganizationSettingsTabs())

  return useMemo(
    () => isSuperAdmin && (hasOrgSettingsTabs || isOrgUserProfilesEnabled || isGlobalUserProfilesEnabled),
    [isSuperAdmin, hasOrgSettingsTabs, isOrgUserProfilesEnabled, isGlobalUserProfilesEnabled],
  )
}
