import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'

export interface ApiKey {
  id: number
  name: string
  token: string
  personId: number
  organizationId: number
}

export const useCreateApiKey = (): [(name?: string) => Promise<ApiKey>, Status<ApiKey>] => {
  const [request, status] = useFetchRefactored<ApiKey>()

  const createApiKey = useCallback(
    (name = 'default') => {
      return request({
        method: 'post',
        url: `/api-keys`,
        config: {
          body: { name },
        },
      })
    },
    [request],
  )

  return [createApiKey, status]
}

export const useGetApiKeys = (): [() => Promise<ApiKey[]>, Status<ApiKey[]>] => {
  const [request, status] = useFetchRefactored<ApiKey[]>()

  const getApiKeys = useCallback(() => {
    return request({
      method: 'get',
      url: `/api-keys`,
    })
  }, [request])

  return [getApiKeys, status]
}

export const useDeleteApiKey = (): [(token: string) => Promise<void>, Status<void>] => {
  const [request, status] = useFetchRefactored<void>()

  const deleteApiKey = useCallback(
    token => {
      return request({
        method: 'del',
        url: `/api-keys/${token}`,
      })
    },
    [request],
  )

  return [deleteApiKey, status]
}
