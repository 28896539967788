import React from 'react'
import Tag, { TagProps } from './Tag'
import Grid from '@material-ui/core/Grid'

export default function Tags({ tags, onDelete }: { tags: TagProps[]; onDelete?: (id: number) => void }) {
  return (
    <Grid container direction="row" alignItems="flex-start" spacing={1}>
      {tags.map(props => (
        <Grid item key={props.id}>
          <Tag {...props} />
        </Grid>
      ))}
    </Grid>
  )
}
