import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core'
import find from 'lodash/find'
import { FormikPersonAutocomplete } from 'components/PersonAutocomplete.old'
import { Field, Form, Formik } from 'formik'
import { useGroups } from 'group/hooks/useGroups'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import yup from 'civic-champs-shared/utils/yup'
import { StyledKeyboardDatePicker } from 'components/StyledFormikDateTimePickers.old'
import { StyledAutocomplete } from 'components/StyledAutocomplete.old'
import useFetchPrograms from 'new-mentorship/hooks/useFetchPrograms'
import { MentorshipProgram, Tag } from 'new-mentorship/types'
import { TagAutocomplete } from 'components/TagAutoComplete.old'
import useTagManagement from 'new-mentorship/hooks/useTagsManagement'
import { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import AddPersonFlow from 'civic-champs-shared/core/add-person/AddPersonFlow'
import useAddPerson from 'civic-champs-shared/api/hooks/useAddPerson'
import first from 'lodash/first'
import { Person } from 'people/interface'
import Loading from 'civic-champs-shared/core/Loading'
import StepModalContainerOld, { useModalStyles } from 'civic-champs-shared/core/StepModalContainer.old'
import moment from 'moment-timezone'
import isNull from 'lodash/isNull'
import { timezones } from 'civic-champs-shared/helpers/timezones'

const getInitialPersonCreateValues = (input: string) => {
  if (input.trim()) {
    const emailMatch = input.match(/[^\s]+@[^\s]+/)
    const email = first(emailMatch) || ''
    input = input.replace(email, '')
    const phoneMatch = input.match(/^\+?\d+$|^\+?\d+[^\s]|[^\s]\+?\d+[^\s]/)
    const phone = first(phoneMatch) || ''
    const names = input.replace(phone, '').trim().replace(/\s+/, ' ').split(' ')
    return {
      givenName: names.shift(),
      familyName: names.join(' '),
      email,
      phone,
      home: null,
      birthday: null,
      sendInvite: true,
    }
  }
}

interface CreateMatchForm {
  program: MentorshipProgram | null
  mentor: PersonSuggestion | null
  mentee: PersonSuggestion | null
  startedAt: Date | null
  stoppedAt: Date | null
  meetingsTimezone: { name: string; value: string } | null
  tags: Tag[]
}

const validationSchema = yup.object({
  program: yup.object().nullable().required('Please select mentorship program'),
  mentor: yup.object().nullable().required('Please select mentor'),
  mentee: yup
    .object()
    .nullable()
    .required('Please select mentee')
    .when('mentor', (mentor: PersonSuggestion, schema: any) => {
      return schema.test({
        test: (mentee: PersonSuggestion) => {
          return mentor?.id !== mentee?.id
        },
        message: 'Cannot mentor yourself',
      })
    }),
  startedAt: yup
    .date()
    .typeError('The date provided is not a valid date format (MM/DD/YYYY).')
    .nullable()
    .required('Please select match start date'),
  meetingsTimezone: yup.string().nullable().required('Please select meetings timezone'),
  stoppedAt: yup.date().typeError('The date provided is not a valid date format (MM/DD/YYYY).').nullable(),
})

export default function MatchFlowEditor(props: any) {
  const {
    showing,
    submit,
    cancel,
    label,
    editable = true,
    initialValues = {
      program: null,
      mentor: null,
      mentee: null,
      startedAt: null,
      stoppedAt: null,
      meetingsTimezone: null,
      tags: [],
    } as CreateMatchForm,
  } = props

  const classes = useModalStyles()
  const [tags, setTags] = useState<Tag[]>([])
  const [tagsLoading, setTagsLoading] = useState<boolean>(false)
  const { groups, loading: groupsLoading } = useGroups()
  const { mentorGroupId, menteeGroupId } = useMemo(
    () => ({
      mentorGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentor } })?.id,
      menteeGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentee } })?.id,
    }),
    [groups],
  )
  const [fetchPrograms, { result: programs, loading: programsLoading }] = useFetchPrograms()

  const [addNewMentor] = useAddPerson({ groupId: mentorGroupId })
  const [addNewMentee] = useAddPerson({ groupId: menteeGroupId })

  const handleMentorAdd = (userData: any) =>
    addNewMentor({
      type: 'NEW_USER',
      ...userData,
    })

  const handleMenteeAdd = (userData: any) =>
    addNewMentee({
      type: 'NEW_USER',
      ...userData,
    })

  useEffect(() => {
    fetchPrograms()
  }, [fetchPrograms])
  const { getOrgTags } = useTagManagement()
  const refreshTags = () => {
    setTagsLoading(true)
    getOrgTags().then(tags => {
      setTags(tags)
      setTagsLoading(false)
    })
  }
  useEffect(refreshTags, [getOrgTags])

  const showAddPerson = useShowPrompt(AddPersonFlow)

  const handleSubmit = useCallback(
    ({ program, mentor, mentee, startedAt, stoppedAt, tags, meetingsTimezone }: CreateMatchForm) => {
      return submit({
        programId: program?.id,
        mentorId: mentor?.id,
        menteeId: mentee?.id,
        // @ts-ignore
        startedAt: isNull(startedAt) ? startedAt : moment(startedAt).format('YYYY-MM-DD 00:00:00'),
        // @ts-ignore
        stoppedAt: isNull(stoppedAt) ? stoppedAt : moment(stoppedAt).format('YYYY-MM-DD 23:59:59'),
        meetingsTimezone: meetingsTimezone?.value,
        tags,
      }).then(() => cancel()) // TODO ...not really how prompts are intended to work
    },
    [cancel, submit],
  )

  const getHandleCreate = (setFieldValue: any, isMentor: boolean) => (input: string) => {
    showAddPerson({
      submit: (data: any) =>
        (isMentor ? handleMentorAdd : handleMenteeAdd)(data).then(([{ person }]: [{ person: Person }]) => {
          const { id, givenName, familyName, email, phoneNumber } = person
          const contact = email || phoneNumber
          setFieldValue(isMentor ? 'mentor' : 'mentee', {
            id,
            givenName,
            familyName,
            contact,
            title: `${givenName} ${familyName} - ${contact}`,
            groupId: isMentor ? mentorGroupId : menteeGroupId,
          })
        }),
      label: 'Add New ' + (isMentor ? 'Mentor' : 'Mentee'),
      initialValues: getInitialPersonCreateValues(input),
    })
  }

  return (
    <StepModalContainerOld showing={showing} cancel={cancel} steps={[label]} activeStep={0}>
      {groupsLoading || tagsLoading || programsLoading ? (
        <Loading />
      ) : (
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ validateForm, submitForm, isSubmitting, setFieldValue }) => {
            return (
              <Form>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Field
                      name="program"
                      label="Select Program"
                      component={StyledAutocomplete}
                      options={programs}
                      getOptionLabel={({ name }: MentorshipProgram) => name}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="meetingsTimezone"
                      label="Select Meetings TimeZone"
                      component={StyledAutocomplete}
                      options={timezones}
                      getOptionLabel={({ name }: any) => name}
                      getOptionSelected={(option: any, value: any) => {
                        return option?.value === value?.value
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      onChange={() => setTimeout(validateForm, 100)}
                      name="mentor"
                      label="Select Mentor"
                      groupId={mentorGroupId}
                      groupTitle="Mentors"
                      disabled={!editable}
                      onCreate={getHandleCreate(setFieldValue, true)}
                      required={true}
                      component={FormikPersonAutocomplete}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="mentee"
                      label="Select Mentee"
                      groupId={menteeGroupId}
                      groupTitle="Mentees"
                      disabled={!editable}
                      onCreate={getHandleCreate(setFieldValue, false)}
                      required={true}
                      component={FormikPersonAutocomplete}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="startedAt"
                      fullWidth
                      label="Started At"
                      component={StyledKeyboardDatePicker}
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Field
                      name="stoppedAt"
                      fullWidth
                      label="Stopped At"
                      component={StyledKeyboardDatePicker}
                      inputVariant="outlined"
                      format="MM/DD/YYYY"
                      clearable={true}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Field
                      name="tags"
                      label="Tags"
                      options={tags}
                      addOption={(option: Tag) => {
                        setTags(prev => [...prev, option])
                      }}
                      component={TagAutocomplete}
                      getOptionLabel={({ name }: Tag) => name}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  justify="space-between"
                  classes={{ root: classes.buttonsContainer }}
                >
                  <Button
                    disabled={isSubmitting}
                    onClick={cancel}
                    variant="contained"
                    color="secondary"
                    className={classes.button}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={submitForm}
                  >
                    Finish
                  </Button>
                </Grid>
              </Form>
            )
          }}
        </Formik>
      )}
    </StepModalContainerOld>
  )
}
