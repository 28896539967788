import { groupsFilter, firstLastFilter } from 'core/table/filters'

export const GROUPS = 'groups'
export const FIRST_LAST_NAME = 'customFirstLastName'

export const CUSTOM_FILTERS = {
  [GROUPS]: groupsFilter,
  [FIRST_LAST_NAME]: firstLastFilter,
}

export default CUSTOM_FILTERS
