import React, { useMemo, useState } from 'react'
import { Box, Button, Checkbox, FormControlLabel, Grid, makeStyles, TextField, Typography } from '@material-ui/core'
import DateRangeFilter from 'components/filter/DateRangeFilter'
// @ts-ignore
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useDateRangeFilter } from 'filtering/hooks'
import { useVolunteersCollection } from 'volunteering/volunteers/hooks'
import Loading from 'components/Loading'
import { find, get, size } from 'lodash'
import ArchiveIcon from '@material-ui/icons/Archive'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import DataTable from 'components/DataTable'
import { useHasRole } from 'auth/hooks'
import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import ExportChampionsButton from '../components/ExportChampionsButton.old'
import useChampionColumns from '../hooks/useChampionColumns.old'
import { useTableSearch } from 'components/table/hooks'
import { ChampionsSummaryCards } from '../components/ChampionsSummaryCards'
import FeatureEnabled from 'core/feature/components/FeatureEnabled'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import RemoveButton from '../../civic-champs-shared/core/remove-button'
import { ChampionsDatafeedButton } from 'champion/components/ChampionsDatafeedButton'
import AddPersonButton from 'civic-champs-shared/core/add-person/AddPersonButton'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { useGroups } from 'group/hooks/useGroups'
import { AddPersonPayload } from 'civic-champs-shared/core/add-person/AddPersonFlow'
import { useFeatureEnabled } from 'core/feature/hooks'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { useGetSearchableContactsFromVolunteersArray } from 'messages/hooks/useGetSearchableContactsFromVolunteersArray'
import { Volunteer } from 'champion/utils/interface'

const useStyles = makeStyles(theme => ({
  card: {
    borderTop: 'solid 1px #5C8DE8',
    borderBottom: 'solid 1px #5C8DE8',
    textAlign: 'center',
    padding: '25px 0',
  },
  cardColored: {
    backgroundColor: '#FEF4CB',
    border: 'solid 1px #5C8DE8',
  },
  tableHead: {
    backgroundColor: '#CCCCCC',
  },
  name: {
    color: '#5F8FE8',
  },
  clear: {
    color: '#5F8FE8',
  },
  summary: {
    marginTop: '30px',
    marginBottom: '10px',
  },
  cancelButton: {
    // @ts-ignore
    backgroundColor: theme.palette.danger.main,
    // @ts-ignore
    color: theme.palette.neutral.white,
    '&:hover': {
      // @ts-ignore
      backgroundColor: theme.palette.danger.dark,
    },
  },
}))

const searchConfig = {
  fields: ['user.email', 'user.familyName', 'user.givenName', 'user.phoneNumber', 'user.homePhoneNumber'],
  processTerm: (term: string) => term.toLowerCase(),
  searchOptions: {
    processTerm: (term: string) => term.toLowerCase(),
    combineWith: 'AND',
    prefix: true,
  },
  extractField: get,
}

export const Champions = () => {
  const singleAddPersonUIEnabled = useFeatureEnabled('SingleAddPersonUI')
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const classes = useStyles()
  const [selectedChampions, setSelectedChampions] = useState<any[]>([])
  const [dateRange, setDateRange] = useDateRangeFilter()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()
  const [includeArchived, setIncludeArchived] = useState(false)
  const [includeInactive, setIncludeInactive] = useState(true)
  const [{ volunteers, initiallyLoaded }, { addVolunteer, addVolunteerLegacy, removeVolunteer, archiveVolunteer }] =
    useVolunteersCollection(dateRange, includeInactive, includeArchived)
  const { query, setQuery, results: filteredVolunteers } = useTableSearch(volunteers, searchConfig) as any
  const selectedChampionsCount = size(selectedChampions)
  const { groups } = useGroups()
  const volunteerId = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Volunteer } })?.id, [groups])
  const getSearchableContacts = useGetSearchableContactsFromVolunteersArray(volunteers as Volunteer[])

  const onRemoveVolunteer = () => {
    // @ts-ignore
    removeVolunteer(selectedChampions.map(a => a.original))
    setSelectedChampions([])
  }

  const onArchiveVolunteer = () => {
    // @ts-ignore
    archiveVolunteer(selectedChampions.map(a => a.original))
    setSelectedChampions([])
  }

  const onMessageVolunteers = () => {
    const recipientPersonIds = selectedChampions.map(a => a.original.user.id)
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: 'All Volunteers',
    })
  }

  const columns = useChampionColumns()

  function arraysEqual(a: any, b: any) {
    if (a === b) return true
    if (a == null || b == null) return false
    if (a.length !== b.length) return false
    for (var i = 0; i < a.length; ++i) {
      if (a[i].original !== b[i].original) return false
    }
    return true
  }

  const onRowsChanged = (rows: any) => {
    if (!arraysEqual(rows, selectedChampions)) {
      setSelectedChampions(rows)
    }
  }

  if (!initiallyLoaded) {
    return <Loading />
  }

  return (
    <Grid container>
      <Grid container item sm={12} direction="column" spacing={3}>
        <Grid container item justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Grid item>
              <Typography variant="h4">Champions</Typography>
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              {singleAddPersonUIEnabled ? (
                <AddPersonButton
                  groupId={volunteerId}
                  groupTitle="Existing Champions"
                  autocompleteLabel="Select User"
                  otherTitle="Existing Users"
                  personName="Champion"
                  disableGroupPersons={true}
                  legendWidthFixture={90}
                  handlePersonCreate={addVolunteer as (data: AddPersonPayload) => Promise<any>}
                />
              ) : (
                <AddButton
                  // @ts-ignore
                  onClick={addVolunteerLegacy}
                />
              )}
            </Grid>
            <FeatureEnabled code="CustomerDatafeed">
              <Grid item style={{ marginLeft: 10 }}>
                <ChampionsDatafeedButton />
              </Grid>
            </FeatureEnabled>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search"
                value={query}
                onChange={e => setQuery(e.target.value as string)}
              />
            </Grid>
            <Grid item style={{ marginLeft: 10 }}>
              <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4 }}>
                <DateRangeFilter
                  initialValues={dateRange}
                  onFilterApplied={setDateRange}
                  anchorDirection={ANCHOR_LEFT}
                  noBorder
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <div className={classes.summary}>
          <ChampionsSummaryCards volunteers={filteredVolunteers} />
        </div>
        <Grid container item spacing={1} alignItems="center" justify="flex-start">
          <FeatureEnabled code="BulkMessaging">
            <Grid item>
              <Button
                onClick={onMessageVolunteers}
                variant="contained"
                color="secondary"
                disabled={!selectedChampionsCount}
              >
                <MessageIcon style={{ marginRight: 5 }} />
                Message
              </Button>
            </Grid>
          </FeatureEnabled>
          <Grid item>
            <Button
              onClick={onArchiveVolunteer}
              variant="contained"
              color="secondary"
              disabled={includeArchived || selectedChampionsCount === 0}
            >
              <ArchiveIcon style={{ marginRight: 5 }} />
              Archive
            </Button>
          </Grid>
          <Grid item>
            <ExportChampionsButton
              disabled={selectedChampionsCount == 0}
              data={selectedChampions.map(a => a.original)}
              includeDonations={isSuperAdmin}
            />
          </Grid>
          <Grid item>
            <RemoveButton onClick={onRemoveVolunteer} disabled={selectedChampionsCount === 0} />
          </Grid>
          <Grid item>
            <FormControlLabel
              style={{ height: 54 }}
              control={
                <Checkbox
                  checked={includeArchived}
                  onChange={e => setIncludeArchived(!!e.target.checked)}
                  style={{
                    color: '#5F8FE8',
                  }}
                />
              }
              label="Include Archived"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              style={{ height: 54 }}
              control={
                <Checkbox
                  checked={includeInactive}
                  onChange={e => setIncludeInactive(!!e.target.checked)}
                  style={{
                    color: '#5F8FE8',
                  }}
                />
              }
              label="Include Inactive"
            />
          </Grid>
        </Grid>
        <Grid container item style={{ minHeight: 400 }}>
          <DataTable columns={columns} data={filteredVolunteers} onRowsChanged={onRowsChanged} checkBox multiSelect />
        </Grid>
      </Grid>
    </Grid>
  )
}
