import React, { useRef, useState } from 'react'
// @ts-ignore
import { Box, Dialog } from '@material-ui/core'
import { History } from 'history'
import { Column, TableInstance } from 'react-table'
import { TableMenuButton } from 'civic-champs-shared/core'
import { ReactComponent as FilterIcon } from 'images/filter.svg'
import FiltersModal from './NewFiltersModal'
import { makeStyles } from '@material-ui/core/styles'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import { BinaryFilter, FilterRow, GetOperatorOptionsType } from 'core/table/interfaces/Filters'

const useStyles = makeStyles({
  button: {
    padding: '8px 10px 8px 16px',
  },
})

export const NewFilters = (props: {
  table: TableInstance
  columns: Column[]
  getOperatorOptions: GetOperatorOptionsType
  setFilterRows?: (rows: any) => void
  filterRows?: FilterRow[]
  history?: History
  binaryFilters?: BinaryFilter[]
  subHeader?: string
}) => {
  const classes = useStyles()

  const anchorEl = useRef(null)
  const [filtersModalVisible, setFiltersModalVisible] = useState(false)
  return (
    <>
      {/*
      // @ts-ignore */}
      <Box ref={anchorEl}>
        <TableMenuButton
          className={classes.button}
          startIcon={<FilterIcon />}
          onClick={() => setFiltersModalVisible(true)}
        >
          Add Filter
        </TableMenuButton>
      </Box>
      {/*
      // @ts-ignore */}
      <Dialog
        open={filtersModalVisible}
        onClose={() => setFiltersModalVisible(false)}
        PaperComponent={DraggablePaper}
        PaperProps={{ handle: `#filter-title` }}
        aria-labelledby="filter-title"
        maxWidth="lg"
      >
        <FiltersModal {...props} onClose={() => setFiltersModalVisible(false)} />
      </Dialog>
    </>
  )
}

export default NewFilters
