import React, { useState, useMemo } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Button, TextField } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { makeStyles } from '@material-ui/styles'
import Loading from 'components/Loading'
import useApproveMember from '../hooks/useApproveMember'
import useDeclineMember from '../hooks/useDeclineMember'
import { memberStatuses } from './VolunteersTab'
import * as _ from 'lodash'
import format from 'utils/format'
import { ViewAnswerSetsContainer } from 'civic-champs-shared/question-sets/components'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '20px',
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cancelButton: {
    color: theme.palette.danger.main,
  },
}))

const useOtherMembers = ({ member, allMembers }) => {
  return useMemo(() => {
    const unapprovedMembers = allMembers.filter(allMember => allMember.membershipStatus === memberStatuses.applicant)
    const memberIndex = unapprovedMembers.findIndex(unapprovedMember => unapprovedMember.id === member.id)
    const nextMember = unapprovedMembers[memberIndex + 1]
    const previousMember = unapprovedMembers[memberIndex - 1]
    return {
      nextMember,
      previousMember,
    }
  }, [member, allMembers])
}

const useDisplayContact = member => {
  return useMemo(() => {
    return _.filter([member.email, format.phoneNumber(member.phoneNumber)]).join(' / ')
  }, [member])
}

export default function ProcessMemberPrompt(props) {
  const { showing, close, submitting, refresh, allMembers, groupName, groupType } = props
  const [member, setMember] = useState(props.member)
  const [comment, setComment] = useState('')
  const { nextMember, previousMember } = useOtherMembers({ allMembers, member })
  const displayContact = useDisplayContact(member)
  const promptClasses = usePromptStyles()
  const classes = useStyles()
  const { approveMember, loading: approveLoading } = useApproveMember()
  const { declineMember, loading: declineLoading } = useDeclineMember()
  const handleApproveClick = async () => {
    await approveMember(member, comment)
    refresh()
    close()
  }

  const handleDeclineClick = async () => {
    await declineMember(member, comment)
    refresh()
    close()
  }

  const filters = useMemo(
    () => ({
      personId: member.id,
      required: true,
      associatedEntity: { groupId: member.group.id },
      action: PrerequisitesAction.GROUP_SIGN_UP,
      freshAnswerSet: false,
      excludeCompletedAnswerSets: false,
      includeSignedCredentials: true,
    }),
    [member],
  )

  const loading = approveLoading || declineLoading
  const disabled = submitting || loading
  return (
    <Dialog fullWidth={true} open={showing} onClose={close}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DialogTitle>
            <Typography className={promptClasses.title}>Process member</Typography>
            <IconButton disabled={disabled} onClick={close} className={promptClasses.closeButton}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.content}>
            <ViewAnswerSetsContainer filters={filters} />
            <div>
              Would you like to approve {member.givenName} {member.familyName} ({displayContact}) to join your
              organization's {groupName}?
            </div>
            <TextField
              placeholder="Add message to the applicant"
              variant="outlined"
              value={comment}
              onChange={e => setComment(e.target.value)}
              fullWidth
              multiline
              className={classes.marginTop}
              rows={3}
            />
            <div className={`${classes.buttonRow} ${classes.marginTop}`}>
              <Button variant="contained" color="primary" disabled={disabled} onClick={handleDeclineClick}>
                Decline
              </Button>
              {/* TODO: add a 'banned' option ? */}
              <Button variant="contained" color="secondary" disabled={disabled} onClick={handleApproveClick}>
                Yes
              </Button>
            </div>
            <div className={classes.buttonRow}>
              {previousMember && (
                <Button variant="text" color="secondary" disabled={disabled} onClick={() => setMember(previousMember)}>
                  Previous
                </Button>
              )}
              {/* NOTE: just for spacing so that nextMember is on the right */}
              {!previousMember && <div />}
              {nextMember && (
                <Button variant="text" color="secondary" disabled={disabled} onClick={() => setMember(nextMember)}>
                  Next
                </Button>
              )}
            </div>
          </DialogContent>
        </>
      )}
    </Dialog>
  )
}
