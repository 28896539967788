import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { currentOrganization, currentProfile, currentUser } from './selectors'
import { isRole, isRoleAtLeast, USER } from 'civic-champs-shared/auth/utils/permissions'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'

export const useCurrentUser = () => useSelector(currentUser)
export const useCurrentOrg = () => useSelector(currentOrganization)
export const useCurrentProfile = () => useSelector(currentProfile)

export const useHasRole = role => {
  let user = useCurrentUser()

  if (!user) user = {}
  if (!user.permissions) {
    user.permissions = {
      role: USER,
      managedOrganization: null,
    }
  }

  return useMemo(() => {
    if (!isRole(role)) {
      console.error(`Unrecognized role '${role}', hiding components to prevent access`)
      return false
    }

    return isRoleAtLeast(user.permissions, role)
  }, [user, role])
}

export const useIsTester = () => {
  const [, { loading, result }] = useGetOnMount({
    url: `/testers/is-tester`,
    errorMessage: 'Error fetching if the user is a tester',
    emptyValue: { isTester: false },
  })
  return { loading, isTester: result.isTester }
}
