import { filter } from 'lodash'
import { DEFAULT_GEOFENCING } from '../../constants/GEO_DATA'
import Autocomplete = google.maps.places.Autocomplete
import PlaceResult = google.maps.places.PlaceResult

export const defaultLocation: CreatePersonLocationPayload = {
  label: '',
  location: {
    name: '',
    address: {
      line1: '',
      line2: '',
      city: '',
      state: '',
      zipCode: '',
      county: '',
      countryCode: '',
    },
    geofencing: DEFAULT_GEOFENCING,
  },
}

enum GOOGLE_ADDRESS_TYPE {
  BOROUGH = 'sublocality',
  CITY = 'locality',
  STATE = 'administrative_area_level_1',
  COUNTY = 'administrative_area_level_2',
  ZIP = 'postal_code',
  COUNTRY = 'country',
}

enum NAME_TYPES {
  long = 'long_name',
  short = 'short_name',
}

export interface AuditRecord {
  createdAt: Date
  createdBy: PersonRef
  //lastModifedAt: Date
  //lastModifiedBy: PersonRef
  deletedAt?: Date
  deletedBy?: PersonRef
}

export interface GeoCoordinates {
  lat: number
  lng: number
}

export interface GeoPoint {
  type: 'Point'
  coordinates: GeoCoordinates
}

export interface Geofencing {
  location: GeoPoint
  radius: number
}

export interface Address {
  line1?: string
  line2?: string
  city?: string
  state?: string
  zipCode?: string
  county?: string
  countryCode?: string
}

export interface Utils {
  id: number
  name?: string
  address?: Address
  geofencing?: Geofencing
  notes?: string
  createdAt: Date
  createdBy: PersonRef
  deletedAt?: Date
  deletedBy?: PersonRef
}

export interface LinkedLocation extends AuditRecord {
  id?: number
  label?: string
  location: Utils
}

export interface PersonRef {
  id: number
  givenName?: string
  familyName?: string
  email?: string
  phoneNumber?: string
}

export interface PersonLocation extends LinkedLocation {
  person: PersonRef
}

export interface LocationPayload {
  name: string
  address: Address
  geofencing: Geofencing
  utcOffset?: number
}

export interface LinkedLocationPayload {
  label?: string
  location: LocationPayload
}

export interface Location {
  id: number
  name?: string
  address?: Address
  geofencing?: Geofencing
  notes?: string
  createdAt: Date
  createdBy: PersonRef
  deletedAt?: Date
  deletedBy?: PersonRef
}

export interface CreatePersonLocationPayload extends LinkedLocationPayload {}

export const getDisplayAddress = (address: Partial<Address>, label?: string): string => {
  const addressString =
    filter([address.line1, address.line2, address.city, address.state, address.zipCode]).join(', ') || ''
  return label ? `${label}: (${addressString})` : addressString
}

export const getDisplayAddressFromEvent = (event: any) => {
  const { address, city, state, zip } = event
  return filter([address, city, state, zip]).join(', ') || ''
}

export interface Place {
  displayName?: string
  address: Address
  coordinates: GeoCoordinates
}

export const parseGoogleResponse = (autocomplete: Autocomplete): Place => {
  const place: PlaceResult = autocomplete.getPlace()
  const googleAddress = place.address_components || [];
  const coordinates = {
    lat: place?.geometry?.location.lat() || 0,
    lng: place?.geometry?.location.lng() || 0,
  }

  const line1 = place.name
  // shouldn't happen often, but if you look for an address in Brooklyn, for example, it does not return a city
  const city =
    getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.CITY) ||
    getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.BOROUGH) ||
    ''
  const state = getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.STATE) || ''
  const zipCode = getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.ZIP) || ''
  const county = getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.COUNTY) || ''
  const countryCode = getGoogleAddressField(googleAddress, GOOGLE_ADDRESS_TYPE.COUNTRY, NAME_TYPES.short)
  const displayName = getDisplayAddress({ line1, city, state, zipCode })

  return {
    displayName,
    address: {
      line1,
      city,
      state,
      zipCode,
      county,
      countryCode,
    },
    coordinates,
  }
}

interface GoogleAddress {
  long_name: string
  short_name: string
  types: Array<string>
}

const getGoogleAddressField = (
  googleAddress: Array<GoogleAddress>,
  type: string,
  longOrShortName = NAME_TYPES.long,
) => {
  for (let i = 0; i < googleAddress.length; i++) {
    for (let j = 0; j < googleAddress[i].types.length; j++) {
      if (googleAddress[i].types[j] === type) {
        return googleAddress[i][longOrShortName]
      }
    }
  }
}
