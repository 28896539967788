import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { NamedRef } from 'civic-champs-shared/question-sets/types'

export const useDuplicateQuestionSet = (eagerAdd: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()

  return useCallback(
    async (questionSet: NamedRef) => {
      const notification = createNotification('Duplicating questionnaire')
      try {
        // @ts-ignore
        const duplicatedQuestionSet = await request({
          method: 'post',
          url: `/organizations/${currentOrg.id}/question-set`,
          config: {
            body: {
              questionSetId: questionSet.id,
              name: questionSet.name,
            },
          },
        })

        const { questionSetCreatedAt, createdBy, name, questionSetId } = duplicatedQuestionSet
        eagerAdd({ id: questionSetId, name, createdAt: questionSetCreatedAt, createdBy, status: 'Draft' })
        notification.onSuccess('Questionnaire duplicated!')
      } catch (error) {
        notification.onError(`Could not duplicate ${questionSet.name} questionnaire`, error)
      }
    },
    [createNotification, currentOrg.id, eagerAdd, request],
  )
}

export default useDuplicateQuestionSet
