import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import qs from 'query-string'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useUISettings } from 'hooks/useUISettings'
import { EditCell, EditHeader } from 'core/table/cells'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  totalActivitiesColumn: {
    '&>div': {
      width: '100px',
    },
  },
  statusColumn: {
    '&>div': {
      width: '150px',
    },
  },
  typeColumn: {
    '&>div': {
      width: '150px',
    },
  },
  actionsColumn: {
    '&>div': {
      width: '80px',
    },
  },
})

export const RoleNameCell = ({ cell }: any) => {
  const { value } = cell
  const classes = useStyles()
  return (
    <Link
      className={classes.link}
      to={{
        pathname: `/activities/`,
        search: qs.stringify({ 'role:anyOf': [value] }, { arrayFormat: 'bracket' }),
      }}
    >
      {value}
    </Link>
  )
}

export function useRolesColumns({
  editRole,
  deleteRole,
}: {
  editRole: (role: { id: number; name: string }) => void
  deleteRole: (role: { id: number; name: string }) => void
}) {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'order',
            Header: 'Order',
            accessor: 'order',
            disableFilters: true,
            disableSortBy: true,
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            Cell: RoleNameCell,
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'totalActivities',
            Header: 'Total Activities',
            accessor: 'totalActivities',
            disableFilters: true,
            className: classes.totalActivities,
          },
          {
            id: 'status',
            Header: 'Status',
            accessor: 'isActive',
            Cell: ({ cell }: any) => {
              const { isActive } = cell.row.original
              return isActive ? 'Active' : 'Inactive'
            },
            className: classes.statusColumn,
          },
          {
            id: 'type',
            Header: 'Type',
            accessor: 'isDefault',
            Cell: ({ cell }: any) => {
              const { isDefault } = cell.row.original
              return isDefault ? 'Opportunity' : 'Event'
            },
            className: classes.typeColumn,
          },
          {
            id: 'actions',
            Header: <EditHeader />,
            Cell: ({ cell }: any) => <EditCell cell={cell} onEdit={editRole} onDelete={deleteRole} />,
            disableSortBy: true,
            disableFilters: true,
            className: classes.actionsColumn,
          },
        ],
        {
          showAdminItems,
        },
      ),
    [
      classes.nameColumn,
      classes.totalActivities,
      classes.statusColumn,
      classes.typeColumn,
      classes.actionsColumn,
      showAdminItems,
      editRole,
      deleteRole,
    ],
  )
}

export default useRolesColumns
