import usePost from 'civic-champs-shared/api/hooks/usePost'
import { useCurrentOrg } from 'auth/hooks'

export interface NotificationBlastContact {
  id: number
  email: string | null
  phoneNumber: string | null
  sendToPhone: boolean
  firstName: string
  lastName: string
  isPhoneBounced: boolean
  isEmailBounced: boolean
}

export interface NotificationBlastPreviewOverview {
  smsCredits: number
  contacts: NotificationBlastContact[]
}

export default function useMessageOverview() {
  const organization = useCurrentOrg()
  return usePost<NotificationBlastPreviewOverview, { recipientPersonIds: number[] }>({
    configMapper: ({ recipientPersonIds }: { recipientPersonIds: number[] }) => ({
      url: `/organizations/${organization.id}/notification-blast-overview`,
      config: { body: { recipientPersonIds } },
    }),
    emptyValue: {
      smsCredits: 0,
      contacts: [],
    },
  })
}
