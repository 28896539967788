import React, { useState } from 'react'
import { Grid, Typography, Paper, Button } from '@material-ui/core'
import Loading from 'components/Loading'
import BackLink from 'donation/campaigns/components/BackLink'
import usePerson from '../hooks/usePerson'
import format from 'utils/format'
import { useForceVerify } from '../hooks/useForceVerify'
import { useChangePassword } from '../hooks/useChangePassword'
import { usePurgeAccount } from '../hooks/usePurgeAccount'
import { useChangePasswordPrompt } from '../hooks/useChangePasswordPrompt'
import { usePurgeAccountPrompt } from '../hooks/usePurgeAccountPrompt'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useCurrentOrg } from '../../auth/hooks'
import { useRemoveContactPrompt } from '../hooks/useRemoveContactPrompt'

export const PersonDetail = props => {
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const [person, setPerson] = useState(null)
  const id = props.match.params.id
  const currentOrg = useCurrentOrg()
  const { loading } = usePerson({
    id,
    onSuccess: setPerson,
    organizationId: isInternalSuperAdmin ? undefined : currentOrg.id,
  })
  const [forceVerify, { loading: verifyLoading }] = useForceVerify(setPerson)
  const [changePassword, { loading: changePasswordLoading }] = useChangePassword()
  const onChangePasswordPrompt = useChangePasswordPrompt(password => changePassword(person, password))
  const onRemoveContactPrompt = useRemoveContactPrompt(setPerson)
  const onPurgeAccountPrompt = usePurgeAccountPrompt({ personId: id, onComplete: () => props.history.push('/people') })

  if (loading || verifyLoading || changePasswordLoading) {
    return <Loading />
  }

  if (!person) {
    return (
      <div>
        <BackLink text="Back to People" />
        <div>No person exists with this id</div>
      </div>
    )
  }
  return (
    <div>
      <BackLink text="Back to People" />
      <Grid container spacing={4} direction="column">
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Person</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Date</strong>
                  <p>{format.date(person.createdAt)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>First Name</strong>
                  <p>{person.givenName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Last Name</strong>
                  <p>{person.familyName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Account Registered</strong>
                  <p>{person.cognitoSub ? 'Yes' : 'No'}</p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>

        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Contacts</Typography>
          </Grid>
          <Grid item sm={12}>
            {person.contacts.map(personContact => (
              <Paper variant="outlined" style={{ padding: 20 }} key={personContact.id}>
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <strong>ID</strong>
                    <p>{personContact.id}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Added</strong>
                    <p>{format.date(personContact.createdAt)}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Contact</strong>
                    <p>
                      {personContact.contact.contactType === 'email'
                        ? personContact.contact.contact
                        : format.phoneNumber(personContact.contact.contact)}
                    </p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Type</strong>
                    <p>{personContact.contactType}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Status</strong>
                    <p>{personContact.status}</p>
                  </Grid>
                  {personContact.invalidatedBy && (
                    <Grid item sm={3}>
                      <strong>Invalidated By</strong>
                      <p>
                        {personContact.invalidatedBy.givenName} {personContact.invalidatedBy.familyName}
                      </p>
                    </Grid>
                  )}
                  <Grid item sm={3}>
                    <strong>Private</strong>
                    <p>{personContact.private ? 'Yes' : 'No'}</p>
                  </Grid>
                  {personContact.enteredBy && (
                    <Grid item sm={3}>
                      <strong>Entered By</strong>
                      <p>
                        {personContact.enteredBy.givenName} {personContact.enteredBy.familyName}
                      </p>
                    </Grid>
                  )}
                  <Grid item sm={3}>
                    <strong>Organization</strong>
                    <p>{personContact.organization ? personContact.organization.name : '-'}</p>
                  </Grid>
                  {isInternalSuperAdmin &&
                    person.cognitoSub &&
                    personContact.contactType === 'email' &&
                    !['verified', 'invalid'].includes(personContact.status) && (
                      <Grid item sm={3}>
                        <Button onClick={() => forceVerify({ person, email: personContact.contact.contact })}>
                          Force Verify Email
                        </Button>
                      </Grid>
                    )}
                  {isInternalSuperAdmin &&
                    person.cognitoSub &&
                    personContact.contactType === 'mobile-phone' &&
                    !['verified', 'invalid'].includes(personContact.status) && (
                      <Grid item sm={3}>
                        <Button onClick={() => forceVerify({ person, phoneNumber: personContact.contact.contact })}>
                          Force Verify Mobile Phone Number
                        </Button>
                      </Grid>
                    )}
                  {(isInternalSuperAdmin ||
                    (personContact.organization && personContact.organization.id === currentOrg.id)) &&
                    personContact.status !== 'verified' && (
                      <Grid item sm={3}>
                        <Button onClick={() => onRemoveContactPrompt(personContact)}>Remove Contact</Button>
                      </Grid>
                    )}
                </Grid>
              </Paper>
            ))}
          </Grid>
        </Grid>

        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Actions</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                {isInternalSuperAdmin && !person.cognitoSub && (
                  <Grid item sm={3}>
                    <Button onClick={() => props.history.push(`/people/${id}/edit`)}>Edit Name</Button>
                  </Grid>
                )}
                <Grid item sm={3}>
                  <Button onClick={() => props.history.push(`/people/${id}/add-contact`)}>Add Contact</Button>
                </Grid>
                {isInternalSuperAdmin && person.cognitoSub && (
                  <Grid item sm={3}>
                    <Button onClick={onChangePasswordPrompt}>Change Password</Button>
                  </Grid>
                )}
                {isInternalSuperAdmin && (
                  <Grid item sm={3}>
                    <Button onClick={onPurgeAccountPrompt}>Purge Account</Button>
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
