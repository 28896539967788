import React from 'react'
import { Grid, Typography, Paper } from '@material-ui/core'
import Loading from 'components/Loading'
import BackLink from 'donation/campaigns/components/BackLink'
import format from 'utils/format'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { useCurrentOrg } from '../../auth/hooks'
import * as _ from 'lodash'

export const EmailEventType = {
  RENDERING_FAILURE: 'Rendering Failure',
  REJECT: 'Reject',
  BOUNCE: 'Bounce',
  SEND: 'Send',
  COMPLAINT: 'Complaint',
  DELIVERY: 'Delivery',
  OPEN: 'Open',
  CLICK: 'Click',
  DELIVERY_DELAY: 'DeliveryDelay',
}

export const emailEventTypeDescriptionLookup = {
  [EmailEventType.RENDERING_FAILURE]: "The email wasn't sent because of a template rendering issue.",
  [EmailEventType.REJECT]:
    "The email processor accepted the email, determined that it contained a virus, and rejected it. The email processor didn't attempt to deliver the email to the recipient's mail server",
  [EmailEventType.BOUNCE]:
    "The recipient's mail server permanently rejected the email. This event corresponds to hard bounces. Soft bounces are only included when the email processor fails to deliver the email after retrying for a period of time.",
  [EmailEventType.SEND]:
    'The call to the email processor was successful and the email processor will attempt to deliver the email.',
  [EmailEventType.COMPLAINT]:
    'The email was successfully delivered to the recipient, but the recipient marked the email as spam.',
  [EmailEventType.DELIVERY]: "We successfully delivered the email to the recipient's mail server.",
  [EmailEventType.OPEN]: 'The recipient received the message and opened it in their email client.',
  [EmailEventType.CLICK]: 'The recipient clicked one or more links in the email.',
  [EmailEventType.DELIVERY_DELAY]:
    "The email couldn't be delivered to the recipient because a temporary issue occurred. Delivery delays can occur, for example, when the recipient's inbox is full, or when the receiving email server experiences a transient issue.",
}

export const MessageDetail = props => {
  const { id } = props.match.params
  const currentOrg = useCurrentOrg()
  const [, { loading, result }] = useGetOnMount({
    url: `/organizations/${currentOrg.id}/messages/${id}`,
    config: { queryStringParameters: { isCustom: false } },
    errorMessage: 'Error fetching Non-Custom Messages',
  })

  if (loading) {
    return <Loading />
  }
  const { message, events } = result || {}
  if (!message) {
    return (
      <div>
        <BackLink to={'/messages'} text="Back to Message" />
        <div>No message exists with this id</div>
      </div>
    )
  }
  const isEmail = message.method === 'email'
  return (
    <div>
      <BackLink to={'/messages'} text="Back to Messages" />
      <Grid container spacing={4} direction="column">
        <Grid container item sm={8} spacing={2}>
          <Grid item sm={12}>
            <Typography variant="h5">Message</Typography>
          </Grid>
          <Grid item sm={12}>
            <Paper variant="outlined" style={{ padding: 20 }}>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Date</strong>
                  <p>{format.date(message.createdAt)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Method</strong>
                  <p>{_.capitalize(message.method)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Contact</strong>
                  <p>{isEmail ? message.recipientContact : format.phoneNumber(message.recipientContact)}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Type</strong>
                  <p>{message.templateVersion.description}</p>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Recipient: First Name</strong>
                  <p>{message.sentTo.givenName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Recipient: Last Name</strong>
                  <p>{message.sentTo.familyName}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Recipient: Email</strong>
                  <p>{message.sentTo.email || ''}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Recipient: Phone</strong>
                  <p>{message.sentTo.phoneNumber || ''}</p>
                </Grid>
              </Grid>
              {message.sentBy && (
                <Grid container spacing={2}>
                  <Grid item sm={3}>
                    <strong>Sender: First Name</strong>
                    <p>{message.sentBy.givenName}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Sender: Last Name</strong>
                    <p>{message.sentBy.familyName}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Sender: Email</strong>
                    <p>{message.sentBy.email || ''}</p>
                  </Grid>
                  <Grid item sm={3}>
                    <strong>Sender: Phone</strong>
                    <p>{message.sentBy.phoneNumber || ''}</p>
                  </Grid>
                </Grid>
              )}
              <Grid container spacing={2}>
                <Grid item sm={3}>
                  <strong>Opportunity</strong>
                  <p>{(message.opportunity && message.opportunity.name) || ''}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Withheld</strong>
                  <p>{message.withheld ? 'Yes' : 'No'}</p>
                </Grid>
                <Grid item sm={3}>
                  <strong>Reason Withheld</strong>
                  <p>{message.reasonWithheld || 'n/a'}</p>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {!!events.length && (
            <>
              <Grid item sm={12}>
                <Typography variant="h6">Delivery Tracking</Typography>
              </Grid>
              <Grid item sm={12}>
                {events.map(event => (
                  <Paper variant="outlined" style={{ padding: 20 }} key={event.id}>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <strong>Date</strong>
                        <p>{format.date(event.createdAt)}</p>
                      </Grid>
                      {isEmail ? (
                        <>
                          <Grid item sm={3}>
                            <strong>Result</strong>
                            <p>{emailEventTypeDescriptionLookup[event.eventType]}</p>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item sm={3}>
                            <strong>Result</strong>
                            <p>{event.succeeded ? 'Delivered' : event.providerResponse }</p>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Paper>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
