import { useCallback, useMemo } from 'react'
import { useFetch, useSuccessNotification } from 'civic-champs-shared/api/hooks'

export const useSetGlobalUserProfileQuestionSets = (onResultUpdate) => {
  const [request, status] = useFetch('Error creating group')
  const showSuccess = useSuccessNotification()
  const setUserProfileQuestionSets = useCallback(
    async (questionSets) => {
      return request({
        onSuccess: result => {
          showSuccess('Successfully updated user profile questionnaires')
          onResultUpdate && onResultUpdate(result)
        },
        method: 'post',
        url: '/user-profile-question-sets',
        config: {
          body: { questionSets },
        },
      })
    },
    [request],
  )

  return useMemo(() => ([
    setUserProfileQuestionSets,
    status
  ]), [setUserProfileQuestionSets, status])
}
