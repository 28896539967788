import React, { Dispatch, SetStateAction, UIEvent, useCallback, useMemo, useRef, useState } from 'react'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import { NotificationBlastContact, NotificationBlastPreviewOverview } from 'messages/hooks/useMessageOverview'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { Fade, Popper } from '@material-ui/core'
import cn from 'classnames'
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail'
import PhoneIcon from '@material-ui/icons/Phone'
import FeedbackOutlinedIcon from '@material-ui/icons/FeedbackOutlined'
import CloseIcon from '@material-ui/icons/Close'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import { GetFirstLastId, useRecipientsPlaceholderText } from 'messages/hooks/useRecipientsPlaceholderText'
import {
  FULL_HEIGHT,
  HALF_HEIGHT,
  ROW_HEIGHT,
  useMessageRecipientRowStyles,
  useMessageRecipientSelectorStyles,
} from 'messages/hooks/useStyles'
import { MessagingContact } from 'messages/components/MessageRecipientsPrompt'
import isNull from 'lodash/isNull'
import map from 'lodash/map'
import filter from 'lodash/filter'
import sortBy from 'lodash/sortBy'
import first from 'lodash/first'
import { Post } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { findIndex } from 'lodash'
import PersonEmbeddedAutocomplete from 'messages/components/PersonEmbeddedAutocomplete'

interface MessageRecipientSelectorProps {
  contacts: NotificationBlastContact[]
  setContacts: Dispatch<SetStateAction<NotificationBlastContact[]>>
  emailsOnly: boolean
  getSearchableContacts: () => Promise<MessagingContact[]>
  fetchMessageOverview: Post<NotificationBlastPreviewOverview>
  autocompleteSectionTitle?: string
}

interface RecipientRowProps {
  contact: NotificationBlastContact
  emailsOnly: boolean
  onRemove: () => void
}

let requestRunning: number | null = null

const isBounced = ({ isEmailBounced, isPhoneBounced }: NotificationBlastContact, emailsOnly: boolean): boolean => {
  if (emailsOnly) return isEmailBounced
  return isEmailBounced || isPhoneBounced
}

const sortByFunction = ({ firstName, lastName }: MessagingContact) => `${firstName} ${lastName}`.toLowerCase()

const RecipientRow = ({ contact, emailsOnly, onRemove }: RecipientRowProps) => {
  const classes = useMessageRecipientRowStyles()
  let icon
  if (contact.sendToPhone && !emailsOnly) {
    icon = <PhoneIcon className={classes.rowIcon} />
  } else if (contact.email) {
    icon = <AlternateEmailIcon className={classes.rowIcon} />
  } else {
    icon = <FeedbackOutlinedIcon className={cn(classes.rowIcon, classes.error)} />
  }
  return (
    <div className={classes.recipientRow}>
      <div className={classes.recipientContainer}>
        {icon}{' '}
        <div className={classes.recipientNameContainer}>
          <div className={classes.recipientName}>
            {contact.firstName} {contact.lastName}
          </div>
          {!contact.email && (!contact.sendToPhone || emailsOnly) ? (
            <div className={classes.recipientErrorInfo}>
              Invalid: {isBounced(contact, emailsOnly) ? 'Bounced' : 'No Contact Information on File'}
            </div>
          ) : null}
        </div>
      </div>
      <CloseIcon className={classes.closeIcon} onClick={onRemove} />
    </div>
  )
}

const AddRecipient = ({ handleClick }: { handleClick: () => void }) => {
  const classes = useMessageRecipientRowStyles()
  return (
    <div className={cn(classes.recipientRow, classes.button)} onClick={handleClick}>
      <div className={classes.recipientContainer}>
        <AddCircleIcon className={classes.rowIcon} />{' '}
        <div className={classes.recipientNameContainer}>
          <div className={classes.recipientName}>Add Recipient</div>
        </div>
      </div>
    </div>
  )
}

const getFirstLastId: GetFirstLastId<NotificationBlastContact | MessagingContact> = ({ id, firstName, lastName }) => ({
  id,
  firstName,
  lastName,
})

const MessageRecipientSelector = ({
  contacts,
  setContacts,
  emailsOnly,
  getSearchableContacts,
  autocompleteSectionTitle,
  fetchMessageOverview,
}: MessageRecipientSelectorProps) => {
  const classes = useMessageRecipientSelectorStyles()
  const [open, setOpen] = useState<boolean>(false)
  const [autocompleteOpen, setAutocompleteOpen] = useState<boolean>(false)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [searchableContacts, setSearchableContacts] = useState<MessagingContact[] | null>(null)
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const { text, more } = useRecipientsPlaceholderText<NotificationBlastContact>({ contacts, getFirstLastId })
  const contactIds = useMemo(() => map(contacts, 'id'), [contacts])

  const handleAddRecipient = useCallback(async () => {
    setAutocompleteOpen(true)
    if (isNull(searchableContacts)) {
      const searchableContactsResult = filter(await getSearchableContacts(), ({ id }) => !contactIds.includes(id))
      setSearchableContacts(sortBy(searchableContactsResult, sortByFunction))
    }
  }, [contactIds, getSearchableContacts, searchableContacts])

  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts, displayFrom } =
    useDisplayAreaInfo<NotificationBlastContact>({
      contacts,
      scrollTopPosition,
      rowHeight: ROW_HEIGHT,
      height: autocompleteOpen ? HALF_HEIGHT : FULL_HEIGHT,
    })

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    if (requestRunning === null) {
      const { scrollTop } = e.currentTarget
      requestRunning = window.requestAnimationFrame(function () {
        setScrollTopPosition(scrollTop)
        requestRunning = null
      })
    }
  }

  const handleClick = () => {
    setScrollTopPosition(0)
    setOpen(val => !val)
  }

  const removeContact = (index: number) => {
    const { id, firstName, lastName } = first(contacts.slice(index, index + 1)) as NotificationBlastContact
    setContacts([...contacts.slice(0, index), ...contacts.slice(index + 1)])
    if (!isNull(searchableContacts)) {
      setSearchableContacts(sortBy([...searchableContacts, { id, firstName, lastName }], sortByFunction))
    }
  }

  const handleAddContact = useCallback(
    async (id: number) => {
      const result = await fetchMessageOverview({ recipientPersonIds: [id] })
      setContacts([...contacts, ...result.contacts])
      if (!isNull(searchableContacts)) {
        const index = findIndex(searchableContacts, { id })
        setSearchableContacts([...searchableContacts.slice(0, index), ...searchableContacts.slice(index + 1)])
      }
      setAutocompleteOpen(false)
    },
    [contacts, fetchMessageOverview, searchableContacts, setContacts],
  )

  return (
    <>
      <div
        id="message-recipient-selector"
        className={cn(classes.input, { [classes.open]: open })}
        tabIndex={-1}
        onClick={handleClick}
        ref={anchorEl}
      >
        <div className={classes.text}>
          {text}
          {more ? (
            <span>
              {'\u00A0'}+{more} more
            </span>
          ) : null}
        </div>
        {open ? (
          <ExpandLessIcon className={classes.expandCollapse} />
        ) : (
          <ExpandMoreIcon className={classes.expandCollapse} />
        )}
      </div>
      <Popper
        className={classes.popper}
        id="message-recipient-selector-popper"
        open={open}
        anchorEl={anchorEl.current}
        transition
        tabIndex={-1}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div tabIndex={-1} className={classes.popperPaper}>
              <div
                className={cn(classes.recipients, { [classes.shortened]: autocompleteOpen })}
                onScroll={handleScroll}
              >
                <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />

                {visibleContacts.map((contact, index) => (
                  <RecipientRow
                    key={contact.id}
                    contact={contact}
                    emailsOnly={emailsOnly}
                    onRemove={() => removeContact(displayFrom + index)}
                  />
                ))}
                <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
              </div>
              <div style={{ borderTop: '1px rgba(0,0,0,0.1) solid', margin: '5px 9.5px' }} />
              {autocompleteOpen ? (
                <PersonEmbeddedAutocomplete<MessagingContact>
                  sectionTitle={autocompleteSectionTitle}
                  onAdd={handleAddContact}
                  selectedContactCount={contacts.length}
                  contacts={searchableContacts}
                  getFirstLastId={getFirstLastId}
                />
              ) : (
                <AddRecipient handleClick={handleAddRecipient} />
              )}
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default (props: MessageRecipientSelectorProps) => (
  <ThemeProvider theme={muiTheme}>
    <MessageRecipientSelector {...props} />
  </ThemeProvider>
)
