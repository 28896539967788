import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useArchiveVolunteer from './useArchiveVolunteer'
import { useCallback } from 'react'
import { volunteerSummary } from './utils'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'

const useArchiveVolunteerPrompt = (eagerRemoveMany: any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const archiveVolunteer = useArchiveVolunteer(eagerRemoveMany)

  return useCallback(
    async volunteers => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Are you sure you want to archive ${volunteerName} from volunteers?  His/her activities will no longer appear in reports.`
        : `Are you sure you want to archive ${numVolunteers} volunteers?  Their activities will no longer appear in reports.`

      const confirmed = await showPrompt({
        text,
        title: 'Archive Volunteer',
        confirmText: 'Archive Volunteer',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        await archiveVolunteer(volunteers)
      }
    },
    [showPrompt, archiveVolunteer],
  )
}

export default useArchiveVolunteerPrompt
