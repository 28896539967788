import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import qs from 'query-string'
import moment from 'moment'

import { useEditMatch } from '../hooks'
import { CellProps } from 'react-table'
import { Tag } from 'new-mentorship/types'
import { DATE_RANGE, DYNAMIC_NUMBER, DynamicTextArray } from 'core/table/filters'
import { EditHeader } from 'core/table/cells'
import { useTableColumnStyles as useStyles } from 'core/table/table-hooks'

import Tags from 'components/tag/Tags'
import { MentorshipMatchesEditCell } from '../components/MentorshipMatchesEditCell'

export function useMentorshipMatchesColumns({ showAddMatch, fetchMatches }: any) {
  const classes = useStyles()
  const { editMatch } = useEditMatch()

  return useMemo(
    () => [
      {
        Header: 'Program',
        accessor: 'programName',
        id: 'programName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ program: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentor',
        accessor: 'mentorName',
        id: 'mentorName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ mentor: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Mentee',
        accessor: 'menteeName',
        id: 'menteeName',
        filter: 'includesSome',
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Link
            className={classes.link}
            to={{
              pathname: '/mentorship/meetings',
              search: qs.stringify({ mentee: [value] }, { arrayFormat: 'bracket' }),
            }}
          >
            {value}
          </Link>
        ),
      },
      {
        Header: 'Matched Since',
        accessor: 'startedAt',
        id: 'startedAt',
        disableFilters: true,
        filter: DATE_RANGE,
        Cell: ({ cell: { value } }: CellProps<any>) => moment(value).format('MMMM D, YYYY'),
      },
      {
        Header: '# Meetings',
        accessor: 'meetingCount',
        id: 'meetingCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify(
                  { mentee: [o.menteeName], mentor: [o.mentorName], type: ['meeting'] },
                  { arrayFormat: 'bracket' },
                ),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },
      {
        Header: '# Moments',
        accessor: 'momentCount',
        id: 'momentCount',
        filter: DYNAMIC_NUMBER,
        Cell: ({
          cell: {
            value,
            row: { original: o },
          },
        }: CellProps<any>) =>
          value ? (
            <Link
              className={classes.link}
              to={{
                pathname: '/mentorship/meetings',
                search: qs.stringify(
                  { mentee: [o.menteeName], mentor: [o.mentorName], type: ['moment'] },
                  { arrayFormat: 'bracket' },
                ),
              }}
            >
              {value}
            </Link>
          ) : (
            <span>{value || 0}</span>
          ),
      },

      {
        Header: 'Avg. Meeting Time',
        accessor: 'averageMeetingDuration',
        id: 'averageMeetingDuration',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => (value && value + ' min') || '-',
      },

      {
        Header: 'Tags',
        accessor: 'tags',
        id: 'tags',
        filter: DynamicTextArray('name'),
        Cell: ({ cell: { value } }: CellProps<any>) => (
          <Tags
            tags={value.map(({ name: label, id, color }: Tag) => ({ label, id, color, foregroundColor: 'white' }))}
          />
        ),
      },
      {
        Header: <EditHeader hideDelete />,
        accessor: 'id',
        disableSortBy: true,
        Cell: ({ cell }: CellProps<any>) => (
          <MentorshipMatchesEditCell
            cell={cell}
            showAddMatch={showAddMatch}
            editMatch={editMatch}
            fetchMatches={fetchMatches}
          />
        ),
      },
    ],
    [classes.link, showAddMatch, editMatch, fetchMatches],
  )
}

export default useMentorshipMatchesColumns
