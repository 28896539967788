import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { TripOrderPayload } from '../types'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

export const useTripOrderDriverAssignment = (): [
  (body: Partial<TripOrderPayload>) => Promise<TripOrderPayload>,
  Status<TripOrderPayload>,
] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripOrderPayload>({
    successMessage: 'Assigned driver successfully updated',
    errorMessage: 'Error updating driver',
  })
  const updateDriver = useCallback(
    async (data: any) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/trip-orders/${data.id}/events`,
        config: {
          body: {
            payload: data.payload,
            eventCode: data.eventCode,
          },
        },
      })
    },
    [request, organization.id],
  )

  return [updateDriver, status]
}
