import React, { useState } from 'react'
import pickerData from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {
  Chat,
  MessageInput,
  MessageList,
  TypingIndicator,
  useChannelMembers,
  MessagePayload,
} from '@pubnub/react-chat-components/dist/index'

import 'chats/components/pubnub-restyle.scss'
import { Chat as IChat } from 'chats/interfaces'

export default function ChatDialog({ currentChannel }: { currentChannel: IChat }): JSX.Element {
  const [theme] = useState<'light' | 'dark'>('light')

  /**
   * All data related to Users, Channels and Memberships is stored within PubNub Objects API
   * It can be easily accessed using React Chat Components hooks
   */

  const [channelMembers, , , totalChannelMembers] = useChannelMembers({
    channel: currentChannel.id,
    include: { customUUIDFields: true },
  })

  /**
   * Handling publish errors
   */
  const handleError = (e: any) => {
    if (
      (e.status?.operation === 'PNPublishOperation' && e.status?.statusCode === 403) ||
      e.message.startsWith('Publish failed')
    ) {
      alert(
        'Your message was blocked. Perhaps you tried to use offensive language or send an image that contains nudity?',
      )
    }

    console.warn(e)
  }

  /**
   * Rendered markup is a mixture of PubNub Chat Components (Chat, ChannelList, MessageList,
   * MessageInput, MemberList) and some elements to display additional information and to handle
   * custom behaviors (channels modal, showing/hiding panels, responsive design)
   */
  return (
    <div className={`app-moderated app-moderated--${theme}`}>
      {/* Be sure to wrap Chat component in PubNubProvider from pubnub-react package.
        In this case it's done in the index.tsx file */}
      {/* Current uuid is passed to channels prop to subscribe and listen to User metadata changes */}
      <Chat theme={theme} users={channelMembers} currentChannel={currentChannel.id} onError={handleError}>
        <div className="chat-window">
          <div className="channel-info">
            <span>
              <strong>{currentChannel.name || currentChannel.id}</strong>
              <p>{totalChannelMembers} members</p>
            </span>
            <hr />
          </div>
          <MessageList
            fetchMessages={25}
            enableReactions={true}
            reactionsPicker={<Picker data={pickerData} theme={theme} />}
          />
          <TypingIndicator />
          <hr />
          <MessageInput
            typingIndicator
            fileUpload="image"
            emojiPicker={<Picker data={pickerData} theme={theme} />}
            placeholder={'Send message'}
            onBeforeSend={(message: MessagePayload) => {
              // FIXME:
              return Object.assign(message, {
                pn_apns: {
                  aps: {
                    alert: {
                      title: 'Notification',
                      body: message.text,
                    },
                  },
                  pn_push: [
                    {
                      push_type: 'alert',
                      auth_method: 'token',
                      targets: [
                        {
                          environment: 'production',
                          topic: 'com.mentoringworks',
                        },
                      ],
                      version: 'v2',
                    },
                  ],
                },
                pn_debug: true,
                pn_gcm: {
                  notification: {
                    title: 'Notification',
                    sound: 'default',
                    body: message.text,
                  },
                },
              })
            }}
          />
        </div>
      </Chat>
    </div>
  )
}
