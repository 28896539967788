import * as moment from 'moment'
import * as _ from 'lodash'

export const formatDate = (date) => date ? moment(date)
  .clone()
  .utc()
  .format('MM/DD/YYYY hh:mm:ss a') : date

export const volunteerSummary = volunteers => {
  const numVolunteers = _.size(volunteers)
  const onlyOneVolunteer = numVolunteers === 1
  const volunteerName = onlyOneVolunteer ? `${volunteers[0].user.givenName} ${volunteers[0].user.familyName}` : ''
  return { numVolunteers, onlyOneVolunteer, volunteerName }
}
