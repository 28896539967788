import _ from 'lodash'
import moment from 'moment'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ShiftItem from './shift-item/index'
import BigPlusButton from '../../../../components/big-plus-button/index'
import MultiStepNavButton from '../navigation-button-area'
import { useFormatDates } from '../../../../helpers/toFormatFullDateToISO'
import { INIT_VALIDATOR } from '../../../../helpers/validation'
import { toCopyArray } from '../../../../helpers/toCopyArray'

import './index.scss'

const emptyRoles = {
  name: '',
  available: null,
  is_editable: true,
}

const emptyShift = index => ({
  time_start: '9:00 AM',
  time_end: '10:00 AM',
  name: `Shift ${index}`,
  is_editable: true,
  roles: [{ ...emptyRoles }],
  created_at: moment().toISOString(),
})

const defaultErrorMessages = {
  timeshiftsLength: '',
}

const ShiftModule = props => {
  const {
    onNextClick,
    onPrevClick,
    onSaveDraftClick,
    isSendingDraftRequest,
    event,
    editMode = false
  } = props

  const { timeshifts, startsAt, endsAt, published, dates } = event
  const { toFormatFullCustomDate } = useFormatDates()

  const [shiftState, setShiftState] = useState(timeshifts)
  const [isClickedToNextButton, setIsClickedToNextButton] = useState(null)
  const [shiftStateWithValidField, setShiftStateWithValidField] = useState([])

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage } = customValidator
  //Ending initializing

  useEffect(() => {
    props.setEventField('timeshifts', shiftState)

    const minTime = moment.min(shiftState.map(item => moment(item.time_start, 'h:mmA')))
    const maxTime = moment.max(shiftState.map(item => moment(item.time_end, 'h:mmA')))

    const startDateFull = toFormatFullCustomDate(minTime, startsAt)
    const endDateFull = toFormatFullCustomDate(maxTime, endsAt)

    props.setEventField('startsAt', startDateFull)
    props.setEventField('endsAt', endDateFull)

    if (dates && dates.length) {
      props.setEventField(
        'dates',
        dates.map(date => toFormatFullCustomDate(minTime, date, false)),
      )
    }

    if (timeshifts && timeshifts.length) {
      onChangeErrorMessage('timeshiftsLength', '')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shiftState])

  const handleAddNewShifts = () => {
    setShiftState([...shiftState, { ...emptyShift(shiftState.length + 1) }])
  }

  const handleRemoveShift = idx => () => {
    const updatedShifts = [...shiftState]
    updatedShifts.splice(idx, 1)
    setShiftState(updatedShifts)
  }

  const handleDuplicateShift = index => () => {
    const duplicatedShift = _.cloneDeep(shiftState[index])
    delete duplicatedShift.id
    duplicatedShift.created_at = moment().toISOString()
    setShiftState([...shiftState, duplicatedShift])
  }

  const handleChangeField = idx => (val, field) => {
    setIsClickedToNextButton(false)
    const updatedShifts = [...shiftState]
    updatedShifts[idx][field] = val
    setShiftState(updatedShifts)
  }

  const handleNextClick = () => {
    setIsClickedToNextButton(true)
    if (!timeshifts.length) {
      onChangeErrorMessage('timeshiftsLength', 'Please add at least one event')
    } else {
      !isHaveErrorAtTimeShifts() && onNextClick()
    }
  }

  const handleChangeValidField = idx => value => {
    const updatedShifts = toCopyArray(shiftState)
    updatedShifts[idx]['is_valid'] = value
    setShiftStateWithValidField(updatedShifts)
  }

  const isHaveErrorAtTimeShifts = () => {
    const isHaveFalseVal = shiftStateWithValidField.some(i => i.is_valid === false)
    const isHaveNullVal = shiftStateWithValidField.some(i => i.is_valid === null)
    return isHaveFalseVal || isHaveNullVal
  }

  const toDisableShiftEditing = shift => {
    if (!editMode) {
      return false
    }

    return !shift.is_editable
  }

  const toEnableDelete = shift => {
    if (!shift.is_editable) {
      return false
    }

    if (_.size(shiftState) <= 1) {
      return false
    }

    return true
  }

  return (
    <div className="shift-module">
      <h2 className="shift-module__title">Add Shift</h2>
      <div className="shifts-form">
        <div className="shift-list">
          {shiftState.map((shift, idx) => (
            <ShiftItem
              editMode={editMode}
              timeshifts={shiftState}
              isShiftEditingDisabled={toDisableShiftEditing(shift)}
              key={shift.created_at}
              shift={shift}
              enableShiftDelete={toEnableDelete(shift)}
              onChangeField={handleChangeField(idx)}
              onDuplicateShift={handleDuplicateShift(idx)}
              onRemoveShift={handleRemoveShift(idx)}
              onChangeValidField={handleChangeValidField(idx)}
              isClickedToNextStep={isClickedToNextButton}
            />
          ))}
        </div>
        <BigPlusButton
          title="Add shift"
          className={!!shiftState.length ? 'add-shift-button-wrap' : ''}
          onClick={handleAddNewShifts}
        />
        <span className="shifts-error-message">{errorMessages.timeshiftsLength}</span>
      </div>
      <MultiStepNavButton
        isShowDraftButton={editMode ? !published : true}
        draftButtonText={editMode ? 'Update Draft' : 'Save For Later'}
        onNextClick={handleNextClick}
        onPrevClick={onPrevClick}
        onSaveDraftClick={onSaveDraftClick}
        isSendingRequest={isSendingDraftRequest}
      />
    </div>
  )
}

ShiftModule.propTypes = {
  onPrevClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onSaveDraftClick: PropTypes.func.isRequired,
  isSendingDraftRequest: PropTypes.bool,
  event: PropTypes.object,
  editMode: PropTypes.bool,
}

export default ShiftModule
