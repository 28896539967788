import { Address, Geofencing } from 'civic-champs-shared/core/location/utils'
import { Person } from 'people/interface'

export enum TripOrderEventType {
  DRIVER_UNASSIGNED = 'driver-unassigned',
  DISPATCHER_CANCELLED = 'dispatcher-cancelled',
  DRIVER_CANCELLED = 'driver-cancelled',
  UPDATED = 'updated',
  DRIVER_REASSIGNED = 'driver-reassigned',
  STARTED = 'started',
  DRIVER_FEEDBACK = 'driver-feedback',
  DRIVER_NO_SHOW = 'driver-no-show',
  RIDER_NO_SHOW = 'rider-no-show',
  PICKED_UP = 'picked-up',
  DRIVER_COMPLETED = 'driver-completed',
  DRIVER_CONFIRMED = 'driver-confirmed',
  DRIVER_ASSIGNED = 'driver-assigned',
  DISPATCHER_COMPLETED = 'dispatcher-completed',
  CREATED = 'created',
}

export enum TripRequestEventType {
  CANCELLED = 'cancelled',
  IN_PROGRESS = 'in-progress',
  COMPLETED = 'completed',
  CREATED = 'created',
}

export interface TripRequest {
  tripOrders: TripOrder[]
  rider: Person
  riderId: number
  tripOrderIndex: number
}

export interface TripRequestPayload {
  tripOrders?: TripOrder[]
  rider?: Person
}

export interface TripLocation {
  address: Address
  geofencing: Geofencing
}

export interface TripOrderPayload {
  id?: number
  driver?: Person
  driverId?: number
  description: string
  dropOffAt: Date
  dropOffLocation: TripLocation
  pickupAt: Date
  pickupLocation: TripLocation
  validated: boolean
  createdBy: Person
  createdById?: number
  payload: any
  eventCode?: TripOrderEventType
}

export interface TripOrder {
  id?: number
  driver?: Person
  description: string
  dropOffAt: Date
  dropOffLocation: TripLocation
  pickupAt: Date
  pickupLocation: TripLocation
  validated: boolean
  createdBy: Person
}

export interface MappedTripTableRow {
  subRows: any
  orderName: string
  pickupTime: any
  date: any
  tripOrderCount: any
  assignedDriverCount: any
  driver: null
  status: any
  actions?: string
  data: any
}
