import { useMemo } from 'react'
import { Row } from 'react-table'
import reduce from 'lodash/reduce'

import { ProgramMeetingType } from '../types'

export const useMentorshipMeetingsSummary = (rows: Row<any>[]) =>
  useMemo(() => {
    const { meetingCount, momentCount, totalMeetingTime, positiveFeedbackCount, negativeFeedbackCount } = reduce(
      rows,
      (acc, { original: { type, duration, mentorRating, menteeRating } }: Row<any>) => ({
        meetingCount: acc.meetingCount + (type === ProgramMeetingType.meeting ? 1 : 0),
        momentCount: acc.momentCount + (type === ProgramMeetingType.moment ? 1 : 0),
        totalMeetingTime: acc.totalMeetingTime + duration,
        positiveFeedbackCount:
          acc.positiveFeedbackCount + (mentorRating === true ? 1 : 0) + (menteeRating === true ? 1 : 0),
        negativeFeedbackCount:
          acc.negativeFeedbackCount + (mentorRating === false ? 1 : 0) + (menteeRating === false ? 1 : 0),
      }),
      {
        meetingCount: 0,
        momentCount: 0,
        totalMeetingTime: 0,
        positiveFeedbackCount: 0,
        negativeFeedbackCount: 0,
      },
    )
    const feedbackCount = positiveFeedbackCount + negativeFeedbackCount
    return {
      meetingCount,
      momentCount,
      averageMeetingTime: meetingCount ? Math.round(totalMeetingTime / meetingCount) : 0,
      positiveFeedbackPercent:
        (feedbackCount ? Math.round((positiveFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
      negativeFeedbackPercent:
        (feedbackCount ? Math.round((negativeFeedbackCount / feedbackCount) * 10000) / 100 : 0) + '%',
    }
  }, [rows])
