import { find } from 'lodash'
import React, { useMemo, useState, useEffect } from 'react'
import { RECURRING_TYPE_OPTIONS, RECURRING_TYPE } from 'Event/constants/RECURRING_TYPE_OPTIONS'
import SelectComponent from '../select'

const RecurringTypeSelect = (props) => {
    const { startsAt, value, onChange, editMode } = props

    const options = useMemo( () => RECURRING_TYPE_OPTIONS(startsAt), [startsAt])

    const setInitialOption = () => {
      let option = find(options, { value });
      if (option == null) {
        option = find(options, { value: RECURRING_TYPE.NEVER })
      }

      return option
    }

    const [option, setOption] = useState(setInitialOption())

    const handleOnChange = option => { 
        setOption(option)
        if(onChange) onChange(option.value) 
    }

    useEffect(() => {
      editMode && setOption(setInitialOption(value))
    }, [value])

    return (
        <SelectComponent
            disabled={!editMode ? !startsAt : true}
            value={option}
            onChange={handleOnChange}
            options={options}
            className="select"
        />
    );
}

export default RecurringTypeSelect;