import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Loading from 'civic-champs-shared/core/Loading'
import { QuestionSetPicker } from './QuestionSetPicker'
import { useSetGlobalUserProfileQuestionSets } from '../hooks/useSetGlobalUserProfileQuestionSets'
import { useFetchGlobalUserProfileQuestionSets } from '../hooks/useFetchGlobalUserProfileQuestionSets'
import * as _ from 'lodash'
import { ReadOnlyQuestionSet } from 'civic-champs-shared/question-sets/components/ReadOnlyQuestionSet'

const ReadonlyQuestionSets = ({ existingQuestionSets }) => {
  if (_.size(existingQuestionSets)) {
    return (
      <>
        {existingQuestionSets.map(questionSet => (
          <ReadOnlyQuestionSet questionSetId={questionSet.questionSet.id} />
        ))}
      </>
    )
  } else {
    return <div>No questions</div>
  }
}

export const GlobalUserProfileQuestionnaires = ({ readonly }) => {
  const [existingQuestionSets, setExistingQuestionSets] = useState([])
  const [setUserProfileQuestionSets] = useSetGlobalUserProfileQuestionSets(setExistingQuestionSets)
  const [
    fetchUserProfileQuestionSets,
    { loading: userProfileQuestionSetsLoading, called },
  ] = useFetchGlobalUserProfileQuestionSets()

  useEffect(() => {
    fetchUserProfileQuestionSets().then(res => setExistingQuestionSets(res))
  }, [])

  const handleSubmit = async questionSets => {
    await setUserProfileQuestionSets(questionSets)
  }

  const loading = userProfileQuestionSetsLoading || !called
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <Grid container direction="column" spacing={3}>
        <Grid container item>
          {readonly ? (
            <ReadonlyQuestionSets existingQuestionSets={existingQuestionSets} />
          ) : (
            <QuestionSetPicker
              loading={loading}
              showRequired
              title={
                <Typography variant="h4">Global User Profile Questionnaires</Typography>
              }
              value={existingQuestionSets.map(userProfileQuestionSet => ({
                questionSetId: userProfileQuestionSet.questionSet.id,
                name: userProfileQuestionSet.questionSet.name,
                optional: !userProfileQuestionSet.required,
              }))}
              onSubmit={handleSubmit}
              isGlobalUserProfile
            />
          )}
        </Grid>
      </Grid>
    </>
  )
}
