//TODO move this to the /filtering package
import { getDateRangeFromPreset, DATE_RANGE_THIS_YEAR } from 'components/filter/DateRangePresets';
import { SET_DATE_RANGE_FILTER } from './actions';

//TODO track when filter was last updated so we can ignore current settings on hydrate after too long
const defaultState = () => ({
    dateRange: getDateRangeFromPreset(DATE_RANGE_THIS_YEAR)
});

export default function reducer(state = defaultState(), action) {
    switch(action.type) {
        case SET_DATE_RANGE_FILTER:
            return {
                ...state,
                dateRange: action.payload.dateRange
            }
        default:
            return state;
    }
}