import { useCallback } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { NamedRef } from 'civic-champs-shared/common/types'

export const useRemoveQuestionSet = (eagerRemove: any) => {
  const [request] = useFetch()
  const createNotification = useCreateApiNotification()
  const currentOrg = useCurrentOrg()

  return useCallback(
    async (questionSet: NamedRef) => {
      const undo = eagerRemove(questionSet)
      const notification = createNotification('Removing questionnaire')
      try {
        // @ts-ignore
        await request({
          method: 'del',
          url: `/organizations/${currentOrg.id}/question-set/${questionSet.id}`,
        })

        notification.onSuccess('Questionnaire removed!')
      } catch (error) {
        notification.onError(`Could not remove ${questionSet.name} from questionnaires`, error)
        undo()
      }
    },
    [createNotification, currentOrg.id, eagerRemove, request],
  )
}

export default useRemoveQuestionSet
