import format from 'utils/format'
import moment from 'moment'
import activitySources from 'tracking/enum/activitySources'
import { LOG_OTHER_HOURS } from 'tracking/enum/entryMethod'
import { get } from 'lodash'

export default function useActivityExportConfig() {
  const filename = 'activities.csv'
  const headers = [
    { label: 'First Name', key: 'user.givenName' },
    { label: 'Last Name', key: 'user.familyName' },
    { label: 'Email', key: 'user.email' },
    { label: 'Phone Number', key: 'user.phoneNumber' },
    { label: 'Opportunity', key: 'opportunity.name' },
    { label: 'Activity', key: 'volunteeringRole.name' },
    { label: 'Check-In', key: 'checkIn' },
    { label: 'Check-Out', key: 'checkOut' },
    { label: 'Hours', key: 'hoursVolunteered' },
    { label: 'Reflection', key: 'reflection' },
    { label: 'Rating', key: 'volunteerSatisfaction' },
    { label: 'High-Fives', key: 'rating' },
    { label: 'Groups/Team', key: 'team' },
    { label: 'Address', key: 'location.address.line1' },
    { label: 'City', key: 'location.address.city' },
    { label: 'State', key: 'location.address.state' },
    { label: 'Zip', key: 'location.address.zipCode' },
  ]
  const useFormattedData = data =>
    data.map(o => {
      const source = activitySources[o.occurredAtSource]
      const entryMethod = get(source, 'entryMethod', LOG_OTHER_HOURS)
      const fromLogOtherHours = entryMethod === LOG_OTHER_HOURS

      if (fromLogOtherHours) {
        o.checkIn = format.date(moment(o.occurredAt).startOf('day'))
        o.checkOut = ''
      } else {
        o.checkIn = format.datetimeForExport(o.occurredAt)
        o.checkOut = format.datetimeForExport(moment(o.occurredAt).add(o.hoursVolunteered, 'hours'))
      }
      o.hoursVolunteered = format.hours(o.hoursVolunteered, 2)
      if (o.user) {
        o.user.phoneNumber = format.phoneNumber(o.user.phoneNumber)
      }
      return o
    })

  return { headers, filename, useFormattedData }
}
