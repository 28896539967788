import React, { FC, useCallback } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import isUndefined from 'lodash/isUndefined'
import map from 'lodash/map'
import { EventQuestionnaireEditorProps } from 'Event/components/EventSurveyQuestionSetEditor'
import { useEditQuestionSet, useQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const EventQuestionSetEditor: FC<EventQuestionnaireEditorProps> = ({ eventForm, setEventField, backLink, id }) => {
  const isNew = isUndefined(id)
  const questionSetId = isNew ? undefined : parseInt(id as string)
  const { questionSet, loading: fetchLoading } = useQuestionSet(questionSetId)
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet()
  const organization = useCurrentOrg()
  const onSubmit = useCallback(
    async payload => {
      const result = await editQuestionSet({ ...payload, organization, questionSetId })
      if (isNew) {
        setEventField('questionSets', [
          ...eventForm.event.questionSets,
          {
            questionSetId: result.questionSetId,
            name: result.name,
            optional: false,
            required: true,
          },
        ])
      } else {
        setEventField(
          'questionSets',
          map(eventForm.event.questionSets, questionSet =>
            questionSet.questionSetId !== questionSetId ? { ...questionSet } : { ...questionSet, name: result.name },
          ),
        )
      }
      return result
    },
    [editQuestionSet, eventForm.event.questionSets, isNew, organization, questionSetId, setEventField],
  )

  if (!isNew && fetchLoading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={editLoading}
      onSubmit={onSubmit}
      questionSet={questionSet}
      backLink={backLink}
      backText="Back to Event Questionnaires"
      title={isNew ? 'New Questionnaire' : 'Edit Questionnaire'}
    />
  )
}

export default EventQuestionSetEditor
