import { get } from 'lodash'
import React, { Component } from 'react'
import Button from '../../components/button'
import BaseService from '../../Mentorship/services/base.service'
import { Loader } from '../../Mentorship/kit/components/loader'
import Table from '../../Mentorship/kit/components/table'
import { MatchFilters } from '../../Mentorship/kit/modules/mentor-ship/matches'
import { withCurrentOrganizationHOC } from 'Event/helpers/withCurrentOrganizationHOC'
import { FormControlLabel, Switch } from '@material-ui/core'

interface ParsedResultProps {
  currentOrganization: any
  rows: [] | undefined
  cancel: () => void
  getResults?: (params: MatchFilters) => void
  confirmParsedRows: (orgId: number, newRows: any[], sendInvite?: boolean) => Promise<any>
  columns: any[]
  friendlyReference: string
}

interface ParsedResultState {
  isLoading?: boolean
  confirmError?: string | null
  sendInvite: boolean
}

class ParsedRows extends Component<ParsedResultProps, ParsedResultState> {
  constructor(props: ParsedResultProps) {
    super(props)
    this.state = {
      isLoading: false,
      sendInvite: false
    }
  }

  request = new BaseService()

  confirmParsedRows = async () => {
    this.setState({
      isLoading: true,
      confirmError: null
    })
    try {
      const orgId = get(this.props.currentOrganization, 'id') as number
      const newRows = this.props.rows!.filter(({isNew}) => isNew)
      const response = await this.props.confirmParsedRows(orgId, newRows, this.state.sendInvite)
      const errTest = new RegExp(/(4|5)[0-9]{2,}/, 'g')
      if (errTest.test(String(response.statusCode))) {
        throw new Error(response.message)
      }
      if (response.success && this.props.getResults) {
        this.props.getResults({
          offset: 0,
          limit: 15,
          archived: false
        })
        this.props.cancel()
      }
    } catch (err) {
      this.setState({ confirmError: err.message })
    } finally {
      this.setState({ isLoading: false })
    }
  }

  updateSendInvite = () => {
    this.setState({ sendInvite: !this.state.sendInvite })
  }

  render() {
    if (!this.props.rows) {
      return null
    }
    const { rows } = this.props

    return (
      <>
        {this.state.isLoading && <Loader />}
        <div className="upload-csv-popup__matches-count">{rows!.filter(({isNew}) => isNew).length} New {this.props.friendlyReference}</div>
        <div className="upload-csv-popup__matches-table">
          <Table
            columns={this.props.columns}
            data={rows}
          />
        </div>
        {this.state.confirmError && <div className="upload-csv-popup__matches-error">{this.state.confirmError}</div>}

        <div>
          <FormControlLabel
            control={<Switch name="sendInvite" checked={this.state.sendInvite} onChange={this.updateSendInvite}/>}
            label="Send Invitation"
          />
        </div>

        <div className="upload-csv-popup__matches-btns">
          <Button className="upload-csv-popup__matches-btn cancel" disabled={this.state.isLoading} onClick={this.props.cancel}>Cancel</Button>
          <Button
            className="upload-csv-popup__matches-btn confirm"
            onClick={this.confirmParsedRows}
            isLoading={this.state.isLoading}
            disabled={rows!.filter(({isNew}) => isNew).length === 0 || this.state.isLoading}
          >
            Confirm & Complete Upload
          </Button>
        </div>
      </>
    )
  }
}

export default withCurrentOrganizationHOC(ParsedRows)
