import { find, map } from 'lodash'
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { Box, Grid, makeStyles, Switch, Typography } from '@material-ui/core'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import EditIcon from '@material-ui/icons/Edit'

import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { ContainedButton, Loading, TableMenuButton } from 'civic-champs-shared/core/'
import { ReadOnlyQuestionSetQuestions } from 'civic-champs-shared/question-sets/components/'
import Select from 'civic-champs-shared/core/select'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { useHasRole } from 'auth/hooks'
import { SurveyVersion } from 'surveys/types'
import CreateUpdateSurveyPrompt from 'surveys/components/CreateUpdateSurveyPrompt'
import BackLink from 'donation/campaigns/components/BackLink'
import { useDownloadExportedSurvey, useSurvey, useUpdateSurveyVisibility } from '../hooks/'
import { useInactiveQuestionSetPrompt } from '../../question-sets/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'
import { Redirect } from 'react-router-dom'

const useStyles = makeStyles(() => ({
  headerButton: {
    margin: 5,
    fontSize: 14,
  },
  toggle: {
    marginTop: 5,
  },
  toggleLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: 5,
  },
  statusLabel: {
    fontWeight: 'bold',
    marginRight: 5,
  },
}))

export interface SurveyDetailsProps {
  match: {
    params: { id: string }
    url: string
  }
  backLink?: string
  backText?: string
}

interface Option {
  value: number
  label: string
}

export const SurveyDetails: FC<SurveyDetailsProps> = ({
  match,
  backLink = '/questionnaires',
  backText = 'Back to Questionnaires',
}) => {
  const surveyId = parseInt(match.params.id)
  const [survey, setSurvey] = useState<any>(null)
  const [redirect, setRedirect] = useState<null | string>(null)
  const [{ loading, value: surveyData }] = useSurvey(surveyId)
  const classes = useStyles()
  const [downloadExportedSurvey] = useDownloadExportedSurvey()
  const isNewQuestionnairesEditorEnabled = useFeatureEnabled('NewQuestionnairesEditor')

  const showCreateUpdatePrompt = useShowPrompt(CreateUpdateSurveyPrompt)
  useEffect(() => {
    !loading && setSurvey(surveyData)
  }, [loading, surveyData])
  const handleEdit = useCallback(async () => {
    if (isNewQuestionnairesEditorEnabled) {
      setRedirect(`${match.url}/edit`)
    } else {
      setSurvey(await showCreateUpdatePrompt({ survey }))
    }
  }, [isNewQuestionnairesEditorEnabled, match.url, showCreateUpdatePrompt, survey])

  const [selectedVersion, setSelectedVersion] = useState<SurveyVersion | undefined>()
  useEffect(() => {
    // @ts-ignore
    !loading && setSelectedVersion(survey?.versions[0])
  }, [loading, survey])

  const [options, value] = useMemo(() => {
    const options = map(survey?.versions, ({ version }) => ({
      value: version,
      label: `Version ${version}`,
    })) as Option[]
    const value = find(options, { value: selectedVersion?.version })
    return [options, value]
  }, [survey, selectedVersion])

  const inactiveQuestionSetPrompt = useInactiveQuestionSetPrompt()
  const [updateSurveyVisibility] = useUpdateSurveyVisibility(surveyId, setSurvey)
  const isSuperAdmin = useHasRole(SUPER_ADMIN)

  const handleVersionChange = ({ value }: Option) => {
    setSelectedVersion(find(survey?.versions, { version: value }))
  }

  const onToggleActive = async (active: boolean) => {
    if (active) {
      updateSurveyVisibility(active)
    } else {
      inactiveQuestionSetPrompt(() => updateSurveyVisibility(active))
    }
  }

  if (redirect) {
    return <Redirect to={redirect} />
  }

  if (loading) {
    return <Loading />
  }

  const { anonymous, name, questions } = selectedVersion?.questionSet || {}

  return (
    <div>
      <Grid container spacing={4} direction="column">
        <Grid container item sm={10} spacing={2}>
          <Grid item xs={5}>
            <BackLink to={backLink} text={backText} />
          </Grid>
          <Grid item xs={7} container justify="flex-end">
            {isSuperAdmin && (
              <>
                <Typography className={classes.toggleLabel} component="span">
                  <Box fontWeight={'bold'} className={classes.statusLabel} component="span">
                    Status:
                  </Box>
                  {anonymous ? 'Inactive' : 'Active'}
                </Typography>

                <Switch
                  className={classes.toggle}
                  checked={!anonymous}
                  onChange={e => onToggleActive(e.target.checked)}
                />
              </>
            )}

            <TableMenuButton
              className={classes.headerButton}
              startIcon={<SaveAltOutlinedIcon />}
              onClick={() => {
                // @ts-ignore
                downloadExportedSurvey(survey, selectedVersion)
              }}
            >
              Export Responses
            </TableMenuButton>
            <ContainedButton startIcon={<EditIcon />} className={classes.headerButton} onClick={handleEdit}>
              Edit
            </ContainedButton>
          </Grid>
          <Grid item sm={8}>
            <Typography variant="h5">{name || 'Untitled Survey'}</Typography>
          </Grid>

          <Grid item sm={4}>
            <Select options={options} onChange={handleVersionChange} value={value}></Select>
          </Grid>
          <ReadOnlyQuestionSetQuestions questions={questions || []} />
        </Grid>
      </Grid>
    </div>
  )
}
