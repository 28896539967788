import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { AddActivityDialog } from '../components'
import { Activity } from 'champion/utils/interface'

export const useAddActivityPrompt = (eagerAdd: (activity: Activity) => {}) => {
  const showPrompt = useShowPrompt(AddActivityDialog)

  return useCallback(async () => {
    const savedActivity = await showPrompt()
    if (savedActivity) {
      eagerAdd(savedActivity)
    }
  }, [eagerAdd, showPrompt])
}

export default useAddActivityPrompt
