import React, { useEffect, useState } from 'react'
import { Typography } from '@material-ui/core'

import Loading from 'components/Loading'
import { QuestionSetPicker } from '../../question-sets/components/QuestionSetPicker'
import { useFetchGroupQuestionSets } from '../hooks/useFetchGroupQuestionSets'
import { useSetGroupQuestionSets } from '../hooks/useSetGroupQuestionSets'
import { useVolunteerGroup } from '../hooks/useVolunteerGroup'
import { WaiverPicker } from '../../question-sets/components/WaiverPicker'
import { useGroupCredentials } from '../hooks/useGroupCredentials'
import { useSetGroupCredentials } from '../hooks/useSetGroupCredentials'

export const VolunteerQuestionSets = () => {
  const [existingQuestionSets, setExistingQuestionSets] = useState([])
  const [existingCredentials, setExistingCredentials] = useState([])
  const { group, loading: groupLoading } = useVolunteerGroup()
  const { loading: credentialsLoading } = useGroupCredentials(group && group.id, setExistingCredentials)
  const [setGroupQuestionSets] = useSetGroupQuestionSets(setExistingQuestionSets)
  const [setGroupCredentials] = useSetGroupCredentials(setExistingCredentials)
  const [fetchGroupQuestionSets, { loading: groupQuestionSetsLoading, called }] =
    useFetchGroupQuestionSets(setExistingQuestionSets)

  useEffect(() => {
    if (group) {
      fetchGroupQuestionSets(group.id)
    }
  }, [fetchGroupQuestionSets, group])

  const handleQuestionSetSubmit = async questionSets => {
    await setGroupQuestionSets(group?.id, questionSets)
  }

  const handleCredentialSubmit = async credentialIds => {
    await setGroupCredentials(
      group?.id,
      credentialIds.map(id => ({ id, requiresReview: false })),
    )
  }

  const loading = groupLoading || groupQuestionSetsLoading || !called || credentialsLoading
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <QuestionSetPicker
        loading={loading}
        showRequired
        title={<Typography variant="h4">Champion Profile Questionnaires</Typography>}
        value={existingQuestionSets.map(groupQuestionSet => ({
          questionSetId: groupQuestionSet.questionSet.id,
          name: groupQuestionSet.questionSet.name,
          optional: !groupQuestionSet.required,
        }))}
        onSubmit={handleQuestionSetSubmit}
      />

      <WaiverPicker
        loading={loading}
        title={<Typography variant="h4">Champion Profile Waivers</Typography>}
        value={existingCredentials.map(credential => credential.id)}
        onSubmit={handleCredentialSubmit}
      />
    </>
  )
}
