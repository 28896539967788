import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'

import getErrorMessage from 'utils/getErrorMessage'
import usePublishCampaign from  '../hooks/usePublishCampaign'
import Loading from 'components/Loading'

const useStyles = makeStyles(theme => ({
    nextText: {
     color: 'white',
     zIndex: 1000,
     fontSize: '15px',
    },
    modalBody: {
      display: 'flex',
      backgroundColor: 'white',
      width: '583px',
      height: '392px',
      flexDirection: 'column',
      alignItems: 'center',
    },
    modal: {
      display: 'flex',
      alignSelf: 'center',
      justifyContent: 'center',
      alignItems: 'center',
    },
    modalTitle: {
      marginTop: '52px',
      fontSize: '33px',
    },
    modalSubTitle: {
      marginTop: '72px',
      fontSize: '23px',
    },
    modalButton: {
      width: '512px',
      height: '56px',
      background: '#5F8FE8',
      marginTop: '100px',
    },
}));

const RequiredDialog = ({ showing, children }) => (
    <Dialog
        open={showing}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
    >
        {children}
    </Dialog>
)

export default function PublishCampaignModal(props) {
    const { campaign, showing, close, complete } = props
    const classes = useStyles()

    const [
        publishCampaign, 
        { called, error, loading, result: finalCampaign }
    ] = usePublishCampaign()

    useEffect(() => { publishCampaign(campaign) }, [publishCampaign, campaign])
    
    const publishing = !called | loading

    const handleComplete = () => {
        if(publishing) return
        complete(finalCampaign)
    } 

    if(publishing) {
        return <RequiredDialog showing={showing}>
            <DialogContent>
                <Loading/>
            </DialogContent>
        </RequiredDialog>
    } else if (error)  {
        return <RequiredDialog showing={showing}>
            <DialogTitle>An Error Has Occurred!</DialogTitle>
            <DialogContent>
                <DialogContentText>{getErrorMessage(error)}</DialogContentText>  
            </DialogContent>
            <DialogActions>
                <Button 
                    className={classes.modalButton}
                    onClick={close}
                >
                    Back
                </Button>
            </DialogActions>
        </RequiredDialog>
    } else {
        return <RequiredDialog showing={showing}>
            <DialogTitle>Congratulations!</DialogTitle>
            <DialogContent>
                <DialogContentText>Your campaign is published</DialogContentText>
            </DialogContent> 
            <DialogActions>
                <Button
                    className={classes.modalButton}
                    onClick={handleComplete}
                >
                    <div className={classes.nextText}>
                        <b>Done</b>
                    </div> 
                </Button>
            </DialogActions>
        </RequiredDialog>
    }
}

/*
    <div className={classes.modalTitle}><b>Congratulations!</b></div>
    <div className={classes.modalSubTitle}>Your campaign is published</div>
*/