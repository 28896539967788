import React, { useMemo } from 'react'
import { Grid, Typography, Box, makeStyles } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import useMessagesSummary from 'messages/hooks/useMessagesSummary'
import SummaryCards from 'core/table/components/SummaryCards'
import { useHistory } from 'react-router'
import useOrganization from '../../../volunteering/organizations/hooks/useOrganization'
import { useCurrentOrg } from '../../../auth/hooks'
import { useCards, useColumns, useMessagesSummaryCards } from './hooks'

const useStyles = makeStyles((theme) => ({
  cell: {
      paddingTop: 5,
      paddingBottom: 5,
      maxWidth: 250,
      border: '1px solid rgba(224, 224, 224, 1)',
  },
  th: {
    border: 0,
  },
  summary: {
    marginTop: '30px',
    marginBottom: '10px',
  },
}))


const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: ['original.subject'],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
}

export const BulkMessagesOverview = props => {
  const { result: messages, loading: messagingLoading } = useMessagesSummary()
  const currentOrg = useCurrentOrg()
  const classes = useStyles()
  const { result: organization, loading: organizationLoading } = useOrganization(currentOrg.id)
  const loading = messagingLoading || organizationLoading
  const [dateRange, setDateRange] = useDateRangeFilter()
  const table = useTable(
    {
      data: useMemo(() => messages, [messages]),
      columns: useColumns(),
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  const history = useHistory()
  const cards = useCards(history)
  const summary = useMessagesSummaryCards(messages, organization)
  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} item>
            <Typography variant="h4">Custom Messages</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <SummaryCards cards={cards} summary={summary} className={classes.summary} />
              <PagedTable
                {...table}
                classes={{
                  cell: classes.cell,
                  headerCell: classes.th,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
