import { map } from 'lodash' //TODO this is ridiculous, why is this the only export where we have to do this
import React from 'react'
import format from 'utils/format';
import ExportCsvMenuItem from 'components/table/ExportCsvMenuItem'

const filename = "opportunities.csv";
const headers = [
    { label: 'Name', key: 'name' }, // String-based value accessors!
    { label: 'Event Start', key: 'startsAt' },
    { label: 'Event End', key: 'endsAt' },
    { label: 'Hours', key: 'totalVolunteerHours' }, // Custom header components!
    { label: 'Check-ins', key: 'totalCheckIns' } // Custom header components!
];

const useFormattedData = (data) => {
    return map(data, o => {
        o.totalVolunteerHours = format.hours(o.totalVolunteerHours, 2);
        o.startsAt = format.datetimeForExport(o.startsAt);
        o.endsAt = format.datetimeForExport(o.endsAt);
        return o;
    });
}

const exportConfig = {
    filename,
    headers,
    useFormattedData
};

export default function ExportOpportunitiesActionItem({ opportunities }) {
    return (
        <ExportCsvMenuItem
            data={opportunities}
            config={exportConfig}
        />
    )
}