import { useCallback } from 'react'
import _ from 'lodash'
import { useFetch, useCreateApiNotification } from 'civic-champs-shared/api/hooks'
import { volunteerSummary } from './utils'

const useRemoveVolunteer = (eagerRemoveMany) => {
  const [request, ] = useFetch()
  const createNotification = useCreateApiNotification()

  return useCallback(
    async volunteers => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Removing ${volunteerName} from volunteers...`
        : `Removing ${numVolunteers} volunteers...`
      const undo = eagerRemoveMany(volunteers)
      const notification = createNotification(text)
      try {
        await request({
          method: 'del',
          url: `/volunteers?ids=${_.join(
            _.map(volunteers, item => item.id),
            ',',
          )}`,
        })

        notification.onSuccess('Volunteer removed!')
      } catch (error) {
        const text = onlyOneVolunteer
          ? `Could not remove ${volunteerName} from volunteers`
          : `Could not remove ${numVolunteers} volunteers`
        notification.onError(text, error)
        undo()
      }
    },
    [createNotification, eagerRemoveMany, request],
  )
}

export default useRemoveVolunteer
