import React, { useCallback, useState } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import useExportStyles from 'export/useExportStyles'

export const ExportButton = ({
  data,
  disabled,
  headers,
  filename,
  formatData,
}: {
  data: any[]
  disabled?: boolean
  headers: { label: string; key: string }[]
  filename: string
  formatData?: (inputData: any[]) => any[]
}) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData ? formatData(data) : data)
  }, [data, formatData])
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled || data.length === 0} rightMargin>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        onClick={createFormattedData}
        className={classes.fill}
        filename={fileNameWithDate as string}
        headers={headers}
        data={formattedData}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportButton
