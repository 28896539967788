import { Geofencing } from '../civic-champs-shared/core/location/utils';
import { CreateOrUpdateOrganizationParam } from '../volunteering/organizations/models';

export interface CreateOrUpdateOrganizationGeofencingParam {
	name: string,
	website: string,
	address: {
		line1: string
		line2: string
		city: string
		state: string
		zip: string
		geofencing: Geofencing
		country: string
		utcOffset: number
	},
	timeZone: string,
	isTest?: boolean
}

export const mapDbOrganizationToGeofencing = (organization: CreateOrUpdateOrganizationParam): CreateOrUpdateOrganizationGeofencingParam => {
	const {address: destructAddress, ...adresslessOrganisation} = organization;
	const {gps, ...address} = destructAddress;
	return {
		...adresslessOrganisation,
		address: {
			...address,
			geofencing: {
				location: {
					type: 'Point',
					coordinates: gps,
				},
				radius: 0,
			}
		}
	}
}

export const mapGeofencingOrganizationToDb = (organization: CreateOrUpdateOrganizationGeofencingParam): CreateOrUpdateOrganizationParam  => {
	const {address: destructAddress, ...adresslessOrganisation} = organization;
	const {geofencing: {location: {coordinates: gps}}, ...address} = destructAddress;
	return {
		...adresslessOrganisation,
		address: {
			...address,
			gps
		}
	}
}
