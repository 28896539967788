import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

import { EditActivityDialog } from '../components'
import { Activity } from 'champion/utils/interface'
import { ActivityPayload } from '../types'

export const useEditActivityPrompt = (eagerReplace: (activity: Activity) => {}) => {
  const showPrompt = useShowPrompt(EditActivityDialog)

  return useCallback(
    async (activity: ActivityPayload) => {
      const savedActivity = await showPrompt({ activity, open: true })
      if (savedActivity) {
        eagerReplace(savedActivity)
      }
    },
    [eagerReplace, showPrompt],
  )
}

export default useEditActivityPrompt
