import React, { useCallback, useEffect, useState } from 'react'
import find from 'lodash/find'
import isNull from 'lodash/isNull'
import { muiTheme } from 'theme'
import { Box, Chip, Grid, IconButton, InputAdornment, OutlinedInput, Popover } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'
import { ThemeProvider } from '@material-ui/styles'
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import ClearIcon from '@material-ui/icons/Clear'
// @ts-ignore
import { ANCHOR_LEFT } from 'react-dates/constants'
import { Column, TableInstance } from 'react-table'
import * as H from 'history'

import SummaryCards, { SummaryCard } from 'civic-champs-shared/core/SummaryCards'

import NewFilters from 'core/table/components/NewFilters'
import PagedTable from 'core/table/components/PagedTable'
import { useFiltersFromQuery } from 'core/table/components/FiltersModal'
import { ColumnSelector } from 'core/table/components/ColumnSelector'
import { ColumnState } from 'core/table/interfaces/ColumnState'
import useExtendedTableStyles from 'core/table/table-hooks/useExtendedTableStyles'
import useSetFilterRowsHistory from 'core/table/table-hooks/useSetFiltersRowHistory'
import { BinaryFilter, GetOperatorOptionsType } from 'core/table/interfaces/Filters'
import DateRangeFilter from 'components/filter/StyledDateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import useDebounce from 'utils/useDebounce'
import { getColumnHeaderText } from 'core/table/utils'
import map from 'lodash/map'
import filter from 'lodash/filter'

export interface ExtenedPagedTableProps {
  summary?: {
    [key: string]: any
  }
  cards?: SummaryCard[]
  useGlobalSearch?: boolean
  useFilters?: boolean
  useDateRange?: boolean
  history: H.History
  location: H.Location
  table: TableInstance
  columns: Column[]
  getOperatorOptions?: GetOperatorOptionsType
  buttons?: JSX.Element
  addButton?: JSX.Element
  binaryFilters?: BinaryFilter[]
  columnState?: ColumnState[]
  popover?: JSX.Element
  tableName?: string
  searchPlaceholder?: string
  filterSubHeader?: string
  getCellStyle?: Function
  getHeaderCellStyle?: Function
}

export const ExtendedPagedTableComponent = (props: ExtenedPagedTableProps) => {
  const {
    cards,
    summary,
    useGlobalSearch,
    useFilters,
    useDateRange,
    history,
    table,
    columns,
    location,
    getOperatorOptions,
    buttons,
    popover,
    addButton,
    binaryFilters,
    columnState = null,
    tableName,
    searchPlaceholder = 'Search',
    filterSubHeader,
    getCellStyle,
    getHeaderCellStyle,
  } = props
  const classes = useExtendedTableStyles()
  const [dateRange, setDateRange] = useDateRangeFilter()
  const { filterRows: initialFilterRows } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [search, setSearch] = useState<string>('')
  const debouncedSearch = useDebounce(search, 250)
  useEffect(() => {
    // @ts-ignore
    table.setGlobalFilter(debouncedSearch)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, table.data])

  const setFilterRowsHistory = useSetFilterRowsHistory(history)
  const removeFilter = useCallback(
    (columnName: string, operator: any) => {
      const rows = history.location.state?.filterRows?.filter(
        ({ column, operator: columnOperator }: { column: string; operator: any }) =>
          column !== columnName || columnOperator !== operator,
      )
      setFilterRowsHistory(rows)
      // @ts-ignore
      table.setAllFilters(rows.map(({ column, value }) => ({ id: column, value })))
    },
    [history.location.state, setFilterRowsHistory, table],
  )

  useEffect(() => {
    if (!isNull(columnState)) {
      const columnsInState = map(columnState, 'id')
      const hiddenColumnsNotInState = filter(
        table.initialState?.hiddenColumns || [],
        id => !columnsInState.includes(id),
      )
      table.setHiddenColumns([
        ...columnState.filter(({ visible }) => !visible).map(({ id }) => id),
        ...hiddenColumnsNotInState,
      ])
      // @ts-ignore
      table.setColumnOrder(columnState.map(({ id }) => id))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Grid container item>
      {cards && summary && (
        <Grid item xs={12} style={{ marginBottom: '24px' }}>
          <SummaryCards cards={cards} data={summary} />
        </Grid>
      )}
      {(useGlobalSearch || useFilters || useDateRange) && (
        <Grid item container xs={12}>
          {(useGlobalSearch || useFilters) && (
            <Grid item container xs={6} spacing={1}>
              {useGlobalSearch && (
                <Grid item>
                  <OutlinedInput
                    labelWidth={0}
                    id="search"
                    placeholder={searchPlaceholder}
                    value={search}
                    onChange={(event: any) => setSearch(event.target.value)}
                    classes={{
                      root: classes.searchField,
                      notchedOutline: classes.notchedOutline,
                      input: classes.input,
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchIcon style={{ fontSize: '16px' }} />
                      </InputAdornment>
                    }
                  />
                </Grid>
              )}
              {useFilters && getOperatorOptions && (
                <Grid item>
                  <NewFilters
                    columns={columns}
                    table={table}
                    filterRows={initialFilterRows}
                    history={history}
                    getOperatorOptions={getOperatorOptions}
                    binaryFilters={binaryFilters}
                    subHeader={filterSubHeader}
                  />
                </Grid>
              )}
            </Grid>
          )}
          {(useDateRange || addButton) && (
            <Grid item container xs={6} direction="row-reverse" spacing={1}>
              {addButton && <Grid item>{addButton}</Grid>}
              <Grid item>
                {useDateRange && (
                  <DateRangeFilter
                    initialValues={dateRange}
                    onFilterApplied={setDateRange}
                    anchorDirection={ANCHOR_LEFT}
                    noBorder
                  />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      {useFilters && !!history?.location?.state?.filterRows && (
        <Grid item container xs={12} spacing={1} style={{ marginTop: 0 }}>
          {history?.location?.state?.filterRows
            // @ts-ignore
            ?.map(({ column, value: { operator, operand } }, index) => {
              if (Array.isArray(operand)) {
                operand = operand.join(', ')
              }
              if (operand.length > 50) {
                operand = operand.substr(0, 47) + '...'
              }
              const label = /^[><]=?$/.test(operator) ? `${operator} "${operand}"` : operand
              const columnInstance = find(table.columns, { id: column })
              return (
                <Grid item key={index}>
                  <Chip
                    className={classes.filterChip}
                    label={`${getColumnHeaderText(columnInstance)}: ${label}`}
                    onDelete={() => removeFilter(column, operator)}
                    color="secondary"
                    deleteIcon={<ClearIcon className={classes.filterChipIcon} />}
                  />
                </Grid>
              )
            })}
        </Grid>
      )}

      <Grid item xs={12} className={classes.wrapper}>
        <PagedTable
          {...table}
          // @ts-ignore
          header={
            <Grid item container xs={12} justify="space-between" className={classes.header}>
              <Grid item>
                {buttons}
                {popover && (
                  <PopupState variant="popover" popupId="menu-buttons-popover">
                    {popupState => (
                      <>
                        <IconButton
                          className={classes.popoverButton}
                          aria-label="show more"
                          component="span"
                          {...bindTrigger(popupState)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Popover
                          {...bindPopover(popupState)}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                        >
                          <Box className={classes.popover} p={2}>
                            {popover}
                          </Box>
                        </Popover>
                      </>
                    )}
                  </PopupState>
                )}
              </Grid>
              <Grid item>
                <ColumnSelector table={table} tableName={tableName} />
              </Grid>
            </Grid>
          }
          // @ts-ignore
          newStyles
          isDraggable
          getCellStyle={getCellStyle}
          getHeaderCellStyle={getHeaderCellStyle}
        />
      </Grid>
    </Grid>
  )
}

export const ExtendedPagedTable = (props: ExtenedPagedTableProps) => {
  return (
    <ThemeProvider theme={muiTheme}>
      <ExtendedPagedTableComponent {...props} />
    </ThemeProvider>
  )
}
