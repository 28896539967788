import moment from 'moment'
import React, { useCallback, useState } from 'react'
import format, { DEFAULT_TIME_ZONE } from 'utils/format'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import { TableMenuButton } from 'civic-champs-shared/core'
import { OrgNamedRef } from 'civic-champs-shared/question-sets/types'
import useExportStyles from 'export/useExportStyles'

const headers = [
  { label: 'Volunteer First Name', key: 'firstName' },
  { label: 'Volunteer Last Name', key: 'lastName' },
  { label: 'Volunteer Email Address', key: 'email' },
  { label: 'Volunteer Mobile Phone', key: 'phoneNumber' },
  { label: 'Volunteer Home Phone', key: 'homePhoneNumber' },
  { label: 'Volunteer Groups', key: 'groups' },
  { label: 'Event Name', key: 'eventName' },
  { label: 'Event Date', key: 'eventDate' },
  { label: 'Volunteer Shift', key: 'shiftName' },
  { label: 'Volunteer Role', key: 'roleName' },
  { label: 'Volunteer Status', key: 'status' },
  { label: 'Waivers Status', key: 'waiverStatus' },
]
const formatData = (registrants: any[]) =>
  registrants.map(({ original: { user, opportunity, occurence, prerequisites, role, timeshift, status, groups } }) => ({
    firstName: user.givenName,
    lastName: user.familyName,
    eventName: opportunity.name,
    eventDate: format.datetime(occurence.startsAt),
    shiftName: timeshift.name,
    roleName: role.name,
    waiverStatus: prerequisites.fulfilledAllRequirements ? 'Complete' : 'Action Required',
    email: user.email,
    phoneNumber: format.phoneNumber(user.phoneNumber),
    homePhoneNumber: format.phoneNumber(user.homePhoneNumber),
    status,
    groups: groups.map(({ name }: any) => name).join(', '),
  }))

const formatFileName = (filename: string, organization: OrgNamedRef, separator: string = '_') => {
  const today = moment()
    .tz(organization.timeZone || DEFAULT_TIME_ZONE)
    .format(`DDMMMYYYY${separator}HH:mm`)
  return `${organization.name}${separator}${filename}${separator}${today}.csv`
}

export const ExportRegistrantsButton = ({
  data,
  disabled,
  organization,
}: {
  data: any[]
  disabled?: boolean
  organization: OrgNamedRef
}) => {
  const [formattedData, setFormattedData] = useState<any[]>([])
  const [fileNameWithDate, setFileNameWithDate] = useState<string>('')
  const classes = useExportStyles()

  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
    setFileNameWithDate(formatFileName('Registrations', organization))
  }, [data, organization])

  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin onClick={createFormattedData}>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        onClick={createFormattedData}
        filename={fileNameWithDate}
        headers={headers}
        data={formattedData}
        className={classes.fill}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportRegistrantsButton
