import React, { FC, useState } from 'react'
import { History } from 'history'
import { Box, Grid, Typography, makeStyles, Switch } from '@material-ui/core'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import EditIcon from '@material-ui/icons/Edit'

import { SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { ReadOnlyQuestionSetQuestions } from 'civic-champs-shared/question-sets/components/'
import { ContainedButton, TableMenuButton, Loading } from 'civic-champs-shared/core/'
import { useEditQuestionSet, useQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import { QuestionSetResponse } from 'civic-champs-shared/question-sets/types'

import BackLink from 'donation/campaigns/components/BackLink'
import { useHasRole } from 'auth/hooks'
import { useDownloadExportedQuestionnaire, useInactiveQuestionSetPrompt } from '../hooks'

const useStyles = makeStyles(() => ({
  headerButton: {
    margin: 5,
    fontSize: 14,
  },
  toggle: {
    marginTop: 5,
  },
  toggleLabel: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: 5,
  },
  statusLabel: {
    fontWeight: 'bold',
    marginRight: 5,
  },
}))

export interface QuestionnaireDetailsProps {
  match: {
    params: { id: string }
  }
  history: History
  backLink?: string
  backText?: string
}

export const QuestionnaireDetails: FC<QuestionnaireDetailsProps> = ({
  match,
  backLink = '/questionnaires',
  backText = 'Back to Questionnaires',
  history,
}) => {
  const questionSetId = parseInt(match.params.id)
  const [questionSet, setQuestionSet] = useState<QuestionSetResponse | null>(null)
  const { loading } = useQuestionSet(questionSetId, setQuestionSet)
  const classes = useStyles()
  const [downloadExportedQuestionnaire] = useDownloadExportedQuestionnaire()
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet(setQuestionSet, false)
  const isSuperAdmin = useHasRole(SUPER_ADMIN)
  const inactiveQuestionSetPrompt = useInactiveQuestionSetPrompt()

  const setActive = (active: boolean) =>
    questionSet && editQuestionSet({ ...questionSet, anonymous: !active, questions: undefined })

  const onToggleActive = async (active: boolean) => {
    if (active) {
      setActive(true)
    } else {
      inactiveQuestionSetPrompt(() => setActive(false))
    }
  }

  if (loading || editLoading) {
    return <Loading />
  }

  const { anonymous, name, questions } = questionSet || {}

  return (
    <div>
      <Grid container spacing={4} direction="column">
        <Grid container item sm={10}>
          <Grid item xs={5}>
            <BackLink to={backLink} text={backText} />
          </Grid>
          <Grid item xs={7} container justify="flex-end">
            {isSuperAdmin && (
              <>
                <Typography className={classes.toggleLabel} component="span">
                  <Box fontWeight={'bold'} className={classes.statusLabel} component="span">
                    Status:
                  </Box>
                  {anonymous ? 'Inactive' : 'Active'}
                </Typography>

                <Switch
                  className={classes.toggle}
                  checked={!anonymous}
                  onChange={e => onToggleActive(e.target.checked)}
                />
              </>
            )}
            <TableMenuButton
              className={classes.headerButton}
              startIcon={<SaveAltOutlinedIcon />}
              onClick={() => {
                // @ts-ignore
                downloadExportedQuestionnaire(questionSet)
              }}
            >
              Export Responses
            </TableMenuButton>
            <ContainedButton
              startIcon={<EditIcon />}
              className={classes.headerButton}
              onClick={() => history.push(`/questionnaires/${questionSetId}/edit`)}
            >
              Edit
            </ContainedButton>
          </Grid>

          <Grid container spacing={4} direction="column">
            <Grid container item sm={12} spacing={2}>
              <Grid item sm={12}>
                <Typography variant="h5">{name || '-'}</Typography>
              </Grid>
              <ReadOnlyQuestionSetQuestions questions={questions || []} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}
