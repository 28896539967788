import React from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Radio, FormControlLabel, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks';
import { RadioGroup, TextField } from 'formik-material-ui';
import { Field, Form, Formik } from 'formik';
import { makeStyles } from '@material-ui/styles';
import { useAcceptTask, useDeclineTask } from '../hooks'
import { useErrorNotification } from 'civic-champs-shared/api/hooks'

const useStyles = makeStyles(theme => ({
    content: {
        textAlign: 'center'
    },
    submit: {
        margin: theme.spacing(2)
    }
}))

const APPROVED = 'approved'
const DECLINED = 'declined'

export default function AcceptTaskPrompt(props) {
    const promptClasses = usePromptStyles()
    const classes = useStyles()
    const { showing, close, complete, submitting, task } = props;
    const [acceptTask, acceptLoading] = useAcceptTask()
    const [declineTask, declineLoading] = useDeclineTask()
    const onError = useErrorNotification()

    const validate = (values) => {
        const errors = {}
        if (values.approval === DECLINED && !values.comment) {
            errors.comment = 'Required if request is declined.'
        }
        return errors
    }

    const handleSubmit = async (values, actions) => {
        try {
            if (values.approval === APPROVED) {
                await acceptTask(task)
            } else {
                await declineTask(task, { comment: values.comment })
            }
            complete()
        } catch(err) {
            onError('There was a problem accepting the task', err)
        }
    }

    return (
        <Dialog
            open={showing}
            onClose={close}
        >
            <DialogTitle>
                <Typography className={promptClasses.title}>Approve Request</Typography>
                <IconButton
                    disabled={submitting}
                    onClick={close}
                    className={promptClasses.closeButton}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Formik initialValues={{ approval: APPROVED, comment: '' }} onSubmit={handleSubmit} validate={validate}>
                    {({ isSubmitting, isValid, values }) => (
                        <Form>
                            <Field component={RadioGroup} name='approval'>
                                <FormControlLabel
                                    value={APPROVED}
                                    control={<Radio disabled={isSubmitting} />}
                                    label='This request is approved'
                                    disabled={isSubmitting}
                                />
                                <FormControlLabel
                                    value={DECLINED}
                                    control={<Radio disabled={isSubmitting} />}
                                    label='This request is declined'
                                    disabled={isSubmitting}
                                />
                            </Field>
                            <Field
                                component={TextField}
                                label='Add reasons here'
                                variant='outlined'
                                name='comment'
                                disabled={values.approval !== DECLINED || isSubmitting}
                                fullWidth
                                multiline
                                rows={3}
                            />
                            <Button
                                variant='contained'
                                color='secondary'
                                type='submit'
                                className={classes.submit}
                                disabled={!isValid || isSubmitting}
                            >
                                Submit
                            </Button>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
