import React, { useMemo } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { ANCHOR_LEFT } from 'react-dates/constants'
import { useTable, useGlobalFilter, usePagination, useFilters, useSortBy } from 'react-table'
import matchSorter from 'match-sorter'
import Loading from 'components/Loading'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import Search from 'core/table/components/Search'
import PagedTable from 'core/table/components/PagedTable'
import DEFAULT_FILTERS from 'core/table/filters'
import { useCurrentOrg } from '../../../auth/hooks'
import { useColumns } from './hooks'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'

const fuzzyMatchFilter = (rows, id, filterValue) =>
  matchSorter(rows, filterValue, {
    keys: [
      'original.method',
      'original.subject',
      'original.templateVersion.description',
      'original.userEnteredSubject',
      'original.sentTo.givenName',
      'original.sentTo.familyName',
      'original.recipientContact',
      'original.opportunity.name',
      'original.registration.name',
    ],
  })

fuzzyMatchFilter.autoRemove = value => !value

const filterTypes = {
  fuzzyMatch: fuzzyMatchFilter,
  ...DEFAULT_FILTERS,
}

export const AllMessagesOverview = () => {
  const currentOrg = useCurrentOrg()
  const [, { loading, result: messages }] = useGetOnMount({
    url: `/organizations/${currentOrg.id}/messages`,
    config: { queryStringParameters: { isCustom: false } },
    errorMessage: 'Error fetching Non-Custom Messages',
    emptyValue: [],
  })
  const [dateRange, setDateRange] = useDateRangeFilter()
  const columns = useColumns()
  const table = useTable(
    {
      data: useMemo(() => messages, [messages]),
      columns,
      filterTypes,
      globalFilter: 'fuzzyMatch',
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  )

  const handleQueryChange = query => {
    table.setGlobalFilter(query || undefined)
  }

  const handleDateRangeChange = newRange => {
    setDateRange(newRange)
    table.setFilter('createdAt', newRange)
  }

  return (
    <div>
      <Grid container direction="column" spacing={3}>
        <Grid container xs={12} item justify="space-between" alignItems="center" direction="row">
          <Grid xs={6} item>
            <Typography variant="h4">Non-Custom Messages</Typography>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Search onQueryChange={handleQueryChange} variant="outlined" placeholder="Search" />
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4, marginLeft: '10px' }}>
              <DateRangeFilter
                initialValues={dateRange}
                onFilterApplied={handleDateRangeChange}
                anchorDirection={ANCHOR_LEFT}
                noBorder={true}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid item>
          {loading ? (
            <Loading />
          ) : (
            <>
              <PagedTable {...table} />
            </>
          )}
        </Grid>
      </Grid>
    </div>
  )
}
