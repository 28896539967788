import moment from 'moment'
import * as _ from 'lodash'

export interface OccurrenceId {
  date?: string
  opportunityId: number
}

const getTimeZoneFromEvent = (event: any) => _.get(event, 'organization.timeZone', moment.tz.guess(true))

const encodeOccurrenceFromEvent = (event: any): string => {
  //if this is already an encoded ID we need to get the opportunity ID out of it
  const { opportunityId } = decodeOccurrenceId(event.opportunityId)

  const payload = JSON.stringify({
    opportunityId,
    date: moment.tz(event.startsAt, getTimeZoneFromEvent(event)).format('YYYY-MM-DD'),
  })

  return window.btoa(payload)
}

const decodeOccurrenceId = (id: string | number): OccurrenceId => {
  const idAsString = String(id)
  const idAsNumber = Number(id)
  if (isNaN(idAsNumber)) {
    // this is then a classic encodedOccurrence, used by 'admin' and 'scheduler' repeatedly
    return JSON.parse(window.atob(idAsString))
  } else {
    if (idAsNumber > 0) {
      // this is just a straight id from the opportunity table - shouldn't happen - just a fallback
      return { opportunityId: idAsNumber }
    } else {
      // this was an encodedId that we created for the client, which is expecting a number, not a string, so we cannot use the regular encoding
      const date = `${idAsString.substring(1, 5)}-${idAsString.substring(5, 7)}-${idAsString.substring(7, 9)}`
      const opportunityId = Number(idAsString.substring(9, idAsString.length))

      return { date, opportunityId }
    }
  }
}

export { encodeOccurrenceFromEvent, decodeOccurrenceId }
