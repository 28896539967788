import React, { useEffect, useMemo } from 'react'
import moment from 'moment-timezone'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { RouteComponentProps } from 'react-router'
import RemoveCircleOutlineOutlinedIcon from '@material-ui/icons/RemoveCircleOutlineOutlined'
import AddIcon from '@material-ui/icons/Add'
import Grid from '@material-ui/core/Grid'

import { TableMenuButton } from 'civic-champs-shared/core/TableMenuButton'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { ContainedButton } from 'civic-champs-shared/core'

import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import { useFiltersFromQuery } from 'core/table/components/NewFiltersModal'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useActivityLogColumns, { useStyles } from '../hooks/useActivityLogColumns'
import { useDateRangeFilter } from 'filtering/hooks'
import useActivitySummary from '../hooks/useActivitySummary'
import format from 'utils/format'
import Loading from 'components/Loading'
import ExportActivityLogButton from '../components/ExportActivityLogButton'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'

import DEFAULT_FILTERS, { useGetGlobalFilter } from 'core/table/filters'
import CUSTOM_FILTERS from '../utils/filters'

import PageHeader from 'App/components/PageHeader'
import {
  ARRAY_OPERATOR_OPTIONS,
  NUMBER_OPERATOR_OPTIONS,
  operatorTypes,
  STRING_OPERATOR_OPTIONS,
} from 'core/table/interfaces/Filters'
import requestWithRetry from 'civic-champs-shared/api/requestWithRetry'
import { useUISettings } from 'hooks/useUISettings'
import { useActivitiesCollection } from '../hooks/useActivitiesCollection'

const cards = [
  {
    header: 'Total Activities',
    accessor: 'count',
    transform: format.number,
  },
  {
    header: 'Average Activity Length',
    accessor: 'averageHours',
    transform: format.number,
  },
  {
    header: 'Total Hours',
    accessor: 'totalHours',
    transform: format.number,
  },
]

const tableName = 'activityLog'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'firstName':
    case 'lastName':
    case 'opportunity':
    case 'name':
      return STRING_OPERATOR_OPTIONS

    case 'hours':
      return NUMBER_OPERATOR_OPTIONS

    case 'role':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'reflections':
      return [{ value: operatorTypes.CONTAINS, displayValue: 'Contains' }]

    case 'satisfaction':
      return [
        { value: operatorTypes.ANY_OF, displayValue: 'Include any of' },
        { value: operatorTypes.NOT_ANY_OF, displayValue: 'Do not include any of' },
      ]

    case 'groups':
      return ARRAY_OPERATOR_OPTIONS

    default:
      return []
  }
}

const exportAllHandler = async () => {
  const blob = await requestWithRetry({
    url: '/activity_export',
    config: {
      responseType: 'blob',
    },
  })
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', 'activity.csv')
  link.click()
}

export const ActivityLogComponent = ({ location, history }: RouteComponentProps) => {
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  const columnStyles = useStyles()
  const [dateRange] = useDateRangeFilter()
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)

  const [{ activities, loading }, { addActivity, editActivity, removeActivities }] = useActivitiesCollection(dateRange)

  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const organization = useCurrentOrg()

  // @ts-ignore
  const columns = useActivityLogColumns({ onEdit: editActivity })
  const globalFilter = useGetGlobalFilter({
    columns,
    filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
    getOperatorOptions,
  })

  const [dateRangeFilter] = useDateRangeFilter()

  const filteredActivities = useMemo(() => {
    if (!activities) return []
    const { startDate, endDate } = dateRangeFilter
    const { timeZone } = organization

    if (startDate === undefined || endDate === undefined) return activities

    const filterStart = moment(startDate).tz(timeZone, true)
    const filterEnd = moment(endDate).tz(timeZone, true)

    return activities.filter((a: any) => {
      const start = moment(a.occurredAt).tz(timeZone)
      const end = start.clone().add(a.hoursVolunteered, 'hours')
      return filterStart.isSameOrBefore(end, 'day') && filterEnd.isSameOrAfter(start, 'day')
    })
  }, [activities, dateRangeFilter, organization])

  const summary = useActivitySummary(filteredActivities)

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: ['firstName', 'lastName', 'reflections', 'groups'],
        // @ts-ignore
        globalFilter: '',
        filters,
      },
      globalFilter,
      // @ts-ignore
      filterTypes: { ...DEFAULT_FILTERS, ...CUSTOM_FILTERS },
      data: filteredActivities,
      columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const [selectedRows, selectedRowsCount] = useMemo(
    () => [table.selectedFlatRows.map(({ original }: any) => original), table.selectedFlatRows.length],
    [table.selectedFlatRows],
  )

  const onRemoveActivity = async () => {
    // @ts-ignore
    await removeActivities(selectedRows)
    table.toggleAllRowsSelected(false)
  }

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Activities"
        filterSubHeader="Select activity attributes to filter by:"
        buttons={
          <>
            <ExportActivityLogButton data={selectedRows} disabled={!selectedRowsCount} />
            <TableMenuButton
              startIcon={<RemoveCircleOutlineOutlinedIcon />}
              disabled={!selectedRowsCount}
              onClick={onRemoveActivity}
              rightMargin={showAdminItems}
            >
              Remove
            </TableMenuButton>
            {showAdminItems && (
              <TableMenuButton startIcon={<SaveAltOutlinedIcon />} onClick={exportAllHandler}>
                Export all
              </TableMenuButton>
            )}
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={addActivity}>
            Add Activity
          </ContainedButton>
        }
        getOperatorOptions={getOperatorOptions}
        useGlobalSearch
        useFilters
        useDateRange
      />
    </Grid>
  )
}

export const ActivityLog = (props: RouteComponentProps) => (
  <PageHeader title="Activity Log" subTitle="View, add, and edit volunteer activities">
    <ActivityLogComponent {...props} />
  </PageHeader>
)
