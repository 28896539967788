import { findIndex, range, isEmpty, map, sortedIndexBy } from 'lodash'
import moment from 'moment'
import React, { useState } from 'react'
import cn from 'classnames'
import { Dialog, makeStyles, Button } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

import { useCurrentOrg } from 'auth/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import CustomTimeslotPrompt from './CustomTimeslotPrompt'
import { slotIsPast } from '../utils'

const timeFormat = 'hh:mm a'
const generateTimeslots = (date, orgTimezone = 'America/New_York') => range(8, 18).map(i => ({
    startTime: moment(date).tz(orgTimezone).hours(i).minutes(0).seconds(0),
    endTime: moment(date).tz(orgTimezone).hours(i + 2).minutes(0).seconds(0)
}))

const slotMinimumOrNextPossible = (slots, orgTimezone = 'America/New_York') => {
    if( isEmpty(slots) ) {
        let date = moment().tz(orgTimezone).startOf('day')
        return date.clone().hours(18).isBefore() ? date.add(1, 'day') : date
    } else {
        return moment.min( map(slots, s => s.startTime) )
    }
}


const getSlotIndex = (slots, slot) => 
        findIndex(slots, s => s.startTime.isSame(slot.startTime) && s.endTime.isSame(slot.endTime))

//assumes the list is sorted
const findInsertIndex = (slots, toInsert) => sortedIndexBy(slots, toInsert, s => s.startTime.format())

const useStyles = makeStyles(theme => ({
    modal: {
        padding: theme.spacing(2),
        textAlign: 'center'
    },
    header: {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        margin: theme.spacing(2)
    }, 
    timeSlot: {
        border: '1px solid',
        borderColor: '#707070',
        borderRadius: '2px',
        padding: theme.spacing(1),
        margin: theme.spacing(1),
        cursor: 'pointer',
    },
    inactive: {
        borderColor: theme.palette.neutral.lightGray,
        color: theme.palette.neutral.lightGray,
        cursor: 'not-allowed'
    },
    selected: {
        background: theme.palette.secondary.main,
        color: 'white',
        cursor: 'pointer'
    },
    date: {
        marginBottom: theme.spacing(2)
    },
    button: {
        margin: theme.spacing(1)
    }
}))

export default function AddTimeslotPrompt(props) {
    const { showing, slots, remove, insert, maxSlots, closeModal } = props
    const customPrompt = useShowPrompt(CustomTimeslotPrompt)
    const org = useCurrentOrg()
    const classes = useStyles()

    const [date, setDate] = useState(() => slotMinimumOrNextPossible(slots, org.timeZone))

    const handleSlotClick = (slot) => {
        // do nothing if slot is in past
        if (slotIsPast(slot)) return
        let idx = getSlotIndex(slots, slot)
        // remove already selected slot
        if (idx > -1) { 
            remove(idx) 
        } else if (slots.length < maxSlots) {
            idx = findInsertIndex(slots, slot)
            insert(idx, slot)
        }
    }
    const handleCustomClick = async () => {
        await customPrompt({ addSlot: handleSlotClick })
    }

    return (
        <Dialog open={showing} onClose={closeModal}>
            <div className={classes.modal}>
                <p className={classes.header}>What day?</p>
                <KeyboardDatePicker
                    format='MM/DD/YYYY'
                    minDate={moment()}
                    value={date}
                    onChange={setDate}
                    inputVariant='outlined'
                    className={classes.date}
                />
                {
                    generateTimeslots(date, org.timeZone).map((slot) => (
                    <div
                        key={slot.startTime.toString()}
                        className={cn(
                            classes.timeSlot,
                            { [classes.inactive]: slots.length >= maxSlots || slotIsPast(slot) },
                            { [classes.selected]: getSlotIndex(slots, slot) > -1 },
                        )}
                        role='button'
                        onClick={() => { handleSlotClick(slot)} }
                    >
                        {slot.startTime.format(timeFormat)} - {slot.endTime.format(timeFormat)}
                    </div>
                    ))
                }
                
                <div>
                    <Button
                        onClick={handleCustomClick}
                        variant='contained'
                        color='secondary'
                        disabled={slots.length >= maxSlots}
                        className={classes.button}
                    >
                        Custom Slot
                    </Button>
                </div>

                <Button
                    onClick={closeModal}
                    variant='contained'
                    color='primary'
                    className={classes.button}
                >
                    Submit
                </Button>
            </div>
        </Dialog>
    )
}
