import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { useCallback } from 'react'
import fileDownload from 'js-file-download'
import moment from 'moment'

import { DEFAULT_TIME_ZONE } from 'utils/format'
import { useCurrentOrg } from 'auth/hooks'
import { OrgNamedRef } from 'civic-champs-shared/question-sets/types'

const formatFileName = (name: string, organization: OrgNamedRef, separator = '_') => {
  const today = moment()
    .tz(organization.timeZone || DEFAULT_TIME_ZONE)
    .format(`DDMMMYYYY${separator}HH:mm`)
  return `${name.replace(/\s/g, separator)}${separator}Responses${separator}${today}.csv`
}

export const useDownloadExportedSurvey = () => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored({
    successMessage: 'Export completed',
    errorMessage: 'Error exporting survey',
  })

  const downloadExportedQuestionnaire = useCallback(
    (survey: { id: number }, selectedVersion: any) => {
      const { id } = survey
      const { version, questionSet } = selectedVersion

      return request({
        method: 'post',
        url: `/organizations/${organization.id}/survey/${id}/export`,
        config: {
          queryStringParameters: {
            version,
          },
          responseType: 'blob',
        },
      }).then(blob => fileDownload(blob as ArrayBuffer, formatFileName(questionSet.name, organization)))
    },
    [organization, request],
  )

  return [downloadExportedQuestionnaire, status]
}

export default useDownloadExportedSurvey
