import { EditEventForm, EventFormKeys, TimeshiftsRolesEditEvent } from '../../interfaces/interfaceCreateEditEvent'
import moment from 'moment'
import { OpportunityVisibility } from 'Event/interfaces'
import { DEFAULT_GEOFENCING } from 'civic-champs-shared/constants/GEO_DATA'

export enum ACTION_TYPES {
  CHANGE_STEP = 'CHANGE_STEP',
  CHANGE_EVENT_FIELD = 'CHANGE_EVENT_FIELD',
  CLEAR_EVENT_FORM = 'CLEAR_EVENT_FORM',
  CHANGE_DATA = 'CHANGE_DATA',
  SET_EVENT_DATA_FROM_API = 'SET_EVENT_DATA_FROM_API',
}

const { CHANGE_STEP, CHANGE_EVENT_FIELD, CLEAR_EVENT_FORM, CHANGE_DATA, SET_EVENT_DATA_FROM_API } = ACTION_TYPES

const initialState: EditEventForm = {
  currentStep: 1,
  event: {
    published: false,
    name: '',
    startsAt: '',
    endsAt: '',
    recurring_type: 5,
    is_recurring: false,
    dates: [],
    description: '',
    instructions: '',
    contact_name: '',
    contact_email: '',
    contact_phone_number: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    isTest: false,
    geofencing: DEFAULT_GEOFENCING,
    timeshifts: [
      {
        time_start: '9:00 AM',
        time_end: '10:00 AM',
        name: '',
        is_editable: true,
        roles: [
          {
            name: '',
            available: null,
            all: null,
            is_editable: true,
          },
        ],
      },
    ],
    requiredCredentials: [],
    visibility: OpportunityVisibility.PUBLIC,
    questionSets: [],
    visibilityGroups: [],
    onboardingGroups: [],
  },
}

export const changeStep = (step: number) => ({
  type: CHANGE_STEP,
  step,
})

export const setEventField = (key: EventFormKeys, val: boolean | string | number) => ({
  type: CHANGE_EVENT_FIELD,
  val,
  key,
})

export const clearEventForm = () => ({
  type: CLEAR_EVENT_FORM,
})

export const setNewData = (data: any) => ({
  type: CHANGE_DATA,
  newData: data,
})

export const setEventDataFromApi = (data: any) => ({
  type: SET_EVENT_DATA_FROM_API,
  newData: data,
})

export default function reducer(state = initialState, action: any): EditEventForm {
  switch (action.type) {
    case CHANGE_STEP:
      return {
        currentStep: action.step,
        event: { ...state.event },
      }
    case CHANGE_EVENT_FIELD:
      return {
        ...state,
        event: {
          ...state.event,
          [action.key]: action.val,
        },
      }
    case CLEAR_EVENT_FORM:
      return {
        ...state,
        event: { ...initialState.event },
      }
    case CHANGE_DATA:
      const { newData } = action
      const updatedShifts = [...newData.timeshifts]
      updatedShifts.forEach(shift => {
        const hasRolesWithRegistrations = shift.roles.some((role: TimeshiftsRolesEditEvent) => {
          if (role.available !== null && role.all !== null) {
            return role.available < role.all
          }

          return false
        })

        shift['is_editable'] = !hasRolesWithRegistrations

        shift.roles.forEach((role: TimeshiftsRolesEditEvent, index: number) => {
          if (role.available !== null && role.all !== null) {
            if (role.available < role.all) {
              shift.roles[index].is_editable = false
              return
            }
          }

          shift.roles[index].is_editable = true
        })
      })
      return {
        ...state,
        event: {
          published: newData.published,
          name: newData.name,
          startsAt: moment(newData.startsAt).local().format(),
          endsAt: moment(newData.endsAt).local().format(),
          recurring_type: newData.recurring_type ? newData.recurring_type : 5,
          is_recurring: newData.is_recurring ? newData.is_recurring : false,
          dates: newData.dates ? newData.dates : [],
          description: newData.description,
          isTest: newData.isTest,
          instructions: newData.instructions,
          contact_name: newData.contact_name,
          contact_email: newData.contact_email,
          contact_phone_number: newData.contact_phone_number,
          address: newData.address,
          city: newData.city,
          state: newData.state,
          zip: newData.zip,
          geofencing: newData.geofencing,
          timeshifts: newData.timeshifts ? updatedShifts : [],
          questionSets: newData.questionSets || [],
          visibilityGroups: newData.visibilityGroups || [],
          onboardingGroups: newData.onboardingGroups || [],
          requiredCredentials: newData.requiredCredentials || [],
          visibility: newData.visibility,
          recurrenceInfo: newData.recurrenceInfo,
        },
      }
    case SET_EVENT_DATA_FROM_API: {
      const { newData } = action
      const updatedShifts = [...newData.timeshifts]
      updatedShifts.forEach(shift => {
        const hasRolesWithRegistrations = shift.roles.some((role: TimeshiftsRolesEditEvent) => {
          if (role.available !== null && role.all !== null) {
            return role.available < role.all
          }

          return false
        })

        shift['is_editable'] = !hasRolesWithRegistrations

        shift.roles.forEach((role: TimeshiftsRolesEditEvent, index: number) => {
          if (role.available !== null && role.all !== null) {
            if (role.available < role.all) {
              shift.roles[index].is_editable = false
              return
            }
          }

          shift.roles[index].is_editable = true
        })

        shift.roles.forEach((role: TimeshiftsRolesEditEvent) => {
          role.available = role.all
        })
      })
      return {
        ...state,
        event: {
          published: newData.published,
          name: newData.name,
          startsAt: moment(newData.startsAt).local().format(),
          endsAt: moment(newData.endsAt).local().format(),
          recurring_type: newData.recurring_type ? newData.recurring_type : 5,
          is_recurring: newData.is_recurring ? newData.is_recurring : false,
          dates: newData.dates ? newData.dates : [],
          description: newData.description,
          instructions: newData.instructions,
          contact_name: newData.contact_name,
          contact_email: newData.contact_email,
          contact_phone_number: newData.contact_phone_number,
          address: newData.address,
          city: newData.city,
          state: newData.state,
          zip: newData.zip,
          geofencing: newData.geofencing,
          isTest: newData.isTest || false,
          timeshifts: newData.timeshifts ? updatedShifts : [],
          questionSets: newData.questionSets || [],
          visibilityGroups: newData.visibilityGroups || [],
          onboardingGroups: newData.onboardingGroups || [],
          requiredCredentials: newData.requiredCredentials || [],
          visibility: newData.visibility,
          recurrenceInfo: newData.recurrenceInfo,
          opportunityId: newData.opportunityId,
          occurrenceId: newData.occurrenceId,
        },
      }
    }
    default:
      return state
  }
}
