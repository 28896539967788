import { isEmpty } from 'lodash'
import React, { useMemo, useState } from 'react'
import cn from 'classnames'
import PropTypes from 'prop-types'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { Box, Drawer, Typography } from '@material-ui/core'
import ApartmentIcon from '@material-ui/icons/Apartment'
import { mdiAccount, mdiAccountSwitch, mdiCar, mdiFace, mdiHandHeart, mdiSettings, mdiWebhook } from '@mdi/js'
import HelpingHandsIcon from 'components/icon/HelpingHandsIcon'
import { INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN, SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled, useOrganizationSettingsTabs } from 'core/feature/hooks'
import { useCurrentOrg, useHasRole } from 'auth/hooks'
import NavMenu from 'App/components/NavMenu'
import useSettingsEnabledForSuperAdmin from '../core/feature/hooks/useSettingsEnabledForSuperAdmin'
import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useMediaQuery } from 'react-responsive'
import { Overlay } from 'components/overlay/Overlay'
import TuneIcon from '@material-ui/icons/Tune'
import MessageIcon from '@material-ui/icons/Message'
import EventIcon from '@material-ui/icons/Event'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { muiTheme } from 'theme'
import { useBreakPoint } from 'civic-champs-shared/helpers/useBreakpoint'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: theme.drawer.width,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    flexShrink: 0,
    height: '100%',
    '&$closed': {
      width: '50px',
    },
  },
  drawerPaper: {
    width: theme.drawer.width,
    backgroundColor: '#4985DF',
    borderRight: 'none',
    height: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    '&$closed': {
      width: '50px',
    },
  },
  closed: {},
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  toolbar: {
    height: 69,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '&$closed': {
      justifyContent: 'center',
      paddingLeft: 0,
    },
  },
  iconBlock: {
    marginRight: 10,
    borderRight: '10px solid transparent',
    display: 'block',
    width: '1px',
    height: '45px',
  },
  iconsSelected: {
    color: 'white',
    borderRight: '10px solid',
    display: 'block',
    width: '1px',
    height: '45px',
  },
  orgNameWrapper: {
    display: 'flex',
    color: 'white',
    justifyContent: 'center',
    alignItems: 'center',
    width: '240px',
    height: '69px',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    padding: '20px 0',
  },
  link: {
    fontSize: '12px',
    marginBottom: '4px',
    marginTop: '4px',
    color: 'white',
  },
  nav: {
    zIndex: 1,
    height: '100%',
  },
  holePuncher: {
    zIndex: 11,
  },
  orgCaption: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 600,
    fontSize: '10px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  orgName: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  container: {
    height: '100%',
  },
  openClose: {
    marginRight: '12.58px',
    cursor: 'pointer',
    '&$closed': {
      marginRight: 0,
    },
  },
}))

const getItems = ({ organization, classes }) => {
  return [
    {
      label: organization ? <OrganizationMenuItem name={organization.name} /> : 'Organizations',
      route: '/organizations',
      requires: 'internalAdmin',
      isAdminItem: true,
      IconComponent: () => <ApartmentIcon size="large" />,
    },
    {
      label: 'People',
      route: ['/people', '/invitations', '/merge-accounts', '/all-people', '/testers'],
      iconPath: mdiFace,
      items: [
        { label: 'All People', route: '/all-people', requires: 'internalSuperAdmin' },
        { label: 'Overview', route: '/people' },
        { label: 'Invitations', route: '/invitations' },
        { label: 'Testers', route: '/testers', requires: 'internalSuperAdmin' },
      ],
      requires: 'internalAdmin',
      isAdminItem: true,
    },
    {
      label: 'Champions',
      iconPath: mdiAccount,
      items: [
        { label: 'Overview', route: '/champions' },
        { label: 'Activity Log', route: '/activities' },
        { label: 'Waivers', route: '/credentials' },
        { label: 'Credential IDs', route: '/credential-ids', requires: 'credentialIDs' },
      ],
      route: ['/activities', '/credentials', '/champions', '/credential-ids'],
      aClassName: classes.holePuncher,
    },
    {
      label: 'Messages',
      route: [
        '/messages',
        '/bad-contacts',
        '/white-listed-phone-numbers',
        '/add-sms-credits',
        '/opted-out-phone-numbers',
        '/custom-messages',
      ],
      IconComponent: () => <MessageIcon size="large" />,
      items: [
        // this overview is only behind 'internalSuperAdmin' because if not internalAdmin,
        // there is no reason to have a drawer, as /messages is the only route
        {
          label: 'Non-Custom Messages',
          route: '/messages',
          requires: 'internalAdmin',
          isAdminItem: true,
        },
        { label: 'Custom Messages', route: '/custom-messages', requires: 'bulkMessaging' },
        { label: 'Bad Contacts', route: '/bad-contacts', requires: 'internalSuperAdmin', isAdminItem: true },
        { label: 'Opt Outs', route: '/opted-out-phone-numbers', requires: 'internalSuperAdmin', isAdminItem: true },
        {
          label: 'Whitelisted Phones',
          route: '/white-listed-phone-numbers',
          requires: 'internalSuperAdmin',
          isAdminItem: true,
        },
        {
          label: 'Add SMS Credits',
          route: '/add-sms-credits',
          requires: { allOf: ['internalSuperAdmin', 'bulkMessaging'] },
          isAdminItem: true,
        },
        {
          label: 'Chats',
          route: '/chats',
          requires: 'chats',
        },
      ],
      requires: { anyOf: ['internalAdmin', 'bulkMessaging', 'internalSuperAdmin'] },
    },
    {
      label: 'Donations',
      route: ['/donations', '/campaigns'],
      iconPath: mdiHandHeart,
      items: [
        { label: 'Overview', route: '/donations/overview' },
        { label: 'Campaigns', route: '/campaigns' },
      ],
      requires: 'donations',
    },
    {
      label: 'Trip Planner',
      route: '/trip-planner',
      iconPath: mdiCar,
      requires: 'tripPlanner',
    },
    {
      label: 'Groups',
      route: '/groups',
      IconComponent: () => <img alt="groups" src="/assets/icons/Groups.svg" />,
      requires: 'groups',
    },
    {
      label: 'Events',
      IconComponent: () => <EventIcon size="large" />,
      items: [
        { label: 'Calendar', route: '/events' },
        { label: 'Opportunities', route: '/opportunities', requires: 'volunteering' },
        { label: 'Registrations', route: '/registrants', requires: 'registrants' },
      ],
      route: ['/events', '/opportunities', '/registrants'],
      requires: { allOf: ['volunteering', 'events'] },
      aClassName: classes.holePuncher,
    },
    {
      label: 'Kiosk',
      route: '/kiosk',
      IconComponent: () => <PhoneIphoneIcon size="large" />,
      requires: 'volunteering',
    },
    {
      label: 'Helping Hands',
      route: '/helping-hands',
      IconComponent: HelpingHandsIcon,
      requires: 'helpingHands',
    },
    {
      label: 'Mentor',
      route: '/mentorship',
      iconPath: mdiAccountSwitch,
      items: [
        { label: 'Meetings', route: '/mentorship/meetings' },
        { label: 'Matches', route: '/mentorship/matches' },
        { label: 'Programs', route: '/mentorship/programs' },
        { label: 'Surveys', route: '/mentorship/surveys' },
      ],
      requires: { allOf: ['mentoring'] },
    },
    {
      label: 'Webhooks',
      route: '/webhooks',
      iconPath: mdiWebhook,
      requires: 'apiKeyEnabled',
    },
    {
      label: 'Setup',
      IconComponent: () => <TuneIcon size="large" />,
      requires: 'superAdmin',
      items: [
        {
          label: 'Admins',
          route: '/team/admins',
        },
        {
          label: 'Questionnaires',
          route: '/questionnaires',
        },
        {
          label: 'Roles',
          route: '/roles',
          requires: 'rolesManagement',
        },
      ],
    },

    // show full settings if an internal admin
    {
      label: 'Settings',
      route: '/settings',
      iconPath: mdiSettings,
      items: [
        {
          label: 'Organization',
          requires: 'settingsTabs',
          route: '/settings/organization',
        },
        { label: 'Features', route: '/settings/features', isAdminItem: true },
        {
          label: 'Champions Profile',
          route: '/settings/champions-profile',
        },
        {
          label: 'User Profile',
          route: '/settings/user-profile',
          requires: { anyOf: ['orgUserProfiles', 'globalUserProfiles'] },
        },
        {
          label: 'Stripe Products',
          route: '/settings/stripe-products',
          requires: 'internalSuperAdmin',
          isAdminItem: true,
        },
      ],
      requires: 'internalAdmin',
      atBottom: true,
    },

    // show partial list if just a superAdmin and any of the sub-items are enabled
    {
      label: 'Settings',
      route: '/settings',
      iconPath: mdiSettings,
      items: [
        { label: 'Organization', requires: 'settingsTabs', route: '/settings/organization' },
        {
          label: 'Champions Profile',
          route: '/settings/champions-profile',
          requires: 'volunteerQuestionnaires',
        },
        {
          label: 'User Profile',
          route: '/settings/user-profile',
          requires: { anyOf: ['orgUserProfiles', 'globalUserProfiles'] },
        },
      ],
      requires: 'settingsEnabledForSuperAdmin',
      hide: 'internalAdmin',
      atBottom: true,
    },
  ]
}

const OrganizationMenuItem = props => {
  const classes = useStyles()
  return (
    <Box display="flex" flexDirection="column" justifyContent="center">
      <Typography variant="caption" className={classes.orgCaption}>
        Organization
      </Typography>
      <Typography variant="body2" className="truncated-multiline">
        <Box lineHeight={1} component="span" className={classes.orgName}>
          {props.name}
        </Box>
      </Typography>
    </Box>
  )
}

const useDrawerItems = () => {
  const internalAdmin = useHasRole(INTERNAL_ADMIN)
  const internalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const superAdmin = useHasRole(SUPER_ADMIN)
  const volunteering = useFeatureEnabled('Volunteering')
  const events = useFeatureEnabled('EventScheduling')
  const mentoring = useFeatureEnabled('Mentorship')
  const mentoringUpdates = useFeatureEnabled('NewMentorship')
  const donations = useFeatureEnabled('Donations')
  const bulkMessaging = useFeatureEnabled('BulkMessaging')
  const orgUserProfiles = useFeatureEnabled('OrgUserProfiles')
  const globalUserProfiles = useFeatureEnabled('GlobalUserProfiles')
  const volunteerQuestionnaires = useFeatureEnabled('VolunteerProfiles')
  const helpingHands = useFeatureEnabled('HelpingHands')
  const tripPlanner = useFeatureEnabled('TripPlanner')
  const groups = useFeatureEnabled('Groups')
  const credentialIDs = useFeatureEnabled('CredentialIDs')
  const apiKeyEnabled = useFeatureEnabled('ApiKey')
  const registrants = useFeatureEnabled('Registrants')
  const chats = useFeatureEnabled('Chats')
  const rolesManagement = useFeatureEnabled('RolesManagement')
  const settingsTabs = !isEmpty(useOrganizationSettingsTabs())
  const settingsEnabledForSuperAdmin = useSettingsEnabledForSuperAdmin()
  const organization = useCurrentOrg()
  const classes = useStyles()

  const items = useMemo(() => getItems({ organization, classes }), [classes, organization])

  return useMemo(() => {
    const enabled = {
      internalAdmin,
      internalSuperAdmin,
      superAdmin,
      volunteering,
      events,
      mentoring,
      mentoringUpdates,
      donations,
      bulkMessaging,
      orgUserProfiles,
      globalUserProfiles,
      volunteerQuestionnaires,
      helpingHands,
      tripPlanner,
      groups,
      settingsTabs,
      settingsEnabledForSuperAdmin,
      credentialIDs,
      apiKeyEnabled,
      registrants,
      chats,
      rolesManagement,
    }

    return filterByRequireRules(items, {
      config: enabled,
      recursive: true,
      nestingProperty: 'items',
    })
  }, [
    internalAdmin,
    internalSuperAdmin,
    superAdmin,
    volunteering,
    events,
    mentoring,
    mentoringUpdates,
    donations,
    bulkMessaging,
    orgUserProfiles,
    globalUserProfiles,
    volunteerQuestionnaires,
    helpingHands,
    tripPlanner,
    groups,
    settingsTabs,
    settingsEnabledForSuperAdmin,
    credentialIDs,
    apiKeyEnabled,
    registrants,
    items,
    chats,
    rolesManagement,
  ])
}

const DrawerContents = ({ history, onChangeRoute, open, onToggle }) => {
  const classes = useStyles() //TODO move styles and component to a new place
  const navItems = useDrawerItems()

  return (
    <div className={classes.container}>
      <Overlay />
      <div className={cn(classes.toolbar, 'menu-logo', { [classes.closed]: !open })}>
        {open && <img src="/assets/icons/white-logo.svg" alt="logo" />}
        <img
          src={open ? '/assets/icons/close-menu.svg' : '/assets/icons/open-menu.svg'}
          alt={open ? 'close' : 'open'}
          className={cn(classes.openClose, { [classes.closed]: !open })}
          onClick={onToggle}
        />
      </div>
      <NavMenu items={navItems} history={history} open={open} onChangeRoute={onChangeRoute} />
    </div>
  )
}

const DrawerBar = ({ history }) => {
  const classes = useStyles()
  const isSmall = useBreakPoint(1280)
  const [open, setOpen] = useState(!isSmall)
  useMediaQuery({ minWidth: 1280 }, undefined, isOpen => {
    if (isOpen !== open) {
      setOpen(isOpen)
    }
  })

  return (
    <>
      <nav className={classes.nav}>
        <Drawer
          className={cn(classes.drawer + ' menu-sidebar', { [classes.closed]: !open })}
          variant="persistent"
          anchor="left"
          open={true}
          classes={{
            paper: cn(classes.drawerPaper, { [classes.closed]: !open }),
          }}
        >
          <DrawerContents history={history} open={open} onToggle={() => setOpen(v => !v)} />
        </Drawer>
      </nav>
    </>
  )
}

Drawer.propTypes = {
  history: PropTypes.object,
}

export default function WrappedDrawer(props) {
  return (
    <ThemeProvider theme={muiTheme}>
      <DrawerBar {...props} />
    </ThemeProvider>
  )
}
