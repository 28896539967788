import React, { useState, useEffect, useMemo } from 'react'
import Popup from '../../../../components/popup'
import TabSwitcher from '../../../../../core/tab-switcher'
import AddVolunteerForm from '../../../../modules/events/details/add-volunteer-form'
import { Opportunity } from '../../../../interfaces'
import Loader from '../../../../components/loader'
import EventDetailsHeader from '../../../../modules/events/details/header'
import Table, { TableHeader } from '../../../../components/table'
import './style.scss'
import API_ENDPOINTS from '../../../../constants/API_ENDPOINTS'
import VolunteersFilters from '../../../../modules/events/details/volunteers/filter'
import Checkbox from '../../../../components/checkbox/index'
import BaseService from '../../../../services/base.service'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import { find, get } from 'lodash'
import { useCancelRegistrations } from './hooks/useCancelRegistrations'
import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { mdiAccountRemove } from '@mdi/js'
import IconButton from '@material-ui/core/IconButton'
import Icon from '@mdi/react'
import useRemoveShiftsPrompt from './hooks/useRemoveShiftsPrompt'
import { Person } from '../../../../../people/interface'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import { ContactCell } from 'core/table/cells'
import { contactAccessor } from 'core/table/utils'
import format from 'utils/format'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { Group, GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'

const initialFilters = {
  timeshift: {
    value: '',
    label: 'Shift',
  },
  role: {
    value: '',
    label: 'Role',
  },
}

const base = new BaseService()

export default function EventDetailsVolunteers(props: any) {
  const [event, setEvent] = useState<Opportunity | null>(null)
  const [availableSlots, setAvailableSlots] = useState<any>(0)
  const [showAddVolunteerPopup, setShowAddVolunteerPopup] = useState(false)
  const [selectedAll, setSelectedAll] = useState(false)
  const [roles, setRoles] = useState<any>(null)
  const [filters, setFilters] = useState(initialFilters)
  const [selectedEmail, setSelectedEmail] = useState<any>([])
  const [volunteers, setVolunteers] = useState<any>(null)
  const [searchValue, setSearchValue] = useState<string>('')
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const orgId = props.match.params.orgId
  const encodedOccurrence = props.match.params.encodedOccurrence
  const roleOptions: any[] = []
  const timeshiftOptions: any[] = []
  const passed = !event || new Date() > new Date(String(event.endsAt))
  const [groups, setGroups] = useState<Group[]>([])
  useGetOnMount<Group[]>({
    errorMessage: 'Error fetching groups',
    onSuccess: setGroups,
    emptyValue: [],
    url: `/organizations/${orgId}/groups`,
  })
  const volunteerGroupId = useMemo(() => find(groups, { groupType: { code: GroupTypeCode.Volunteer } })?.id, [groups])

  const handleRequirements = async (user: Person) => {
    try {
      const done = await fulfillRequirementsPrompt({
        person: user,
        filters: {
          personId: user.id,
          associatedEntity: { encodedOccurrence },
          action: PrerequisitesAction.EVENT_REGISTRATION,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
          excludeCredentials: true,
        },
        // this gives the user the ability to skip the question sets
        isAdmin: true,
        continueEvenIfError: true,
      })
      return done
    } catch (error) {
      console.error('error', error)
      // TODO: confirm we we want to still continue https://civicchamps.atlassian.net/browse/CCA-1369
      return false
    }
  }

  const { enqueueSnackbar } = useSnackbar()
  const getVolunteers = async () => {
    try {
      const response = await base.getJSON(API_ENDPOINTS.Volunteers.getAllVolunteers(encodedOccurrence))
      await setVolunteers(
        response.map((r: any) => {
          return {
            ...r,
            selected: false,
          }
        }),
      )
    } catch (e) {
      console.error('e', e)
    }
  }

  const removeShifts = useRemoveShiftsPrompt(getVolunteers)

  const [cancelRegistrations, { loading: cancellingRegistrations }] = useCancelRegistrations(getVolunteers)

  const getRoles = async () => {
    const endpoint = API_ENDPOINTS.Roles.getOrganizationRolesOccurrenceRefactor(orgId)
    const response = await base.getJSON(endpoint)
    setRoles(response)
  }

  const getEvent = async () => {
    const endpoint = API_ENDPOINTS.Events.getOccurrence({ encodedOccurrence, populateRecurrenceInfo: false })
    const response = await base.getJSON(endpoint)
    await setEvent(response)

    const availableSlots = response.timeshifts.reduce((acc: any, curr: any) => acc + curr.available, 0)
    setAvailableSlots(availableSlots)
  }

  const handleFilterChange = async () => {
    const query = queryString.stringify(
      {
        roleId: get(filters, 'role.value'),
        timeshiftId: get(filters, 'timeshift.value'),
        search: searchValue,
      },
      { skipNull: true, skipEmptyString: true },
    )
    const endpoint = API_ENDPOINTS.Volunteers.searchVolunteersOccurrenceRefactor(encodedOccurrence, query)
    const response = await base.getJSON(endpoint)
    await setVolunteers(response)
  }

  const handleSaveAddVolunteer = async (response: any) => {
    setShowAddVolunteerPopup(false)
    if (response.statusCode) {
      enqueueSnackbar(response.message, {
        preventDuplicate: true,
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      })
    } else {
      // we don't care what the response is, as the user is actually registered either way
      // if it handleRequirements failed, that'd just mean that the user would have to answer again when they show up
      // but, this has to come AFTER the call, as the user might not even exist yet
      await handleRequirements(response.user)
      enqueueSnackbar('You have successfully added a new volunteer', {
        preventDuplicate: true,
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      })
      await getVolunteers()
      await getEvent()
    }
  }

  const handleCloseAddVolunteer = () => {
    setShowAddVolunteerPopup(false)
  }

  useEffect(() => {
    getEvent()
    getRoles()
    handleFilterChange()
  }, [searchValue === '', filters])

  useEffect(() => {
    let hasUnchecked = false
    volunteers &&
      volunteers.map((item: any) => {
        if (!item.selected) {
          hasUnchecked = true
        }
      })
    if (hasUnchecked) {
      setSelectedAll(false)
    } else {
      setSelectedAll(true)
    }
  }, [volunteers])

  if (!event || !volunteers || cancellingRegistrations) {
    return <Loader />
  }

  const TABLE_HEADERS: TableHeader[] = [
    {
      key: 'checkbox',
      customWidth: '5%',
      title: (
        <Checkbox
          value={selectedAll}
          onClick={() => {
            setSelectedAll(!selectedAll)
            setVolunteers(
              volunteers.map((i: any) => {
                return { ...i, selected: !selectedAll }
              }),
            )
          }}
        />
      ),
      sortable: false,
    },
    {
      key: 'name',
      title: 'NAME',
      customWidth: '25%',
      sortable: true,
    },
    {
      key: 'email',
      title: 'Email',
      customWidth: '25%',
      customColor: '#5C8DE8',
      sortable: true,
    },
    {
      key: 'mobilePhone',
      title: 'MOBILE PHONE',
      customWidth: '25%',
      sortable: false,
    },
    {
      key: 'homePhone',
      title: 'HOME PHONE',
      customWidth: '25%',
      sortable: false,
    },
    {
      key: 'roles',
      title: 'SHIFT & ROLES',
      customWidth: '35%',
      sortable: false,
    },
    {
      key: 'edit',
      customWidth: '10%',
      title: '',
      sortable: false,
    },
  ]

  const isSelected = volunteers && volunteers.some((s: any) => s.selected)

  const renderRoles = (event: any) =>
    event &&
    event.map((item: any, index: number) => {
      const isRoleExist = roleOptions.some(role => role.value === item.role.id)
      !isRoleExist && roleOptions.push({ value: item.role.id, label: item.role.name })

      const isTimeshiftExist = timeshiftOptions.some(timeshift => timeshift.value === item.timeshift.id)
      !isTimeshiftExist &&
        timeshiftOptions.push({
          value: item.timeshift.id,
          label: `${item.timeshift.time_start}-${item.timeshift.time_end}`,
        })

      return (
        <div className="event-vol_times_roles" key={index}>
          <span className="event-vol_role-text">
            {item.timeshift.time_start}-{item.timeshift.time_end} -{' '}
          </span>
          <span className="event-vol_role-text">{item.role.name}</span>
        </div>
      )
    })

  const TableData =
    volunteers &&
    volunteers.map((item: any, index: number) => {
      return {
        checkbox: (
          <div className="event-vol_checkbox">
            <Checkbox
              value={volunteers[index].selected}
              onClick={() => {
                const newArr = [...volunteers]
                newArr[index].selected = !newArr[index].selected
                volunteers[index].selected = !volunteers[index].selected
                setVolunteers(newArr)
              }}
            />
          </div>
        ),
        name: `${item.user.givenName} ${item.user.familyName}`,
        email: (
          <ContactCell
            cell={{
              value: contactAccessor({
                valuePath: 'user.email',
                verificationStatusPath: 'user.emailVerified',
                type: 'email',
              })(item),
            }}
          />
        ),
        mobilePhone: (
          <ContactCell
            cell={{
              value: contactAccessor({
                valuePath: 'user.phoneNumber',
                verificationStatusPath: 'user.phoneNumberVerified',
                type: 'phone',
              })(item),
            }}
          />
        ),
        homePhone: format.phoneNumber(item.user.homePhoneNumber),
        roles: renderRoles(item.details),
        selected: item.selected,
        edit: (
          <div>
            <IconButton
              aria-label="inherit"
              aria-describedby={item.id}
              onClick={() => removeShifts(item)}
              disabled={passed}
            >
              <Icon path={mdiAccountRemove} size={1} color="primary" />
            </IconButton>
          </div>
        ),
      }
    })

  const handleChange = (value: any, type: string) => {
    setFilters({ ...filters, [type]: value })
  }

  const clearAll = () => {
    volunteers.map((item: any) => {
      item.selected = false
    })
    setSelectedAll(false)
    setSelectedEmail([])
  }

  const sendEmail = () => {
    const selectedVolunteers = volunteers.filter((item: any) => item.selected)
    const emails = selectedVolunteers.map((e: any) => {
      return e.user.email
    })
    window.open(`mailto:${emails}`)
    //clearAll()
  }

  const handleCancelRegistrations = () => {
    const selectedVolunteers = volunteers.filter((item: any) => item.selected)
    return cancelRegistrations({ encodedOccurrence, volunteers: selectedVolunteers })
  }

  const tabDetails = [
    {
      to: `/events/${encodedOccurrence}/${orgId}`,
      text: 'Event Summary',
    },
    {
      to: `/events/${encodedOccurrence}/${orgId}/volunteers`,
      text: 'Volunteers',
      // default wasn't working for some reason
      isActive: (match: any, location: any) => match && match.url.includes('volunteers'),
    },
  ]

  return (
    <article className="section-scene">
      {showAddVolunteerPopup && (
        <Popup
          handleCloseModal={() => setShowAddVolunteerPopup(false)}
          shouldCloseOnOverlayClick={true}
          hideCloseButton={true}
          width={500}
        >
          <AddVolunteerForm
            event={event}
            volunteerGroupId={volunteerGroupId}
            onSave={handleSaveAddVolunteer}
            onClose={handleCloseAddVolunteer}
            {...props}
          />
        </Popup>
      )}
      <div className="detail_event_block">
        <div className="detail_event_block_back_arrow" onClick={() => props.history.push('/events')}>
          <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
          Events
        </div>

        <div>
          <EventDetailsHeader
            event={event}
            volunteersList={volunteers}
            isVolunteers
            onAddVolunteer={() => setShowAddVolunteerPopup(!showAddVolunteerPopup)}
            isDraft={!!event.timeshifts.length}
          />

          <TabSwitcher linkDetails={tabDetails} />

          <div>
            <VolunteersFilters
              event={event}
              encodedOccurrence={encodedOccurrence}
              availableSlots={availableSlots}
              volunteersList={volunteers}
              onAddVolunteer={() => setShowAddVolunteerPopup(!showAddVolunteerPopup)}
              onSearchClick={handleFilterChange}
              filtersValues={filters}
              onChange={handleChange}
              getVolunteers={handleFilterChange}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
              clearSearchValue={() => {
                setSearchValue('')
                setFilters(initialFilters)
              }}
              clearFilters={async () => {
                setFilters(initialFilters)
                await getVolunteers()
              }}
              sendEmail={sendEmail}
              cancelRegistrations={handleCancelRegistrations}
              isChecked={isSelected}
              timeshiftOptions={timeshiftOptions}
              roleOptions={roleOptions}
            />
            {!!TableData.length && (
              <Table
                className="event-vol_row-style"
                onRowClick={(r: any, index: number) => {
                  volunteers[index].selected = !volunteers[index].selected
                  setVolunteers([...volunteers])
                }}
                setRows={() => TableData}
                rows={TableData}
                headers={TABLE_HEADERS}
              />
            )}
          </div>
        </div>
      </div>
    </article>
  )
}
