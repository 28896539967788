import { Grid, IconButton, makeStyles, Modal } from '@material-ui/core'
import React, { useCallback, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close'
import useSendNotification from 'champion/hooks/useSendNotification'
import SendNotificationSelectionStep from 'champion/components/SendNotificationSelectionStep'
import { SendNotificationErrorsStep } from 'champion/components/SendNotificationErrorsStep'
import cn from 'classnames'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'

const useStyles = makeStyles({
  modalBody: {
    backgroundColor: 'white',
    width: '590px',
    height: '312px',
    position: 'fixed',
    top: 'calc(50% - 156px)',
    left: 'calc(50% - 295px)',
    border: '1px solid #E5E5E5',
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '4px',
    padding: '30px',
  },
  modalBodyExtended: {
    height: '370px',
  },
  close: {
    position: 'absolute',
    top: '18px',
    right: '18px',
  },
})

export const SendNotificationModal = (props: any) => {
  const organization = useCurrentOrg()
  const [sendNotification, { loading }] = useSendNotification(organization.id)
  const { showing, complete, cancel, persons } = props
  const [rows, setRows] = useState<any[]>(persons)
  const [noContactRows, setNoContactRows] = useState<any[]>(
    persons.filter(({ user: { email, phoneNumber } }: any) => !email && !phoneNumber),
  )
  const [step, setStep] = useState<number>(rows.length !== noContactRows.length ? 1 : 2)
  const classes = useStyles()
  const removeRow = useCallback(
    (id: number) => {
      setRows(rows.filter(({ user }: any) => user.id !== id))
    },
    [rows],
  )

  const handleSend = useCallback(async () => {
    const eligibleRows = rows.filter(({ user: { email, phoneNumber } }: any) => email || phoneNumber)
    const emptyContacts = rows.filter(({ user: { email, phoneNumber } }: any) => !email && !phoneNumber)
    if (eligibleRows.length) {
      await sendNotification(
        eligibleRows.map(({ user: { id, email } }: any) => ({
          personId: id,
          sendMethod: email ? 'email' : 'phone',
        })),
      )
    }
    if (emptyContacts.length) {
      setNoContactRows(emptyContacts)
      setStep(2)
    } else {
      complete()
    }
  }, [complete, rows, sendNotification])

  return (
    <Modal open={showing}>
      <Grid container direction="column" className={cn(classes.modalBody, { [classes.modalBodyExtended]: step === 2 })}>
        <IconButton className={classes.close} aria-label="close" component="span" onClick={() => cancel()}>
          <CloseIcon />
        </IconButton>
        {
          [
            <SendNotificationSelectionStep
              removeRow={removeRow}
              handleSend={handleSend}
              loading={loading}
              rows={rows}
            />,
            <SendNotificationErrorsStep rows={noContactRows} complete={complete} />,
          ][step - 1]
        }
      </Grid>
    </Modal>
  )
}
