import React, { useCallback, useState } from 'react'
import { Dialog, DialogTitle, IconButton, DialogContent, Typography, Grid } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useFetch } from 'civic-champs-shared/api/hooks'
import AddUserFlow from 'users/components/AddUserFlow'
import { useCurrentOrg } from 'auth/hooks'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import cn from 'classnames'
import Button from '../../../components/button'
import UploadCsvPopup from '../../../components/upload-csv-popup'
import ENDPOINT_ROUTES from '../../../Mentorship/constants/APIs'
import BaseService from '../../../Mentorship/services/base.service'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'

const columns = [
  {
    Header: 'First Name',
    accessor: 'givenName',
  },
  {
    Header: 'Last Name',
    accessor: 'familyName',
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
  {
    Header: 'Mobile Phone',
    accessor: 'phoneNumber',
  },
  {
    Header: 'Home Phone',
    accessor: 'homePhoneNumber',
  },
]

export default function AddVolunteerPrompt(props) {
  const classes = usePromptStyles()
  const [uploadModalOpen, setUploadModalOpen] = useState(false)
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const { showing, close, cancel, complete } = props
  const baseService = new BaseService()
  const organization = useCurrentOrg()

  const [request, { loading: submitting, error, result }] = useFetch()
  const handleSubmit = useCallback(
    (event) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/volunteers`,
        config: {
          headers: {
            'X-Api-Version': '2020-01-17',
          },
          body: event,
        },
      })
    },
    [organization.id, request],
  )

  const handleDone = () => complete(result)

  const closeBothModals = () => {
    setUploadModalOpen(false)
    close()
  }

  const confirmParsedRows = async (orgId, volunteers, sendInvite) => {
    const newVolunteers = await baseService.postJSON(ENDPOINT_ROUTES.Volunteers.confirmVolunteers(orgId), {
      volunteers,
      sendInvite,
    })
    // TODO: need to add an error snack or something here in case this fails (as it can fail for good reasons)
    complete(newVolunteers)
    closeBothModals()
  }

  return (
    <>
      <Dialog fullScreen className={cn({ fullScreen: true })} open={showing && !uploadModalOpen} onClose={close}>
        <DialogTitle className={classes.titleContainer}>
          <Grid container direction="row" justify="space-between">
            <Grid item container direction="row">
              <Grid>
                <Typography className={classes.title}>Add Volunteer</Typography>
              </Grid>
              {isInternalAdmin && (
                <Grid>
                  <Button variant="contained" className={classes.uploadButton} onClick={() => setUploadModalOpen(true)}>
                    <CloudUpload style={{ marginRight: 5 }} />
                    Upload List
                  </Button>
                </Grid>
              )}
            </Grid>
            <Grid>
              <IconButton onClick={close} className={classes.closeButton}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <AddUserFlow
            userLabel="Volunteer"
            onSubmit={handleSubmit}
            submitting={submitting}
            error={error}
            onCancel={cancel}
            onDone={handleDone}
          />
        </DialogContent>
      </Dialog>
      {uploadModalOpen && (
        <UploadCsvPopup
          close={closeBothModals}
          reference="volunteer"
          downloadLink={process.env.REACT_APP_UPLOAD_TEMPLATE_VOLUNTEERS}
          confirmParsedRows={confirmParsedRows}
          columns={columns}
        />
      )}
    </>
  )
}
