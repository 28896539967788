import { map, uniqBy, sum, max, mean, filter, get } from 'lodash'
import React, { useMemo } from 'react'
import format from 'utils/format'
import SummaryCards from 'core/table/components/SummaryCards'
import { NumberCell, CurrencyCell } from 'core/table/cells'
import { CampaignEventType, DonationStatus } from '../interface'
import { Button, Link } from '@material-ui/core'

export const useCampaignDonationsSummary = (donations, combinedActivitiesAndEvents) =>
  useMemo(() => {
    const paidDonations = filter(donations, donation => donation.status === DonationStatus.PAID)
    const amounts = map(paidDonations, 'amountInCents')
    const numVolunteers = combinedActivitiesAndEvents.length
    const numConverted = filter(combinedActivitiesAndEvents, event => get(event, `events.${CampaignEventType.DONATED}`)).length
    return {
      conversionPercentage: numVolunteers ? (numConverted / numVolunteers) * 100 : 0,
      donorCount: uniqBy(paidDonations, 'person.id').length,
      donations: {
        total: sum(amounts) / 100,
        largest: max(amounts) / 100,
        average: mean(amounts) / 100,
      },
    }
  }, [donations])

const getCards = (showMore, showMoreOrLess) => [
  {
    Header: 'Donors',
    accessor: 'donorCount',
    Cell: NumberCell,
  },
  {
    Header: 'Total',
    accessor: 'donations.total',
    Cell: CurrencyCell,
  },
  {
    Header: 'Largest',
    accessor: 'donations.largest',
    Cell: CurrencyCell,
  },
  {
    Header: 'Average / Donation',
    accessor: 'donations.average',
    Cell: CurrencyCell,
  },
  {
    Header: 'Conversions',
    accessor: 'conversionPercentage',
    Cell: ({ cell }) => <div>{format.fixedPoint(cell.value, 1)}%</div>,
    Footer: ({ cell }) => (
      <div>
        <Button
          component={Link}
          onClick={showMoreOrLess}
          style={{ color: '#5F8FE8', textDecoration: 'underline', textTransform: 'none' }}
        >
          {showMore ? 'Show Less' : 'Show More'}
        </Button>
      </div>
    ),
  },
]

export default function CampaignDonationSummary(props) {
  const { donations, combinedActivitiesAndEvents, showMoreOrLess, showMore } = props
  const summary = useCampaignDonationsSummary(donations, combinedActivitiesAndEvents)

  return <SummaryCards cards={getCards(showMore, showMoreOrLess)} summary={summary} />
}
