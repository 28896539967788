import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import PageHeader from 'App/components/PageHeader'
import { useGetChats } from 'chats/hooks/useGetChats'
import { Chat } from 'chats/interfaces'
import Loading from 'civic-champs-shared/core/Loading'
import useChatsColumns, { useStyles as useColumnStyles } from 'chats/hooks/useChatsColumns'
import {
  Column,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import DEFAULT_FILTERS from 'core/table/filters'
import { useConditionalSelectColumn } from 'core/table/table-hooks'
import Grid from '@material-ui/core/Grid'
import { ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import { TableMenuButton } from 'civic-champs-shared'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import AddIcon from '@material-ui/icons/Add'
import { useDateRangeFilter } from 'filtering/hooks'
import { NUMBER_OPERATOR_OPTIONS, STRING_OPERATOR_OPTIONS } from 'core/table/interfaces/Filters'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'
import ArchiveOutlinedIcon from '@material-ui/icons/ArchiveOutlined'
import filter from 'lodash/filter'
import useDeleteChatsPrompt from 'chats/hooks/useDeleteChatsPrompt'
import map from 'lodash/map'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { AddChatPrompt } from 'chats/components/AddChatPrompt'
import useAddChat from 'chats/hooks/useAddChat'
import useAddChatMembers from 'chats/hooks/useAddChatMembers'
// @ts-ignore
import uuid from 'uuid'

export const Chats = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Chats" subTitle="Send messages within the mentoring works app from here">
      <ChatsComponent {...props} />
    </PageHeader>
  )
}

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'name':
      return STRING_OPERATOR_OPTIONS
    case 'membersCount':
      return NUMBER_OPERATOR_OPTIONS
    default:
      return []
  }
}
const tableName = 'chats'
export const ChatsComponent = ({ location, history }: RouteComponentProps) => {
  const [chats, setChats] = useState<Chat[]>([])
  const [data, setData] = useState<Chat[]>([])
  const showDeletePrompt = useDeleteChatsPrompt({ setChats })
  const [fetchChats, { loading }] = useGetChats()
  const [addChat] = useAddChat()
  const [addChatMembers] = useAddChatMembers()
  useEffect(() => {
    fetchChats().then(setChats)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const onDelete = useCallback((chat: any) => showDeletePrompt([chat]), [showDeletePrompt])
  const columns = useChatsColumns({ onDelete })
  const columnClasses = useColumnStyles()
  const [dateRange] = useDateRangeFilter()
  const showAddChatPrompt = useShowPrompt(AddChatPrompt)
  useEffect(() => {
    const { startDate, endDate } = dateRange
    setData(filter(chats, ({ lastActivityAt }) => lastActivityAt >= startDate && lastActivityAt <= endDate))
  }, [chats, dateRange])
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const handleAddChat = useCallback(
    async ({ name, description, members }) => {
      const tempId = uuid()
      setChats(chats => [
        {
          id: tempId,
          name,
          description,
          createdAt: new Date(),
          lastActivityAt: new Date(),
          membersCount: members.length,
        },
        ...chats,
      ])
      const chat = await addChat({ name, description })
      setChats(chats =>
        map(chats, ({ id, ...rest }) => ({
          id: id === tempId ? chat.id : id,
          ...rest,
        })),
      )
      await addChatMembers({ chatId: chat.id, uuids: map(members, 'pubnubId') })
    },
    [setChats, addChat, addChatMembers],
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
        // @ts-ignore
        filters,
      },
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      data,
      columns: columns as Column<Chat>[],
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnClasses.selectColumn),
  )

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Chats"
        filterSubHeader="Select chat attributes to filter by:"
        buttons={
          <>
            <TableMenuButton
              startIcon={<ArchiveOutlinedIcon />}
              onClick={() => showDeletePrompt(map(table.selectedFlatRows, 'original'))}
              disabled={!table.selectedFlatRows.length}
            >
              Archive
            </TableMenuButton>
          </>
        }
        addButton={
          <ContainedButton
            startIcon={<AddIcon />}
            // @ts-ignore
            onClick={() => showAddChatPrompt().then(handleAddChat)}
          >
            New Chat
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
      />
    </Grid>
  )
}

export default Chats
