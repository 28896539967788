// NOTE: I don't believe anything actually routes to the page
import React, { useState, useCallback } from 'react'
import { Grid, Typography, Box, makeStyles, Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core"
import { useDateRangeFilter } from "filtering/hooks"
import DateRangeFilter from "components/filter/DateRangeFilter"
import Loading from 'components/Loading'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import format from 'utils/format'
import DeleteActivityDialog from 'tracking/activity/components/DeleteActivityDialog'
import EditActivityDialog from 'tracking/activity/components/EditActivityDialog'
import { getDonationStatusDisplayNames } from '../../donation/campaigns/interface'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { Volunteer, Donation, Activity } from '../utils/interface'

interface ChampionInfo { volunteer: Volunteer; donations: Donation[]; activities: Activity[] }

const useStyles = makeStyles(theme => ({
  card: {
    border: 'solid 1px rgb(147, 199, 242)',
    textAlign: 'center',
    padding: '25px 0',
    backgroundColor: 'rgb(238, 243, 253)',
    marginLeft: -1,
  },
}));

export const ChampionDetail = (props: any) => {
  const id = props.match.params.id
  const [dateRange, setDateRange] = useDateRangeFilter()
  const [, { result, loading }] = useGetOnMount<ChampionInfo>({ url: `/organizations/champion/${id}` })
  const classes = useStyles()

  const [activityToEdit, setActivityToEdit ] = useState<Activity | null>(null);
  const [activityToDelete, setActivityToDelete] = useState<Activity | null>(null);

  const handleCloseDeleteDialog = useCallback( () => { setActivityToDelete(null) }, [setActivityToDelete]);
  const handleCloseEditDialog = useCallback( () => { setActivityToEdit(null) }, [setActivityToEdit]);

  if (loading) {
    return <Loading />
  }

  const { volunteer, donations = [], activities = [] } = result
  return (
    <Grid container>
      <Grid container item sm={10} direction="column" spacing={4}>
        <Grid item container justify="space-between">
          <Grid item>
            <Typography variant="h4">
              {volunteer.user.givenName} {volunteer.user.familyName}
            </Typography>
          </Grid>
          <Grid item>
            <Box style={{ padding: 4, border: 'solid 1px rgba(179, 179, 179)', borderRadius: 4 }}>
              <DateRangeFilter initialValues={dateRange} onFilterApplied={setDateRange} noBorder={true} />
            </Box>
          </Grid>
        </Grid>
        <Grid item container>
          <Grid item sm={3}>
            <Typography>EMAIL ADDRESS</Typography>
            <Typography>{volunteer.user.email}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>MOBILE PHONE</Typography>
            <Typography>{volunteer.user.phoneNumber}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>HOME PHONE</Typography>
            <Typography>{volunteer.user.homePhoneNumber}</Typography>
          </Grid>
          <Grid item sm={3}>
            <Typography>LAST ACTIVITY</Typography>
            <Typography>{volunteer.volunteering.latestActivity || '-'}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Typography variant="h5">Volunteer Activity</Typography>
          </Grid>
          <Grid item container>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{_.size(activities)}</Typography>
                <Typography variant="h6">Total Activities</Typography>
              </div>
            </Grid>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{_.sumBy(activities, 'hoursVolunteered').toFixed(2)}</Typography>
                <Typography variant="h6">Total Hours</Typography>
              </div>
            </Grid>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{_.meanBy(activities, 'hoursVolunteered')?.toFixed(2) || 0}</Typography>
                <Typography variant="h6">Average Time</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Check-in</TableCell>
                  <TableCell>Opportunity</TableCell>
                  <TableCell>Activity</TableCell>
                  <TableCell>Hours</TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(activities, (item) => {
                  return (
                    <TableRow>
                      <TableCell>{format.datetime(item.occurredAt)}</TableCell>
                      <TableCell>{_.get(item, 'opportunity.name', '')}</TableCell>
                      <TableCell>{item.team}</TableCell>
                      <TableCell>{item.hoursVolunteered?.toFixed(2) || 0}</TableCell>
                      <TableCell>
                        <Link
                          style={{ textDecoration: 'none', fontWeight: 'bold' }}
                          to="#"
                          onClick={() => {
                            setActivityToEdit(item)
                          }}
                        >
                          Edit Hours
                        </Link>
                      </TableCell>
                      <TableCell>
                        <Link
                          style={{ textDecoration: 'none', fontWeight: 'bold', color: 'red' }}
                          to="#"
                          onClick={() => {
                            setActivityToDelete(item)
                          }}
                        >
                          Delete
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
            <DeleteActivityDialog
              key={`delete-${activityToDelete ? _.get(activityToDelete, 'id') : 'none'}`}
              open={!!activityToDelete}
              onClose={handleCloseDeleteDialog}
              activity={activityToDelete}
            />
            <EditActivityDialog
              key={`edit-${activityToEdit ? _.get(activityToEdit, 'id') : 'none'}`}
              open={!!activityToEdit}
              onClose={handleCloseEditDialog}
              activity={activityToEdit}
            />
          </Grid>
        </Grid>
        <Grid item container spacing={2}>
          <Grid item>
            <Typography variant="h5">Donations</Typography>
          </Grid>
          <Grid item container>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{_.size(donations)}</Typography>
                <Typography variant="h6">Donations</Typography>
              </div>
            </Grid>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{`$${format.fixedPoint(
                  _.sumBy(donations, 'amountInCents') / 100,
                  2,
                )}`}</Typography>
                <Typography variant="h6">Total</Typography>
              </div>
            </Grid>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{`$${format.fixedPoint(
                  _.get(_.maxBy(donations, 'amountInCents'), 'amountInCents', 0) / 100,
                  2,
                )}`}</Typography>
                <Typography variant="h6">High</Typography>
              </div>
            </Grid>
            <Grid item sm={2}>
              <div className={`${classes.card}`}>
                <Typography variant="h5">{`$${format.fixedPoint(
                  _.meanBy(donations, 'amountInCents') || 0 / 100,
                  2,
                )}`}</Typography>
                <Typography variant="h6">Average</Typography>
              </div>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <Table style={{ width: '100%' }}>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Campaign</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell>Processing Fee</TableCell>
                  <TableCell>Net</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {_.map(donations, (item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>{format.datetime(item.createdAt)}</TableCell>
                      <TableCell>-</TableCell>
                      <TableCell>{`$${format.fixedPoint(item.amountInCents / 100, 2)}`}</TableCell>
                      <TableCell>{`$${format.fixedPoint(item.fees / 100, 2)}`}</TableCell>
                      <TableCell>{`$${format.fixedPoint((item.amountInCents - item.fees) / 100, 2)}`}</TableCell>
                      <TableCell>{getDonationStatusDisplayNames(item.status)}</TableCell>
                      <TableCell>
                        <Link style={{ textDecoration: 'none', fontWeight: 'bold' }} to={`/donations/${item.id}`}>
                          View Transaction
                        </Link>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
