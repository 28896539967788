import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { NamedRef } from 'civic-champs-shared/question-sets/types'

import { DYNAMIC_TEXT } from 'core/table/filters'
import { DateCell } from 'core/table/cells'
import { PersonLinkCell } from 'core/table/cells/modern'
import { useUISettings } from 'hooks/useUISettings'

import { QuestionnaireEditHeader, QuestionnaireEditCell } from '../components/QuestionnaireEditCell'

export const useStyles = makeStyles({
  link: {
    color: '#0F5DB5',
    textDecoration: 'none',
    cursor: 'pointer',
  },
  selectColumn: {
    '&>div': {
      width: '46px',
    },
  },
  idColumn: {
    '&>div': {
      width: '70px',
    },
  },
  nameColumn: {
    '&>div': {
      width: '250px',
    },
  },
  fullNameColumn: {
    '&>div': {
      width: '200px',
    },
  },

  statusColumn: {
    '&>div': {
      width: '150px',
    },
  },
  versionColumn: {
    '&>div': {
      width: '100px',
    },
  },
  actionsColumn: {
    '&>div': {
      width: '76px',
    },
  },
  deleteIcon: {
    color: '#ba1b1b',
    height: '18px',
    cursor: 'pointer',
  },
  deleteHeaderIcon: {
    color: '#000000',
    height: '18px',
    marginLeft: '6px',
  },
})

export const QuestionnaireNameCell = ({ cell }: any) => {
  const { id, name } = cell.row.original
  const classes = useStyles()

  return (
    <Link className={classes.link} to={`/questionnaires/${id}`}>
      {name}
    </Link>
  )
}

export function useQuestionnairesColumns({
  deleteQuestionSet,
  duplicateQuestionSet,
}: {
  duplicateQuestionSet: (questonSet: NamedRef) => void
  deleteQuestionSet: (questonSet: NamedRef) => void
}) {
  const classes = useStyles()
  // @ts-ignore
  const { showAdminItems } = useUISettings()

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            id: 'id',
            Header: 'Id',
            accessor: 'id',
            disableFilters: true,
            requires: 'showAdminItems',
          },
          {
            id: 'name',
            Header: 'Name',
            accessor: 'name',
            Cell: QuestionnaireNameCell,
            disableFilters: true,
            className: classes.nameColumn,
          },
          {
            id: 'createdAt',
            Header: 'Created On',
            accessor: 'createdAt',
            Cell: DateCell,
            disableFilters: true,
            className: classes.createdAt,
          },
          {
            id: 'createdBy',
            Header: 'Created By',
            accessor: 'createdBy',
            Cell: PersonLinkCell,
            disableFilters: true,
            className: classes.fullNameColumn,
          },
          {
            id: 'lastEditedBy',
            Header: 'Last Edited By',
            accessor: 'lastEditedBy',
            Cell: PersonLinkCell,
            disableFilters: true,
            className: classes.fullNameColumn,
          },
          {
            id: 'lastEditedAt',
            Header: 'Last Edited On',
            accessor: 'lastEditedAt',
            Cell: DateCell,
            disableFilters: true,
            className: classes.createdAt,
          },
          {
            id: 'version',
            Header: 'Version',
            accessor: 'version',
            filter: DYNAMIC_TEXT,
            className: classes.versionColumn,
          },
          {
            id: 'status',
            Header: 'Status',
            accessor: 'status',
            filter: DYNAMIC_TEXT,
            className: classes.statusColumn,
          },
          {
            id: 'actions',
            Header: <QuestionnaireEditHeader />,
            Cell: ({ cell }: any) => (
              <QuestionnaireEditCell cell={cell} onDuplicate={duplicateQuestionSet} onDelete={deleteQuestionSet} />
            ),
            disableSortBy: true,
            disableFilters: true,
            className: classes.actionsColumn,
          },
        ],
        {
          showAdminItems,
        },
      ),
    [
      classes.nameColumn,
      classes.createdAt,
      classes.fullNameColumn,
      classes.versionColumn,
      classes.statusColumn,
      classes.actionsColumn,
      showAdminItems,
      duplicateQuestionSet,
      deleteQuestionSet,
    ],
  )
}

export default useQuestionnairesColumns
