
import { usePrompt } from 'civic-champs-shared/core/modal/hooks';
import CreateOrUpdateCredentialPrompt from '../components/CreateOrUpdateCredentialPrompt';
import { useCallback } from 'react';


const useCreateOrUpdateCredentialPrompt = (onCreateOrUpdate?: () => void, disableDraft?: boolean) => {
  const prompt = usePrompt(CreateOrUpdateCredentialPrompt) as any

  return useCallback((credentialId?: number) => {
    if (prompt.showing) return

    //TODO this is still awkward and we're still repeating it...
    if (onCreateOrUpdate) {
      const unlistens = [
        prompt.onComplete(onCreateOrUpdate),
        prompt.onHide( () => { unlistens.forEach( unlisten => unlisten() ) })
      ]
    }

    prompt.show({ disableDraft, credentialId });
  }, [prompt, onCreateOrUpdate, disableDraft])
}

export default useCreateOrUpdateCredentialPrompt
