import  _ from 'lodash'
import React, { useMemo } from 'react'
import { useDispatch } from 'react-redux';

import { useCurrentOrg } from 'auth/hooks';
import useOrganization from '../../../volunteering/organizations/hooks/useOrganization';
import useCreateOrUpdateOrganization from '../../../volunteering/organizations/hooks/useCreateOrUpdateOrganization'
import OrganizationForm from '../../../volunteering/organizations/components/OrganizationForm'
import Loading from 'components/Loading'
import { currentOrgUpdated } from 'auth/actions';
import { useIsTester } from '../../../auth/hooks'

export default function ProductFeatureSettings() {
    const organization = useCurrentOrg()
    const { result, loading, called } = useOrganization(organization.id)
    const { isTester, loading: testerLoading } = useIsTester()

    const organizationInfo = useMemo(() => {
      if (_.isEmpty(result)) return undefined
      const c = _.clone(result)
      // we save coords as [X,Y] in the db
      _.set(c, ['address', 'gps'], {
        lat: _.get(result, ['location', 'coordinates', 1]),
        lng: _.get(result, ['location', 'coordinates', 0]),
      })
      return c
    }, [result])

    const createOrUpdateOrganization = useCreateOrUpdateOrganization()

    const dispatch = useDispatch()

    const onSubmit = async (values) => {
      const updatedOrg = await createOrUpdateOrganization(values, organization.id)
      dispatch( currentOrgUpdated(updatedOrg) )
      alert("Changes were saved successfully!")
    }

      if (!!organization.id && (loading || !called || testerLoading)) {
        return <Loading />
      }

    return (<div>
        <OrganizationForm
          justify={"flex-start"}
          onSubmit={onSubmit}
          organization={organizationInfo}
          isTester={isTester}
        />
    </div>)
    
}
