import React from 'react'
import MultiStepBar from '../../components/multi-step-bar/index'
import BaseInfoModule from '../../modules/events/create-edit/basic-info/index'
import ParticipantsModule from '../../modules/events/create-edit/participants'
import LocationModule from '../../modules/events/create-edit/location/index'
import ShiftModule from '../../modules/events/create-edit/shifts/index'
import CustomQuestionModule from '../../modules/events/create-edit/custom-questions/index'
import ReviewModule from '../../modules/events/create-edit/review/index'
import SummaryInfo from '../../modules/events/create-edit/summary-info/index'
import classNames from 'classnames'
import API_ENDPOINTS from '../../constants/API_ENDPOINTS'
import BaseService from '../../services/base.service'
import { withCurrentOrganizationHOC } from '../../helpers/withCurrentOrganizationHOC'
import CongratulationsPopup from '../../modules/events/create-edit/congratulations-popup'
import { EVENT_FORM_STEPS, FORM_STEPS } from '../../constants/EVENT_FORM_STEPS'
import { encodeOccurrenceFromEvent } from '../../helpers/encodeOccurrence'

import './index.scss'
import { mapEventPayload } from '../events/helpers/mapEventPayload'

const base = new BaseService()

class CreateEventScene extends React.Component<any> {
  state = {
    isSendingDraftRequest: false,
    isSendingPublicRequest: false,
    isShowPopup: false,
    showInitial: false,
    encodedOccurrence: null,
    clearOnExit: true,
  }

  currentOrgId: number = this.props.currentOrganization.id

  componentDidMount() {
    if (this.checkPath()) {
      const { currentStep } = this.props.createEventForm
      const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep)[0].path
      this.props.history.push(`/event/new-event/${currentPath}`)
      this.props.changeStep(currentStep)
    }
  }

  componentWillUnmount() {
    if (this.state.clearOnExit) {
      this.props.changeStep(1)
      this.props.clearEventForm()
    }
  }

  render() {
    const { currentStep, event } = this.props.createEventForm
    const { showInitial } = this.state
    const renderSteps: { [key in FORM_STEPS]: React.ReactNode } = {
      [FORM_STEPS.BASIC]: (
        <BaseInfoModule
          onNextClick={this.nextStep}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          {...this.props}
        />
      ),
      [FORM_STEPS.PARTICIPANTS]: (
        <ParticipantsModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          {...this.props}
        />
      ),
      [FORM_STEPS.LOCATION]: (
        <LocationModule
          onPrevClick={this.prevStep}
          onUpdateShowInitial={this.updateShowInitial}
          showInitial={showInitial}
          onNextClick={this.nextStep}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          {...this.props}
        />
      ),
      [FORM_STEPS.SHIFTS]: (
        <ShiftModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          {...this.props}
        />
      ),
      [FORM_STEPS.QUESTIONS]: (
        <CustomQuestionModule
          onPrevClick={this.prevStep}
          onNextClick={this.nextStep}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          event={event}
          onBeforeAddEdit={() => this.setState({ clearOnExit: false })}
          {...this.props}
        />
      ),
      [FORM_STEPS.REVIEW]: (
        <ReviewModule
          onPrevClick={this.prevStep}
          onSaveClick={this.handleSave}
          onCancel={this.handleCancelEvent}
          onSaveDraftClick={this.handleSaveDraft}
          isSendingDraftRequest={this.state.isSendingDraftRequest}
          isSendingPublicRequest={this.state.isSendingPublicRequest}
          event={event}
          {...this.props}
        />
      ),
    }

    const contentMainClasses = classNames('create-event-module-content__main', {
      'full-width': currentStep === FORM_STEPS.QUESTIONS || currentStep === FORM_STEPS.REVIEW,
    })

    return (
      <div className="main-container">
        <div className="create-event-module">
          <div className="button-back-wrapper">
            <div className="buton-back-container">
              <div className="detail_event_block_back_arrow" onClick={() => this.props.history.push('/events')}>
                <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
                Events
              </div>
            </div>
          </div>
          <span className="new-event-text">Create New Event</span>
          <div className="create-event-module-steps">
            <MultiStepBar
              currentStep={currentStep}
              steps={EVENT_FORM_STEPS}
              changeStep={this.props.changeStep}
              editMode={false}
            />
          </div>
          <div className="create-event-module-content">
            <div className={contentMainClasses}>{renderSteps[currentStep as FORM_STEPS]}</div>
          </div>
          {this.state.isShowPopup && (
            <CongratulationsPopup
              isRecurring={event.is_recurring}
              editMode={false}
              handleClose={this.handleClosePopup}
            />
          )}
        </div>
        {currentStep !== FORM_STEPS.REVIEW && (
          <div className="sidebar">
            <SummaryInfo currentStep={currentStep} event={event} />
          </div>
        )}
      </div>
    )
  }

  handleClosePopup = () => {
    this.setState({ isShowPopup: false })
    this.goToEventScreen()
  }

  updateShowInitial = () => {
    this.setState({ showInitial: true })
  }

  checkPath = () => {
    const locationPath = this.props.history.location.pathname.split('/')
    const locationPathLength = locationPath.length
    const currentPath = locationPath[locationPathLength - 1]
    return EVENT_FORM_STEPS.some(item => item.path === currentPath)
  }

  getEventListing = () => this.props.history.push(`/events`)

  goToEventScreen = () => this.props.history.push(`/events/${this.state.encodedOccurrence}/${this.currentOrgId}`)

  nextStep = () => {
    const { currentStep } = this.props.createEventForm
    if (currentStep === EVENT_FORM_STEPS.length) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep + 1)[0].path
    this.props.history.push(`/event/new-event/${currentPath}`)
    this.props.changeStep(currentStep + 1)
  }

  prevStep = () => {
    const { currentStep } = this.props.createEventForm
    if (currentStep === 1) return
    const currentPath = EVENT_FORM_STEPS.filter(item => item.step === currentStep - 1)[0].path
    this.props.history.push(`/event/new-event/${currentPath}`)
    this.props.changeStep(currentStep - 1)
  }

  handleCancelEvent = () => {
    this.getEventListing()
  }

  handleSaveDraft = async () => {
    this.setState({ isSendingDraftRequest: true })
    try {
      const { event } = this.props.createEventForm
      const endpoint = API_ENDPOINTS.Events.occurrenceRefactorSaveDraftEvent(this.currentOrgId)
      const response = await base.postJSON(endpoint, mapEventPayload(event))
      if (!response.error) {
        this.setState({ isSendingDraftRequest: false })
        this.setState({ encodedOccurrence: encodeOccurrenceFromEvent(response) })
        this.goToEventScreen()
      }
      this.setState({ isSendingDraftRequest: false })
    } catch (e) {
      console.error(e)
    }
  }

  handleSave = async () => {
    this.setState({ isSendingPublicRequest: true })
    try {
      const { event } = this.props.createEventForm
      const endpoint = API_ENDPOINTS.Events.occurrenceRefactorPublishEvent(this.currentOrgId)
      const response = await base.postJSON(endpoint, mapEventPayload(event))
      if (!response.error) {
        this.setState({ isSendingPublicRequest: false })
        this.setState({ isShowPopup: true })
        this.setState({ encodedOccurrence: encodeOccurrenceFromEvent(response) })
      }
      this.setState({ isSendingPublicRequest: false })
    } catch (e) {
      console.error(e)
    }
  }
}

export default withCurrentOrganizationHOC(CreateEventScene)
