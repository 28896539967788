import * as React from 'react'
import moment from 'moment'
import { RECURRING_TYPE, RECURRING_TYPE_OPTIONS } from '../../../../../constants/RECURRING_TYPE_OPTIONS'

interface SummaryBasicInfoProps {
  name: string
  startsAt: string
  endsAt: string
  selectedRecurringType: number
  editMode: boolean
  isTest: boolean
}

const invalidDateText = 'not specified'

function formatDate(date: any) {
  return moment(date)
    .local()
    .format('MMMM D, YYYY')
}

const SummaryBasicInfo = (props: SummaryBasicInfoProps) => {
  const { name, startsAt, endsAt, selectedRecurringType, editMode, isTest } = props

  const startDate = startsAt ? formatDate(startsAt) : invalidDateText
  const endDate = endsAt ? formatDate(endsAt) : invalidDateText

  const recurringOptions = RECURRING_TYPE_OPTIONS(startsAt)

  const renderRecurringInfo = () => {
    if (selectedRecurringType === RECURRING_TYPE.NEVER) {
      return 'No Repeats'
    } else {
      const selectedOption = recurringOptions.filter(item => item.value === selectedRecurringType)[0]
      return `Type Repeat: ${selectedOption.label}`
    }
  }

  const renderRangeDateInfo = () => {
    if (selectedRecurringType === RECURRING_TYPE.NEVER) {
      return `${startDate}`
    } else {
      return `${startDate} - ${endDate}`
    }
  }

  return (
    <React.Fragment>
      <span className="summary-info-sidebar__name">{name}</span>
      <div className="summary-info-sidebar__date">
        <span className="date-info">{editMode ? startDate : renderRangeDateInfo()}</span>
        <span className="repeats-info">{renderRecurringInfo()}</span>
      </div>
      {
        isTest && (
          <div className="summary-info-sidebar__date">
            <span className="date-info">Test Event</span>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default SummaryBasicInfo
