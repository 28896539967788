import { useCallback } from 'react'
import { useCurrentOrg } from 'auth/hooks'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { TripRequest } from '../types'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'

const useCreateTripRequest = (): [(body: Partial<TripRequest>) => Promise<TripRequest>, Status<TripRequest>] => {
  const organization = useCurrentOrg()
  const [request, status] = useFetchRefactored<TripRequest>({
    successMessage: 'Trip successfully created',
    errorMessage: 'Error creating trip',
  })
  const createTrip = useCallback(
    (formData: any) => {
      return request({
        method: 'post',
        url: `/organizations/${organization.id}/trip-requests`,
        config: {
          body: formData,
        },
      })
    },
    [request, organization.id],
  )

  return [createTrip, status]
}

export default useCreateTripRequest
