import { useCallback } from "react"
import { useFetch } from 'civic-champs-shared/api/hooks'

const useUpdateNonSchedulableOpportunity = (encodedOccurrence) => {
  const [request, status] = useFetch()
  const postOpportunity = useCallback((body) => {
    return request({
      method: 'put',
      url: `/opportunity_instances/${encodedOccurrence}/non_schedulable`,
      config: { body }
    })
  }, [request, encodedOccurrence])

  return [postOpportunity, status]
}

export default useUpdateNonSchedulableOpportunity
