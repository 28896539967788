import usePost from 'civic-champs-shared/api/hooks/usePost'

export default function useAddPersonToGroup(groupId: number) {
  return usePost<any, any>({
    successMessage: 'Successfully added contact',
    errorMessage: 'Error creating contact',
    configMapper: body => ({
      url: `/groups/${groupId}/members`,
      config: { body },
    }),
  })
}
