import { useCallback } from 'react'
import { makeStyles } from "@material-ui/styles";
import { useFetch } from 'civic-champs-shared/api/hooks'

export const useStyles = makeStyles((theme) => ({
  field: {
    margin: '8px',
  },
  removeButton: {
    color: theme.palette.danger.main,
    margin: '16px 0'
  },
  role: {
    borderBottom: '1px solid #aaaaaa',
    paddingBottom: '8px',
    marginBottom: '8px'
  }
}))

export const useGetRoles = (orgId) => {
  const [fetch, status] = useFetch();
  const getRoles = useCallback(() => {
    return fetch({
      method: 'get',
      url: `/organizations/${orgId}/roles?defaultOnly=true`,
    })
  }, [fetch, orgId])

  return [getRoles, status]
}

export const useCreateDefaultRole = (orgId) => {
  const [fetch, status] = useFetch()
  const createRole = useCallback((payload) => {
    return fetch({
      method: 'post',
      url: `/organizations/${orgId}/default_roles`,
      config: { body: payload }
    })
  }, [fetch, orgId])

  return [createRole, status]
}

export const useUpdateRoleAndMakeDefault = (orgId) => {
  const [fetch, status] = useFetch('Error updating role. Please try again.')
  const updateRole = useCallback((payload) => {
    return fetch({
      method: 'put',
      url: `/organizations/${orgId}/default_roles`,
      config: { body: payload }
    }, [payload])
  }, [fetch, orgId])

  return [updateRole, status]
}

export const useDeleteRoleAssignment = (orgId) => {
  const [fetch, status] = useFetch('There was a problem removing the role. Please try again')
  const deleteRoleAssignment = useCallback((roleId) => {
    return fetch({
      method: 'del',
      url: `/organizations/${orgId}/default_roles/${roleId}`
    })
  }, [fetch, orgId])

  return [deleteRoleAssignment, status]
}
