import * as React from 'react'
import { makeStyles, Button} from '@material-ui/core'
import './sumarry.scss'
import * as _ from 'lodash'

const useStyles = makeStyles(theme => ({ 
  previewTitle: {
    fontSize: '24px',
    color: '#3D3D3D',
  },
  appearance: {
    fontSize: '15px',
    color: '#3D3D3D',
    marginTop: '9px',
    marginBottom: '14px',
  },
  phoneContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '303px',
    height: '620px',
    border: '4px solid #000000',
    borderRadius: '28px',
    paddingTop: '22px',
    paddingLeft: '28px',
    paddingRight: '29px',
    paddingBottom: '22px',
    justifyContent: 'space-between',
    wordWrap: 'break-word',
  },
  phoneTitle: {
    fontSize: '28px',
    color: '#3D3D3D',
    marginTop: '22px',
    marginBottom: '9px',
  },
  thankYouText: {
    fontSize: '1.8vh',
    //fontSize: '15px',
    color: '#3D3D3D',
    maxWidth: '290px',
    maxHeight: '170px',
    overflow: 'hidden',
  },
  image: {
    width: '242px',
    height: 'auto',
    marginTop: '9px',
    marginBottom: '16px',
  },
  button: {
    width: '240px',
    height: '44px',
    backgroundColor: '#3D67B5',
    borderRadius: '4px',
    marginTop: '16px',
    marginBottom: '22px',
  },
  buttonTwo: {
    marginTop: 0,
  },
  buttonText: {
    color: 'white',
    fontSize: '12px',
  },
  text: {
    marginTop: '20px',
  },
  subTitle: {
    marginTop: '32px',
  }
}));

const ThankYouPrompt = (props: any) => {
  const { data } = props
  const classes = useStyles()

  return (
    <div className={classes.phoneContainer}>
    <div className={classes.phoneTitle}><b>{data!=null && data.title!=null ? data.title: "Thank You"}</b></div> 
       <div className={classes.thankYouText}
       dangerouslySetInnerHTML={{
        __html: data!=null && data.firstParagraph!=null? data.firstParagraph : 
        "Challenges and opportunities change-makers but sustainable families society.Strategize, resist, or preliminary thinking natural resources youth justice state of play impact."
      }}
       >
        </div>  
      <img className={classes.image} src={_.get(data, 'image.url', '/assets/icons/blue-logo.svg')}  alt="User_picture" />
      <div className={classes.thankYouText} 
       dangerouslySetInnerHTML={{
        __html:data!=null && data.secondParagraph!=null? data.secondParagraph : 
        "The inspirational, empower communities commitment venture philanthropy commitment the resistance."
      }}
      >
      </div> 
      <Button
        className={classes.button}
        variant="contained"
        >
         <div className={classes.buttonText}><b>
         {data!=null && data.buttonText!=null ? data.buttonText : "Tell Me More"}
           </b></div>
        </Button>
    </div> 
  )
}

export default ThankYouPrompt
