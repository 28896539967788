import { useCallback, useEffect, useState } from 'react'
import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'
import { useDuplicateQuestionSetPrompt } from './useDuplicateQuestionSetPrompt'
import { useRemoveQuestionSetPrompt } from './useRemoveQuestionSetPrompt'

export const useQuestionnairesCollection = () => {
  const [request, { loading, error }] = useFetch()
  const [questionnaires, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const { syncCollection } = operations
  const getQuestionnaires = useCallback(async () => {
    const updatedQuestionnaires = await request({
      url: `/organizations/${organization.id}/${
        isQuestionnaireAsSurveyEnabled ? 'survey-questionnaires' : 'question-sets'
      }`,
    })

    syncCollection(updatedQuestionnaires)
    setInitiallyLoaded(true)
  }, [request, organization.id, isQuestionnaireAsSurveyEnabled, syncCollection])

  useEffect(() => {
    getQuestionnaires()
  }, [getQuestionnaires])

  const duplicateQuestionSet = useDuplicateQuestionSetPrompt(operations.eagerAdd)
  const deleteQuestionSet = useRemoveQuestionSetPrompt(operations.eagerRemove)

  return [
    {
      questionnaires,
      loading,
      error,
      initiallyLoaded,
    },
    {
      duplicateQuestionSet,
      deleteQuestionSet,
    },
  ]
}

export default useQuestionnairesCollection
