import { useCallback, useEffect, useState } from 'react'
import * as moment from 'moment'
import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'

const useRegistrants = currentDayDiff => {
  const [request, { loading, error }] = useFetch()
  const [registrants, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection } = operations
  const getRegistrants = useCallback(async () => {
    const updatedRegistrants = await request({
      url: `/organizations/${organization.id}/registrants`,
      config: {
        queryStringParameters: {
          date: moment().clone().add(currentDayDiff, 'days').toISOString(),
        },
      },
    })

    syncCollection(updatedRegistrants)
    setInitiallyLoaded(true)
  }, [request, organization.id, currentDayDiff, syncCollection])

  useEffect(() => {
    getRegistrants()
  }, [getRegistrants])

  return [
    {
      registrants,
      loading,
      error,
      initiallyLoaded,
    },
  ]
}

export default useRegistrants
