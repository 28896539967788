import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useCreateApiNotification } from 'civic-champs-shared/api/hooks'

import { AddEditRoleModal } from '../components/AddEditRoleModal'
import { useSaveRole } from './index'

import { RolePayload } from '../types'

export const useAddEditRolePrompt = ({
  eagerAdd,
  eagerReplace,
}: {
  eagerReplace: (role: RolePayload) => {}
  eagerAdd: (role: RolePayload) => {}
}) => {
  const createNotification = useCreateApiNotification()
  const showPrompt = useShowPrompt(AddEditRoleModal)
  const { saveRole } = useSaveRole()
  return useCallback(
    async (role?: RolePayload) => {
      const formData = await showPrompt({ role })
      const notification = createNotification('Saving role')
      try {
        const savedRole = await saveRole(formData, { reporting: true })
        if (formData.id) {
          eagerReplace(savedRole)
        } else {
          eagerAdd(savedRole)
        }

        notification.onSuccess('Role was saved')
      } catch (error) {
        notification.onError('Failed to save role', error)
      }
    },
    [createNotification, eagerAdd, eagerReplace, saveRole, showPrompt],
  )
}

export default useAddEditRolePrompt
