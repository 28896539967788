import React, { useState } from 'react'
import { size } from 'lodash'
import cn from 'classnames'
import { Dialog, DialogContent, makeStyles } from '@material-ui/core'

import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import Loading from 'civic-champs-shared/core/Loading'
import useMessageRecipients from './useMessageRecipients'
import useMessagesPreview from './useMessagePreview'
import { EmailAndSmSPersonIds, BadContact, SubjectAndBody } from './schema'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { parseEmailAndSmSPersonIds } from './utils'
import { DialogTitle } from '../DialogTitle';

const useStyles = makeStyles(() => ({
  contentContainer: {
    paddingLeft: '5em !important',
    paddingRight: '5em !important',
    paddingBottom: '2em !important',
    paddingTop: '2em !important',
  },
  dialog: {
    height: '80vh',
  },
}))

export default function MessageRecipientsPrompt(props: any) {
  const { showing, close, recipientPersonIds } = props
  const [step, setStep] = useState<number>(1)
  const [contactBoth, setContactBoth] = useState<boolean>(false)
  const [badContacts, setBadContacts] = useState<BadContact[]>([])
  const [emailAndSmsValues, setEmailAndSmsValues] = useState<EmailAndSmSPersonIds>({
    emailPersonIds: [],
    smsPersonIds: [],
  })
  const { result: preview, loading: previewLoading } = useMessagesPreview(recipientPersonIds)
  const [messageRecipients, { loading: sending }] = useMessageRecipients()
  const classes = usePromptStyles()
  const ownClasses = useStyles()

  const onSubmitStepOne = (contactBoth: boolean) => {
    setContactBoth(contactBoth)
    // computedBadContacts === the badContacts that were selected based on step 1
    const { smsPersonIds, badContacts: computedBadContacts, emailPersonIds } = parseEmailAndSmSPersonIds({
      contactBoth,
      preview,
    })
    setBadContacts(computedBadContacts)
    if (size(computedBadContacts)) {
      setStep(2)
    } else {
      setEmailAndSmsValues({ smsPersonIds, emailPersonIds })
      setStep(3)
    }
  }

  const onSubmitStepTwo = (badContactsToExclude: BadContact[]) => {
    // badContactsToExclude === the badContacts that were selected based on step 1,
    // but that were NOT selected in step 2 (so we won't send to them)
    const { smsPersonIds, emailPersonIds } = parseEmailAndSmSPersonIds({
      contactBoth,
      preview: { ...preview, badContacts: badContactsToExclude },
    })
    setEmailAndSmsValues({ smsPersonIds, emailPersonIds })
    setStep(3)
  }

  const onSubmitStepThree = async (values: SubjectAndBody) => {
    const { subject, body } = values
    const { emailPersonIds, smsPersonIds } = emailAndSmsValues
    await messageRecipients({ subject, body, emailPersonIds, smsPersonIds })
    close()
  }

  const loading = sending || previewLoading

  if (loading) {
    return <Loading />
  }

  return (
    <Dialog open={showing} onClose={close} fullWidth maxWidth='md'>
      <DialogTitle onClose={close} />
      <DialogContent className={cn(classes.contentContainer, ownClasses.contentContainer)}>
        <div className={ownClasses.dialog}>
          {step === 1 && (
            <StepOne
              onSubmit={onSubmitStepOne}
              preview={preview}
              cancel={close}
              recipientPersonIds={recipientPersonIds}
              smsCredits={10}
            />
          )}
          {step === 2 && (
            <StepTwo badContacts={badContacts} onSubmit={onSubmitStepTwo} cancel={close} back={() => setStep(1)} />
          )}
          {step === 3 && (
            <StepThree
              recipientPersonIds={recipientPersonIds}
              onSubmit={onSubmitStepThree}
              cancel={close}
              emailPersonIds={emailAndSmsValues.emailPersonIds}
              smsPersonIds={emailAndSmsValues.smsPersonIds}
              back={() => setStep(size(badContacts) ? 2 : 1)}
            />
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}
