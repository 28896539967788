import React from 'react'
import { Grid, Box, Card, Typography, makeStyles } from '@material-ui/core'

import { ExternalCustomerDatafeed } from 'external-datafeed/types'
import format from 'utils/format'
import { ExportDatafeedButton } from './ExportDatafeedButton'
import { ImportDatafeedButton } from './ImportDatafeedButton'

export interface ExternalDatafeedCardProps {
  datafeed: ExternalCustomerDatafeed
}

const useStyles = makeStyles({
  card: {
    width: 'fit-content',
    padding: '30px'
  },
  datafeedAction: {
    margin: '25px',
    '& button': {
      marginTop: '10px'
    }
  }
})

const Timestamp = ({ label, value }: { label: any, value?: Date })  => (
  <Box>
    {label} 
    <Typography>{!value ? 'Never' : format.datetime(value)}</Typography>
  </Box>
)

export const ExternalDatafeedCard = (props: ExternalDatafeedCardProps) => {
  const { lastExportedAt, lastImportedAt } = props.datafeed || { } as ExternalCustomerDatafeed
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <Grid container direction="row">
        <Grid item className={classes.datafeedAction}>
          <Timestamp label="Last Exported at:" value={lastExportedAt} />
          <ExportDatafeedButton datafeed={props.datafeed} />
        </Grid>
        <Grid item className={classes.datafeedAction}>
          <Timestamp label="Last Imported at:" value={lastImportedAt} />
          <ImportDatafeedButton datafeed={props.datafeed} />
        </Grid>
      </Grid>
    </Card>
  )
}