import React from 'react'
import Select from 'react-select'
import classNames from 'classnames'
import { CustomBaseSelectStyles } from './custom-styles'

import './index.scss'

interface Props {
  onChange: (option: any) => void
  options: any[]
  value: any
  disabled?: boolean
  placeholder?: string
  hasError?: boolean
  className?: string
  errorMessage?: string
  isOptionDisabled?: any
  onBlur?: () => void
}

const SelectComponent = (props: Props) => {
  const {
    disabled,
    onChange,
    options,
    value,
    placeholder,
    hasError,
    className,
    isOptionDisabled,
    errorMessage = 'Required field',
    onBlur
  } = props

  const classes = classNames('custom-select-component__select', {
    'custom-select-component__select--error': hasError,
    'custom-select-component__select--disabled': disabled,
    [`${className}`]: className,
  })

  return (
    <div className="custom-select-component">
      <Select
        isOptionDisabled={isOptionDisabled}
        hasError={hasError}
        isDisabled={disabled}
        className={classes}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        styles={CustomBaseSelectStyles}
        options={options}
        onBlur={onBlur}
      />
      {hasError && <div className="custom-select-component__error-msg">{errorMessage}</div>}
    </div>
  )
}

export default SelectComponent