import * as yup from 'yup'
import { OrgNamedRef } from 'civic-champs-shared/common/types'

export interface RolePayload {
  id?: number
  name: string
  isActive: boolean
  isDefault: boolean
}

export interface Role extends RolePayload {
  id: number
  description?: string
  organization: OrgNamedRef
  order: number
}

export interface RoleWithReporting extends Role {
  totalActivities: number
}

export const rolePayloadSchema = yup.object({
  name: yup.string().required(),
  isActive: yup.boolean().default(true),
  isDefault: yup.boolean().default(false),
})
