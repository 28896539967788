import React, { FC, useState } from 'react'
import NewQuestionSetEditor from 'question-sets/components/NewQuestionSetEditor'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmSurveyChangesDialog from 'surveys/components/ConfirmSurveyChangesDialog'
import { Redirect } from 'react-router-dom'
import PageHeader from 'App/components/PageHeader'
import { QuestionSetResponse } from 'civic-champs-shared/question-sets/types'
import isUndefined from 'lodash/isUndefined'

export interface QuestionSetEditorComponentProps {
  questionSet?: QuestionSetResponse
  isSubmitting: boolean
  onSubmit: (...props: any) => Promise<any>
  backLink: string
  backText: string
  title: string
  redirectLink?: string
}

const QuestionSetEditorComponent: FC<Omit<QuestionSetEditorComponentProps, 'title'>> = ({
  questionSet,
  isSubmitting,
  onSubmit,
  backLink,
  backText,
  redirectLink,
}) => {
  const showConfirmationDialog = useShowPrompt(ConfirmSurveyChangesDialog)
  const [redirect, setRedirect] = useState<boolean>(false)
  const isNew = isUndefined(questionSet)
  const handleSubmit = async (payload: any): Promise<void> => {
    if (!isNew && payload.questionsEdited) {
      const success = await showConfirmationDialog()
      if (!success) return
    }
    await onSubmit(payload)
    setRedirect(true)
  }

  if (redirect) {
    return <Redirect to={`${redirectLink || backLink}`} />
  }

  return (
    <NewQuestionSetEditor
      questionSet={questionSet}
      isNew={isNew}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      backLink={backLink}
      backText={backText}
    />
  )
}

export const QuestionSetEditor: FC<QuestionSetEditorComponentProps> = ({ title, ...props }) => {
  return (
    <PageHeader title={title}>
      <QuestionSetEditorComponent {...props} />
    </PageHeader>
  )
}

export default QuestionSetEditor
