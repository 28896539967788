import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { ConfirmDialog } from 'civic-champs-shared/core/confirm-dialog/'
import { useRemoveQuestionSet } from './useRemoveQuestionSet'

export const useRemoveQuestionSetPrompt = (eagerRemove: any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeQuestionSet = useRemoveQuestionSet(eagerRemove)

  return useCallback(
    async questionSet => {
      const text = `Are you sure you want to delete "${questionSet.name}"? The action can not be undone.`
      const confirmed = await showPrompt({
        text,
        title: 'Confirm Action',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        await removeQuestionSet(questionSet)
      }
    },
    [showPrompt, removeQuestionSet],
  )
}

export default useRemoveQuestionSetPrompt
