import React, { useCallback, useState } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import format from 'utils/format'
import { NotificationBlastContact } from 'messages/hooks/useMessageOverview'
import useExportStyles from 'export/useExportStyles'

const filename = 'failed-recipients.csv'
const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Phone', key: 'phoneNumber' },
]

const formatData = (recipients: NotificationBlastContact[]) => {
  return recipients.map(row => ({
    ...row,
    phoneNumber: format.phoneNumber(row.phoneNumber),
  }))
}

const ExportFailedRecipientsButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  const classes = useExportStyles()
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin>
      <CSVLink
        style={
          disabled
            ? { textDecoration: 'none', color: 'rgba(0, 0, 0, 0.26)' }
            : { textDecoration: 'none', color: '#0F5DB5' }
        }
        filename={fileNameWithDate as string}
        headers={headers}
        onClick={createFormattedData}
        data={formattedData}
        className={classes.fill}
      />
      Export List
    </TableMenuButton>
  )
}

export default ExportFailedRecipientsButton
