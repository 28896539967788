import React from 'react'
import cn from 'classnames'
import { Box, makeStyles } from '@material-ui/core'
import DOMPurify from "dompurify";

const useStyles = makeStyles(theme => ({
  messagePreview: {
    padding: 10,
    border: '1px solid rgba(0, 0, 0, 0.23)',
    height: 225,
    overflowY: 'scroll',
    marginTop: '10px',
    msOverflowStyle: 'none' /* IE and Edge */,
    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  messagePreviewContent: {
    display: 'flex',
    flexDirection: 'column',
    width: "85%",
    marginLeft: "auto",
  },
  messageSkeleton: {
    backgroundColor: '#5C8DE871',
    borderRadius: 5,
  },
  warning: {
    color: theme.palette.danger.main,
  },
  sms: {
    fontSize: 12,
    backgroundColor: '#6E99EB',
    color: 'white',
    borderRadius: 5,
    padding: 10,
    wordWrap: 'break-word',

    '& a': {
      color: 'inherit',
    },
  },
  email: {
    width: '100%',
    wordWrap: 'break-word',
  },
  failed: {
    color: theme.palette.danger.main,
    textDecoration: 'underline',
  },
}))

export function EmailPreview(props) {
  const { subject, body = '', emailCount, failedEmailEvents = [] } = props
  const classes = useStyles()
  const bodyDiv = (typeof body === 'object')
    ? (<div>{body}</div>)
    : (
      <div dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(body)
      }}/>
    )

  return (
    <div>
      <b>Email to {emailCount} contacts</b>

      <Box className={classes.messagePreview} display="flex" flexDirection="column">
        <div className={classes.email}>
          <div>
            <Box
              width="50%"
              height={30}
              mb={2}
              className={classes.messageSkeleton}
            />
            <Box className="truncated" maxWidth="100%">
              <b>Subject: </b>
              <span>{subject || ''}</span>
            </Box>
          </div>
          <br />
          <div>
            {bodyDiv}
          </div>
        </div>
          <Box
            height={16}
            mt="auto"
            className={cn(classes.messageSkeleton, 'fullwidth')}
          />
      </Box>

      {!!failedEmailEvents.length && (
        <Box mt={3}>
          <div className={classes.warning}>{failedEmailEvents.length} of the emails sent failed to send</div>
          {failedEmailEvents.map(failedEmailEvent => (
            <Box key={failedEmailEvent.id} className={classes.failed} mt={1} ml={3}>
              {failedEmailEvent.sentTo.givenName} {failedEmailEvent.sentTo.familyName} - {failedEmailEvent.message.to}{' '}
              ({failedEmailEvent.eventType})
            </Box>
          ))}
        </Box>
      )}
    </div>
  )
}

export function SMSPreview(props) {
  const { subject, smsCount, failedSMSEvents = [] } = props
  const classes = useStyles()

  return (
    <div>
      <b>Text Message to {smsCount} contacts</b>

      <div className={classes.messagePreview}>
        <div className={classes.messagePreviewContent}>
          <Box
            height={30}
            mb={1}
            className={classes.messageSkeleton}
          />
          <Box
            height={30}
            mb={1}
            className={classes.messageSkeleton}
          />
          <Box
            height={30}
            mb={1}
            className={classes.messageSkeleton}
          />
          <div className={classes.sms} dangerouslySetInnerHTML={{ __html: subject }} />
        </div>
      </div>

      {!!failedSMSEvents.length && (
        <Box mt={3}>
          <div className={classes.warning}>{failedSMSEvents.length} of the Text Messages failed to send</div>
          {failedSMSEvents.map(failedSMSEvents => (
            <div key={failedSMSEvents.id} className={classes.failed}>
              {failedSMSEvents.sentTo.givenName} {failedSMSEvents.sentTo.familyName} - {failedSMSEvents.message.to} (
              {failedSMSEvents.providerResponse})
            </div>
          ))}
        </Box>
      )}
    </div>
  )
}
