import { first, uniqueId } from 'lodash'
import React, { useRef, useState } from 'react'
import { useRouteMatch } from 'react-router'
import classNames from 'classnames'
import Icon from '@mdi/react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles, Popover } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import NavMenuPopoverItem from 'App/components/NavMenuPopoverItem'
import isUndefined from 'lodash/isUndefined'

const useStyles = makeStyles(theme => ({
  adminListItem: {},
  listItem: {
    padding: 15,
    color: 'white',
    backgroundColor: 'transparent',
    margin: '0',
    minHeight: 50,
    maxHeight: 50,
    paddingLeft: 16,
    '&:hover': {
      backgroundColor: '#3776CF',
    },
    '&$selected > div > span$listItemText, &$selected:hover > div > span$listItemText': {
      color: 'white',
    },
    '&$adminListItem': {
      backgroundColor: theme.palette.background.green,
      color: theme.palette.neutral.darkGray,

      '&.MuiListItem-root.Mui-selected, &.MuiListItem-root.Mui-selected:hover': {
        color: `${theme.palette.neutral.darkGray} !important`,

        '& path': {
          fill: 'currentColor !important',
        },
        '& span': {
          color: 'currentColor',
        },
      },

      '&$selected': {
        backgroundColor: `${theme.palette.accent.yellowGreen.light} !important`,
      },

      '& $icons': {
        color: 'currentColor',
      },
    },
  },
  listItemText: {
    fontFamily: 'Nunito, sans-serif',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  listItemTextInset: {
    paddingLeft: '45px',
  },
  icons: {
    color: 'white',
    minWidth: 30,
    '&>svg': {
      width: '20px',
      height: '20px',
    },
  },
  selected: {
    backgroundColor: '#3776CF !important',
  },
  a: {
    textDecoration: 'none',
  },
  popover: {
    pointerEvents: 'none',
  },
}))

export default function NavMenuItem(props) {
  const {
    label,
    route,
    iconPath,
    IconComponent,
    onChangeRoute,
    items = [],
    isAdminItem,
    showAdminItems,
    className,
    aClassName,
    collapsed,
  } = props

  const isExpandable = items?.length > 0

  const match = useRouteMatch(route)
  const isSelected = !isUndefined(route) && match && (collapsed || !isExpandable)
  const [open, setOpen] = useState(!isUndefined(route) && match && isExpandable)
  const [popoverOpen, setPopoverOpen] = useState(false)
  const anchorEl = useRef(null)
  const { route: childRoute } = first(items) || {}
  const handleClick = e => {
    e.preventDefault()
    if (isExpandable) {
      setOpen(true)
      collapsed && setPopoverOpen(true)
      if (childRoute) {
        !collapsed && onChangeRoute(childRoute)
        return
      }
    }

    onChangeRoute(route)
  }

  const handleExpandCollapse = e => {
    e.preventDefault()
    e.stopPropagation()
    setOpen(v => !v)
  }

  const classes = useStyles()

  return (
    <>
      <a href={childRoute || route || '#'} onClick={handleClick} className={classNames(classes.a, aClassName)}>
        <ListItem
          button
          className={classNames(classes.listItem, className, {
            [classes.adminListItem]: isAdminItem,
            hidden: isAdminItem && !showAdminItems,
          })}
          selected={isSelected}
          classes={{ selected: classes.selected }}
          ref={anchorEl}
        >
          {!!iconPath && (
            <ListItemIcon title={label} className={classes.icons}>
              <Icon path={iconPath} size={'20px'} color="currentColor" />
            </ListItemIcon>
          )}
          {!!IconComponent && (
            <ListItemIcon title={label} className={classes.icons}>
              <IconComponent viewBox="0 0 50 50" width="20px" height="20px" />
            </ListItemIcon>
          )}
          {!collapsed && (
            <>
              <ListItemText
                classes={{ primary: classes.listItemText, inset: classes.listItemTextInset }}
                primary={label}
                inset={!iconPath && !IconComponent}
              />
              {isExpandable && (
                <div onClick={handleExpandCollapse}>{open ? <ExpandLessIcon /> : <ExpandMoreIcon />}</div>
              )}
            </>
          )}
        </ListItem>
      </a>
      {isExpandable &&
        (collapsed ? (
          <Popover
            id={`expandable-${uniqueId()}`}
            open={popoverOpen}
            anchorEl={anchorEl.current}
            classes={{ root: classes.popover }}
            onClose={() => {
              setPopoverOpen(false)
              setOpen(false)
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: -4,
            }}
          >
            <NavMenuPopoverItem {...props} setPopoverOpen={setPopoverOpen} />
          </Popover>
        ) : (
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {items.map((item, index) => (
                <NavMenuItem
                  {...item}
                  className={classNames({ hidden: (isAdminItem || item.isAdminItem) && !showAdminItems })}
                  isAdminItem={isAdminItem || item.isAdminItem}
                  showAdminItems={showAdminItems}
                  key={index}
                  onChangeRoute={onChangeRoute}
                />
              ))}
            </List>
          </Collapse>
        ))}
    </>
  )
}
