import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ConfirmDialog, { useStyles } from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'
import React, { Dispatch, SetStateAction, useCallback, useState } from 'react'
import map from 'lodash/map'
import { Chat } from 'chats/interfaces'
import DialogContentText from '@material-ui/core/DialogContentText'
import { isAllResponsesSuccessful, useDeleteChats } from 'chats'
import { filter } from 'lodash'

export const useDeleteChatsPrompt = ({
  setChats,
}: {
  setChats: Dispatch<SetStateAction<Chat[]>>
}): ((chats: Chat[]) => Promise<void>) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const deleteChats = useDeleteChats()
  const classes = useStyles()
  const [existingChats, setExistingChats] = useState<Chat[]>([])

  return useCallback(
    async (chats: Chat[]) => {
      const text =
        chats.length === 1 ? (
          <>
            <strong>{chats[0].name}</strong> chat
          </>
        ) : (
          <>
            <strong>{chats.length}</strong> selected chats
          </>
        )
      const children = (
        <DialogContentText classes={{ root: classes.text }}>
          Are you sure you want to delete {text} for all members? This action cannot be undone.
        </DialogContentText>
      )
      const confirmed = await showPrompt({
        children,
        title: chats.length === 1 ? 'Delete Chat?' : 'Delete Chats?',
        confirmText: 'Delete',
        cancelText: 'Cancel',
        type: 'warning',
      })

      if (confirmed) {
        const ids = map(chats, 'id')
        setChats(values => {
          setExistingChats([...values])
          return filter(values, ({ id }) => !ids.includes(id))
        })
        const result = await deleteChats(ids)
        if (!isAllResponsesSuccessful(result)) {
          setChats(filter(existingChats, ({ id }) => !result[id]))
        }
      }
    },
    [classes.text, deleteChats, existingChats, setChats, showPrompt],
  )
}

export default useDeleteChatsPrompt
