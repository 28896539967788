import { useCallback, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { useFeatureEnabled } from 'core/feature/hooks'

export const useFetchGlobalUserProfileQuestionSets = onResultUpdate => {
  const [request, status] = useFetch(
    'Error Fetching User Profile Questionnaires.  Please refresh the page and try again',
  )

  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')

  const fetchGlobalUserProfileQuestionSets = useCallback(async () => {
    return request({
      onSuccess: result => {
        onResultUpdate && onResultUpdate(result)
      },
      method: 'get',
      url: isQuestionnaireAsSurveyEnabled ? '/user-profile-surveys' : '/user-profile-question-sets',
    })
  }, [isQuestionnaireAsSurveyEnabled, onResultUpdate, request])

  return useMemo(() => [fetchGlobalUserProfileQuestionSets, status], [fetchGlobalUserProfileQuestionSets, status])
}
