import React, { useCallback, useState } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import useExportStyles from 'export/useExportStyles'
import useActivityExportConfig from '../export'

const ExportActivityLogButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const config = useActivityExportConfig()
  const fileNameWithDate = useCurrentDateWithFilename(config.filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()

  const createFormattedData = useCallback(() => {
    setFormattedData(config.useFormattedData(data))
  }, [config, data])
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin onClick={createFormattedData}>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        onClick={createFormattedData}
        className={classes.fill}
        filename={fileNameWithDate as string}
        headers={config.headers}
        data={formattedData}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportActivityLogButton
