export const SIGNED_IN = 'auth.signed-in'
export const SIGNED_OUT = 'auth.signed-out'
export const CONTINUE_SESSION = 'auth.continue-session'
export const AUTH_INITIALIZED = 'auth.initialized'
export const AUTH_PROCESS_ERROR = 'auth.process-error'

//TODO This is not *really* part of auth, more part of app?
export const START_MANAGING_ORG = 'auth.organization.start-managing'
export const CURRENT_ORG_UPDATED = 'auth.organization.update-current'

export const authInitialized = (user, organization, profile) => ({
  type: AUTH_INITIALIZED,
  payload: { user, organization, profile },
})

export const signedIn = user => ({
  type: SIGNED_IN,
  payload: { user },
})

export const continueSession = user => ({
  type: CONTINUE_SESSION,
  payload: { user },
})

export const signedOut = () => ({ type: SIGNED_OUT })

export const authProcessError = (message, data = undefined) => ({
  type: AUTH_PROCESS_ERROR,
  payload: { message, data },
})

export const startManagingOrg = organization => ({
  type: START_MANAGING_ORG,
  payload: { organization },
})

export const currentOrgUpdated = organization => ({
  type: CURRENT_ORG_UPDATED,
  payload: { organization },
})
