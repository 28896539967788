import { makeStyles } from '@material-ui/styles'

export const usePageHeaderStyles = makeStyles(theme => ({
  wrapper: {
    display: 'flex',
    width: 'calc(100% + 180px)',
    flexDirection: 'column',
    margin: '-24px -90px',
    height: 'calc(100% + 48px)',
    backgroundColor: '#F8FAFB',
    // @ts-ignore
    [theme.breakpoints.down(960)]: {
      marginLeft: '-30px',
      width: 'calc(100% + 120px)',
    },
  },
  header: {
    width: '100%',
    height: '70px',
    display: 'flex',
    padding: '0px 10px 0px 40px',
    backgroundColor: 'white',
    alignItems: 'center',
  },
  titles: {
    flexGrow: 1,
    flexShrink: 1,
    height: '100%',
  },
  userProfile: {
    borderLeft: '1px #EFF1F1 solid',
    width: '278px',
    flexShrink: 0,
    flexGrow: 0,
    height: '100%',
    alignItems: 'center',
    padding: '0 0 0 12px',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    marginRight: '12px',
    // @ts-ignore
    [theme.breakpoints.down(960)]: {
      width: '62px',
    },
  },
  helpIcon: {
    // @ts-ignore
    color: theme.palette.primary.main,
  },
  helpButton: {
    border: '1px solid #E0E3E3',
    borderRadius: '100px',
    padding: '8px',
    marginRight: '12px',
  },
  helpLabel: {
    fontFamily: 'Nunito',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
  },
  childrenWrapper: {
    padding: '20px 40px',
    display: 'flex',
  },
  title: {
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '32px',
    color: '#000000',
    margin: 0,
    marginTop: '17px',
  },
  subTitle: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.1px',
    color: '#747778',
    margin: 0,
    marginTop: '-3px',
  },
  backLink: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    // @ts-ignore
    color: theme.palette.primary.main,
    margin: 0,
    marginTop: '-3px',
    paddingLeft: '19px',
    textDecoration: 'none',
    background: 'no-repeat left url("/assets/icons/back.svg")',
  },
  avatar: {
    width: '50px',
    height: '50px',
    borderRadius: '50px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    marginRight: '10px',
    flexGrow: 0,
  },
  name: {
    fontFamily: 'Nunito',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
    color: '#000000',
    margin: 0,
    marginBottom: '3px',
  },
  role: {
    fontFamily: 'Nunito',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    margin: 0,
  },
  profileLeft: {
    display: 'flex',
    alignItems: 'center',
  },
  openClose: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
    // @ts-ignore
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  nameRole: {
    // @ts-ignore
    [theme.breakpoints.down(960)]: {
      display: 'none',
    },
  },
  popover: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    gap: '15px',
    background: '#FFFFFF',
    border: '1px solid #E1E1E2',
    boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
    '& > a': {
      fontFamily: 'Nunito',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0.4px',
      color: '#000000',
      textDecoration: 'none',
    },
  },
}))
