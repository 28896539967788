import yup from 'civic-champs-shared/utils/yup'
import { PersonRef } from 'civic-champs-shared/question-sets/types'

export const notificationBlastSchema = (includesEmail: boolean) => {
  const bodySchema = includesEmail ? yup
    .string()
    .min(5)
    .required()
    .label('Body') : yup
    .string()
    .min(5)
    .notRequired()
    .nullable()
    .label('Body')
  return yup.object({
    subject: yup
      .string()
      .min(5)
      .required()
      .label('Subject'),
    body: bodySchema,
  })
}

export const chooseContactsSchema = yup.object({
  contactBoth: yup.boolean().default(false),
})

export interface StepOneForm {
  contactBoth: boolean
}

export interface EmailAndSmSPersonIds {
  emailPersonIds: number[]
  smsPersonIds: number[]
}

export interface EmailAndSmSPersonIdsWithBadContacts extends EmailAndSmSPersonIds {
  badContacts: BadContact[]
}

export interface SubjectAndBody {
  subject: string
  body?: string | null
}

export interface NotificationBlast extends SubjectAndBody {
  emailPersonIds: number[]
  smsPersonIds: number[]
}

export interface BadContact {
  id: number
  createdAt: Date | string
  deletedAt?: Date | string
  // NOTE: that person might have changed their contact in the meantime - it was the recipient's contact at the time of sending
  sentTo: PersonRef
  // this is who currently has the email or phone number, if anybody
  currentOwner?: PersonRef
  email?: string
  phoneNumber?: string
  reason: string
  emailEventId?: number,
  smsEventId?: number
}

export interface NotificationBlastPreview {
  smsCredits: number
  recipientIdsWithEmails: number[]
  recipientIdsWithPhoneNumbers: number[]
  recipientIdsWithBoth: number[]
  recipientIdsWithNoContacts: number[]
  badContacts: BadContact[]
}

export interface StepOneProps {
  onSubmit: (contactBoth: boolean) => void
  preview: NotificationBlastPreview
  cancel: () => void
  recipientPersonIds: number[]
  smsCredits: number
}

export interface StepTwoProps {
  onSubmit: (badContactsToExclude: BadContact[]) => void
  badContacts: BadContact[]
  cancel: () => void
  back: () => void
}

export interface StepThreeProps {
  recipientPersonIds: number[]
  cancel: () => void
  back: () => void
  emailPersonIds: number[]
  smsPersonIds: number[]
  onSubmit: (values: SubjectAndBody) => void
}
