import { get } from 'lodash'
import React, { useEffect, useState } from 'react'

import Label from '../../../components/label'
import TabSwitcher from 'core/tab-switcher'
import API_ENDPOINTS from '../../../constants/API_ENDPOINTS'
import './style.scss'
import ProgressBar from '../../../components/progress-bar'
import InputComponent from '../../../components/input'
import Loader from '../../../components/loader'
import EventDetailsHeader from '../../../modules/events/details/header'
import useFetch from 'civic-champs-shared/api/hooks/useFetch'
import BaseService from '../../../services/base.service'
import StaticGoogleMap from '../../../components/static-google-map'
import Button from '../../../../components/button'
import OpportunityVisibility from './private-token/OpportunityVisibility'
import format from '../../../../utils/format'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import { MIN_RADIUS_IN_METER } from '../../../helpers/toRoundMetersTo5Feet'
import { useDateStrings } from '../hooks/useDateStrings'
import { RECURRING_TYPE } from '../../../constants/RECURRING_TYPE_OPTIONS'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { EditEndDateModal } from '../../../modules/events/create-edit/basic-info/EditEndDateModal'
import { useFeatureEnabled } from '../../../../core/feature/hooks'
import { mapEventGeofencingToGeofencing } from '../../../../utils/event';
import ReactGoogleMap from '../../../../civic-champs-shared/core/location/ReactGoogleMap';

const base = new BaseService()
const useGetEvent = (encodedOccurrence: string, populateRecurrenceInfo = true) => {
  const [done, setDone] = useState(false)
  const [requestEvent, getEventStatus] = useFetch() as any
  const [requestRoles, getRolesStatus] = useFetch() as any

  useEffect(() => {
    ;(async () => {
      const event = await requestEvent({
        url: `/opportunity_instances/${encodedOccurrence}/?populateRecurrenceInfo=${populateRecurrenceInfo}`,
      })
      await requestRoles({ url: `/organizations/${event.organization.id}/roles` })
      setDone(true)
    })()
  }, [encodedOccurrence, populateRecurrenceInfo, requestEvent, requestRoles, setDone])

  let event = null;
  if (getEventStatus.result) {
    const {geofencing, ...eventData} = getEventStatus.result
    event = {
      ...eventData,
      geofencing: mapEventGeofencingToGeofencing(geofencing)
    }
  }


  return {
    event,
    roles: getRolesStatus.result,
    loading: !done,
  }
}

function EventDetails(props: any) {
  const { orgId, encodedOccurrence } = props.match.params
  const [comment, setComment] = useState<null | string>(null)
  const [showLoader, setShowLoader] = useState(false)
  const [showDynamicMap, setShowDynamicMap] = useState(false)

  const { event, loading } = useGetEvent(encodedOccurrence)
  const { recurrenceString, opportunityTimeDisplay } = useDateStrings({ event, loading })
  const showEditEndDateModal = useShowPrompt(EditEndDateModal)
  const changeEndDateEnabled = useFeatureEnabled('ChangeEndDate')
  if (loading || showLoader) {
    return <Loader />
  }
  const endDateEditable = changeEndDateEnabled && ![RECURRING_TYPE.NEVER, RECURRING_TYPE.CUSTOM_EVENTS].includes(event.recurring_type)
  const signupsSummaryTotal = get(event, 'timeshifts').reduce(
    (acc: number, item: any) => acc + item.roles.reduce((roleAcc: any, role: any) => roleAcc + role.all, 0),
    0,
  )

  const signupsSummaryCurrent =
    event.timeshifts &&
    signupsSummaryTotal -
      event.timeshifts.reduce(
        (acc: number, item: any) => acc + item.roles.reduce((roleAcc: any, role: any) => roleAcc + role.available, 0),
        0,
      )

  const saveComment = async () => {
    try {
      setShowLoader(true)
      const eventStatus = event && event.published ? 'publish' : 'draft'
      await base.putJSON(API_ENDPOINTS.Events.occurrenceRefactorUpdateEvent(encodedOccurrence, eventStatus), {
        ...event,
        comment,
      })
    } catch (e) {
      console.error(e)
    } finally {
      setShowLoader(false)
    }
  }

  const volunteers =
    event.timeshifts &&
    event.timeshifts
      .map((shift: any) =>
        shift.roles.map((role: any) => {
          return { name: role.name, available: role.all - role.available, all: role.all }
        }),
      )
      .flat()

  const createMarkup = (html: string) => {
    return { __html: html }
  }

  const { coordinates } = event.geofencing.location
  const geofenceRadius = (event.geofencing && event.geofencing.radius) || MIN_RADIUS_IN_METER
  const handleShowDynamicMap = () => setShowDynamicMap(true)

  const renderGoogleMaps = () =>
    showDynamicMap ? (
      <ReactGoogleMap zoom={16} geofenceRadius={geofenceRadius} coordinates={coordinates} />
    ) : (
      <>
        <StaticGoogleMap zoom={17} coordinates={coordinates} />
        <div className="left_side_map--overlay">
          <Button onClick={handleShowDynamicMap} size="small">
            Explore More
          </Button>
        </div>
      </>
    )

  const tabDetails = [
    { to: `/events/${encodedOccurrence}/${orgId}`, text: 'Event Summary' },
    { to: `/events/${encodedOccurrence}/${orgId}/volunteers`, text: 'Volunteers' },
  ]

  return (
    <article className="section-scene">
      <div className="detail_event_block">
        <div className="detail_event_block_back_arrow" onClick={() => props.history.push(
          get(props.history.location.state, 'goBackUrl', '/events')
        )}>
          <img src="/assets/icons/back-arrow.svg" alt="icon" className="arrow" />
          {get(props.history.location.state, 'goBackTitle', 'Events')}
        </div>
        <div>
          <EventDetailsHeader event={event} encodedOccurrence={encodedOccurrence} isDraft={!!event.timeshifts.length} />

          <TabSwitcher linkDetails={tabDetails} />
          <div className="event_summary_container">
            <div className="left_side">
              <div className="left_side_label">
                <Label
                  title="WHEN"
                  icon={<img src="/assets/icons/place.svg" alt="place" style={{ marginRight: 9 }} />}
                  color="#5c8de8"
                  className="left_side_label_item"
                />
                <div className="left_side_label_text">{opportunityTimeDisplay}</div>
                <div className="left_side_label_text">
                  <i>{recurrenceString}</i>
                </div>
                <div>
                  {endDateEditable && (
                    <div className="base-info-form-row multi-date-row">
                      <Button onClick={() => showEditEndDateModal({ recurrenceInfo: event.recurrenceInfo, encodedOccurrence })}>
                        Edit End Date
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="left_side_label">
                <Label
                  title="WHERE"
                  icon={<img src="/assets/icons/place.svg" alt="place" style={{ marginRight: 9 }} />}
                  color="#5c8de8"
                  className="left_side_label_item"
                />
                <div className="left_side_label_text">{getDisplayAddressFromEvent(event)}</div>
              </div>
              <div className="left_side_map left_side_map--hover">{renderGoogleMaps()}</div>
              <div className="left_side_progress_bar">
                <div className="left_side_progress_bar_title">Signups Summary:</div>
                <ProgressBar total={signupsSummaryTotal} current={signupsSummaryCurrent} />
              </div>
              <div className="left_side_volunteers">
                <div className="left_side_volunteers_title">Volunteers:</div>
                {volunteers &&
                  volunteers.map((item: any, index: number) => {
                    return (
                      <div key={index}>
                        {item.name}: {item.available} / {item.all}
                      </div>
                    )
                  })}
              </div>
              <div className="left_side_comments">
                <div className="left_side_comments_title">Comment:</div>
                <div className="left_side_comments_textarea">
                  <InputComponent
                    value={comment !== null ? comment : event.comment}
                    onChange={e => setComment(e)}
                    placeholder=""
                    label=""
                    isTextarea={true}
                    rows={'3'}
                  />
                  <div className="left_side_comments_textarea_save" onClick={saveComment}>
                    Save Comments
                  </div>
                </div>
              </div>
            </div>
            <address className="right_side">
              <label className="right_side_title">Description:</label>
              <div dangerouslySetInnerHTML={createMarkup(event.description)} />
              <label className="right_side_title gap">Day-of Event Instructions for Volunteers:</label>
              <div dangerouslySetInnerHTML={createMarkup(event.instructions)} />
              <label className="right_side_title gap">Point Of Contact</label>
              {(event.contact_name || event.contact_email || event.contact_phone_number) && (
                <div>
                  {event.contact_name && <span>{event.contact_name}</span>}
                  {event.contact_email && (
                    <span>
                      {' '}
                      -&nbsp;<b>{event.contact_email}</b>
                    </span>
                  )}
                  {event.contact_phone_number && <span> -&nbsp;{format.phoneNumber(event.contact_phone_number)}</span>}
                </div>
              )}
              <div className="visibility_container">
                <OpportunityVisibility
                  opportunity={event}
                  encodedOccurrence={encodedOccurrence}
                />
              </div>
              {event.isTest && <label className="right_side_title">Test Event</label>}
            </address>
          </div>
        </div>
      </div>
    </article>
  )
}

export default React.memo(EventDetails)
