import React, { useEffect, useMemo } from 'react'
import { RouteComponentProps } from 'react-router'
import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import moment from 'moment-timezone'
import map from 'lodash/map'
import { Grid } from '@material-ui/core'

import Loading from 'components/Loading'

import { NumberCell } from 'core/table/cells'
import DEFAULT_FILTERS from 'core/table/filters'
import { ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import {
  useGetColumnState,
  useConditionalSelectColumn,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks'
import { NUMBER_OPERATOR_OPTIONS, operatorTypes } from 'core/table/interfaces/Filters'

import {
  useFetchMeetingsGrid,
  useMentorshipMeetingsColumns as useColumns,
  useMentorshipMeetingsSummary,
} from '../hooks'
import { ExportMentorshipMeetingsButton } from '../components/ExportMentorshipMeetingsButton'

import PageHeader from 'App/components/PageHeader'

const getOperatorOptions = (column: string) => {
  switch (column) {
    case 'duration':
      return NUMBER_OPERATOR_OPTIONS

    case 'mentor':
    case 'mentee':
    case 'program':
    case 'type':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]

    default:
      return []
  }
}

const cards = [
  {
    header: 'Matches',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    header: 'Meetings',
    accessor: 'meetingCount',
    Cell: NumberCell,
  },
  {
    header: 'Avg. Meeting Duration',
    accessor: 'averageMeetingTime',
    Cell: NumberCell,
  },
  {
    header: 'Positive Experiences',
    accessor: 'positiveFeedbackPercent',
  },
]

const tableName = 'mentorshipMeetings'

const NewMentorshipMeetingsComponent = ({ location, history }: RouteComponentProps) => {
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()
  const columns: any = useColumns()

  const [
    fetchMeetings,
    {
      loading,
      result: { meetings: meetingsUnmapped, questionColumns },
    },
  ] = useFetchMeetingsGrid()

  useEffect(() => {
    fetchMeetings()
  }, [fetchMeetings])

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const meetings = useMemo(
    () =>
      map(
        meetingsUnmapped,
        ({ id, startsAt, endsAt, type, mentorName, menteeName, programName, mentorRating, menteeRating, answers }) => ({
          id,
          startsAt,
          mentor: mentorName,
          mentee: menteeName,
          program: programName,
          type,
          duration: Math.round(moment.duration(moment(endsAt).diff(moment(startsAt))).as('minutes')),
          mentorRating,
          menteeRating,
          answers,
        }),
      ),
    [meetingsUnmapped],
  )

  const table = useTable(
    {
      data: meetings,
      columns,
      // @ts-ignore
      filterTypes: DEFAULT_FILTERS,
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  const summary = useMentorshipMeetingsSummary(table.rows)

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Meetings"
        filterSubHeader="Select meeting attributes to filter by:"
        buttons={
          <>
            <ExportMentorshipMeetingsButton data={table.selectedFlatRows} questionColumns={questionColumns} />
          </>
        }
        useGlobalSearch
        useFilters
        useDateRange
      />
    </Grid>
  )
}

export const NewMentorshipMeetings = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Mentor Meetings" subTitle="This is the mentor meetings table">
      <NewMentorshipMeetingsComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipMeetings
