import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import isUndefined from 'lodash/isUndefined'
import uniq from 'lodash/uniq'
import differenceBy from 'lodash/differenceBy'
import isArray from 'lodash/isArray'
import filter from 'lodash/filter'
import uuid from 'uuid'
import {
  Box,
  Button,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core'
import { Icon } from '@mdi/react'
import { mdiClose } from '@mdi/js'
import { makeStyles } from '@material-ui/core/styles'

import { AddButton } from 'civic-champs-shared/core/add-button'
import Autocomplete from '@material-ui/lab/Autocomplete'
import forOwn from 'lodash/forOwn'
import first from 'lodash/first'
import qs from 'query-string'
import map from 'lodash/map'

const { array, arrayOf, func, shape, object, oneOfType, string } = PropTypes

const useFiltersModalStyles = makeStyles(theme => ({
  button: {
    textTransform: 'none',
    fontWeight: 'bold',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    backgroundColor: theme.palette.primary.main,
    border: `1px solid ${theme.palette.neutral.darkGray}`,
    textTransform: 'capitalize',
    margin: 2,
  },
  field: {
    width: '100%',
  },
  table: {
    tableLayout: 'fixed',
  },
}))

export const operatorTypes = {
  CONTAINS: 'contains',
  EQUALS: '=',
  GREATER_OR_EQUAL: '>=',
  GREATER_THAN: '>',
  IS: 'is',
  LESS_THAN: '<',
  LESS_OR_EQUAL: '<=',
}

export const getEmptyFilterRow = () => ({
  id: uuid(),
  column: '',
  operator: '',
  value: '',
})

export const useFiltersFromQuery = (search, getOperatorOptions) => {
  const query = qs.parse(search, { arrayFormat: 'bracket' })
  const filters = []
  const filterRows = []

  forOwn(query, (value, key) => {
    const [column, operator] = key.split(':')
    let defaultOperator
    if (operator) {
      value = {
        operator,
        operand: value,
      }
    } else {
      if (!getOperatorOptions) {
        return
      }
      defaultOperator = first(getOperatorOptions(column))
      if (!defaultOperator) {
        return
      }
    }
    filters.push({ id: column, value: value })
    filterRows.push({
      ...getEmptyFilterRow(),
      column,
      operator: operator || defaultOperator?.value,
      value,
    })
  })
  return useMemo(() => ({ filters, filterRows }), [filterRows, filters])
}

export const useSetFilterRowsHistory = history => {
  return useCallback(
    rows => {
      if (isUndefined(history)) return
      const fragments = rows.reduce((acc, row) => {
        const key = row.value.hasOwnProperty('operator') ? `${row.column}:${row.value.operator}` : row.column
        const value = row.value.hasOwnProperty('operand') ? row.value.operand : row.value
        return { ...acc, [key]: value }
      }, {})
      let path = history.location.pathname
      if (rows.length) path += '?' + qs.stringify(fragments, { arrayFormat: 'bracket' })
      history.push(path, { filterRows: [...rows] })
    },
    [history],
  )
}

export const FiltersModal = props => {
  const [filterRows, setFilterRows] = useState(props.filterRows.length ? [...props.filterRows] : [getEmptyFilterRow()])
  const [uiFilterRows, setUiFilterRows] = useState(
    props.filterRows.length ? [...props.filterRows] : [getEmptyFilterRow()],
  )
  const { history } = props
  const setFilterRowsHistory = useSetFilterRowsHistory(history)

  const onClose = (...args) => {
    props.onClose(...args)
    setUiFilterRows([...filterRows])
  }

  const classes = useFiltersModalStyles()

  const handleChange = (rowId, value, name) => {
    setUiFilterRows(state =>
      state.map(row =>
        row.id === rowId
          ? {
              ...row,
              [name]: value,
            }
          : row,
      ),
    )
  }

  useEffect(() => {
    if (
      !isUndefined(history) &&
      !isUndefined(history.location.state) &&
      history.location.state.filterRows &&
      JSON.stringify(history.location.state.filterRows) !== JSON.stringify(filterRows)
    ) {
      setUiFilterRows(
        history.location.state.filterRows.length ? history.location.state.filterRows : [getEmptyFilterRow()],
      )
      setFilterRows(history.location.state.filterRows)
      history.location.state.filterRows.forEach(row => {
        props.table.setFilter(row.column, row.value)
      })
      const filteredColumns = map(history.location.state.filterRows, 'column')
      props.table.columns
        .filter(column => column.filterValue && !filteredColumns.includes(column.id))
        .forEach(({ id }) => {
          props.table.setFilter(id, undefined)
        })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, history && history.location && history.location.state])

  const handleColumnChange = (rowId, value, name) => {
    handleChange(rowId, value, name)
    handleChange(rowId, '', 'operator')
    handleChange(rowId, '', 'value')
  }

  const handleRemoveFilter = row => {
    const updatedRows = uiFilterRows.filter(item => item.id !== row.id)
    if (updatedRows.length === 0) updatedRows.push(getEmptyFilterRow())
    setUiFilterRows(updatedRows)
  }

  const applyFilters = () => {
    const valuableRows = filter(uiFilterRows, ({ value }) =>
      isArray(value) ? value.length > 0 : value.operand && value.operand.trim() !== '',
    )
    const removedRows = filter(differenceBy(filterRows, valuableRows, 'column'), ({ column }) => column !== '')
    valuableRows.forEach(row => {
      props.table.setFilter(row.column, row.value)
    })
    removedRows.forEach(row => {
      props.table.setFilter(row.column, undefined)
    })

    setFilterRowsHistory(valuableRows)
    props.setFilterRows && props.setFilterRows(valuableRows)
    props.onClose()
    const newUIRows = valuableRows.length === 0 ? [getEmptyFilterRow()] : valuableRows
    setUiFilterRows([...newUIRows])
    setFilterRows([...valuableRows])
  }

  const addFilter = () => {
    setUiFilterRows([...uiFilterRows, getEmptyFilterRow()])
  }

  return (
    <Box width={850} mb={2} pb={1} pl={2} pr={2}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell width={60} />
              <TableCell width="25%">
                <b>Column</b>
              </TableCell>
              <TableCell width="25%">
                <b>Operator</b>
              </TableCell>
              <TableCell>
                <b>Value</b>
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {uiFilterRows.map(row => {
              const operatorOptions = props.getOperatorOptions(row.column)
              const operatorValueOptions = uniq(props.table.initialRows.map(item => item.original[row.column]))
              const selectedColumn = props.columns.find(column => column.id === row.column)

              return (
                <TableRow key={row.id}>
                  <TableCell>
                    <IconButton onClick={() => handleRemoveFilter(row)}>
                      <Icon path={mdiClose} size={1} />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <Select
                      className={classes.field}
                      variant="outlined"
                      name="column"
                      value={row.column}
                      onChange={ev => {
                        handleColumnChange(row.id, ev.target.value, ev.target.name)
                      }}
                    >
                      {props.columns
                        .filter(column => column && column.id && !column.disableFilters)
                        .map(column => (
                          <MenuItem key={column.Header} value={column.id}>
                            {column.Header}
                          </MenuItem>
                        ))}
                    </Select>
                  </TableCell>

                  <TableCell>
                    <Select
                      disabled={!operatorOptions.length}
                      className={classes.field}
                      variant="outlined"
                      name="operator"
                      value={row.operator}
                      onChange={ev => {
                        handleChange(row.id, ev.target.value, ev.target.name)
                      }}
                    >
                      {operatorOptions.map(option => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.displayValue}
                        </MenuItem>
                      ))}
                    </Select>
                  </TableCell>
                  <TableCell>
                    {row.operator === operatorTypes.IS ? (
                      <Autocomplete
                        multiple
                        disabled={!operatorOptions.length}
                        options={operatorValueOptions}
                        ChipProps={{ className: classes.chip }}
                        value={row.value || []}
                        onChange={(_, value) => handleChange(row.id, value, 'value')}
                        filterSelectedOptions
                        renderInput={params => (
                          <TextField
                            {...params}
                            type="text"
                            name="value"
                            className={classes.field}
                            variant="outlined"
                            label="Filter Value"
                            placeholder="Value"
                          />
                        )}
                      />
                    ) : (
                      <TextField
                        type={selectedColumn && selectedColumn.filter === 'dynamicNumber' ? 'number' : 'text'}
                        className={classes.field}
                        disabled={!row.operator}
                        name="value"
                        value={row.value && row.value.operand}
                        variant="outlined"
                        onChange={ev => {
                          handleChange(row.id, { operator: row.operator, operand: ev.target.value }, ev.target.name)
                        }}
                      />
                    )}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Box display="flex" mt={2} mb={2}>
        <AddButton onClick={addFilter} />
        <Box ml="auto" display="inline-block">
          <Button className={classes.button} variant="contained" color="secondary" onClick={applyFilters}>
            Apply
          </Button>

          <Box ml={1} display="inline-block">
            <Button className={classes.button} variant="contained" color="default" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

FiltersModal.propTypes = {
  columns: array,
  table: object,
  filterRows: arrayOf(
    shape({
      id: string,
      column: string,
      operator: string,
      value: oneOfType([
        shape({
          operator: string,
          operand: string,
        }),
        array,
      ]),
    }),
  ),
  setFilterRows: func,
  onClose: func.isRequired,
}
