import { makeStyles } from '@material-ui/core/styles'

export const useExtendedTableStyles = makeStyles(theme => ({
  button: {
    color: theme.palette.primary.main,
    textTransform: 'none',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    borderRadius: '100px',
    // @ts-ignore
    borderColor: theme.palette.background.gray,
    padding: '6px 10px 6px 16px',
  },
  rightMargin: {
    marginRight: '8px',
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: '18px !important',
  },
  disabledIcon: {
    color: 'rgba(0, 0, 0, 0.26)',
  },
  searchField: {
    // @ts-ignore
    background: theme.palette.background.gray,
    border: 0,
    borderRadius: '100px',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '20px',
    letterSpacing: '0.25px',
  },
  notchedOutline: {
    border: 0,
  },
  input: {
    padding: '8px 16px',
    width: '200px',
    lineHeight: '20px',
  },
  filterChip: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '20px',
    color: '#333333',
    // @ts-ignore
    background: theme.palette.background.lightBlue,
    padding: '5px',
    height: '30px',
  },
  filterChipIcon: {
    width: '20px',
    color: 'rgba(0, 0, 0, 0.54) !important',
  },
  popoverButton: {
    padding: '4px',
  },
  popover: {
    maxWidth: '225px',
    padding: '10px 10px 0',
  },
  wrapper: {
    marginTop: '10px',
  },
  header: {
    padding: '10px 0 10px 10px',
  },
}))

export default useExtendedTableStyles
