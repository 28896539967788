import * as moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { useAddActivityPrompt, useEditActivityPrompt, useRemoveActivitiesPrompt } from './index'

export const formatDate = date => (date ? moment(date).clone().utc().format('MM/DD/YYYY hh:mm:ss a') : date)

export const useActivitiesCollection = filters => {
  const [request, { loading, error }] = useFetch()
  const [activities, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)
  const { syncCollection, eagerAdd, eagerReplace, eagerRemoveMany } = operations

  const getActivities = useCallback(
    async filters => {
      const { startDate, endDate } = filters
      const params = {}

      if (startDate) {
        params.from = formatDate(startDate)
      }

      if (endDate) {
        params.to = formatDate(endDate)
      }

      const updatedActivities = await request({
        url: `/organizations/${organization.id}/activities`,
        config: {
          queryStringParameters: params,
        },
      })

      syncCollection(updatedActivities)
      setInitiallyLoaded(true)
    },
    [organization.id, request, syncCollection],
  )
  useEffect(() => {
    getActivities(filters)
  }, [filters, getActivities])

  const addActivity = useAddActivityPrompt(eagerAdd)
  const editActivity = useEditActivityPrompt(eagerReplace)
  const removeActivities = useRemoveActivitiesPrompt(eagerRemoveMany)

  return [
    {
      activities,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addActivity,
      editActivity,
      removeActivities,
    },
  ]
}

export default useActivitiesCollection
