import React from 'react'
import { Autocomplete } from 'formik-material-ui-lab'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { makeStyles, TextField } from '@material-ui/core'
import { getBasicTextFieldStyles } from 'components/PersonAutocomplete'

const useStyles = makeStyles({
  root: ({ legendWidthFixture }: any) => getBasicTextFieldStyles(legendWidthFixture),
})

export const StyledAutocomplete = ({ label, ...props }: any) => {
  const {
    field: { name },
    form: { touched, errors },
  } = props
  const classes = useStyles()
  return (
    <Autocomplete
      {...props}
      style={{ width: '100%' }}
      popupIcon={<KeyboardArrowDownIcon />}
      renderInput={(params: any) => (
        <TextField
          {...params}
          fullWidth
          classes={classes}
          label={label}
          variant="outlined"
          error={Boolean(touched[name] && errors[name])}
          helperText={touched[name] && errors[name]}
        />
      )}
    />
  )
}
