import moment from 'moment'
import React from 'react'
import { 
  makeStyles,
  IconButton,
  Modal,
} from '@material-ui/core'
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
modalBody: {
    display: 'flex',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  modal: {
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
  }, 
  icon: {
    alignSelf: 'flex-end',
    width: '60px',
    height: '60px',
    color: '#FF6F3E'
  },
  modalTitle: {
    fontSize: '34px',
    color: '#3D3D3D',
    fontFamily: 'SemiBold Open Sans',
    marginBottom: '25px',
    alignSelf: 'center',
  },
  text: {
    marginLeft: '100px',
    marginRight: '100px',
    overflow: 'scroll',
    marginBottom: '30px',
  }
}));   


export default function WaiverAgreementPrompt(props) {
    const { showing, close, waiver } = props
    const classes = useStyles()
    
      const createMarkup = (html) => {
        return { __html: html }
      }

    return (<Modal
      open={showing}
      className={classes.modal}
    >
      <div className={classes.modalBody}>
        <IconButton 
          color="#FF6F3E" 
          className={classes.icon} 
          aria-label="close" 
          component="span" 
          onClick={close} 
         >
            <Close />
        </IconButton>
        <div className={classes.modalTitle}>Waiver agreement</div> 
        <p className={classes.text} dangerouslySetInnerHTML={createMarkup(waiver.version.waiverStatement)}/>
      </div>
    </Modal>)
}