import { useEffect, useCallback, useMemo } from 'react'
import { useCurrentOrg } from '../../auth/hooks'
import { useFetch } from 'civic-champs-shared/api/hooks'
import _ from 'lodash'

const useOrganizationCredentials = (onSuccess?: (data: any) => any) => {
  // @ts-ignore
  const [request, { result, loading, error, called }] = useFetch()
  const currentOrganization = useCurrentOrg()

  const getOrganizationCredentials = useCallback(async () => {
    // @ts-ignore
    return await request({
      url: `/organizations/${currentOrganization.id}/credentials`,
    }).then((data: any) => {
      if (onSuccess) {
        onSuccess(data)
      }
      return data
    })
  }, [request, currentOrganization.id])

  useEffect( () => { getOrganizationCredentials() }, [getOrganizationCredentials])

  return {
    credentials: useMemo(() => _.map(result, r => ({
      ...r,
      id: r.credential.id,
    })), [result]),
    loading: loading || !called,
    error,
    refresh: getOrganizationCredentials
  }
}

export default useOrganizationCredentials
