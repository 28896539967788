import React, { useEffect, useState } from 'react'
import { Button as ButtonMaterial, makeStyles } from '@material-ui/core'
import GetAppIcon from '@material-ui/icons/GetApp'
import BaseService from '../../../../../services/base.service'
import MailOutline from '@material-ui/icons/MailOutline'
import { CancelOutlined } from '@material-ui/icons'
import API_ENDPOINTS from '../../../../../constants/API_ENDPOINTS'
import './index.scss'
import SelectComponent from '../../../../../components/select'
import Button from '../../../../../../components/button'
import { useFeatureEnabled } from 'core/feature/hooks'
import useFormatToOrgTime from 'Event/scenes/events/hooks/useFormatToOrgTime'
import MessageIcon from '@material-ui/icons/MessageOutlined'
import useMessageRecipientsPrompt from 'messages/hooks/useMessageRecipientsPrompt'
import { filter, map } from 'lodash'
import { AddButton } from 'civic-champs-shared/core/add-button'
import { useGetSearchableContactsFromVolunteersArray } from 'messages/hooks/useGetSearchableContactsFromVolunteersArray'

const useStyles = makeStyles((theme: any) => ({
  button: {
    height: 45,
    marginRight: 10,
  },
  container: {
    display: 'flex',
    alignSelf: 'flex-end',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
  cancelButton: {
    backgroundColor: theme.palette.danger.main,
    color: theme.palette.neutral.white,
    '&:hover': {
      backgroundColor: theme.palette.danger.dark,
    },
  },
}))

const baseService = new BaseService()

export default function VolunteersFilters(props: any) {
  const {
    availableSlots,
    filtersValues,
    onChange,
    sendEmail,
    cancelRegistrations,
    clearFilters,
    isChecked,
    timeshiftOptions,
    roleOptions,
    event,
    volunteersList,
    encodedOccurrence,
    onAddVolunteer,
  } = props

  const classes = useStyles()
  const isSchedulingEnabled = useFeatureEnabled('EventScheduling')
  const isBulkMessagingEnabled = useFeatureEnabled('BulkMessaging')
  const formatTime = useFormatToOrgTime()
  const showMessageRecipientsPrompt = useMessageRecipientsPrompt()

  const [disableExport, setDisableExport] = useState(true)

  const isPublished = event.published
  const passed = new Date() > new Date(event.endsAt)

  useEffect(() => {
    const list = volunteersList && volunteersList.filter((item: any) => item.selected)
    list && list.length > 0 ? setDisableExport(false) : setDisableExport(true)
  }, [volunteersList])

  const getSearchableContacts = useGetSearchableContactsFromVolunteersArray(volunteersList)

  const exportCsv = async () => {
    const volunteersIds: any = []
    volunteersList.filter((item: any) => {
      item.selected && volunteersIds.push(item.id)
    })
    const response: any = await baseService.postCSV(
      API_ENDPOINTS.Volunteers.exportCsvOccurrenceRefactor(encodedOccurrence),
      { registration_ids: volunteersIds },
    )
    const csvData = await new Blob([response.value], { type: 'application/csv;charset=utf-8;' })
    const csvURL = window.URL.createObjectURL(csvData)
    const tempLink = document.createElement('a')
    tempLink.href = csvURL
    tempLink.setAttribute('download', `${event.name}_${formatTime(event.startsAt, 'MM-DD-YYYY')}_Volunteer Data.csv`)
    tempLink.click()
  }

  const onMessageVolunteers = () => {
    const recipientPersonIds = map(filter(volunteersList, 'selected'), 'user.id')
    showMessageRecipientsPrompt({
      recipientPersonIds,
      getSearchableContacts,
      autocompleteSectionTitle: `${event.name} Volunteers`,
    })
  }

  return (
    <div className="volunteer-filter">
      <div className="volunteer-filter_fields">
        {/* <div className="volunteer-filter_fields_input">
          <InputComponent
            label={'Search'}
            value={searchValue}
            onChange={e => setSearchValue(e)}
            onKeyDown={(e: any) => {
              if (e.key === 'Enter') {
                onSearchClick()
              }
            }}
          />
          {searchValue ? (
            <div
              className="volunteer-filter_fields_input_clear"
              onClick={clearSearchValue}
            >
              <img src="/assets/icons/cancel.svg" alt="clear" />
            </div>
          ) : null}
        </div>
        <Button
          className="volunteer-filter_fields_search"
          type="outlined"
          color="secondary"
          children={'Search'}
          onClick={onSearchClick}
        /> */}
        <SelectComponent
          className="volunteer-filter_fields_shift-select"
          placeholder={filtersValues.timeshift.label}
          value={filtersValues.timeshift.value}
          options={timeshiftOptions}
          onChange={(value: any) => onChange(value, 'timeshift')}
        />
        <SelectComponent
          className="volunteer-filter_fields_select"
          placeholder={filtersValues.role.label}
          options={roleOptions}
          value={filtersValues.role.value}
          onChange={(value: any) => onChange(value, 'role')}
        />
        <Button
          className="volunteer-filter_fields_clear-button"
          type="text"
          color="secondary"
          children={'Clear Filters'}
          size="small"
          onClick={clearFilters}
        />
      </div>
      {isSchedulingEnabled && (
        <nav className={classes.container}>
          <AddButton
            className={classes.button}
            disabled={!isPublished || passed || availableSlots === 0}
            onClick={onAddVolunteer}
          />
          <ButtonMaterial
            style={{ height: '45px', marginRight: '10px' }}
            variant="contained"
            color="secondary"
            onClick={() => exportCsv()}
            disabled={disableExport}
          >
            <GetAppIcon style={{ marginRight: 5 }} />
            Export
          </ButtonMaterial>
          {isBulkMessagingEnabled ? (
            <ButtonMaterial
              style={{ height: '45px', marginRight: '10px' }}
              variant="contained"
              color="secondary"
              onClick={onMessageVolunteers}
              disabled={!isChecked}
            >
              <MessageIcon style={{ marginRight: 5 }} />
              Message
            </ButtonMaterial>
          ) : (
            <ButtonMaterial
              style={{ height: '45px', marginRight: '10px' }}
              variant="contained"
              color="secondary"
              onClick={sendEmail}
              disabled={!isChecked}
            >
              <MailOutline style={{ marginRight: 5 }} />
              Email
            </ButtonMaterial>
          )}
          <ButtonMaterial
            style={{ height: '45px' }}
            variant="contained"
            className={classes.cancelButton}
            onClick={cancelRegistrations}
            disabled={!isChecked || passed}
          >
            <CancelOutlined style={{ marginRight: 5 }} />
            Cancel Registration
          </ButtonMaterial>
        </nav>
      )}
    </div>
  )
}
