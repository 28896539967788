import { isString, isObject, every, some, reduce, filter } from "lodash"

//TODO these types could probably use a cleanup
export interface ShowHideConfig {
    [key: string]: boolean
}

export type ShowHideParam<T extends ShowHideConfig> = keyof T

export interface ShowHideExpr<T extends ShowHideConfig> {
    allOf?: ShowHideParam<T>[]
    anyOf?: ShowHideParam<T>[]
}

export type ShowHideRule<T extends ShowHideConfig> = ShowHideParam<T> | ShowHideExpr<T>

export interface ItemWithRules<T extends ShowHideConfig> {
    requires?: ShowHideRule<T>,
    hide?: ShowHideRule<T>
}

export interface RequireRuleFilterConfigBase<C extends ShowHideConfig> {
    config: C
}

function isFilterConfig<C extends ShowHideConfig>(filterConfig: any): filterConfig is RequireRuleFilterConfigBase<C> {
    return !!filterConfig && isObject(filterConfig.config)
}

export interface FlatRequireRuleFilterConfig<C extends ShowHideConfig> extends RequireRuleFilterConfigBase<C> {
    recursive: false
}

export interface RecursiveRequireRuleFilterConfig<C extends ShowHideConfig, T> extends RequireRuleFilterConfigBase<C> {
    recursive: true,
    nestingProperty: keyof T
}

export type RequireRuleFilterConfig<C extends ShowHideConfig, T> = 
    C | 
    FlatRequireRuleFilterConfig<C> | 
    RecursiveRequireRuleFilterConfig<C, T>

export const filterByRequireRules = 
    <C extends ShowHideConfig, T extends ItemWithRules<C>>(items: T[], filterConfig: RequireRuleFilterConfig<C, T>): T[] => {
        let config: C, recursive = false, nestingProp: keyof T 
        if( isFilterConfig(filterConfig) ) {
            config = filterConfig.config;
            recursive = filterConfig.recursive;
            if(filterConfig.recursive) {
                nestingProp = filterConfig.nestingProperty
            }
        } else {
            config = filterConfig as C
        }

        const showItem = (item: T) => {
            const { requires, hide } = item
            const isEnabled = (key: ShowHideParam<C>) => !!config[key]
            if (isString(hide) && isEnabled(hide)) return false
            if (
                isObject(hide) &&
                (!hide.allOf || every(hide.allOf, isEnabled)) &&
                (!hide.anyOf || some(hide.anyOf, isEnabled))
            ) {
                return false
            }

            if (isString(requires)) return isEnabled(requires)
            if (isObject(requires)) {
                return (
                    (!requires.allOf || every(requires.allOf, isEnabled)) && 
                    (!requires.anyOf || some(requires.anyOf, isEnabled))
                )
            }

            return true
        }
        
        return recursive 
            ? reduce(items, (acc: T[], item: T) => {
                if (!showItem(item)) {
                    return acc
                }

                acc.push({ 
                    ...item, 
                    //TODO there might be a way to capture a dynamic, self-nesting property, but...
                    [nestingProp]: filterByRequireRules(item[nestingProp] as unknown as T[], filterConfig) 
                })

                return acc
              }, [])
            : filter(items, showItem)
    }
 
