import { get } from 'lodash'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'
import LockIcon from '@material-ui/icons/Lock'

import { filterByRequireRules } from 'civic-champs-shared/utils/filterByRequireRules'
import { useHasRole } from 'auth/hooks'
import { INTERNAL_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { useFeatureEnabled } from 'core/feature/hooks'
import { DateCell, IntegerCell, HoursCell, CurrencyCell } from 'core/table/cells'
import useGroupOverviewApprovalButtonCell from './useGroupOverviewApprovalButtonCell'
import { GroupTypeCode } from '../../Event/components/opportunity/GroupPicker/types'

export const GroupNameCell = ({ cell }) => {
  const id = get(cell.row.original, 'id')
  const isSystemGroup = get(cell.row.original, 'groupType.code') !== GroupTypeCode.UserDefined

  return (
    <>
      <Link to={`/groups/${id}`}>{cell.value}</Link>
      {isSystemGroup && (
        <Tooltip title="Required by Civic Champs Platform">
          <LockIcon
            style={{
              verticalAlign: 'middle',
              display: 'inline-flex',
              paddingLeft: '4px',
            }}
          />
        </Tooltip>
      )}
    </>
  )
}

const useGroupOverviewColumns = (onGotoApprovals) => {
  const isInternalAdmin = useHasRole(INTERNAL_ADMIN)
  const isDonationsEnabled = useFeatureEnabled('Donations')
  const isSummaryEnabled = useFeatureEnabled('GroupSummaryReporting')

  const ApprovalButtonCell = useGroupOverviewApprovalButtonCell(onGotoApprovals)

  return useMemo(
    () =>
      filterByRequireRules(
        [
          {
            Header: 'ID',
            accessor: 'id',
            requires: 'isInternalAdmin',
          },
          {
            Header: 'Name',
            accessor: 'name',
            Cell: GroupNameCell,
          },
          {
            id: 'approvalType',
            Header: 'Approval Type',
            accessor: (group) => (group.closed ? 'Limited' : 'Open'),
          },
          {
            Header: 'Members',
            accessor: 'memberCount',
            Cell: IntegerCell,
          },
          {
            Header: 'Pending Members',
            accessor: 'pendingMemberCount',
            Cell: IntegerCell,
          },
          {
            Header: 'Date Created',
            accessor: 'createdAt',
            Cell: DateCell,
          },
          {
            Header: 'Hours',
            accessor: null,
            Cell: HoursCell,
            requires: 'isSummaryEnabled',
          },
          {
            Header: 'Activities',
            accessor: null,
            Cell: IntegerCell,
            requires: 'isSummaryEnabled',
          },
          {
            Header: 'Donations',
            accessor: null,
            Cell: CurrencyCell,
            requires: { allOf: ['isDonationsEnabled', 'isSummaryEnabled'] },
          },
          {
            id: '$approvals',
            Header: 'Actions',
            Cell: ApprovalButtonCell,
            disableSortBy: true,
          },
        ],
        {
          isInternalAdmin,
          isDonationsEnabled,
          isSummaryEnabled,
        },
      ),
    [ApprovalButtonCell, isInternalAdmin, isDonationsEnabled, isSummaryEnabled],
  )
}

export default useGroupOverviewColumns
