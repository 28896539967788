import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent, Grid, Button, makeStyles } from '@material-ui/core'
import Loading from 'civic-champs-shared/core/Loading'
import DatePickerCustom from '../../../../components/date-picker'
import { RecurrenceInfo } from '../../../../interfaces/interfaceCreateEditEvent'
import moment from 'moment'
import usePut from 'civic-champs-shared/api/hooks/usePut'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme: any) => ({
  container: {
    minHeight: '500px',
  },
}))

interface Props {
  complete: () => void
  close: () => void
  cancel: () => void
  showing: boolean
  recurrenceInfo: RecurrenceInfo
  encodedOccurrence: string | number
}

export const EditEndDateModal = (props: Props) => {
  const { close, complete, cancel, showing, recurrenceInfo, encodedOccurrence } = props
  const history = useHistory()
  const { endDate, startDate } = recurrenceInfo
  const [newEndDate, setNewEndDate] = useState<string>(endDate)
  const [editEndDate, { loading }] = usePut<void, { endDate: string }>({
    errorMessage: 'Error updating end date',
    successMessage: 'Successfully updated end date',
    configMapper: (body: { endDate: string }) => ({
      url: `/opportunity_instances/${encodedOccurrence}/end-date`,
      config: { body },
      onSuccess: () => history.push(`/events/week`),
    }),
  })
  const minDate = moment(startDate).isBefore(moment(), 'day') ? new Date() : startDate
  const endDateChanged = useMemo(() => newEndDate != endDate, [newEndDate, endDate])
  const classes = useStyles()
  const handleSubmit = async () => {
    if (endDateChanged) {
      await editEndDate({ endDate: newEndDate })
      complete()
    }
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={classes.container}>
        {loading && <Loading />}
        {!loading && (
          <Grid container spacing={2} direction="column">
            <Grid item>
              <DatePickerCustom
                minDate={minDate}
                value={newEndDate}
                onChange={setNewEndDate}
                onBlur={setNewEndDate}
                placeholder="End date*"
              />
            </Grid>
            <Grid item>
              <Button onClick={cancel} variant="text" disabled={loading}>
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="secondary" disabled={loading || !endDateChanged} onClick={handleSubmit}>
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
