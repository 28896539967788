import React, { useCallback, useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete'
import useFetchPersonSuggestions, { PersonSuggestion } from 'civic-champs-shared/api/hooks/useFetchPersonSuggestions'
import isNull from 'lodash/isNull'
import last from 'lodash/last'
import { makeStyles } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import useAddPersonToGroup from 'hooks/useAddPersonToGroup'
import format from 'utils/format'
import { FieldProps } from 'formik'

const formatContact = (contact: string) => (/^\+\d{11}$/.test(contact) ? format.phoneNumber(contact) : contact)

export const getBasicTextFieldStyles = (legendWidthFixture?: number) => ({
  '& label:not(.Mui-focused):not(.MuiFormLabel-filled)': {
    top: '-6px',
  },
  '& label.Mui-focused': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& label.MuiFormLabel-filled': {
    color: 'rgba(0, 0, 0, 0.87)',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '16px',
    lineHeight: '20px',
    minHeight: '45px',
    padding: '2px 62px 2px 8px',
    '& fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(175, 188, 213)',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'rgba(0, 0, 0, 0.87)',
      border: '1px solid',
    },
  },
  ...(legendWidthFixture
    ? {
        '& label.MuiInputLabel-shrink ~ .MuiOutlinedInput-root fieldset.MuiOutlinedInput-notchedOutline legend': {
          width: `${legendWidthFixture}px !important`,
        },
      }
    : {}),
})

const useStyles = makeStyles(theme => ({
  option: {
    // @ts-ignore
    color: theme.palette.neutral.gray,
    padding: '0px 12px',
  },
  groupLabel: {
    // @ts-ignore
    color: theme.palette.neutral.gray,
    fontWeight: 600,
    padding: '4px 12px',
    lineHeight: 'inherit',
  },
  autoComplete: {
    width: '100%',
  },
  textField: ({ legendWidthFixture }: any) => getBasicTextFieldStyles(legendWidthFixture),
}))

export interface PersonAutocompleteProps {
  label: string
  groupId: number
  groupTitle: string
  otherTitle?: string
  onCreate?: (inputValue: string, groupId: number) => void
  onGroupAdd?: (personId: number, groupId: number) => void
  onBlur?: (...values: any) => void
  onChange: (person: PersonSuggestion) => void
  error?: string
  hasError?: boolean
  required?: boolean
  value?: PersonSuggestion | null
  disableGroupPersons?: boolean
  addToGroupOnSelection?: boolean
  legendWidthFixture?: number
  disabled?: boolean
  addNewPersonText?: string
}

export default function PersonAutocomplete(props: PersonAutocompleteProps) {
  const {
    label,
    groupId,
    groupTitle,
    otherTitle = 'Other Members',
    onCreate,
    onChange,
    onBlur,
    error,
    value,
    hasError = false,
    required = false,
    disableGroupPersons = false,
    addToGroupOnSelection = true,
    legendWidthFixture,
    disabled = false,
    addNewPersonText = `+ Add a new person`,
  } = props

  const getOptionLabel = useCallback(
    (option: PersonSuggestion) =>
      option.id ? `${option.givenName} ${option.familyName} - ${formatContact(option.contact)}` : addNewPersonText,
    [addNewPersonText],
  )

  const getSearchLabel = useCallback(
    (option: PersonSuggestion) =>
      option.id ? `${option.givenName} ${option.familyName} - ${option.contact}` : addNewPersonText,
    [addNewPersonText],
  )

  const filterSuggestions = useCallback(createFilterOptions({ stringify: getSearchLabel }), [
    createFilterOptions,
    getSearchLabel,
  ])

  const [suggestions, setSuggestions] = useState<PersonSuggestion[]>([])
  const [lastSuggestionTime, setLastSuggestionTime] = useState<number>(performance.now())
  const [inputValue, setInputValue] = useState('')
  const [addPersonToGroup] = useAddPersonToGroup(groupId)
  const defaultGroupAdd = (personId: number) => {
    addPersonToGroup({
      type: 'EXISTING_USER',
      userId: personId,
    })
  }
  const { onGroupAdd = defaultGroupAdd } = props
  // @ts-ignore
  const [fetchSuggestions] = useFetchPersonSuggestions(groupId, disableGroupPersons)
  const classes = useStyles({ legendWidthFixture })

  useEffect(() => {
    // @ts-ignore
    groupId && fetchSuggestions(value || '').then(({ suggestions }) => setSuggestions(suggestions))
  }, [fetchSuggestions, value, groupId])

  return (
    <Autocomplete
      disabled={disabled}
      options={suggestions}
      value={value}
      getOptionDisabled={({ groupId, id }) => !!(disableGroupPersons && id && groupId)}
      onChange={(event, newValue) => {
        if (isNull(newValue) || newValue.id) {
          addToGroupOnSelection &&
            onGroupAdd &&
            !isNull(newValue) &&
            isNull(newValue.groupId) &&
            onGroupAdd(newValue.id, newValue.groupId)
          onChange(newValue)
        } else {
          onCreate && onCreate(inputValue, groupId)
        }
      }}
      onInputChange={(event, newValue) => {
        if (newValue === addNewPersonText) return
        setInputValue(newValue)
        // @ts-ignore
        groupId &&
          fetchSuggestions(newValue).then(({ suggestions, time }) => {
            setLastSuggestionTime(prevTime => {
              if (time < lastSuggestionTime) {
                return prevTime
              } else {
                setSuggestions(suggestions)
                return time
              }
            })
          })
      }}
      onBlur={onBlur}
      filterOptions={(options, params) => {
        const filtered = filterSuggestions(options, params).slice(0, 10)
        return onCreate
          ? [
              ...filtered,
              {
                id: null,
                givenName: null,
                familyName: null,
                contact: null,
                groupId: last(filtered)?.groupId,
              },
            ]
          : filtered
      }}
      blurOnSelect
      classes={{ popper: classes.popper }}
      groupBy={option => (option.groupId ? groupTitle : otherTitle)}
      getOptionLabel={getOptionLabel}
      popupIcon={<KeyboardArrowDownIcon />}
      className={classes.autoComplete}
      renderInput={params => {
        return (
          <TextField
            {...params}
            fullWidth
            value={inputValue}
            classes={{
              root: classes.textField,
            }}
            error={hasError}
            helperText={hasError && error}
            required={required}
            label={label}
            variant="outlined"
          />
        )
      }}
    />
  )
}

interface FormikPersonAutocompleteProps
  extends Omit<PersonAutocompleteProps, 'onChange' | 'hasError' | 'error' | 'value' | 'onBlur'>,
    FieldProps {}

export const FormikPersonAutocomplete = ({
  field: { name, value },
  form: { touched, errors, setFieldValue, setFieldTouched },
  meta,
  ...props
}: FormikPersonAutocompleteProps) => {
  return (
    <PersonAutocomplete
      {...props}
      onChange={value => {
        setFieldValue(name, value)
      }}
      hasError={touched[name] && (isNull(value) || !!errors[name])}
      error={errors[name] as string}
      value={value}
      onBlur={() => {
        // TODO: figure out better solution to make validation work
        setTimeout(() => setFieldTouched(name, true), 250)
      }}
    />
  )
}
