import React from 'react'
import { Form, Formik, Field, FormikHelpers as FormikActions } from 'formik'
import _ from 'lodash'
import { Grid, Button, FormControlLabel, Switch } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { defaultAddress, AddressField } from 'civic-champs-shared/formik/components/AddressField'
import { CreateOrUpdateOrganizationParam } from '../models'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import {
  CreateOrUpdateOrganizationGeofencingParam,
  mapDbOrganizationToGeofencing,
  mapGeofencingOrganizationToDb,
} from 'utils/organization'

const defaultValues = {
  name: '',
  website: '',
  address: defaultAddress(),
  timeZone: '',
  isTest: false,
} as CreateOrUpdateOrganizationGeofencingParam

const OrganizationForm = (props: {
  onSubmit: (values: CreateOrUpdateOrganizationParam) => void
  organization?: CreateOrUpdateOrganizationParam
  justify: any
  isTester: boolean
}) => {
  const { organization, justify, isTester } = props
  const onSubmit = async (
    values: CreateOrUpdateOrganizationGeofencingParam,
    actions: FormikActions<CreateOrUpdateOrganizationGeofencingParam>,
  ) => {
    props.onSubmit(mapGeofencingOrganizationToDb(values))
    actions.setSubmitting(false)
  }
  const initialValues = organization ? mapDbOrganizationToGeofencing(organization) : defaultValues
  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={(values: CreateOrUpdateOrganizationGeofencingParam) => {
        const errors: any = {}
        if (!values.name) {
          errors.name = 'Name is required'
        }
        if (!values.website) {
          errors.website = 'Website is required'
        }
        if (!values.timeZone) {
          errors.timeZone = 'Time zone is required'
        }
        if (!values.address.line1) {
          _.set(errors, ['address', 'line1'], 'Address is required')
        }
        return errors
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <Grid container direction="row" justify={justify}>
            <Grid item sm={6}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="name"
                    placeholder="Name for this organization"
                    label="Name"
                  />
                </Grid>
                <Grid item>
                  <Field
                    fullWidth
                    variant="outlined"
                    component={TextField}
                    name="website"
                    placeholder="Website for this organization"
                    label="Website"
                  />
                </Grid>
                <Grid item>
                  <FormControl variant="outlined" fullWidth error={!!(errors.timeZone && touched.timeZone)}>
                    <Field
                      fullWidth
                      variant="outlined"
                      component={TextField}
                      select={true}
                      label="Time Zone"
                      name="timeZone"
                      inputProps={{
                        id: 'timeZone',
                      }}
                    >
                      <MenuItem value={'America/New_York'}>Eastern Time</MenuItem>
                      <MenuItem value={'America/Chicago'}>Central Time</MenuItem>
                      <MenuItem value={'America/Denver'}>Mountain Time</MenuItem>
                      <MenuItem value={'America/Phoenix'}>Mountain Standard Time</MenuItem>
                      <MenuItem value={'America/Los_Angeles'}>Pacific Time</MenuItem>
                      <MenuItem value={'America/Anchorage'}>Alaska Time</MenuItem>
                      <MenuItem value={'Pacific/Honolulu'}>Hawaii Time</MenuItem>
                    </Field>
                  </FormControl>
                </Grid>
                <Grid item>
                  <Field component={AddressField} name="address" label="Address" />
                </Grid>
                {/* I couldn't figure out how to use 'Field' with 'Switch'*/}
                {isTester && (
                  <Grid item>
                    <FormControlLabel
                      control={
                        <Switch
                          name="isTest"
                          checked={values.isTest}
                          onChange={() => setFieldValue('isTest', !values.isTest)}
                        />
                      }
                      label="Test Organization?"
                    />
                  </Grid>
                )}
                <Grid item container justify="flex-end">
                  <Button size="large" variant="contained" color="secondary" type="submit">
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default OrganizationForm
