import React, { useCallback, useState } from 'react'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import format from 'utils/format'
import useExportStyles from 'export/useExportStyles'

const filename = 'surveys.csv'
const headers = [
  { label: 'Name', key: 'name' },
  { label: 'Created On', key: 'createdAt' },
  { label: 'Created By', key: 'createdBy' },
  { label: 'Last Edited On', key: 'lastEditedOn' },
  { label: 'Last Edited By', key: 'lastEditedBy' },
]

const formatPerson = (person: any) => (person ? `${person.givenName} ${person.familyName}` : null)

const formatData = (questionnaires: any[]) => {
  return questionnaires.map(({ original: { versions, createdAt, createdBy } }) => ({
    name: versions[0]?.questionSet?.name,
    createdAt: format.datetime(createdAt),
    createdBy: formatPerson(createdBy),
    lastEditedOn: format.datetime(versions[0]?.createdAt),
    lastEditedBy: formatPerson(versions[0]?.questionSet?.createdBy),
  }))
}

export const ExportMentorshipSurveysButton = ({ data, disabled }: { data: any[]; disabled?: boolean }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()

  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])

  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin>
      <CSVLink
        style={{
          textDecoration: 'none',
          color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5',
        }}
        className={classes.fill}
        onClick={createFormattedData}
        filename={fileNameWithDate as string}
        headers={headers}
        data={formattedData}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportMentorshipSurveysButton
