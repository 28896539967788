import React from 'react'
import * as _ from 'lodash'

export const QuestionDetails = props => {
  const { question, index, questionByQuestionId } = props
  const {
    questionId,
    question: label,
    questionType,
    options,
    conditionedOnQuestionId,
    conditionedOnAnswer,
    isRequired,
  } = question
  const hasIndex = !_.isNil(index)
  return (
    <div key={questionId}>
      <p>
        <b>
          {hasIndex ? `${index + 1}. ` : ''}
          {label}
          {isRequired ? '*' : ''}
        </b>
      </p>
      <p>
        <i>{questionType}</i>
      </p>
      {!!_.get(options, 'length') && (
        <ul>
          {_.map(options, (option, index) => (
            <li key={index}>{option}</li>
          ))}
        </ul>
      )}
      {conditionedOnQuestionId && questionByQuestionId && (
        <>
          <p>Conditioned on question: '{questionByQuestionId[conditionedOnQuestionId].question}'</p>
          <p>Conditioned on answer: '{conditionedOnAnswer}'</p>
        </>
      )}
    </div>
  )
}
