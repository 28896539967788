import React, { useEffect, useRef } from 'react'
import InputComponent from '../../../../../../components/input/index'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { INIT_VALIDATOR } from '../../../../../../helpers/validation'
import { disablePastingNonNumbers } from '../../../../../../helpers/disablePastingNonNumbers'
import { disableEntryNegativeNumbers } from '../../../../../../helpers/disableEntryNegativeNumbers'

import './index.scss'

const defaultErrorMessages = {
  role: '',
  available: '',
}

const RoleListItem = props => {
  const {
    role,
    onChangeRoleField,
    onRemoveRole,
    enableDelete,
    isClickedToNextStep,
    onChangeValidField,
    timeshifts,
    editMode = false,
    disabled = false
  } = props
  const availableRef = useRef(null)

  //Initializing custom validation function
  const customValidator = INIT_VALIDATOR(defaultErrorMessages)
  const { errorMessages, onChangeErrorMessage, onValidateField } = customValidator
  //Ending initializing

  const handleChangeField = type => value => {
    onChangeRoleField(value, type)
  }

  const checkRoleField = field => {
    return timeshifts.every(shift => shift.roles.every(role => role[field]))
  }

  useEffect(() => {
    if (isClickedToNextStep !== null && isClickedToNextStep) {
      !role.available && onChangeErrorMessage('available', 'The available field is required')
      !role.name && onChangeErrorMessage('role', 'The role field is required')
    }
  }, [isClickedToNextStep])

  useEffect(() => {
    const isHaveNotErrors = !errorMessages.role && !errorMessages.available
    onChangeValidField(checkRoleField('name') && checkRoleField('available') && isHaveNotErrors)
  }, [errorMessages.role, errorMessages.available, timeshifts])

  useEffect(() => {
    ;(!role.name || !role.available) && onChangeValidField(null)
    const availableInput = availableRef.current.getElementsByTagName('input')[0]
    availableInput.addEventListener('keypress', disableEntryNegativeNumbers, false)
    availableInput.addEventListener('paste', disablePastingNonNumbers, false)
  }, [])

  const closeIconClassNames = classNames('shift-close-icon', {
    'shift-close-icon--hidden': !enableDelete,
  })

  return (
    <div className="shift-role-list__item">
      <span className="shift-role-list__item-indicator" />
      <div className="shift-role-list__item-role">
        <InputComponent
          label="Role*"
          value={role.name}
          onChange={handleChangeField('name')}
          onBlur={onValidateField('role', { required: true, maxLength: 255 })}
          hasError={!!errorMessages.role}
          errorMessage={errorMessages.role}
          disabled={disabled}
        />
      </div>
      <div ref={availableRef} className="shift-list-item-row__item-number">
        <InputComponent
          type="number"
          label="# available*"
          value={role.available}
          onChange={handleChangeField('available')}
          onBlur={onValidateField('available', { required: true, minValue: 1 })}
          hasError={!!errorMessages.available}
          errorMessage={errorMessages.available}
        />
      </div>
      <span onClick={onRemoveRole} className={closeIconClassNames} />
    </div>
  )
}

RoleListItem.propTypes = {
  role: PropTypes.object,
  onChangeRoleField: PropTypes.func,
  onRemoveRole: PropTypes.func,
  isClose: PropTypes.bool,
  editMode: PropTypes.bool,
  disabled: PropTypes.bool
}

export default RoleListItem
