import React, { useCallback, useEffect, useMemo } from 'react'
import reduce from 'lodash/reduce'
import AddIcon from '@material-ui/icons/Add'
import useFetchPrograms from 'new-mentorship/hooks/useFetchPrograms'
import {
  Row,
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core'

import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'

import DEFAULT_FILTERS from 'core/table/filters'
import { ExportButton, ExtendedPagedTable, useFiltersFromQuery } from 'core/table/components'
import {
  useConditionalSelectColumn,
  useGetColumnState,
  useTableColumnStyles as useStyles,
} from 'core/table/table-hooks/'
import { OperatorOption, NUMBER_OPERATOR_OPTIONS, operatorTypes } from 'core/table/interfaces/Filters'

import Loading from 'components/Loading'
import format from 'utils/format'

import ProgramFlowEditor from 'new-mentorship/components/ProgramFlowEditor'
import { MentorshipProgramWithStatistics } from 'new-mentorship/types'
import PageHeader from 'App/components/PageHeader'
import useColumns from '../hooks/useMentorshipProgramsColumns'

const csvHeaders = [
  { label: 'Name', key: 'name' },
  { label: 'Description', key: 'description' },
  { label: 'Start', key: 'startsAt' },
  { label: 'End', key: 'endsAt' },
  { label: '# Matches', key: 'matchCount' },
  { label: 'Avg. Meeting Time', key: 'meetingLengthAverage' },
  { label: '% Positive Rating by Mentors', key: 'mentorFeedbackAverage' },
  { label: '% Positive Rating by Mentees', key: 'menteeFeedbackAverage' },
  { label: 'Status', key: 'status' },
]

const getOperatorOptions = (column: string): OperatorOption[] => {
  switch (column) {
    case 'name':
    case 'description':
      return [
        { value: operatorTypes.CONTAINS, displayValue: 'Contains' },
        { value: operatorTypes.EQUALS, displayValue: 'Equals' },
      ]

    case 'matchCount':
    case 'meetingLengthAverage':
    case 'mentorFeedbackAverage':
    case 'menteeFeedbackAverage':
      return NUMBER_OPERATOR_OPTIONS

    case 'status':
      return [{ value: operatorTypes.IS, displayValue: 'Is' }]

    default:
      return []
  }
}

const cards = [
  {
    header: 'Programs',
    accessor: 'programs',
    transform: format.number,
  },
  {
    header: 'Matches',
    accessor: 'matches',
    transform: format.number,
  },
]

const tableName = 'mentorshipPrograms'

const useSummary = (rows: Row<any>[]) =>
  useMemo(() => {
    return {
      programs: rows.length,
      matches: reduce(
        rows,
        (sum: number, { original: { matchCount } }: Row<MentorshipProgramWithStatistics>) => matchCount + sum,
        0,
      ),
    }
  }, [rows])

const NewMentorshipProgramsComponent = ({ location, history }: RouteComponentProps) => {
  const { filters } = useFiltersFromQuery(location.search, getOperatorOptions)
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()
  const columnStyles = useStyles()

  const [fetchPrograms, { loading, result: programs }] = useFetchPrograms()
  useEffect(() => {
    fetchPrograms()
  }, [fetchPrograms])

  const showAddProgram = useShowPrompt(ProgramFlowEditor)

  const columns = useColumns({ showAddProgram, fetchPrograms })

  const table = useTable(
    {
      data: programs,
      columns,
      initialState: {
        // @ts-ignore
        filters,
        // @ts-ignore
        globalFilter: '',
        hiddenColumns: ['description'],
      },
      filterTypes: DEFAULT_FILTERS,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  useEffect(() => {
    fetchColumnState(tableName)
  }, [fetchColumnState])

  const formatData = useCallback(
    (inputData: any[]) =>
      inputData.map(
        ({ original: { meetingLengthAverage, mentorFeedbackAverage, menteeFeedbackAverage, ...row } }: any) => ({
          ...row,
          meetingLengthAverage: meetingLengthAverage ? Math.round(meetingLengthAverage / 60) : '',
          mentorFeedbackAverage: mentorFeedbackAverage ? Math.round(mentorFeedbackAverage * 100) : '',
          menteeFeedbackAverage: menteeFeedbackAverage ? Math.round(menteeFeedbackAverage * 100) : '',
        }),
      ),
    [],
  )

  const summary = useSummary(table.rows)

  if (loading || columnStateLoading) {
    return <Loading />
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        summary={summary}
        cards={cards}
        history={history}
        location={location}
        table={table}
        columns={columns}
        getOperatorOptions={getOperatorOptions}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Programs"
        filterSubHeader="Select program attributes to filter by:"
        buttons={
          <>
            <ExportButton
              data={table.selectedFlatRows}
              formatData={formatData}
              headers={csvHeaders}
              filename={'mentorship-programs.csv'}
            />
          </>
        }
        addButton={
          <ContainedButton
            startIcon={<AddIcon />}
            onClick={() =>
              showAddProgram({
                submit: fetchPrograms,
              })
            }
          >
            Add Program
          </ContainedButton>
        }
        useGlobalSearch
        useFilters
        useDateRange
      />
    </Grid>
  )
}

export const NewMentorshipPrograms = (props: RouteComponentProps) => {
  return (
    <PageHeader title="Programs" subTitle="This is the mentor programs table">
      <NewMentorshipProgramsComponent {...props} />
    </PageHeader>
  )
}

export default NewMentorshipPrograms
