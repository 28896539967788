import { map, pickBy, iteratee } from 'lodash'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Typography, IconButton, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import RefreshIcon from '@material-ui/icons/Refresh'
import { useReloadProductFeatures } from '../hooks'
import { getFeatures } from '../selectors'
import ProductFeatureSwitch from './ProductFeatureSwitch'
import { AdditionalFeatureTools } from './AdditionalFeatureTools'

const useStyles = makeStyles({
  title: {
    marginBottom: '35px',
  },
  list: {
    maxWidth: '600px',
  },
  switch: {
    marginBottom: '15px',
  },
})

const useProductFeatures = () => {
  const features = useSelector(getFeatures)
  return useMemo(() => {
    const productType = iteratee({ type: 'product' })
    return pickBy(features, productType)
  }, [features])
}

export default function ProductFeatureSettings() {
  //TODO move this up into a parent component when there are other settings!
  const productFeatures = useProductFeatures()
  const reloadFeatures = useReloadProductFeatures()

  const classes = useStyles()

  return (
    <div>
      <Typography variant="h4" gutterBottom>
        Product Features
        <IconButton onClick={reloadFeatures}>
          <RefreshIcon />
        </IconButton>
      </Typography>
      <Grid container direction="column" className={classes.list}>
        {map(productFeatures, feature => (
          <Grid container direction="row" justify="flex-start" key={feature.id} className={classes.switch}>
            <Grid item container xs={4} alignContent="center">
              <div>{feature.name}</div>
            </Grid>
            <Grid item container xs={3} alignContent="center">
              <ProductFeatureSwitch feature={feature} />
            </Grid>
            <Grid item container xs={5} alignContent="center">
              <AdditionalFeatureTools feature={feature} />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
