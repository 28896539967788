import { OpportunityResponseWithRecurrenceInfo } from '../../../interfaces/interfaceCreateEditEvent'
import { useMemo } from 'react'
import { RECURRING_TYPE, RECURRING_TYPE_OPTIONS } from '../../../constants/RECURRING_TYPE_OPTIONS'
import moment from 'moment-timezone'

export const useDateStrings = ({
  event,
  loading,
}: {
  event: OpportunityResponseWithRecurrenceInfo
  loading?: boolean
}) =>
  useMemo(() => {
    if (loading || !event || !event.recurrenceInfo) {
      return { recurrenceString: '', opportunityTimeDisplay: '' }
    }
    const options = RECURRING_TYPE_OPTIONS(
      typeof event.startsAt === 'string' ? event.startsAt : event.startsAt.toString(),
    )
    const recurrenceOption = options.find(x => x.value === event.recurrenceInfo.type)
    const isRecurring = event.recurrenceInfo.type !== RECURRING_TYPE.NEVER
    const timeZone = event.recurrenceInfo.timeZone || 'America/New_York'
    const timeRange = `${moment(event.startsAt)
      .tz(timeZone)
      .format('h:mm a')} -
    ${moment(event.endsAt)
      .tz(timeZone)
      .format('h:mm a z')}`
    const instanceStartString = moment(event.startsAt)
      .tz(timeZone)
      .format('dddd, MMMM Do')
    const startString = moment(event.recurrenceInfo.startDate)
      .tz(timeZone)
      .format('MMMM Do')
    const endString = moment(event.recurrenceInfo.endDate)
      .tz(timeZone)
      .format('MMMM Do')
    const recurrenceString = isRecurring
      ? `${recurrenceOption && recurrenceOption.label} from ${startString} until ${endString}`
      : ''
    const opportunityTimeDisplay = `${instanceStartString} at ${timeRange}`
    return { recurrenceString, opportunityTimeDisplay }
  }, [event, loading])
