import React from 'react'
import { Dialog, DialogContent, Grid, Button } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { useAddWhitelistedPhoneNumber } from '../hooks/useAddWhitelistedPhoneNumber'
import { Field, Formik } from 'formik'
import PhoneField from 'civic-champs-shared/formik/components/PhoneField'
import yup from 'civic-champs-shared/utils/yup'

interface Props {
  complete: (added: boolean) => void
  close: () => void
  cancel: () => void
  refresh: () => Promise<void>
  showing: boolean
}

const schema = yup.object({
  phoneNumber: yup
    .string()
    // @ts-ignore
    .phoneNumber()
    .label('Mobile Phone')
    .nullable(),
})

export const AddWhitelistedPhoneNumberPrompt = (props: Props) => {
  const { close, showing, complete, cancel, refresh } = props
  // @ts-ignore
  const [whitelistPhoneNumber] = useAddWhitelistedPhoneNumber()
  const classes = usePromptStyles()
  const handleSubmit = async (values: any) => {
    // @ts-ignore
    await whitelistPhoneNumber(values.phoneNumber)
    await refresh()
    complete(true)
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={classes.contentContainer}>
        <Formik
          initialValues={{ phoneNumber: '' }}
          onSubmit={val => handleSubmit(schema.cast(val))}
          validationSchema={schema}
        >
          {({ dirty, handleSubmit, isSubmitting, isValid }) => {
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2} direction="column">
                  <Grid item>
                    <Field
                      component={PhoneField}
                      name="phoneNumber"
                      label="Whitelisted Phone Number"
                      variant="outlined"
                      fullWidth
                    />
                  </Grid>
                  <Grid item>
                    <Button onClick={cancel} variant="text" disabled={isSubmitting}>
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      color="secondary"
                      disabled={!isValid || !dirty || isSubmitting}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}
