import pickBy from 'lodash/pickBy'
import * as moment from 'moment'
import { useCallback, useEffect } from 'react'
import { useFetch, useRemoteCollection, useRemoteCollectionPointer } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import { mapOpportunities } from 'volunteering/opportunities/helpers/mapOpportunity'

const toUrlDate = datetime => (datetime ? moment(datetime).clone().utc().format('YYYY-MM-DD') : null)

export default function useOpportunitiesCollection(filters) {
  const [request, { loading, error }] = useFetch()
  const [opportunities, operations, events] = useRemoteCollection()
  const [selected, setSelected] = useRemoteCollectionPointer(events)
  const currentOrg = useCurrentOrg()

  const { syncCollection } = operations
  const getOpportunities = useCallback(
    async filters => {
      const { startDate, endDate } = filters
      const queryStringParameters = pickBy({
        reporting: true,
        startDate: toUrlDate(startDate),
        endDate: toUrlDate(endDate),
      })

      const updatedOpportunities = mapOpportunities(
        await request({
          url: `/organizations/${currentOrg.id}/opportunities`,
          config: { queryStringParameters },
        }),
      )

      syncCollection(updatedOpportunities)
    },
    [request, syncCollection, currentOrg.id],
  )

  useEffect(() => {
    getOpportunities(filters)
  }, [getOpportunities, filters])

  return [
    {
      opportunities,
      loading,
      error,
      selected,
    },
    {
      reload: getOpportunities,
      setSelected,
    },
  ]
}
