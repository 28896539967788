import React, { FC, useCallback } from 'react'
import QuestionSetEditorComponent from 'surveys/components/QuestionSetEditor'
import { Loading } from 'civic-champs-shared'
import { useEditQuestionSet, useQuestionSet } from 'civic-champs-shared/question-sets/hooks'
import isUndefined from 'lodash/isUndefined'
import { useCurrentOrg } from 'civic-champs-shared/auth/hooks'
import { useFetchGroupQuestionSets } from 'group/hooks/useFetchGroupQuestionSets'
import { useSetGroupQuestionSets } from 'group/hooks/useSetGroupQuestionSets'
import map from 'lodash/map'
import { GroupSurveyQuestionSetEditorProps } from 'group/pages/GroupSurveyQuestionSetEditor'

export const GroupQuestionnaireEditor: FC<GroupSurveyQuestionSetEditorProps> = ({
  match: {
    url,
    params: { id: groupId, questionnaireId },
  },
}) => {
  const isNew = isUndefined(questionnaireId)
  const backLink = url.substr(0, isNew ? url.length - 4 : url.indexOf(`/${questionnaireId}/edit`))
  const questionSetId = isNew ? undefined : parseInt(questionnaireId as string)
  const [fetchGroupQuestionSets] = useFetchGroupQuestionSets()
  const [setGroupQuestionSets] = useSetGroupQuestionSets()
  const { questionSet, loading: fetchLoading } = useQuestionSet(questionSetId)
  const [editQuestionSet, { loading: editLoading }] = useEditQuestionSet()
  const organization = useCurrentOrg()
  const onSubmit = useCallback(
    async payload => {
      const result = await editQuestionSet({ ...payload, organization, questionSetId })

      if (isNew) {
        // @ts-ignore
        const existingQuestionSets = await fetchGroupQuestionSets(groupId)
        // @ts-ignore
        await setGroupQuestionSets(groupId, [
          ...map(existingQuestionSets, ({ questionSet: { id: questionSetId }, required }) => ({
            questionSetId,
            required,
          })),
          { questionSetId: result.questionSetId, required: true },
        ])
      }
      return result
    },
    [editQuestionSet, fetchGroupQuestionSets, groupId, isNew, organization, questionSetId, setGroupQuestionSets],
  )

  if (!isNew && fetchLoading) {
    return <Loading />
  }

  return (
    <QuestionSetEditorComponent
      isSubmitting={editLoading}
      onSubmit={onSubmit}
      questionSet={!isNew ? questionSet : undefined}
      backLink={backLink}
      backText="Back to Group Questionnaires"
      title={isNew ? 'New Questionnaire' : 'Edit Questionnaire'}
    />
  )
}

export default GroupQuestionnaireEditor
