import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ThemeProvider } from '@material-ui/core/styles'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import CloseIcon from '@material-ui/icons/Close'
import { Checkbox, FormControlLabel, FormGroup, IconButton } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import StyledOutlinedTextField from 'civic-champs-shared/formik/components/StyledOutlinedTextField'
import * as yup from 'yup'
import CKEditorField from 'civic-champs-shared/formik/components/CkEditorField'
// @ts-ignore
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import useMessageRecipients from 'messages/components/MessageRecipientsPrompt.old/useMessageRecipients'
import FailedRecipientsList from 'components/dialog/FailedRecipientsList'
import useMessageOverview, { NotificationBlastContact } from 'messages/hooks/useMessageOverview'
import ExportFailedRecipientsButton from 'messages/components/ExportFailedRecipientsButton'
import { muiTheme } from 'theme'
import Loading from 'civic-champs-shared/core/Loading'
import MessageRecipientSelector from './MessageRecipientSelector'
import { useMessageRecipientsPromptStyles } from 'messages/hooks/useStyles'
import { getTextWidth } from 'utils/getTextWidth'

export interface MessagingContact {
  id: number
  firstName: string
  lastName: string
}

interface MessageRecipientsPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  recipientPersonIds: number[]
  getSearchableContacts: () => Promise<MessagingContact[]>
  autocompleteSectionTitle?: string
}

const initialValues = {
  emailsOnly: false,
  subject: '',
  message: '',
}

const formSchema = yup.object({
  subject: yup.string().min(5).required(),
  message: yup.string().required(),
})

export function MessageRecipientsPrompt(props: MessageRecipientsPromptProps) {
  const { showing, close, recipientPersonIds, getSearchableContacts, autocompleteSectionTitle } = props
  const [contacts, setContacts] = useState<NotificationBlastContact[]>([])
  const [failure, setFailure] = useState(false)
  const [emailsOnly, setEmailsOnly] = useState(false)
  const [fontSize, setFontSize] = useState(12)
  const [messageOverviewLoading, setMessageOverviewLoading] = useState(true)
  const classes = useMessageRecipientsPromptStyles({ failure })
  const [
    fetchMessageOverview,
    {
      result: { smsCredits },
    },
  ] = useMessageOverview()
  const [messageRecipients] = useMessageRecipients()
  useEffect(
    () => {
      fetchMessageOverview({ recipientPersonIds }).then(result => {
        setContacts(result.contacts)
        setMessageOverviewLoading(false)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { emailIds, smsIds, failedRecipients } = useMemo(() => {
    return contacts.reduce(
      (acc, contact) => {
        if (contact.sendToPhone && !emailsOnly) {
          acc.smsIds.push(contact.id)
        } else if (contact.email) {
          acc.emailIds.push(contact.id)
        } else {
          acc.failedRecipients.push(contact)
        }
        return acc
      },
      {
        emailIds: [],
        smsIds: [],
        failedRecipients: [],
      } as { emailIds: number[]; smsIds: number[]; failedRecipients: NotificationBlastContact[] },
    )
  }, [contacts, emailsOnly])

  useEffect(() => {
    async function processFontSize() {
      let size = 12
      const width = await getTextWidth(`Text Messages: ${smsIds.length}`, '700 12px/16px Nunito', 0.5)
      const text = `\u00A0(${smsCredits} SMS Sends Available)`
      while (width + (await getTextWidth(text, `400 ${size}px/16px Nunito`, 0.5)) > 280) {
        size--
      }
      setFontSize(size)
    }
    processFontSize()
  }, [smsCredits, smsIds.length])

  const handleSubmit = useCallback(
    async (values: { subject: string; message: string }) => {
      const { subject, message: body } = values
      await messageRecipients({
        subject,
        body,
        emailPersonIds: emailIds,
        smsPersonIds: smsIds,
      })
      if (failedRecipients?.length) {
        setFailure(true)
      } else {
        close()
      }
    },
    [close, emailIds, failedRecipients, messageRecipients, smsIds],
  )
  if (messageOverviewLoading) {
    return <Loading />
  }

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#message-users-prompt` }}
      aria-labelledby="draggable-dialog-title"
      maxWidth="xs"
      classes={{ paper: classes.dialog }}
      disableEnforceFocus
    >
      <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={handleSubmit}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="message-users-prompt">
              {failure ? 'Failures' : 'New Message'}
              <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
                <CloseIcon className={classes.dialogCloseIcon} />
              </IconButton>
            </DialogTitle>
            <DialogContent classes={{ root: classes.content }}>
              {!failure && (
                <>
                  <div className={classes.recipients}>
                    <p>To:</p>
                    <div className={classes.recipientsCenter}>
                      <div className={classes.recipientsInner}>
                        <MessageRecipientSelector
                          emailsOnly={emailsOnly}
                          contacts={contacts}
                          setContacts={setContacts}
                          getSearchableContacts={getSearchableContacts}
                          autocompleteSectionTitle={autocompleteSectionTitle}
                          fetchMessageOverview={fetchMessageOverview}
                        />
                        <div className={classes.recipientCounters}>
                          <p>Emails: {emailIds.length}</p>
                          <p>
                            Text Messages: {smsIds.length}
                            <span style={{ fontSize: `${fontSize}px` }}>
                              {'\u00A0'}({smsCredits} SMS Sends Available)
                            </span>
                          </p>
                          <p>Failed Messages: {failedRecipients.length}</p>
                        </div>
                      </div>
                      <FormGroup row>
                        <FormControlLabel
                          classes={{ label: classes.sendChannel }}
                          control={
                            <Checkbox color="primary" value={emailsOnly} onChange={() => setEmailsOnly(v => !v)} />
                          }
                          label="Send to Emails Only"
                        />
                      </FormGroup>
                    </div>
                  </div>

                  <Field
                    fullWidth
                    name="subject"
                    label="Subject"
                    className={classes.whiteBg}
                    component={StyledOutlinedTextField}
                  />
                  <div className={classes.editorContainer}>
                    <Field
                      component={CKEditorField}
                      editor={ClassicEditor}
                      fullWidth
                      config={{
                        toolbar: ['Bold', 'Italic', 'NumberedList', 'BulletedList', 'Blockquote', 'Link', 'Unlink'],
                      }}
                      label="Message"
                      name="message"
                    />
                  </div>
                </>
              )}
              {failure && (
                <FailedRecipientsList
                  errorText={
                    'The following recipient(s) did not receive the message because they do not have valid contact information on file. Visit their volunteer profile to add a valid email address or phone number.'
                  }
                  title={'Failed Recipients'}
                  rows={failedRecipients.map(({ firstName, lastName }) => `${firstName} ${lastName}`)}
                  exportButton={<ExportFailedRecipientsButton data={failedRecipients} />}
                />
              )}
            </DialogContent>
            <DialogActions className={classes.actions}>
              {!failure ? (
                <OutlinedButton disabled={isSubmitting || messageOverviewLoading} onClick={close}>
                  Cancel
                </OutlinedButton>
              ) : (
                <div />
              )}
              <ContainedButton
                disabled={isSubmitting || messageOverviewLoading}
                isLoading={isSubmitting}
                onClick={failure ? close : submitForm}
              >
                {failure ? 'Close' : 'Send'}
              </ContainedButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

export default (props: MessageRecipientsPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <MessageRecipientsPrompt {...props} />
  </ThemeProvider>
)
