import moment from 'moment-timezone'
import { get, isEmpty } from 'lodash'
import React, { useState, useMemo, useCallback } from 'react'
import { Grid, Typography, } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Loading from 'components/Loading'
import DataTable from 'components/DataTable'
import TableToolbar from 'components/table/TableToolbarLight';
import ActionMenu from 'components/menu/ActionMenu';
import ExportCsvMenuItem from 'components/table/ExportCsvMenuItem';
import { NumberCell } from 'core/table/cells'
import { useCurrentOrg } from 'auth/hooks'
import { useQuery } from 'civic-champs-shared/api/hooks'
import { usePrompt } from 'civic-champs-shared/core/modal/hooks'
import { GET_ACTIVITIES } from '../queries'
import useMiniSearch from 'utils/useMiniSearch'
import DeleteActivityDialog from './DeleteActivityDialog'
import AddActivityDialog from './AddActivityDialog'
import EditActivityDialog from './EditActivityDialog'
import DateRangeFilter from 'components/filter/DateRangeFilter'
import { useDateRangeFilter } from 'filtering/hooks'
import useActivityExportConfig from '../export'
import ViewActivityReflectionsDialog from './ViewActivityReflectionsDialog'
import Search from 'core/table/components/Search'
import SummaryCards from 'core/table/components/SummaryCards'
import useActivitySummary from '../hooks/useActivitySummary'
import { AddButton } from 'civic-champs-shared/core/add-button'
import useActivityColumns from '../hooks/useActivityColumns'

const useStyles = makeStyles(() => ({
  cardsWrapper: {
    padding: '3em 0 ',
  },
  summary: {
    marginTop: '30px',
    marginBottom: '30px',
  },
}))

const searchConfig = {
  fields: ['user.givenName', 'user.familyName', 'opportunity.name', 'volunteeringRole.name'],
  extractField: get,
  processTerm: (term, field) => term.toLowerCase(),
  searchOptions: {
    processTerm: term => term.toLowerCase(),
    prefix: true,
  },
}

const useAddActivityPrompt = () => {
  const prompt = usePrompt(AddActivityDialog)

  return useCallback(() => prompt.show(), [prompt])
}

function Activities() {
  const classes = useStyles()
  const organization = useCurrentOrg()
  const queryParams = useMemo(() => ({ variables: { organization } }), [organization])
  const { data: activities, loading } = useQuery(GET_ACTIVITIES, queryParams) //TODO add in 'error' and 'refresh'

  const [filter, setFilter] = useDateRangeFilter()
  const filteredActivities = useMemo(() => {
    if (!activities) return []

    if (filter.startDate === undefined || filter.endDate === undefined) return activities

    const filterStart = moment(filter.startDate).tz(organization.timeZone, true)
    const filterEnd = moment(filter.endDate).tz(organization.timeZone, true)

    return activities.filter(a => {
      const start = moment(a.occurredAt).tz(organization.timeZone)
      const end = start.clone().add(a.hoursVolunteered, 'hours')
      return (filterStart.isSameOrBefore(end, 'day') && filterEnd.isSameOrAfter(start, 'day'))
    })
  }, [activities, filter, organization.timeZone])

  const promptForAddActivity = useAddActivityPrompt()
  const viewReflectionsPrompt = usePrompt(ViewActivityReflectionsDialog)
  const search = useMiniSearch(filteredActivities, searchConfig)

  const [query, setQuery] = useState('');
  const filteredData = useMemo(() => search(query), [search, query]);
  const filterKey = useMemo(() => `${filteredData.length}:${query}`, [filteredData, query]);

  //TODO these should probably be their own routes rather than mucking up this component
  const [activityToEdit, setActivityToEdit] = useState(null)
  const [activityToDelete, setActivityToDelete] = useState(null)
  const actions = useMemo(
    () => ({
      onEdit: setActivityToEdit,
      onDelete: setActivityToDelete,
      viewReflectionsPrompt: viewReflectionsPrompt,
    }),
    [setActivityToEdit, setActivityToDelete, viewReflectionsPrompt],
  )

  const columns = useActivityColumns(actions)
  const cards = [
    {
      Header: 'Total Activities',
      accessor: 'count',
      Cell: NumberCell
    },
    {
      Header: 'Average Activity Length',
      accessor: 'averageHours',
      Cell: NumberCell
    },
    {
      Header: 'Total Hours',
      accessor: 'totalHours',
      Cell: NumberCell
    },
  ]

  const summary = useActivitySummary(filteredActivities);
  const exportConfig = useActivityExportConfig(filteredActivities)

  const handleCloseDeleteDialog = useCallback(() => {
    setActivityToDelete(null)
  }, [setActivityToDelete])
  const handleCloseEditDialog = useCallback(() => {
    setActivityToEdit(null)
  }, [setActivityToEdit])

  const onDateFilterChange = dateFilter => {
    setFilter(Object.assign({}, filter, dateFilter))
  }

  return (
    <div className={classes.activityWrapper}>
      <Grid container>
        <Grid container xs={12} item justify="space-between" direction="row">
          <Grid xs={6} container alignItems="center" item>
            <Typography gutterBottom variant="h4">
              Activity Log
            </Typography>
            <Grid item style={{ marginLeft: 10 }}>
              <AddButton onClick={promptForAddActivity} />
            </Grid>
          </Grid>
          <Grid xs={6} container item alignItems="center" justify="flex-end" direction="row">
            <Grid item sm={4}>
              <Search onQueryChange={setQuery} fullWidth variant="outlined" placeholder="Search" />
            </Grid>
            <Grid item style={{ marginLeft: '10px' }}>
              <DateRangeFilter initialValues={filter} onFilterApplied={onDateFilterChange} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!loading || !isEmpty(filteredActivities) ? (
        <>
          <div className={classes.summary}>
            <SummaryCards
              cards={cards}
              summary={summary}
            />
          </div>
          <div>
            <TableToolbar
              query={query}
              onQueryChange={setQuery}
              actionMenu={(
                <ActionMenu>
                  <ExportCsvMenuItem
                    data={filteredData}
                    config={exportConfig}
                  />
                </ActionMenu>
              )}
            />
            <DataTable
              columns={columns}
              filterKey={filterKey}
              data={filteredData}
            />
          </div>
        </>
      ) : (
        <Loading />
      )}
      <DeleteActivityDialog
        key={`delete-${activityToDelete ? activityToDelete.id : 'none'}`}
        open={!!activityToDelete}
        onClose={handleCloseDeleteDialog}
        activity={activityToDelete}
      />
      <EditActivityDialog
        key={`edit-${activityToEdit ? activityToEdit.id : 'none'}`}
        open={!!activityToEdit}
        onClose={handleCloseEditDialog}
        activity={activityToEdit}
      />
    </div>
  )
}

export default Activities
