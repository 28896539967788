import { useCallback } from 'react'
import useFetchRefactored from 'civic-champs-shared/api/hooks/useFetchRefactored'
import { Status } from 'civic-champs-shared/api/hooks/fetchRefactoredSchema'
import { Webhook } from 'integrations/types'

const useGetWebhooks = (): [() => Promise<Webhook[]>, Status<Webhook[]>] => {
  const [request, status] = useFetchRefactored<Webhook[]>({
    errorMessage: 'Error Fetching Webhooks.  Please refresh the page and try again',
    emptyValue: [],
  })

  const fetchWebhooks = useCallback(() => {
    return request({
      method: 'get',
      url: `/integrations/v1/stats/webhooks`,
    })
  }, [request])

  return [fetchWebhooks, status]
}

export default useGetWebhooks
