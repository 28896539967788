import { isString, isEmpty, upperFirst } from 'lodash'
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CircularProgress from '@material-ui/core/CircularProgress'
import cn from 'classnames'
import { Formik } from 'formik'
import * as yup from 'yup'
import moment from 'moment'
import useStyles from './useStyles'
import { useSignUpVolunteer } from '../hooks/useSignUpVolunteer'
import { RequirementsModal } from 'civic-champs-shared/question-sets/components'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'

const formSchema = yup.object({
  givenName: yup
    .string()
    .label('first name')
    .required(),
  familyName: yup
    .string()
    .label('last name')
    .required(),
  email: yup.string().email(),
  phoneNumber: yup.string().matches(/\d{3}-?\d{3}-?\d{4}/, 'phone number is invalid'),
  homePhoneNumber: yup.string().matches(/\d{3}-?\d{3}-?\d{4}/, 'phone number is invalid'),
  birthday: yup
    .string()
    .test('is-valid-date', '${path} must be MM/DD/YYYY', value => {  // eslint-disable-line no-template-curly-in-string
      return moment(value, 'MM/DD/YYYY', true).isValid()
    })
    .test('is-valid-age', 'Age must be 13 or older', value => {
      return moment().diff(moment(value), 'years') >= 13
    })
    .required(),
})

const trim = value => (isString(value) ? value.trim() : value)

export default function KioskSignUp({
  organizationName,
  organizationId,
  submitting,
  error,
  setSignUpError,
  signUpVolunteerStarted,
  signUpVolunteerSucceeded,
  signUpVolunteerFailed,
  encodedOccurrence,
  checkIn,
  history
}) {
  const classes = useStyles()
  const [signUp] = useSignUpVolunteer()
  const signUpVolunteer = async signUpData => {
    let { givenName, familyName, phoneNumber, email, homePhoneNumber } = signUpData
    return signUp(organizationId, {
      givenName,
      familyName,
      email,
      phoneNumber,
      homePhoneNumber,
    })
  }
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const handleRequirements = async (user) => {
    try {
      const done = await fulfillRequirementsPrompt({
        person: user,
        filters: {
          personId: user.id,
          associatedEntity: { encodedOccurrence },
          action: PrerequisitesAction.EVENT_REGISTRATION,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        isAdmin: false
      })
      return done
    } catch (err) {
      // TODO: confirm we we want to still continue https://civicchamps.atlassian.net/browse/CCA-1369
      return true
    }
  }

  const handleFormikSubmit = async (values, { setSubmitting }) => {
    let email = !isEmpty(values.email) ? values.email : null
    let phoneNumber = !isEmpty(values.phoneNumber) ? '+1' + (values.phoneNumber || '').replace(/[^\d]/g, '') : null
    let homePhoneNumber = !isEmpty(values.homePhoneNumber) ? '+1' + (values.homePhoneNumber || '').replace(/[^\d]/g, '') : null

    //TODO this is a hack!
    if (email == null && phoneNumber == null) {
      setSignUpError('At least one form of contact (email or phone) is required')
      setSubmitting(false)
      return
    }

    let formValues = {
      givenName: trim(values.givenName),
      familyName: trim(values.familyName),
      email: trim(email),
      homePhoneNumber: trim(homePhoneNumber),
      phoneNumber: trim(phoneNumber),
      birthday: moment(values.birthday || '', 'MM/DD/YYYY').format('YYYY-MM-DD'),
    }
    try {
      signUpVolunteerStarted()
      const newVolunteer = await signUpVolunteer(formValues)
      const { user } = newVolunteer
      signUpVolunteerSucceeded(newVolunteer)
      if (await handleRequirements(user)) {
        checkIn(newVolunteer)
      }
      setSubmitting(false)
    } catch (error) {
      signUpVolunteerFailed(error)
    }
    history.push('/kiosk')
  }
  return (
    <>
      <Grid container justify="flex-end">
        <Link to="/kiosk" className={classes.actionButton}>
          Check in
        </Link>
      </Grid>
      <Container maxWidth="md">
        <CssBaseline />
        <div className={classes.paper}>
          <Typography variant="h4" gutterBottom>
            {organizationName}
          </Typography>
          {error && (
            <Typography display="block" color="error">
              {error}
            </Typography>
          )}
          <Formik
            initialValues={{
              givenName: '',
              familyName: '',
              email: '',
              phoneNumber: '',
              homePhoneNumber: '',
              birthday: '',
            }}
            validationSchema={formSchema}
            onSubmit={handleFormikSubmit}
          >
            {({ values, submitCount, errors, handleChange, handleBlur, handleSubmit, setFieldValue }) => {
              const getError = field => submitCount > 0 && !!errors[field]
              const getErrorText = field => (getError(field) ? errors[field] : undefined)
              const formatAsNameOnBlur = field => event => {
                setFieldValue(field, upperFirst(trim(values[field])))
                handleBlur(event)
              }

              return (
                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                  <Container maxWidth="md" className={classes.formWrapper}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="firstName"
                          name="givenName"
                          label="First Name"
                          variant="outlined"
                          value={values.givenName}
                          onChange={handleChange}
                          onBlur={formatAsNameOnBlur('givenName')}
                          error={getError('givenName')}
                          helperText={getErrorText('givenName')}
                          autoCapitalize="none"
                          disabled={submitting}
                          autoComplete="fname"
                          required
                          fullWidth
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="lastName"
                          name="familyName"
                          label="Last Name"
                          variant="outlined"
                          value={values.familyName}
                          onChange={handleChange}
                          onBlur={formatAsNameOnBlur('familyName')}
                          error={getError('familyName')}
                          helperText={getErrorText('familyName')}
                          disabled={submitting}
                          autoCapitalize="none"
                          autoComplete="lname"
                          required
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          name="email"
                          label="Email"
                          variant="outlined"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getError('email')}
                          helperText={getErrorText('email')}
                          disabled={submitting}
                          autoComplete="email"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phoneNumber"
                          name="phoneNumber"
                          label="Mobile"
                          variant="outlined"
                          value={values.phoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getError('phoneNumber')}
                          helperText={getErrorText('phoneNumber')}
                          disabled={submitting}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="homePhoneNumber"
                          name="homePhoneNumber"
                          label="Home"
                          variant="outlined"
                          value={values.homePhoneNumber}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getError('homePhoneNumber')}
                          helperText={getErrorText('homePhoneNumber')}
                          disabled={submitting}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="Birthday"
                          name="birthday"
                          label="Birthday"
                          variant="outlined"
                          placeholder={'MM/DD/YYYY'}
                          value={values.birthday}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          error={getError('birthday')}
                          helperText={getErrorText('birthday')}
                          disabled={submitting}
                          required
                          fullWidth
                        />
                      </Grid>
                    </Grid>
                  </Container>
                  <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={submitting}
                    className={cn(classes.checkIn, classes.checkInColor)}
                  >
                    {submitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                    Check in
                  </Button>
                </form>
              )
            }}
          </Formik>
        </div>
      </Container>
    </>
  )
}
