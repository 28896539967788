import { useCallback } from "react"

import { useFetch } from 'civic-champs-shared/api/hooks'
import { CreateOrUpdateCredentialParam, CredentialType } from 'credential/models'
import { useCurrentOrg } from 'auth/hooks'

const useCreateOrUpdateCredential = () => {
  const [request] = useFetch()
  const currentOrganization = useCurrentOrg()

  return useCallback(
    async (values: CreateOrUpdateCredentialParam, credentialId?: number, isDraft?: boolean) => {
      const isUpdate = !!credentialId
      const body = {
        isPublished: !isDraft,
        type: CredentialType.Waiver,
        title: values.title,
        description: values.description,
        url: values.url,
        content: values.content,
        waiverStatement: values.waiverStatement,
        organizationId: currentOrganization.id,
        contentType: values.contentType,
        expirationOption: values.expirationOption,
        expirationOptionLength: values.expirationOptionLength,
        expirationDate: values.expirationDate,
      }

      // @ts-ignore
      return request({
        method: isUpdate ? 'put' : 'post',
        url: isUpdate ? `/credentials/${credentialId}` : `/credentials`,
        config: {
          headers: { 'X-Api-Version': '2020-01-17' },
          body: body,
        },
      })
    },
    [request, currentOrganization],
  )
}

export default useCreateOrUpdateCredential
