import React from 'react'
import { Dialog, DialogContent, Grid, Button, Typography, DialogActions } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { OrgNamedRef, PersonRef } from 'civic-champs-shared/question-sets/types'
import format from 'utils/format'
import useDeleteBadContacts from '../hooks/useDeleteBadContacts'

export interface BadContact {
  id: number
  createdAt: Date | string
  deletedAt?: Date | string
  organization: OrgNamedRef
  // NOTE: that person might have changed their contact in the meantime - it was the recipient's contact at the time of sending
  sentTo: PersonRef
  // this is who currently has the email or phone number, if anybody
  currentOwner?: PersonRef
  email?: string
  phoneNumber?: string
  reason: string
  emailEventId?: number
  smsEventId?: number
  count?: number
}

interface MessageRecipientsPromptProps {
  badContact: BadContact
  complete: (cleared: boolean) => void
  close: () => void
  cancel: () => void
  refresh: () => Promise<void>
  showing: boolean
}

export const ClearBadContactsPrompt = (props: MessageRecipientsPromptProps) => {
  const { badContact, close, showing, complete, cancel, refresh } = props
  // @ts-ignore
  const [deleteBadContacts, { loading }] = useDeleteBadContacts()
  const { sentTo, currentOwner, phoneNumber, email, count } = badContact
  const classes = usePromptStyles()
  const submit = async () => {
    // @ts-ignore
    await deleteBadContacts(badContact)
    await refresh()
    complete(true)
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={classes.contentContainer}>
        <div className={classes.dialog}>
          <Grid container direction="column">
            <Grid>
              <Typography variant="h5">Do you want to unblock the contact?</Typography>
            </Grid>
            <Grid>
              <div>
                <b>Contact:</b> {email ? email : format.phoneNumber(phoneNumber)}
              </div>
            </Grid>
            <Grid>
              <div>
                <b>Sent To:</b> {sentTo.givenName} {sentTo.familyName}
              </div>
            </Grid>
            {currentOwner && (
              <Grid>
                <div>
                  <b>Current Owner:</b> {currentOwner.givenName} {currentOwner.familyName}
                </div>
              </Grid>
            )}
            {count && (
              <Grid>
                <div>
                  <b>Bounce count:</b> {count}
                </div>
              </Grid>
            )}
            {count && (
              <Grid>
                <div>
                  <b>Status:</b> {count > 1 ? 'BLOCKED' : 'Still active'}
                </div>
              </Grid>
            )}
          </Grid>
        </div>
      </DialogContent>
      <DialogActions className={classes.actionContainer}>
        <Button variant="text" color="primary" onClick={cancel} disabled={loading} className={`${classes.actionButton} ${classes.orangeCancel}`}>
          Cancel
        </Button>
        <Button variant="contained" color="secondary" onClick={submit} disabled={loading} className={`${classes.actionButton} ${classes.orangeButton}`}>
          UNBLOCK CONTACT
        </Button>
      </DialogActions>
    </Dialog>
  )
}
