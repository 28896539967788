import { getSatisfactionExplanation } from './satisfactions'
import { groupsFilter, firstLastFilter } from 'core/table/filters'

export const ROLES = 'roles'
export const REACTIONS = 'reactions'
export const GROUPS = 'groups'
export const FIRST_LAST_NAME = 'customFirstLastName'

const anyOf = (rowValue: string, operand: string[]): boolean => operand.includes(rowValue)
const notAnyOf = (rowValue: string, operand: string[]): boolean => !operand.includes(rowValue)

const operatorToMethodMap = {
  anyOf,
  notAnyOf,
}

const oneToManyRelationFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rowValue = row.values[id]
      return filterMethod(rowValue, filterValue.operand)
    })
  })
}

const reactionsFilter = (rows: any[], ids: any[], filterValue: { operand: string[]; operator: string }) => {
  // @ts-ignore
  const filterMethod = operatorToMethodMap[filterValue.operator]
  // If filter is not define we do not do any filtering and just return all rows to avoid exceptions
  if (typeof filterMethod !== 'function') {
    return rows
  }

  return rows.filter(row => {
    return ids.some(id => {
      const rating = row.values[id]
      const text = rating ? getSatisfactionExplanation(Number(rating)) : ''
      return filterMethod(text, filterValue.operand)
    })
  })
}

export const CUSTOM_FILTERS = {
  [ROLES]: oneToManyRelationFilter,
  [GROUPS]: groupsFilter,
  [REACTIONS]: reactionsFilter,
  [FIRST_LAST_NAME]: firstLastFilter,
}

export default CUSTOM_FILTERS
