import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from 'civic-champs-shared/api/hooks'

export default function useProfile({ id, onSuccess, organizationId }) {
  const [request, { loading, error, result, called }] = useFetch(
    'Error fetching profile. Please refresh the page to try again',
  )
  const getProfile = useCallback(
    id => {
      return request({
        method: 'get',
        url: organizationId ? `/organizations/${organizationId}/champions/${id}` : `/champions/${id}`,
        onSuccess,
      })
    },
    [request, onSuccess, organizationId],
  )

  useEffect(() => {
    id && getProfile(id)
  }, [id, getProfile])

  return useMemo(
    () => ({
      person: result,
      loading: loading || !called,
      error,
      getProfile,
    }),
    [result, loading, called, error, getProfile],
  )
}
