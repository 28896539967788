import React, { useState, useCallback } from 'react'
import { Dialog, DialogContent, DialogActions, Button } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import '../style.scss'
import CheckboxComponent from '../../../../../../Mentorship/kit/components/checkbox'
import * as _ from 'lodash'
import { PersonRef } from 'civic-champs-shared/question-sets/types'
import usePut from 'civic-champs-shared/api/hooks/usePut'

export interface OrgNamedRef {
  id: number
  name: string
  timeZone: string
  website?: string
}

export interface RegistrationTimeshiftRoleDetailResponse {
  id: number
  role: {
    id: number
    name: string
  }
  timeshift: {
    id: number
    name: string
    time_start: string
    time_end: string
  }
}

export interface RegistrationResponse {
  id: number
  user: PersonRef
  opportunity: any
  organization: OrgNamedRef
  date: Date
  answers: any
  created_at: Date
  details: RegistrationTimeshiftRoleDetailResponse[]
}

interface Props {
  showing: boolean
  cancel: any
  complete: any
  registration: RegistrationResponse
}

const isMatchingShift = (
  shift1: RegistrationTimeshiftRoleDetailResponse,
  shift2: RegistrationTimeshiftRoleDetailResponse,
) => shift1.role.id === shift2.role.id && shift1.timeshift.id === shift2.timeshift.id

const RemoveShiftsPrompt = (props: Props) => {
  const { showing, complete, cancel, registration } = props
  const [detailsToDelete, setDetailsToDelete] = useState<RegistrationTimeshiftRoleDetailResponse[]>([])
  const [removeShift, { loading }] = usePut<void, { shiftsToDelete: number[] }>({
    successMessage: 'Successfully updated registration',
    configMapper: (toDelArray) => ({
      url: `/registration/${registration.id}`,
      onSuccess: complete,
      config: { body: { shiftsToDelete: _.map(toDelArray, 'id') } },
    }),
  })
  const promptClasses = usePromptStyles()
  const handleSubmit = useCallback(() => removeShift(detailsToDelete), [registration, detailsToDelete])

  const isChecked = (shift: RegistrationTimeshiftRoleDetailResponse) =>
    detailsToDelete.some(detail => isMatchingShift(detail, shift))
  const onChange = (shift: RegistrationTimeshiftRoleDetailResponse, checked: boolean) => {
    if (checked) {
      const updatedDetails = _.cloneDeep(detailsToDelete)
      const idx = updatedDetails.findIndex(detail => isMatchingShift(detail, shift))
      updatedDetails.splice(idx, 1)
      setDetailsToDelete(updatedDetails)
    } else {
      setDetailsToDelete([...detailsToDelete, shift])
    }
  }

  return (
    <Dialog open={showing}>
      <DialogContent>
        <h3>Please select the volunteer's shifts you would like to remove:</h3>
        <p>The volunteer will receive an update about the changes</p>
        {registration.details.map((shift: RegistrationTimeshiftRoleDetailResponse, index: number) => {
          const checked = isChecked(shift)
          return (
            <CheckboxComponent
              onChange={() => onChange(shift, checked)}
              label={`${shift.timeshift.time_start}-${shift.timeshift.time_end} - ${shift.role.name}`}
              checked={checked}
              key={shift.id}
            />
          )
        })}
      </DialogContent>
      <DialogActions className={promptClasses.actionContainer}>
        <Button
          variant="text"
          color="primary"
          className={`${promptClasses.actionButton} ${promptClasses.orangeCancel}`}
          disabled={loading}
          onClick={cancel}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          className={`${promptClasses.actionButton} ${promptClasses.orangeButton}`}
          disabled={!detailsToDelete.length || loading}
          onClick={handleSubmit}
        >
          Remove Shifts
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemoveShiftsPrompt
