import React, { useCallback, useState } from 'react'
import format from 'utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import useExportStyles from 'export/useExportStyles'

const filename = 'champions.csv'
const headers = [
  { label: 'First Name', key: 'user.givenName' },
  { label: 'Last Name', key: 'user.familyName' },
  { label: 'Email', key: 'user.email' },
  { label: 'Mobile Phone', key: 'user.phoneNumber' },
  { label: 'Home Phone', key: 'user.homePhoneNumber' },
  { label: 'Hours', key: 'hours' },
  { label: 'Donations', key: 'donations' },
  { label: 'Last Activity', key: 'latestActivity' },
  { label: 'Groups', key: 'groups' },
  { label: 'Address', key: 'user.address' },
  { label: 'Emergency Contact First Name', key: 'user.emergencyContact.givenName' },
  { label: 'Emergency Contact Last Name', key: 'user.emergencyContact.familyName' },
  { label: 'Emergency Contact Relationship', key: 'user.emergencyContactRelationship' },
  { label: 'Emergency Contact Mobile Phone', key: 'user.emergencyContact.phoneNumber' },
  { label: 'Emergency Contact Work Phone', key: 'user.emergencyContact.workPhoneNumber' },
  { label: 'Emergency Contact Home Phone', key: 'user.emergencyContact.homePhoneNumber' },
  { label: 'Emergency Contact Email Address', key: 'user.emergencyContact.email' },
]

const formatPhones = (phones: string[], obj: any) =>
  phones.reduce((acc: any, phone: string) => ({ ...acc, [phone]: format.phoneNumber(obj?.[phone]) }), {})

const formatData = (champions: any[]) => {
  return champions.map(({ original: { user, volunteering, groups, donation } }) => {
    return {
      user: {
        ...user,
        ...formatPhones(['phoneNumber', 'homePhoneNumber'], user),
        address: format.address(user.address),
        emergencyContact: {
          ...user.emergencyContact,
          ...formatPhones(['phoneNumber', 'homePhoneNumber', 'workPhoneNumber'], user.emergencyContact),
        },
      },
      hours: format.fixedPoint(volunteering.totalVolunteerHours, 1),
      donations: format.fixedPoint(donation.totalDonations / 100, 2),
      latestActivity: volunteering.latestActivity,
      groups,
    }
  })
}
const ExportChampionsButton = ({
  data,
  disabled,
  includeDonations,
}: {
  data: any[]
  disabled?: boolean
  includeDonations?: boolean | null
}) => {
  const filteredHeaders = includeDonations ? headers : headers.filter(header => header.key !== 'donations')
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin onClick={createFormattedData}>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        onClick={createFormattedData}
        className={classes.fill}
        filename={fileNameWithDate as string}
        headers={filteredHeaders}
        data={formattedData}
      />
      Export
    </TableMenuButton>
  )
}

export default ExportChampionsButton
