import React from 'react'
import { Box, Checkbox } from '@material-ui/core'
import { muiTheme } from 'legacy-theme'
import { ThemeProvider } from '@material-ui/styles'

export const SelectColumnHeader = ({ getToggleAllRowsSelectedProps }) => (
  <ThemeProvider theme={muiTheme}>
    <Checkbox {...getToggleAllRowsSelectedProps()} />{' '}
  </ThemeProvider>
)

export const SelectCell = ({ row }) => (
  <ThemeProvider theme={muiTheme}>
    <Checkbox {...row.getToggleRowSelectedProps()} />
  </ThemeProvider>
)

export function useSelectColumn(hooks) {
  useConditionalSelectColumn(() => true)(hooks)
}

export const selectionColumnId = '@selection'

export const useConditionalSelectColumn = (predicate, className) => hooks => {
  const selectColumn = {
    id: selectionColumnId,
    Header: SelectColumnHeader,
    Cell: ({ row }) => {
      const isEnabled = predicate(row)

      return isEnabled ? <SelectCell row={row} /> : <Box width={42} height={42} />
    },
    className,
  }

  hooks.visibleColumns.push(columns => [selectColumn, ...columns])
}

export default useSelectColumn
