import { isEmpty, isNil, omitBy } from 'lodash'
import { useCallback, useMemo } from 'react'

import { useFetch } from '../../api/hooks'
import { useCurrentOrg } from '../../auth/hooks'

const STABLE_EMPTY_ARRAY = []

const queryParams = params => {
  const newParams = omitBy(params, isNil)
  return isEmpty(newParams) ? undefined : newParams
}

export const useFetchQuestionSets = (
  isGlobalUserProfile = false,
  isQuestionnaireAsSurveyEnabled = false,
  includeQuestions = false,
) => {
  const [request, { loading, called, error, result }] = useFetch(
    'Error Fetching Questionnaires.  Please refresh the page and try again',
  )
  const currentOrg = useCurrentOrg()

  const fetchQuestionSets = useCallback(
    async ({ questionSetIds, surveyIds, onResultUpdate } = {}) => {
      return request({
        onSuccess: result => {
          onResultUpdate && onResultUpdate(result)
        },
        method: 'get',
        url: isGlobalUserProfile
          ? `/${isQuestionnaireAsSurveyEnabled ? 'user-profile-survey' : 'user-profile-question-set'}`
          : `/organizations/${currentOrg.id}/${
              isQuestionnaireAsSurveyEnabled ? 'survey-questionnaires' : 'question-set'
            }`,
        config: {
          queryStringParameters: queryParams({ surveyIds, questionSetIds, includeQuestions }),
        },
      })
    },
    [request, isGlobalUserProfile, isQuestionnaireAsSurveyEnabled, currentOrg.id, includeQuestions],
  )

  return useMemo(
    () => [
      fetchQuestionSets,
      {
        loading,
        called,
        error,
        result: result || STABLE_EMPTY_ARRAY,
      },
    ],
    [fetchQuestionSets, loading, called, error, result],
  )
}
