import { OrgNamedRef } from 'civic-champs-shared/common/types'

export enum GroupTypeCode {
  Volunteer = 'volunteer',
  Member = 'member',
  UserDefined = 'user-defined',
  TaskRequester = 'task-requester',
  TaskRecipient = 'task-recipient',
  TaskVolunteer = 'task-volunteer',
  Mentee = 'mentee',
  Mentor = 'mentor',
  Donors = 'donors',
  Admin = 'admin',
  Driver = 'driver',
  Rider = 'rider',
}

export const mentorshipGroups = [GroupTypeCode.Mentor, GroupTypeCode.Mentee]
export const helpingHandsGroups = [
  GroupTypeCode.TaskRequester,
  GroupTypeCode.TaskRecipient,
  GroupTypeCode.TaskVolunteer,
]
export const donorGroups = [GroupTypeCode.Donors]

export const tripPlannerGroups = [GroupTypeCode.Driver, GroupTypeCode.Rider]

export interface GroupType {
  id: number
  code: GroupTypeCode
}

export enum PermissionOptions {
  PERMANENTLY_CLOSED = 'permanently-closed',
  PERMANENTLY_OPEN = 'permanently-open',
  EDITABLE = 'editable',
}

export interface Group {
  id: number
  organization: OrgNamedRef
  groupType: GroupType
  name: string
  closed: boolean
  permissionOptions: PermissionOptions
  createdAt: Date
  deletedAt?: Date
}
