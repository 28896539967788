import {
  useColumnOrder,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useConditionalSelectColumn } from 'core/table/table-hooks/useSelectColumn'
import React, { useEffect } from 'react'
import { RouteComponentProps } from 'react-router'
import { Grid } from '@material-ui/core/'
import { Add as AddIcon, MergeType as MergeTypeIcon, DragHandle as DragHandleIcon } from '@material-ui/icons'

import { ContainedButton, TableMenuButton } from 'civic-champs-shared/core'

import DEFAULT_FILTERS from 'core/table/filters'
import ExportRolesButton from '../components/ExportRolesButton'
import { ExtendedPagedTable } from 'core/table/components/ExtendedPagedTable'
import useGetColumnState from 'core/table/table-hooks/useGetColumnState'

import Loading from 'components/Loading'
import { useRolesCollection, useRolesColumns, useStyles } from '../hooks'
import PageHeader from 'App/components/PageHeader'

const tableName = 'roles'

export const RolesComponent = ({ location, history }: RouteComponentProps) => {
  const [{ roles, initiallyLoaded }, { addRole, editRole, deleteRole, reorderRoles, mergeRoles }] =
    useRolesCollection() as any
  const columns = useRolesColumns({ editRole, deleteRole })
  const columnStyles = useStyles()
  const [fetchColumnState, { loading: columnStateLoading, result: columnState }] = useGetColumnState()

  useEffect(() => {
    fetchColumnState(tableName)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const table = useTable(
    {
      initialState: {
        hiddenColumns: [],
        // @ts-ignore
        globalFilter: '',
      },
      filterTypes: DEFAULT_FILTERS,
      data: roles,
      columns,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    useColumnOrder,
    useConditionalSelectColumn(() => true, columnStyles.selectColumn),
  )

  if (!initiallyLoaded || columnStateLoading) {
    return <Loading />
  }

  const selectedRows = table.selectedFlatRows
  const disabled = !selectedRows.length

  const onReorderRoles = async () => {
    await reorderRoles(table)
    table.toggleAllRowsSelected(false)
  }

  const onMergeRoles = async () => {
    await mergeRoles(selectedRows.map((row: any) => row.original))
    table.toggleAllRowsSelected(false)
  }

  return (
    <Grid container>
      <ExtendedPagedTable
        history={history}
        location={location}
        table={table}
        columns={columns}
        columnState={columnState}
        tableName={tableName}
        searchPlaceholder="Search Roles"
        buttons={
          <>
            <TableMenuButton startIcon={<DragHandleIcon />} onClick={onReorderRoles} rightMargin>
              Reorder
            </TableMenuButton>
            <TableMenuButton
              startIcon={<MergeTypeIcon />}
              onClick={onMergeRoles}
              disabled={selectedRows.length <= 1}
              rightMargin
            >
              Merge
            </TableMenuButton>

            <ExportRolesButton data={selectedRows} disabled={disabled} />
          </>
        }
        addButton={
          <ContainedButton startIcon={<AddIcon />} onClick={() => addRole()}>
            Add Role
          </ContainedButton>
        }
        useGlobalSearch
      />
    </Grid>
  )
}

export const Roles = (props: RouteComponentProps) => (
  <PageHeader title="Roles" subTitle="Create and manage roles for volunteer activities">
    <RolesComponent {...props} />
  </PageHeader>
)
