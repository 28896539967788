import { useCallback, useEffect, useState } from 'react'
import { useRemoteCollection } from 'civic-champs-shared/api/hooks'
import {
  useFetchRoles,
  useAddEditRolePrompt,
  useRemoveRolePrompt,
  useReorderRolesPrompt,
  useMergeRolesPrompt,
} from './index'

export const useRolesCollection = () => {
  const { fetchRoles, loading, error } = useFetchRoles()
  const [roles, operations] = useRemoteCollection()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  const { syncCollection, eagerAdd, eagerReplace, eagerRemove } = operations
  const getRoles = useCallback(async () => {
    const updatedRoles = await fetchRoles({ reporting: true })

    syncCollection(updatedRoles)
    setInitiallyLoaded(true)
  }, [fetchRoles, syncCollection])

  useEffect(() => {
    getRoles()
  }, [getRoles])

  const addRole = useAddEditRolePrompt({ eagerAdd })
  const editRole = useAddEditRolePrompt({ eagerReplace })
  const deleteRole = useRemoveRolePrompt(eagerRemove)
  const reorderRoles = useReorderRolesPrompt(getRoles)
  const mergeRoles = useMergeRolesPrompt(getRoles)

  return [
    {
      roles,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addRole,
      editRole,
      deleteRole,
      reorderRoles,
      mergeRoles,
    },
  ]
}

export default useRolesCollection
