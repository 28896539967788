import React, { useMemo } from 'react'
import moment from 'moment'

import EventCalendar from './EventCalendar'
import Loading from 'components/Loading'
import useEventsCollection from './hooks/useEventsCollection'
import { CalendarNavigationBar } from './CalendarNavigationBar'
import { useCalendarPeriod } from './hooks/useCalendarPeriod'
import { viewTypes } from './viewTypes'

export const MonthView = () => {
  const {
    firstOfMonth,
    setFirstOfMonth,
    gotoCurrentMonth,
    gotoPrevMonth,
    gotoNextMonth,
  } = useCalendarPeriod(viewTypes.MONTH)

  const { date, month, year, lastOfMonth } = useMemo(() => {
    return {
      date: firstOfMonth.date(),
      month: firstOfMonth.month(),
      year: firstOfMonth.year(),
      lastOfMonth: firstOfMonth.clone().endOf('month'),
    }
  }, [firstOfMonth])

  const handleChangeMonth = (newValue: any) => setFirstOfMonth( moment(newValue).startOf('month') )

  const [{ events, loading }] = useEventsCollection({
    startDate: firstOfMonth.clone().startOf('week').subtract(1, 'day').format('MM-DD-YYYY'),
    endDate: lastOfMonth.clone().endOf('week').add(1, 'day').format('MM-DD-YYYY')
  })

  return (
    <div>
      <CalendarNavigationBar
        date={firstOfMonth}
        onTodayClick={gotoCurrentMonth}
        onNextClick={gotoNextMonth}
        onPrevClick={gotoPrevMonth}
        onMonthChange={handleChangeMonth}
      />

      {
        loading ?
          <Loading />
          :
          <EventCalendar
            date={date}
            month={month}
            year={year}
            // @ts-ignore
            events={events}
            viewType={viewTypes.MONTH}
          />
      }
    </div>
  )
}
