import { OpportunityVisibility } from './index'
import { NamedRef, OrgNamedRef } from 'civic-champs-shared/common/types'
import { JoinQuestionSetPayload, JoinSurveyPayload } from 'civic-champs-shared/question-sets/types'
import { RECURRING_TYPE } from '../constants/RECURRING_TYPE_OPTIONS'
import { Geofencing } from 'civic-champs-shared/core/location/utils'

export type EventFormKeys =
  | 'name'
  | 'startsAt'
  | 'endsAt'
  | 'recurring_type'
  | 'is_recurring'
  | 'dates'
  | 'description'
  | 'instruction'
  | 'contact_name'
  | 'contact_email'
  | 'contact_phone_number'
  | 'address'
  | 'city'
  | 'state'
  | 'zip'
  | 'geofencing'
  | 'timeshifts'
  | 'questionSets'

export enum AssociationType {
  EVENT_PRIVATE_TO_MEMBERS = 'event-private-to-members',
  ADD_PARTICIPANTS_TO_GROUP = 'add-participants-to-group',
}

export interface OpportunityOccurrencePersonGroupPayloadLessOccurrenceId {
  groupId: number
  associationType: AssociationType
  approvedMembersOnly?: boolean
  lockedTag?: boolean
}

export interface OpportunityOccurrencePersonGroup {
  id: number
  organization: OrgNamedRef
  occurrence: NamedRef
  group: {
    id: number
    name: string
    closed: boolean
  }
  associationType: AssociationType
  approvedMembersOnly: boolean
  createdAt: Date
  deletedAt?: Date
}

export interface EventGroup {
  groupId: number
  name: string
  associationType: AssociationType
  approvedMembersOnly: boolean
  closed?: boolean
  lockedTag?: boolean
}

export interface TimeshiftsRoles {
  name: string
  available: number | null
}

export interface TimeshiftsBase {
  time_start: string
  time_end: string
  name: string
  roles: TimeshiftsRoles[]
}

export interface TimeshiftsEditEvent extends TimeshiftsBase {
  is_editable: boolean
  roles: TimeshiftsRolesEditEvent[]
}

export interface TimeshiftsRolesEditEvent extends TimeshiftsRoles {
  is_editable: boolean
  all: number | null
}

export interface RecurrenceInfo {
  type: RECURRING_TYPE
  startDate: any
  endDate: any
  timeZone: string
  customDates?: any[]
  exceptions?: Date[]
  pattern?: string
}

interface BaseEventFields {
  id?: string | number
  name: string
  startsAt: string
  endsAt: string
  recurring_type: number
  is_recurring: boolean
  dates: string[]
  description: string
  instructions: string
  contact_name: string
  contact_email: string
  contact_phone_number: string
  address: string
  city: string
  state: string
  zip: string
  isTest: boolean
  geofencing: Geofencing
  questionSets?: (JoinQuestionSetPayload | JoinSurveyPayload)[] | null
  visibilityGroups?: EventGroup[]
  onboardingGroups?: EventGroup[]
  requiredCredentials: {
    id: number
    title: string
  }[]
  visibility: OpportunityVisibility
  recurrenceInfo?: RecurrenceInfo
  occurrenceId?: number
  opportunityId?: number
}

export interface CreateEventFields extends BaseEventFields {
  timeshifts: TimeshiftsBase[]
}

export interface EditEventFields extends BaseEventFields {
  published: boolean
  timeshifts: TimeshiftsEditEvent[]
}

interface BaseEventForm {
  currentStep: number
}

export interface CreateEventForm extends BaseEventForm {
  event: CreateEventFields
}
export interface EditEventForm extends BaseEventForm {
  event: EditEventFields
}

export interface ConfigRef {
  id: number
  name: string
  isDefault: boolean
}

export interface OpportunityResponse {
  matchedIds?: Array<number | string>
  id: number
  occurrenceId: number
  opportunityId: number
  organization: OrgNamedRef
  config: ConfigRef
  private: boolean
  isTest: boolean
  name: string
  description?: string
  address?: string
  city?: string
  zip?: string
  state?: string
  instructions?: string
  contact_name?: string
  contact_email?: string
  contact_phone_number?: string
  questions?: any
  comment?: string
  startsAt: Date | string
  endsAt?: Date | string
  timeshifts?: any
  geofencing: Geofencing
  createdAt: Date
  deletedAt?: Date
  is_recurring?: boolean
  recurring_type?: number
  published?: boolean
  totalVolunteerHours?: number
  totalCheckIns?: number
  totalActiveVolunteers?: number
  schedulable: boolean
  visibility: OpportunityVisibility
  requiredCredentials?: Array<{ id: number; title: string }>
  recurrenceInfo?: RecurrenceInfo
}

export interface OpportunityResponseWithRecurrenceInfo extends OpportunityResponse {
  recurrenceInfo: RecurrenceInfo
}
