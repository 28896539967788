import _ from 'lodash'
import React, { useState, useMemo, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Button,
  TextField,
  Grid,
  Card,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { makeStyles } from '@material-ui/styles'
import { useApplicantManagement } from '../hooks/useApplicantManagement'

import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import ProcessMemberTabs from './ProcessMemberTabs'
import { PersonInfo } from 'volunteering/components/PersonInfo'

const useStyles = makeStyles(theme => ({
  content: {
    textAlign: 'center',
  },
  marginTop: {
    marginTop: '20px',
  },
  buttonRow: {
    display: 'flex',
  },
  cancelButton: {
    color: theme.palette.danger.main,
  },
  statusDiv: {
    padding: '16px 24px',
  },
  memberDiv: {
    padding: '1em 5em',
  },
  groupTitle: {
    fontSize: '2rem',
    fontWeight: 600,
  },
  memberTitle: {
    fontSize: '1.5rem',
  },
  statusTitle: {
    fontSize: '1.25rem',
  },
  applicantColor: {
    color: '#ff6f3e',
  },
  card: {
    padding: theme.spacing(2),
    border: 'none',
    boxShadow: 'none',
  },
  height90: {
    height: '90%',
  },
  fullHeight: {
    height: '100%',
  },
}))

export default function ProcessMemberPrompt(props) {
  const { showing, groupName } = props //TODO was "submitting" even provided?
  const {
    submitting: disabled,
    prev,
    current: member,
    next,
    gotoPrev,
    gotoNext,
    approve,
    decline,
    exit: close,
    total,
    currentIndex,
  } = useApplicantManagement(props)

  const promptClasses = usePromptStyles()
  const classes = useStyles()

  const [comment, setComment] = useState('')
  const handleApproveClick = async () => {
    approve(comment)
    setComment('')
  }
  const handleDeclineClick = async () => {
    decline(comment)
    setComment('')
  }

  const filters = useMemo(
    () =>
      !member
        ? undefined
        : {
            personId: member.person.id,
            required: true,
            associatedEntity: { groupId: member.groupId },
            action: PrerequisitesAction.GROUP_SIGN_UP,
            freshAnswerSet: false,
            excludeCompletedAnswerSets: false,
            includeSignedCredentials: true,
          },
    [member],
  )
  const statusFormatting = {
    applicant: {
      text: 'Pending Member',
      style: { color: '#ff6f3e' },
    },
    member: {
      text: 'Full Member',
      style: { color: '#5c8de8' },
    },
    declined: {
      text: 'Declined',
      style: { color: '#b24d2b' },
    },
    default: {
      text: 'Unknown Member Status',
      style: { color: '#000' },
    },
  }

  const MemberStatus = props => {
    const { style, text } = statusFormatting[props.member.status.code] || statusFormatting.default
    return (
      <Typography variant="h4" className={classes.statusTitle} style={style}>
        {text}
      </Typography>
    )
  }

  const isApplicant = _.get(member, 'status.code') === 'applicant'

  return (
    <Dialog fullScreen open={showing} onClose={close}>
      {member && (
        <Grid container direction="column" wrap="nowrap" justify="space-between" className={classes.fullHeight}>
          <Grid container item direction="row" wrap="noWrap" className={isApplicant ? '' : classes.height90}>
            <Grid item xs={12} sm={4}>
              <DialogTitle>
                <Typography variant="h2" className={classes.groupTitle}>
                  {groupName}
                </Typography>
                <IconButton disabled={disabled} onClick={close} className={promptClasses.closeButton}>
                  <CloseIcon />
                </IconButton>
              </DialogTitle>
              <Grid container direction="column">
                <Grid item className={classes.statusDiv}>
                  <Typography variant="h3" className={classes.memberTitle}>
                    Member Approval
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <Typography variant="h4" className={classes.statusTitle}>
                      Status&nbsp;:&nbsp;
                    </Typography>
                    {member && <MemberStatus member={member} />}
                  </div>
                </Grid>
                <Grid item className={classes.statusDiv}>
                  <Card className={classes.card}>
                    {member && <PersonInfo person={member.person} hidePhone={!!!member.person.phoneNumber} />}
                  </Card>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={7} className={isApplicant ? '' : classes.fullHeight}>
              <DialogContent className={`${classes.content} ${isApplicant ? '' : classes.fullHeight}`}>
                <ProcessMemberTabs filters={filters} fullHeight={!isApplicant} />
                {member.status.code === 'applicant' && (
                  <>
                    <div className={classes.marginTop} style={{ textAlign: 'left' }}>
                      <p>Would you like to approve this member?</p>
                      <TextField
                        placeholder="Add a message to your response to the applicant"
                        variant="outlined"
                        value={comment}
                        onChange={e => setComment(e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                      />
                    </div>
                    <div className={`${classes.buttonRow} ${classes.marginTop}`}>
                      <Button
                        variant="contained"
                        disabled={disabled}
                        style={{ background: '#BFDF57', marginRight: '20px' }}
                        onClick={handleApproveClick}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        disabled={disabled}
                        onClick={handleDeclineClick}
                        style={{ background: '#ff6f3e', color: 'white' }}
                      >
                        Decline
                      </Button>
                    </div>
                  </>
                )}
              </DialogContent>
            </Grid>
          </Grid>
          <Grid container item justify="space-between" style={{ padding: '20px' }}>
            <Grid item>
              <Button variant="contained" color="secondary" disabled={!!!prev} onClick={gotoPrev}>
                PREVIOUS
              </Button>
            </Grid>
            <Grid item container direction="column" justify="center" style={{ width: 'auto' }}>
              {currentIndex} of {total}
            </Grid>
            <Grid item>
              <Button variant="contained" color="secondary" disabled={!!!next} onClick={gotoNext}>
                NEXT
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </Dialog>
  )
}
