import { connect } from 'react-redux'
import EditEventScene from '../../scenes/edit-event'
import { changeStep, clearEventForm, setEventDataFromApi, setEventField, setNewData } from '../reducers/EditEvent'
import { isFeatureEnabled } from 'core/feature/selectors'

export function MSTPCreateEvent(state) {
  return {
    editEventForm: state.EditEvent,
    isQuestionnaireAsSurveyEnabled: isFeatureEnabled(state, 'QuestionnaireAsSurvey'),
  }
}

export function mapDispatchToProps(dispatch) {
  return {
    changeStep: step => dispatch(changeStep(step)),
    setEventField: (key, val) => dispatch(setEventField(key, val)),
    clearEventForm: () => dispatch(clearEventForm()),
    setNewData: data => dispatch(setNewData(data)),
    setEventDataFromApi: data => dispatch(setEventDataFromApi(data)),
  }
}

export default connect(MSTPCreateEvent, mapDispatchToProps)(EditEventScene)
