import React from 'react'
import { Button, Dialog, makeStyles, Typography } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { useCurrentOrg } from 'auth/hooks'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { KeyboardDatePicker, KeyboardTimePicker } from 'formik-material-ui-pickers'
import { slotIsPast } from '../utils'
import { Schedule } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    field: {
        display: 'block',
        margin: theme.spacing(2)
    },
    dialog: {
        padding: theme.spacing(2),
        textAlign: 'center'
    }
}))

export default function CustomTimeslotPrompt(props) {
    const { showing, close, addSlot } = props
    const classes = useStyles()
    const org = useCurrentOrg()
    const tz = get(org, 'timeZone', 'America/New_York')


    const initialValues = {
        date: moment().tz(tz),
        startTime: moment().tz(tz),
        endTime: moment().add(2, 'hours').tz(tz)
    }

    const validateForm = (values) => {
        const errors = {}
        if (!values.date) { errors.date = 'Date is required' }
        if (!values.startTime) { errors.startTime = 'Start is required' }
        if (!values.endTime) { errors.endTime = 'End is required' }
        if (!isEmpty(errors)) return errors

        const newSlot = {
            startTime: moment(values.date).hour(values.startTime.hour()).minute(values.startTime.minute()),
            endTime: moment(values.date).hour(values.endTime.hour()).minute(values.endTime.minute())
        }
        if (slotIsPast(newSlot)) { errors.startTime = 'Timeslot start must be in the future.' }
        if (newSlot.endTime.isBefore(newSlot.startTime)) { errors.endTime = 'Timeslot end must be after start.' }
        return errors
    }

    const handleFormikSubmit = (values) => {
        const newSlot = {
            startTime: moment(values.date).hour(values.startTime.hour()).minute(values.startTime.minute()),
            endTime: moment(values.date).hour(values.endTime.hour()).minute(values.endTime.minute())
        }
        addSlot(newSlot)
        close()
    }

    return (
        <Dialog open={showing} onClose={close}>
            <div className={classes.dialog}>
                <Typography variant='h4'>Custom Timeslot</Typography>
                <Formik initialValues={initialValues} onSubmit={handleFormikSubmit} validate={validateForm}>
                    {(formikProps) => {
                        const { handleSubmit, values } = formikProps
                        return (
                            <Form onSubmit={handleSubmit}>
                                <Field
                                    name='date'
                                    component={KeyboardDatePicker}
                                    label='Date'
                                    inputVariant='outlined'
                                    className={classes.field}
                                    disablePast
                                />
                                <Field
                                    name='startTime'
                                    component={KeyboardTimePicker}
                                    label='Start Time'
                                    inputVariant='outlined'
                                    className={classes.field}
                                    disablePast
                                    keyboardIcon={<Schedule />}
                                />
                                <Field
                                    name='endTime'
                                    component={KeyboardTimePicker}
                                    label='End Time'
                                    inputVariant='outlined'
                                    className={classes.field}
                                    disablePast
                                    minDate={values.startTime}
                                    keyboardIcon={<Schedule />}
                                />

                                <Button
                                    type='submit'
                                    variant='contained'
                                    color='primary'
                                >
                                    Add slot
                                </Button>
                            </Form>
                        )
                    }}
                </Formik>
            </div>
        </Dialog>
    )
}