import { useCallback } from "react"

import { useConfirm } from 'civic-champs-shared/core/modal/hooks'
import useExpelAdmin from "./useExpelAdmin"

const useExpelAdminPrompt = (eagerRemove) => {
    const confirm = useConfirm()
    const expelMember = useExpelAdmin(eagerRemove)

    return useCallback( async (membership) => {
        const memberName = `${membership.person.givenName} ${membership.person.familyName}`

        const confirmed = await confirm(
            `Are you sure you want to end ${memberName}'s membership?`, 
            { 
                title: 'End Membership', 
                confirmText: 'End Membership', 
                rejectText: 'Cancel'
            }
        )

        if(confirmed) {
            await expelMember(membership)
        }
    }, [confirm, expelMember])
}

export default useExpelAdminPrompt
