import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import useRemoveVolunteer from './useRemoveVolunteer'
import { useCallback } from 'react'
import { volunteerSummary } from './utils'
import ConfirmDialog from 'civic-champs-shared/core/confirm-dialog/ConfirmDialog'

const useRemoveVolunteerPrompt = (eagerRemoveMany: any) => {
  const showPrompt = useShowPrompt(ConfirmDialog)
  const removeVolunteer = useRemoveVolunteer(eagerRemoveMany)

  return useCallback(
    async volunteers => {
      const { numVolunteers, onlyOneVolunteer, volunteerName } = volunteerSummary(volunteers)
      const text = onlyOneVolunteer
        ? `Are you sure you want to remove ${volunteerName} from volunteers?  His/her activities will no longer appear in reports.`
        : `Are you sure you want to remove ${numVolunteers} volunteers?  Their activities will no longer appear in reports.`
      const confirmed = await showPrompt({
        text,
        title: 'Remove Volunteer',
        confirmText: 'Remove Volunteer',
        cancelText: 'Cancel',
      })

      if (confirmed) {
        await removeVolunteer(volunteers)
      }
    },
    [showPrompt, removeVolunteer],
  )
}

export default useRemoveVolunteerPrompt
