import * as React from 'react'
import { ConsoleLogger as Logger, I18n, JS } from '@aws-amplify/core'
import Auth from '@aws-amplify/auth'

import AuthPiece from 'aws-amplify-react/lib-esm/Auth/AuthPiece'
import { UsernameAttributes } from 'aws-amplify-react/lib-esm/Auth/common/types'
import { PhoneField } from 'aws-amplify-react/lib-esm/Auth/PhoneField'
import { FederatedButtons } from 'aws-amplify-react/lib-esm/Auth/FederatedSignIn'
import ForgotPassword from './CustomForgotPassword'
import parseUsername from '../utils/parseUsername'

import {
  Button,
  FormField,
  FormSection,
  Hint,
  Input,
  InputLabel,
  Link,
  SectionBody,
  SectionFooter,
  SectionFooterPrimaryContent,
  SectionFooterSecondaryContent,
  SectionHeader,
} from 'aws-amplify-react/lib-esm/Amplify-UI/Amplify-UI-Components-React'

import { auth } from 'aws-amplify-react/lib-esm/Amplify-UI/data-test-attributes'
import decodeJwtToken from 'civic-champs-shared/auth/utils/decodeJwtToken'

const logger = new Logger('SignIn')

export default class SignIn extends AuthPiece {
  constructor(props) {
    super(props)

    this.checkContact = this.checkContact.bind(this)
    this.signIn = this.signIn.bind(this)

    this._validAuthStates = ['signIn', 'signedOut', 'signedUp']
    this.state = {}
  }

  componentDidMount() {
    super.componentDidMount()
    if (window && window.location) {
      const searchParams = new URLSearchParams(window.location.search)
      const token = searchParams.get('token') || localStorage.getItem('token')
      if (token) {
        try {
          const payload = decodeJwtToken(token)
          if (payload.exp * 1000 > Date.now()) {
            const localStorageToken = localStorage.getItem('token')
            localStorage.setItem('token', token)
            if (token !== localStorageToken) {
              localStorage.removeItem('firstLogin')
            }
            this.changeState('signedIn', {
              user: {
                attributes: payload,
              },
            })
          } else {
            localStorage.removeItem('token')
            localStorage.removeItem('firstLogin')
          }
        } catch (e) {}
      }
    }
  }

  renderUsernameField(theme) {
    const { usernameAttributes = [] } = this.props
    if (usernameAttributes === UsernameAttributes.EMAIL) {
      return (
        <FormField theme={theme}>
          <InputLabel theme={theme}>{I18n.get('Email')} *</InputLabel>
          <Input
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            autoFocus
            placeholder={I18n.get('Enter your email')}
            theme={theme}
            key="email"
            name="email"
            onChange={this.handleInputChange}
            data-test={auth.genericAttrs.emailInput}
          />
        </FormField>
      )
    } else if (usernameAttributes === UsernameAttributes.PHONE_NUMBER) {
      return <PhoneField theme={theme} onChangeText={this.onPhoneNumberChanged} />
    } else {
      let value
      if (window && window.location && window.location.search) {
        const searchParams = new URLSearchParams(window.location.search)
        value = searchParams ? searchParams.get('username') : undefined
      }
      return (
        <FormField theme={theme}>
          <InputLabel theme={theme}>{I18n.get(this.getUsernameLabel())} *</InputLabel>
          <Input
            value={value}
            autoCapitalize="none"
            autoCorrect="off"
            spellCheck="false"
            autoFocus
            placeholder={I18n.get('Enter your username')}
            theme={theme}
            key="username"
            name="username"
            onChange={this.handleInputChange}
            data-test={auth.genericAttrs.usernameInput}
          />
        </FormField>
      )
    }
  }

  checkContact(user) {
    if (!Auth || typeof Auth.verifiedContact !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported')
    }
    Auth.verifiedContact(user).then(data => {
      if (!JS.isEmpty(data.verified)) {
        this.changeState('signedIn', user)
      } else {
        user = Object.assign(user, data)
        this.changeState('verifyContact', user)
      }
    })
  }

  async signIn(event) {
    // avoid submitting the form
    if (event) {
      event.preventDefault()
    }

    let username = parseUsername(this.getUsernameFromInput() || '').toLowerCase()
    const password = this.inputs.password

    if (!Auth || typeof Auth.signIn !== 'function') {
      throw new Error('No Auth module found, please ensure @aws-amplify/auth is imported')
    }
    this.setState({ loading: true })
    try {
      const user = await Auth.signIn(username, password)
      logger.debug(user)
      if (user.challengeName === 'SMS_MFA' || user.challengeName === 'SOFTWARE_TOKEN_MFA') {
        logger.debug('confirm user with ' + user.challengeName)
        this.changeState('confirmSignIn', user)
      } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        logger.debug('require new password', user.challengeParam)
        this.changeState('requireNewPassword', user)
      } else if (user.challengeName === 'MFA_SETUP') {
        logger.debug('TOTP setup', user.challengeParam)
        this.changeState('TOTPSetup', user)
      } else if (
        user.challengeName === 'CUSTOM_CHALLENGE' &&
        user.challengeParam &&
        user.challengeParam.trigger === 'true'
      ) {
        logger.debug('custom challenge', user.challengeParam)
        this.changeState('customConfirmSignIn', user)
      } else {
        this.checkContact(user)
      }
      // window.location.replace('/') TODO: This cancelling requests
    } catch (err) {
      if (err.code === 'UserNotConfirmedException') {
        logger.debug('the user is not confirmed')
        this.changeState('confirmSignUp', { username })
      } else if (err.code === 'PasswordResetRequiredException') {
        logger.debug('the user requires a new password')
        this.changeState('forgotPassword', { username })
      } else {
        this.error(err)
      }
    } finally {
      this.setState({ loading: false })
    }
  }

  showComponent(theme) {
    const { authState, hide = [], federated, onStateChange, onAuthEvent, override = [] } = this.props
    if (hide && hide.includes(SignIn)) {
      return null
    }
    const hideSignUp = true //!override.includes('SignUp') && hide.some(component => component === SignUp);
    const hideForgotPassword =
      !override.includes('ForgotPassword') && hide.some(component => component === ForgotPassword)
    return (
      <FormSection theme={theme} data-test={auth.signIn.section}>
        <div>
          <img src="/assets/icons/blue-logo.svg" alt="logo" />
        </div>

        <SectionHeader theme={theme} data-test={auth.signIn.headerSection}>
          {I18n.get('Sign in to your account')}
        </SectionHeader>
        <FederatedButtons
          federated={federated}
          theme={theme}
          authState={authState}
          onStateChange={onStateChange}
          onAuthEvent={onAuthEvent}
        />
        <form onSubmit={this.signIn}>
          <SectionBody theme={theme}>
            {this.renderUsernameField(theme)}
            <FormField theme={theme}>
              <InputLabel theme={theme}>{I18n.get('Password')} *</InputLabel>
              <Input
                placeholder={I18n.get('Enter your password')}
                theme={theme}
                key="password"
                type="password"
                name="password"
                onChange={this.handleInputChange}
                data-test={auth.signIn.passwordInput}
              />
              {!hideForgotPassword && (
                <Hint theme={theme}>
                  {I18n.get('Forget your password? ')}
                  <Link
                    theme={theme}
                    onClick={() => this.changeState('forgotPassword')}
                    data-test={auth.signIn.forgotPasswordLink}
                  >
                    {I18n.get('Reset password')}
                  </Link>
                </Hint>
              )}
            </FormField>
          </SectionBody>
          <SectionFooter theme={theme} data-test={auth.signIn.footerSection}>
            <SectionFooterPrimaryContent theme={theme}>
              <Button theme={theme} type="submit" disabled={this.state.loading} data-test={auth.signIn.signInButton}>
                {I18n.get('Sign In')}
              </Button>
            </SectionFooterPrimaryContent>
            {!hideSignUp && (
              <SectionFooterSecondaryContent theme={theme}>
                {I18n.get('No account? ')}
                <Link
                  theme={theme}
                  onClick={() => this.changeState('signUp')}
                  data-test={auth.signIn.createAccountLink}
                >
                  {I18n.get('Create account')}
                </Link>
              </SectionFooterSecondaryContent>
            )}
          </SectionFooter>
        </form>
      </FormSection>
    )
  }
}
