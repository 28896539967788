import React, { useMemo } from 'react'
import { CellProps } from 'react-table'
import isBoolean from 'lodash/isBoolean'

import { DATE_RANGE, DYNAMIC_NUMBER } from 'core/table/filters'
import { DateCell, WithLinkCell } from 'core/table/cells'
import { useTableColumnStyles as useStyles } from 'core/table/table-hooks'

import RatingIcon from '../components/rating-icon'

export function useMentorshipMeetingsColumns() {
  const classes = useStyles()
  return useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'startsAt',
        id: 'startsAt',
        disableFilters: true,
        filter: DATE_RANGE,
        Cell: (props: any) =>
          WithLinkCell(
            DateCell,
            (id: number) => `/mentorship/meetings/${id}`,
          )({ ...props, linkProps: { className: classes.link } }),
      },
      {
        Header: 'Mentor',
        accessor: 'mentor',
        id: 'mentor',
        filter: 'includesSome',
      },
      {
        Header: 'Mentee',
        accessor: 'mentee',
        id: 'mentee',
        filter: 'includesSome',
      },
      {
        Header: 'Program',
        accessor: 'program',
        id: 'program',
        filter: 'includesSome',
      },
      {
        Header: 'Rating (Mentor / Mentee)',
        accessor: 'mentorRating',
        Cell: ({ row: { original: o } }: CellProps<any>) => (
          <div style={{ display: 'flex' }}>
            {isBoolean(o.mentorRating) && <RatingIcon icon={o.mentorRating} />}
            {isBoolean(o.menteeRating) && <RatingIcon icon={o.menteeRating} />}
          </div>
        ),
      },
      {
        Header: 'Meeting Time ',
        accessor: 'duration',
        id: 'duration',
        filter: DYNAMIC_NUMBER,
        Cell: ({ cell: { value } }: CellProps<any>) => value + ' min',
      },
    ],
    [classes],
  )
}

export default useMentorshipMeetingsColumns
