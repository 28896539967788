import React, { useCallback, useState } from 'react'
import format from 'utils/format'
import { useCurrentDateWithFilename } from '../../components/table/ExportCsvMenuItem'
import { Button } from '@material-ui/core'
import { CSVLink } from 'react-csv'
import GetAppIcon from '@material-ui/icons/GetApp'
import useExportStyles from 'export/useExportStyles'

const filename = 'members.csv'
const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Phone', key: 'phoneNumber' },
  { label: 'Home Phone', key: 'homePhoneNumber' },
  { label: 'Joined', key: 'joined' },
  { label: 'Status', key: 'status' },
]
const formatData = members =>
  members.map(value => {
    return {
      firstName: value.person.givenName,
      lastName: value.person.familyName,
      email: value.person.email,
      phoneNumber: format.phoneNumber(value.person.phoneNumber),
      homePhoneNumber: format.phoneNumber(value.person.homePhoneNumber),
      joined: format.date(value.createdAt),
      status: value.status.displayName,
    }
  })

const ExportGroupMembersButton = ({ data, disabled }) => {
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState([])
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  const classes = useExportStyles()
  return (
    <Button variant="contained" color="secondary" disabled={disabled} onClick={createFormattedData}>
      <GetAppIcon style={{ marginRight: 5 }} />
      <CSVLink
        style={
          disabled
            ? { textDecoration: 'none', color: 'rgba(0, 0, 0, 0.26)' }
            : { textDecoration: 'none', color: '#fff' }
        }
        onClick={createFormattedData}
        filename={fileNameWithDate}
        headers={headers}
        data={formattedData}
        className={classes.fill}
      />
      Export to CSV
    </Button>
  )
}

export default ExportGroupMembersButton
