import React, { useState, useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Loading from 'civic-champs-shared/core/Loading'
import { QuestionSetPicker } from '../components/QuestionSetPicker'
import { useSetOrgUserProfileQuestionSets } from '../hooks/useSetOrgUserProfileQuestionSets'
import { useFetchOrgUserProfileQuestionSets } from '../hooks/useFetchOrgUserProfileQuestionSets'
import { useCurrentOrg } from 'auth/hooks'
import { useHasRole } from '../../auth/hooks'
import { INTERNAL_SUPER_ADMIN } from 'civic-champs-shared/auth/utils/permissions'
import { GlobalUserProfileQuestionnaires } from '../components/GlobalUserProfileQuestionnaires'
import Divider from '@material-ui/core/Divider'
import FeatureEnabled from '../../core/feature/components/FeatureEnabled'

export const UserProfileQuestionnaire = () => {
  const [existingQuestionSets, setExistingQuestionSets] = useState([])
  const [setUserProfileQuestionSets] = useSetOrgUserProfileQuestionSets(setExistingQuestionSets)
  const [
    fetchUserProfileQuestionSets,
    { loading: userProfileQuestionSetsLoading, called },
  ] = useFetchOrgUserProfileQuestionSets()
  const currentOrg = useCurrentOrg()
  const isInternalSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)

  useEffect(() => {
    fetchUserProfileQuestionSets().then(res => setExistingQuestionSets(res))
  }, [])

  const handleSubmit = async questionSets => {
    await setUserProfileQuestionSets(questionSets)
  }

  const loading = userProfileQuestionSetsLoading || !called
  if (loading) {
    return <Loading />
  }

  return (
    <>
      <FeatureEnabled code="GlobalUserProfiles">
        <div>
          <GlobalUserProfileQuestionnaires readonly={!isInternalSuperAdmin}/>
          <Divider  style={{ margin: '50px 0' }} />
        </div>
      </FeatureEnabled>
      <FeatureEnabled code="OrgUserProfiles">
        <Grid container direction="column" spacing={3}>
          <Grid container item>
            <QuestionSetPicker
              loading={loading}
              showRequired
              title={
                <Typography variant="h4">{currentOrg.name} User Profile Questionnaires</Typography>
              }
              value={existingQuestionSets.map(userProfileQuestionSet => ({
                questionSetId: userProfileQuestionSet.questionSet.id,
                name: userProfileQuestionSet.questionSet.name,
                optional: !userProfileQuestionSet.required,
              }))}
              onSubmit={handleSubmit}
            />
        </Grid>
      </Grid>
      </FeatureEnabled>
    </>
  )
}
