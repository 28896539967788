import React, { useCallback, useEffect, useState } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DraggablePaper from 'civic-champs-shared/core/confirm-dialog/DraggablePaper'
import DialogTitle from '@material-ui/core/DialogTitle'
import { IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import OutlinedButton from 'civic-champs-shared/core/OutlinedButton'
import ContainedButton from 'civic-champs-shared/core/ContainedButton'
import { makeStyles, ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import useChatPromptStyles from 'chats/hooks/useChatPromptStyles'
import MembersSelect from 'chats/components/MembersSelect'
import { Person } from 'people/interface'
import cn from 'classnames'
import useGetChats from 'chats/hooks/useGetChats'
import useGetChatMembers from 'chats/hooks/useGetChatMembers'
import StyledAutocompleteComponent from 'components/StyledAutocompleteComponent'
import { Chat, Member } from 'chats/interfaces'

interface AddChatMembersPromptProps {
  showing: boolean
  complete: (value: any) => void
  close: () => void
  initialMembers: Person[]
}

const useStyles = makeStyles({
  dialog: { width: '380px' },
})

export const AddChatMembersWithChatPrompt = (props: AddChatMembersPromptProps) => {
  const { showing, close, complete, initialMembers } = props
  const [members, setMembers] = useState<Person[]>(initialMembers)
  const [chat, setChat] = useState<Chat | null>(null)
  const [existingMembers, setExistingMembers] = useState<Member[]>([])
  const [fetchChats, { result: chats }] = useGetChats()
  const [fetchChatMembers] = useGetChatMembers()
  const classes = useChatPromptStyles()
  const styles = useStyles()
  useEffect(() => {
    fetchChats()
  }, [fetchChats])

  const handleAutocompleteChange = useCallback(
    (event: any, newValue: Chat | null) => {
      setChat(newValue)
      if (newValue) {
        fetchChatMembers(newValue.id).then(members => {
          setExistingMembers(members)
        })
      }
    },
    [fetchChatMembers],
  )

  return (
    // @ts-ignore
    <Dialog
      open={showing}
      // @ts-ignore
      PaperComponent={DraggablePaper}
      // @ts-ignore
      PaperProps={{ handle: `#add-chat-prompt` }}
      aria-labelledby="add-chat-dialog-title"
      maxWidth="xs"
      classes={{ paper: cn(classes.dialog, styles.dialog) }}
      disableEnforceFocus
    >
      <DialogTitle classes={{ root: classes.title }} disableTypography={true} id="add-chat-prompt">
        Add Members
        <IconButton className={classes.dialogCloseButton} onClick={() => close()}>
          <CloseIcon className={classes.dialogCloseIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent classes={{ root: classes.content }}>
        <MembersSelect members={members} setMembers={setMembers} existingMembers={existingMembers} />
        <StyledAutocompleteComponent
          value={chat}
          onChange={handleAutocompleteChange}
          options={chats}
          getOptionLabel={(option: Chat) => option.name}
          label={'Select a chat*'}
        />
      </DialogContent>
      <DialogActions className={classes.actions}>
        <OutlinedButton onClick={close}>Back</OutlinedButton>
        <ContainedButton
          disabled={members.length === 0 || !chat}
          onClick={() => complete({ chatId: chat?.id, members })}
        >
          Add
        </ContainedButton>
      </DialogActions>
    </Dialog>
  )
}

export default (props: AddChatMembersPromptProps) => (
  <ThemeProvider theme={muiTheme}>
    <AddChatMembersWithChatPrompt {...props} />
  </ThemeProvider>
)
