import React, { useCallback, useState } from 'react'
import format from 'utils/format'
import { useCurrentDateWithFilename } from 'components/table/ExportCsvMenuItem'
import { CSVLink } from 'react-csv'
import SaveAltOutlinedIcon from '@material-ui/icons/SaveAltOutlined'
import TableMenuButton from 'civic-champs-shared/core/TableMenuButton'
import useExportStyles from 'export/useExportStyles'

const filename = 'no-contact-champions.csv'
const headers = [
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Hours', key: 'hours' },
  { label: 'Donations', key: 'donations' },
  { label: 'Last Activity', key: 'latestActivity' },
  { label: 'Groups', key: 'groups' },
]
const formatData = (champions: any[]) => {
  return champions.map(row => {
    return {
      firstName: row.user.givenName,
      lastName: row.user.familyName,
      hours: format.fixedPoint(row.volunteering.totalVolunteerHours, 1),
      donations: format.fixedPoint(row.donation.totalDonations / 100, 2),
      latestActivity: row.volunteering.latestActivity,
      groups: row.groups,
    }
  })
}
const ExportContactlessChampionsButton = ({
  data,
  disabled,
  includeDonations,
}: {
  data: any[]
  disabled?: boolean
  includeDonations?: boolean | null
}) => {
  const filteredHeaders = includeDonations ? headers : headers.filter(header => header.key !== 'donations')
  const fileNameWithDate = useCurrentDateWithFilename(filename)
  const [formattedData, setFormattedData] = useState<any[]>([])
  const classes = useExportStyles()
  const createFormattedData = useCallback(() => {
    setFormattedData(formatData(data))
  }, [setFormattedData, data])
  return (
    <TableMenuButton startIcon={<SaveAltOutlinedIcon />} disabled={disabled} rightMargin onClick={createFormattedData}>
      <CSVLink
        style={{ textDecoration: 'none', color: disabled ? 'rgba(0, 0, 0, 0.26)' : '#0F5DB5' }}
        className={classes.fill}
        onClick={createFormattedData}
        filename={fileNameWithDate as string}
        headers={filteredHeaders}
        data={formattedData}
      />
      Export List
    </TableMenuButton>
  )
}

export default ExportContactlessChampionsButton
