import React, { Dispatch, SetStateAction, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Person } from 'people/interface'
import { GetFirstLastId, useRecipientsPlaceholderText } from 'messages/hooks/useRecipientsPlaceholderText'
import {
  HALF_HEIGHT,
  ROW_HEIGHT,
  useMembersSelectStyles,
  useMessageRecipientRowStyles,
} from 'chats/hooks/useMembersSelectStyles'
import cn from 'classnames'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ThemeProvider } from '@material-ui/core/styles'
import { muiTheme } from 'theme'
import { Fade, Popper } from '@material-ui/core'
import { useDisplayAreaInfo } from 'messages/hooks/useDisplayAreaInfo'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import PersonEmbeddedAutocomplete, { GetContent } from 'messages/components/PersonEmbeddedAutocomplete'
import { useVolunteersCollection } from 'volunteering/volunteers/hooks'
import map from 'lodash/map'
import find from 'lodash/find'
import { Member } from 'chats/interfaces'
import CloseIcon from '@material-ui/icons/Close'

interface MembersSelectProps {
  members: Person[]
  setMembers: Dispatch<SetStateAction<Person[]>>
  existingMembers?: Member[]
}

const getFirstLastId: GetFirstLastId<Person> = ({ id, givenName, familyName }: Person) => ({
  id,
  firstName: givenName,
  lastName: familyName,
})

const getContent: GetContent<Person> = ({ id, givenName, familyName, email }: Person) => ({
  id,
  left: `${givenName} ${familyName}`,
  right: email,
})

let requestRunning: number | null = null

const MemberRow = ({ member, onDelete }: { member: Person; onDelete: (id: number) => void }) => {
  const classes = useMessageRecipientRowStyles()
  return (
    <div className={classes.recipientRow}>
      <div className={classes.recipientContainer}>
        <CheckCircleIcon className={classes.rowIcon} />{' '}
        <div className={classes.recipientNameContainer}>
          <div>
            {member.givenName} {member.familyName}
          </div>
          <div className={classes.email}>{member.email}</div>
        </div>
      </div>
      <CloseIcon className={classes.closeIcon} onClick={() => onDelete(member.id)} />
    </div>
  )
}

const [filters, includeInactive, includeArchived] = [{}, true, false]

const MembersSelect = ({ members, setMembers, existingMembers = [] }: MembersSelectProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const [scrollTopPosition, setScrollTopPosition] = useState(0)
  const [contacts, setContacts] = useState<Person[]>([])
  const { text, more } = useRecipientsPlaceholderText<Person>({ contacts: members, getFirstLastId })
  const anchorEl = useRef<HTMLDivElement | null>(null)
  const focusEl = useRef<HTMLDivElement | null>(null)
  const [{ volunteers, initiallyLoaded }] = useVolunteersCollection(filters, includeInactive, includeArchived) as any
  useEffect(() => {
    if (initiallyLoaded) {
      setContacts(
        map(volunteers, 'user').filter(
          (user: Person) =>
            !members.find(member => member.id === user.id) && !existingMembers.find(member => member.id === user.id),
        ),
      )
    }
  }, [volunteers, members, initiallyLoaded, existingMembers])
  const classes = useMembersSelectStyles()
  const { topPlaceholderHeight, bottomPlaceholderHeight, visibleContacts } = useDisplayAreaInfo<Person>({
    contacts: members,
    scrollTopPosition,
    rowHeight: ROW_HEIGHT,
    height: HALF_HEIGHT,
  })
  const textDiv = useMemo(
    () => (
      <div className={classes.text}>
        {members.length ? (
          <>
            {text}
            {more ? (
              <span>
                {'\u00A0'}+{more} more
              </span>
            ) : null}
          </>
        ) : open ? (
          ''
        ) : (
          'Select Members *'
        )}
      </div>
    ),
    [members.length, classes.text, text, more, open],
  )
  const handleClick = () => {
    setScrollTopPosition(0)
    setOpen(val => !val)
  }

  const handleAdd = (id: number) => {
    const person = find(contacts, { id })
    setMembers(currentMembers => [...currentMembers, person as Person])
  }

  const handleScroll = (e: UIEvent<HTMLDivElement>) => {
    // We set scroll position on scroll to render only elements that are visible
    // We need to use requestAnimationFrame not to trigger re-render more often than something is actually rendered
    if (requestRunning === null) {
      const { scrollTop } = e.currentTarget
      requestRunning = window.requestAnimationFrame(function () {
        setScrollTopPosition(scrollTop)
        requestRunning = null
      })
    }
  }

  const handleBlur = useCallback(() => {
    const isFocused = (): boolean =>
      focusEl.current === document.activeElement || focusEl.current?.contains(document.activeElement) ? true : false
    // Need to ensure that render happened before checking focus
    window.requestAnimationFrame(() => {
      if (!isFocused()) {
        setOpen(false)
      }
    })
  }, [setOpen]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = useCallback(
    (id: number) => {
      setMembers(currentMembers => currentMembers.filter(member => member.id !== id))
    },
    [setMembers],
  )

  return (
    <>
      <div
        id="member-selector"
        className={cn(classes.input, { [classes.open]: open })}
        tabIndex={-1}
        onClick={handleClick}
        ref={anchorEl}
      >
        {textDiv}
        {open ? (
          <ExpandLessIcon className={classes.expandCollapse} />
        ) : (
          <ExpandMoreIcon className={classes.expandCollapse} />
        )}
      </div>
      <Popper
        className={classes.popper}
        id="message-recipient-selector-popper"
        open={open}
        anchorEl={anchorEl.current}
        transition
        tabIndex={-1}
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <div tabIndex={-1} className={classes.popperPaper} ref={focusEl} onBlur={handleBlur}>
              <div className={classes.selected}>
                <strong>Selected</strong>
              </div>
              <div className={classes.recipients} onScroll={handleScroll}>
                <div className={classes.placeholder} style={{ height: topPlaceholderHeight }} />
                {visibleContacts.map(member => (
                  <MemberRow key={member.id} member={member} onDelete={handleDelete} />
                ))}
                <div className={classes.placeholder} style={{ height: bottomPlaceholderHeight }} />
              </div>
              <div style={{ borderTop: '1px rgba(0,0,0,0.1) solid', margin: '5px 9.5px' }} />
              <PersonEmbeddedAutocomplete<Person>
                onAdd={handleAdd}
                selectedContactCount={members.length}
                contacts={contacts}
                getFirstLastId={getFirstLastId}
                getContent={getContent}
              />
            </div>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default (props: MembersSelectProps) => (
  <ThemeProvider theme={muiTheme}>
    <MembersSelect {...props} />
  </ThemeProvider>
)
