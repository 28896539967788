import React, { ReactNode, useEffect, useState } from 'react'
import * as _ from 'lodash'
import { FieldArray, Form, Formik } from 'formik'
import { Button, Grid } from '@material-ui/core'
import { Icon } from '@mdi/react'
import { mdiDownload } from '@mdi/js'

import { AddButton } from 'civic-champs-shared/core/add-button'
import { Loading } from 'civic-champs-shared/core/'
import { useFeatureEnabled } from 'core/feature/hooks'
import { FormikEffect } from 'civic-champs-shared/question-sets/components/FormikEffect'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { useFetchQuestionSets } from 'civic-champs-shared/question-sets/hooks'
import QuestionSetCreationModal from '../QuestionSetCreationModal'
import {
  emptyRow,
  useGetDirtyLookup,
  useHandleNewQuestionSet,
  useHandleNewSurvey,
  useOptions,
  usePickerSchema,
  useSubmit,
} from './hooks'
import { DirtyRow } from './DirtyRow'
import { CleanRow } from './CleanRow'
import { SurveyEditModal } from 'surveys/components'
import { Redirect } from 'react-router'

interface Props {
  loading: boolean
  isGlobalUserProfile: boolean
  showAnonymousCheckbox: boolean
  showRequired: boolean
  title: ReactNode
  max: number
  min: number
  value: Array<{
    name: string
    optional: boolean
    questionSetId?: number
    surveyId?: number
  }>
  onChange: Function
  onSubmit: Function
  onBeforeAddEdit?: Function
  backLink?: string
}

export const QuestionSetPicker = (props: Props) => {
  const {
    loading,
    value,
    onSubmit,
    min = 0,
    max = 1000,
    showRequired = false,
    onChange,
    showAnonymousCheckbox = false,
    isGlobalUserProfile = false,
    backLink,
    onBeforeAddEdit,
  } = props

  const [questionSets, setQuestionSets] = useState([])
  const [redirectLocation, setRedirectLocation] = useState<string | undefined>()
  const isQuestionnaireAsSurveyEnabled = useFeatureEnabled('QuestionnaireAsSurvey')
  const isNewQuestionnairesEditorEnabled = useFeatureEnabled('NewQuestionnairesEditor')

  const key = isQuestionnaireAsSurveyEnabled ? 'surveyId' : 'questionSetId'

  const [fetchQuestionSets, { loading: questionSetsLoading, called: questionSetsCalled }] = useFetchQuestionSets(
    isGlobalUserProfile,
    isQuestionnaireAsSurveyEnabled,
  )

  const options = useOptions({ value, questionSets, isGlobalUserProfile, isQuestionnaireAsSurveyEnabled })
  const openQuestionSetModal = useShowPrompt(QuestionSetCreationModal)
  const pickerSchema = usePickerSchema(min, max, isQuestionnaireAsSurveyEnabled)
  const handleNewQuestionSet = useHandleNewQuestionSet({
    openQuestionSetModal,
    setQuestionSets,
    questionSets,
    showAnonymousCheckbox,
  })

  const showCreateUpdatePrompt = useShowPrompt(SurveyEditModal)
  const handleNewSurvey = useHandleNewSurvey({
    openQuestionSetModal: showCreateUpdatePrompt,
    setQuestionSets,
    questionSets,
    showAnonymousCheckbox,
  })

  const handleNewSurveyOrQuestionSet = (arrayHelpers: any, index: number, id?: number, optional?: boolean) => {
    if (isNewQuestionnairesEditorEnabled) {
      onBeforeAddEdit?.()
      setRedirectLocation(backLink + (id ? `/${id}/edit` : `/new`))
    } else {
      return (isQuestionnaireAsSurveyEnabled ? handleNewSurvey : handleNewQuestionSet)(
        arrayHelpers,
        index,
        id,
        optional,
      )
    }
  }

  const submit = useSubmit({ pickerSchema, onSubmit })
  const getDirtyLookup = useGetDirtyLookup(value, isQuestionnaireAsSurveyEnabled)

  useEffect(() => {
    fetchQuestionSets({}).then(res => {
      // @ts-ignore
      setQuestionSets(res)
    })
  }, [fetchQuestionSets])

  if (redirectLocation) {
    return <Redirect to={redirectLocation} />
  }

  if (loading || questionSetsLoading || !questionSetsCalled) {
    return <Loading />
  }

  return (
    <Grid container direction="column">
      <Formik
        initialValues={{ questionSets: _.cloneDeep(value || []) }}
        onSubmit={submit}
        validationSchema={pickerSchema}
        enableReinitialize
      >
        {formikBag => {
          const { isSubmitting, values, isValid, submitForm, setFieldValue } = formikBag

          const dirtyLookup: Array<{ questionSetId?: number; surveyId?: number; optional: boolean }> = getDirtyLookup(
            values.questionSets,
          )

          const dirty = Object.values(dirtyLookup).some(x => Boolean(x[key] || x.optional))
          const disabled = isSubmitting || !isValid
          return (
            <Form>
              <FormikEffect
                onChange={state => {
                  onChange && onChange(state)
                }}
                formik={formikBag}
              />
              <FieldArray
                name="questionSets"
                validateOnChange
                render={arrayHelpers => (
                  <>
                    <Grid container direction="column" spacing={3}>
                      <Grid container item alignItems="center">
                        {props.title}
                        <Grid item style={{ marginLeft: 10 }}>
                          <AddButton
                            disabled={disabled}
                            title="add"
                            onClick={() => arrayHelpers.push(_.cloneDeep(emptyRow(key)))}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container direction="column">
                      {(values.questionSets || []).map((questionSet, index) => (
                        <Grid
                          item
                          container
                          direction="row"
                          key={index}
                          spacing={2}
                          justify="flex-start"
                          alignContent="center"
                          alignItems="center"
                          style={{ marginBottom: '10px' }}
                        >
                          {dirtyLookup[index][key] ? (
                            <DirtyRow
                              index={index}
                              options={options}
                              formikBag={formikBag}
                              handleNewQuestionSet={handleNewSurveyOrQuestionSet}
                              arrayHelpers={arrayHelpers}
                              showRequired={showRequired}
                              keyField={key}
                            />
                          ) : (
                            <CleanRow
                              questionSet={questionSet}
                              index={index}
                              disabled={isSubmitting}
                              onEdit={() =>
                                handleNewSurveyOrQuestionSet(
                                  arrayHelpers,
                                  index,
                                  isQuestionnaireAsSurveyEnabled ? questionSet.surveyId : questionSet.questionSetId,
                                  questionSet.optional,
                                )
                              }
                              onRemove={() => {
                                arrayHelpers.remove(index)
                                if (onSubmit) {
                                  return submitForm()
                                }
                              }}
                              showRequired={showRequired}
                              onChangeOptional={() =>
                                setFieldValue(
                                  `questionSets.${index}.optional`,
                                  !_.get(values, `questionSets.${index}.optional`),
                                )
                              }
                            />
                          )}
                        </Grid>
                      ))}
                      <Grid container direction="row" style={{ marginTop: '25px' }}>
                        {onSubmit && (
                          <Button disabled={disabled || !dirty} type="submit" variant="contained" color="secondary">
                            <Icon path={mdiDownload} size={0.8} style={{ fill: 'white', marginRight: '3px' }} />
                            Save
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </>
                )}
              />
            </Form>
          )
        }}
      </Formik>
    </Grid>
  )
}
