import React, { useRef, useState } from 'react'
import HelpIcon from '@material-ui/icons/Help'
import { Button, Popover } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useCurrentProfile, useCurrentUser } from 'auth/hooks'
import { ROLE_TO_NAME } from 'civic-champs-shared/auth/utils/permissions'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import { Auth } from 'aws-amplify'
import { HelpModal } from 'App/components/HelpModal'
import { usePageHeaderStyles } from 'App/hooks/usePageHeaderStyles'
import { muiTheme } from 'theme'
import { ThemeProvider } from '@material-ui/styles'
import isNull from 'lodash/isNull'

export interface PageHeaderProps {
  title: string
  subTitle?: string
  backLink?: string
  children: React.ReactNode
}

function PageHeaderComponent({ title, subTitle, backLink, children }: PageHeaderProps) {
  const classes = usePageHeaderStyles()
  const currentUser = useCurrentUser()
  const currentProfile = useCurrentProfile()
  const [helpModalOpen, setHelpModalOpen] = useState(false)
  const [profileExpanded, setProfileExpanded] = useState<boolean>(false)
  const avatarUrl = (!isNull(currentProfile) && currentProfile?.user.avatar?.url) || '/assets/icons/profile.svg'
  // @ts-ignore
  const role = ROLE_TO_NAME[currentUser?.permissions?.role]
  const anchorEl = useRef<any>()

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.titles}>
          <h1 className={classes.title}>{title}</h1>
          {backLink ? (
            <Link className={classes.backLink} to={backLink}>
              {subTitle}
            </Link>
          ) : (
            <p className={classes.subTitle}>{subTitle || '\u00A0'}</p>
          )}
        </div>
        <Button
          classes={{ root: classes.helpButton, startIcon: classes.helpIcon, label: classes.helpLabel }}
          startIcon={<HelpIcon />}
          onClick={() => setHelpModalOpen(true)}
        >
          Help
        </Button>
        <div className={classes.userProfile} ref={anchorEl} onClick={() => setProfileExpanded(open => !open)}>
          <div className={classes.profileLeft}>
            <div style={{ backgroundImage: `url("${avatarUrl}")` }} className={classes.avatar}></div>
            <div className={classes.nameRole}>
              <p className={classes.name}>
                {currentUser.givenName} {currentUser.familyName}
              </p>
              <p className={classes.role}>{role}</p>
            </div>
          </div>
          <div className={classes.openClose}>
            {profileExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        </div>
        <Popover
          id="profile-popover"
          classes={{ paper: classes.popover }}
          open={profileExpanded}
          anchorEl={anchorEl.current}
          onClose={() => setProfileExpanded(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 15,
            horizontal: 'right',
          }}
        >
          <Link to="my/account">My profile</Link>
          <a href="#" onClick={() => Auth.signOut()}>
            Log Out
          </a>
        </Popover>
      </div>
      <div className={classes.childrenWrapper}>{children}</div>
      <HelpModal open={helpModalOpen} onClose={() => setHelpModalOpen(false)} />
    </div>
  )
}

export default function PageHeader(props: PageHeaderProps) {
  return (
    <ThemeProvider theme={muiTheme}>
      <PageHeaderComponent {...props} />
    </ThemeProvider>
  )
}
