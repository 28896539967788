import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import map from 'lodash/map'
import Scrollbars from 'civic-champs-shared/core/Scrollbars'

const useStyles = makeStyles({
  scroll: {
    width: '340px',
    height: '148px',
    border: '1px solid #74777F',
    borderRadius: '4px',
    position: 'relative',
    padding: 0,
    margin: '3px 0 18px 87px',
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
  },
  row: {
    fontFamily: 'Nunito, sans-serif',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.4px',
    color: '#000000',
    marginBottom: '10px',
  },
  info: {
    fontFamily: 'Nunito',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '12px',
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#000000',
    display: 'block',
    float: 'left',
    width: '412px',
  },
  errorIconWrapper: {
    display: 'block',
    float: 'left',
    marginLeft: '62px',
    marginRight: '10px',
  },
  errorIcon: {
    color: ' #D6A400',
    fontSize: '16px',
  },
  scrollBarsWrapper: {
    height: '107px',
    padding: '5px 0 5px 20px',
    borderTop: '1px solid #EEF0FA',
  },
  subTitle: {
    width: '50%',
    padding: '12px 0 12px 12px',
  },
  export: {
    width: '50%',
    textAlign: 'right',
    paddingTop: '3px',
  },
})

interface FailedRecipientsListProps {
  exportButton?: React.ReactNode
  errorText: string
  title: string
  rows: string[]
  className?: string
}

export const FailedRecipientsList = ({
  exportButton,
  errorText,
  title,
  rows,
  className,
}: FailedRecipientsListProps) => {
  const classes = useStyles()
  return (
    <>
      <Grid item className={className}>
        <div className={classes.errorIconWrapper}>
          <ErrorOutlineIcon className={classes.errorIcon} />
        </div>
        <div className={classes.info}>{errorText}</div>
      </Grid>
      <Grid item>
        <div className={classes.scroll}>
          <Grid container>
            <Grid item className={classes.subTitle}>
              {title}
            </Grid>
            {exportButton && (
              <Grid item className={classes.export}>
                {exportButton}
              </Grid>
            )}
          </Grid>
          <div className={classes.scrollBarsWrapper}>
            <Scrollbars>
              {map(rows, (row, id) => (
                <div key={id} className={classes.row}>
                  <span>{row}</span>
                </div>
              ))}
            </Scrollbars>
          </div>
        </div>
      </Grid>
    </>
  )
}

export default FailedRecipientsList
