import { isEmpty, isFunction } from 'lodash'
import React, { useMemo, useCallback } from 'react'
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core'
import {
  ADMIN,
  SUPER_ADMIN,
  INTERNAL_ADMIN,
  INTERNAL_SUPER_ADMIN,
  DEVELOPER,
  ROLE_TO_NAME,
} from 'civic-champs-shared/auth/utils/permissions'
import { useHasRole } from 'auth/hooks'

const BASE_ADMINS = [ADMIN, SUPER_ADMIN]
const INTERNAL_ADMINS = [INTERNAL_ADMIN, INTERNAL_SUPER_ADMIN]

export default function MemberRoleSelect(props) {
  const { disabled, value, onChange, label } = props

  const isSuperAdmin = useHasRole(INTERNAL_SUPER_ADMIN)
  const isDeveloper = useHasRole(DEVELOPER)
  const rolesToGrant = useMemo(() => {
      let roles = BASE_ADMINS
      if (isDeveloper) {
          roles = [...BASE_ADMINS, ...INTERNAL_ADMINS, DEVELOPER]
      } else if (isSuperAdmin) {
          roles = [...BASE_ADMINS, ...INTERNAL_ADMINS]
      }
    return roles.map(role => ({ label: ROLE_TO_NAME[role], value: role }))
  }, [isSuperAdmin, isDeveloper])

  const actualValue = isEmpty(value) ? '' : value

  const handleChange = useCallback(
    e => {
      let rawValue = e.target.value
      let selectedRole = !isEmpty(rawValue) ? rawValue : null

      if (isFunction(onChange)) {
        onChange(selectedRole)
      }
    },
    [onChange],
  )

  return (
    <FormControl variant="outlined" required fullWidth>
      <InputLabel shrink>{label}</InputLabel>
      <Select disabled={!!disabled} value={actualValue} onChange={handleChange} displayEmpty>
        <MenuItem value="">Member</MenuItem>
        {rolesToGrant.map((role, index) => (
          <MenuItem key={role.value} value={role.value}>
            {role.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
