import { useCallback, useEffect, useState } from 'react'
import { useFetch, useRemoteCollection } from 'civic-champs-shared/api/hooks'
import { useCurrentOrg } from 'auth/hooks'
import useRemoveVolunteerPrompt from './useRemoveVolunteerPrompt'
import useRemoveVolunteerPromptOld from './useRemoveVolunteerPromptOld'
import useArchiveVolunteerPromptOld from './useArchiveVolunteerPromptOld'
import useArchiveVolunteerPrompt from './useArchiveVolunteerPrompt'
import { formatDate } from './utils'
import { AddMemberType } from 'civic-champs-shared/core/add-person/AddPersonFlow'
import useAddChampion from 'champion/hooks/useAddChampion'
import useAddVolunteerPrompt from 'volunteering/volunteers/hooks/useAddVolunteerPrompt'

const useVolunteersCollection = (filters, includeInactive, includeArchived = false) => {
  const [request, { loading, error }] = useFetch()
  const [volunteers, operations] = useRemoteCollection()
  const organization = useCurrentOrg()
  const [initiallyLoaded, setInitiallyLoaded] = useState(false)

  //TODO volunteers can
  //const [currentFilters, setCurrentFilters] = useState({})

  const { syncCollection } = operations
  const getVolunteers = useCallback(
    async (filters, showInactive, includeArchived) => {
      const { startDate, endDate } = filters
      const params = {
        reporting: true,
        showInactive,
        includeArchived,
      }

      if (startDate) {
        params.startDate = formatDate(startDate)
      }

      if (endDate) {
        params.endDate = formatDate(endDate)
      }
      const updatedVolunteers = await request({
        url: `/organizations/${organization.id}/volunteers`,
        config: {
          queryStringParameters: params,
        },
      })

      syncCollection(updatedVolunteers)
      setInitiallyLoaded(true)
    },
    [request, organization.id, syncCollection],
  )
  useEffect(() => {
    getVolunteers(filters, includeInactive, includeArchived)
  }, [getVolunteers, filters, includeInactive, includeArchived])

  //TODO should this be the regular API hooks, and then push
  //     the prompts up into the UI layer?
  const removeVolunteerOld = useRemoveVolunteerPromptOld(operations.eagerRemoveMany)
  const [addChampion] = useAddChampion()
  const addVolunteer = ({ id, userData, sendInvite }) => {
    const payload = id
      ? {
          userId: id,
          type: AddMemberType.ExistingUser,
          sendInvite,
        }
      : {
          ...userData,
          type: AddMemberType.NewUser,
          sendInvite,
        }
    return addChampion(payload).then(operations.eagerAdd)
  }
  const addVolunteerLegacy = useAddVolunteerPrompt(operations.eagerAdd)
  const removeVolunteer = useRemoveVolunteerPrompt(operations.eagerRemoveMany)
  const archiveVolunteerOld = useArchiveVolunteerPromptOld(operations.eagerRemoveMany)
  const archiveVolunteer = useArchiveVolunteerPrompt(operations.eagerRemoveMany)

  return [
    {
      volunteers,
      loading,
      error,
      initiallyLoaded,
    },
    {
      addVolunteer,
      addVolunteerLegacy,
      removeVolunteerOld,
      removeVolunteer,
      archiveVolunteerOld,
      archiveVolunteer,
    },
  ]
}

export default useVolunteersCollection
