import React, { useCallback } from 'react'

import format from 'utils/format'
import { useFetch } from 'civic-champs-shared/api/hooks'
import { CsvConfig, ExportCsvButton } from './ExportCsvButton'
import { ExternalCustomerDatafeed } from 'external-datafeed/types'
import { useFeatureEnabled } from 'core/feature/hooks'

export interface ExportCsvButtonProps {
  datafeed: ExternalCustomerDatafeed
}

const headers = [
  { label: 'ID', key: 'id' },
  { label: 'Customer ID', key: 'customerId'},
  { label: 'First Name', key: 'firstName' },
  { label: 'Last Name', key: 'lastName' },
  { label: 'Email', key: 'email' },
  { label: 'Mobile Phone', key: 'phoneNumber' },
  { label: 'Home Phone', key: 'homePhoneNumber' },
  { label: 'Hours', key: 'hours' },
  { label: 'Donations', key: 'donations' },
  { label: 'Last Activity', key: 'latestActivity' },
]

const usePrepareDatafeedCsv = (datafeed: ExternalCustomerDatafeed) => {
  const includeDonations = useFeatureEnabled('Donations')
  const [request,] = useFetch()

  return useCallback(async (): Promise<CsvConfig> => {
    //TODO this should be typescript compatible but the "refactored" version leaves something to be desired
    //@ts-ignore 
    const result = await request({ url: `/external_datafeeds/${datafeed.id}/champions` })

    //TODO this should be a unified tabular system w/ react-table & react-csv
    //TODO straight copied from ExportChampionsButton
    const data = result.map( (o: any) => ({
      id: o.mapping.internalId,
      customerId: o.mapping.externalId,
      firstName: o.user.givenName,
      lastName: o.user.familyName,
      email: o.user.email,
      phoneNumber: format.phoneNumber(o.user.phoneNumber),
      homePhoneNumber: format.phoneNumber(o.user.homePhoneNumber),
      hours: format.fixedPoint(o.volunteering.totalVolunteerHours, 1),
      donations: format.fixedPoint(o.donation.totalDonations / 100, 2),
      latestActivity: o.volunteering.latestActivity,
    }))

    return {
      filename: 'volunteers.csv', //TODO should incorporate the datafeed name...
      headers: includeDonations ? headers : headers.filter(header => header.key !== 'donations'),
      data
    }
  }, [datafeed.id, includeDonations, request])
}

export const ExportDatafeedButton: React.FC<ExportCsvButtonProps> = (props) => {
  const { datafeed } = props
  const onPrepareCsv = usePrepareDatafeedCsv(datafeed)
  
  return (
    <ExportCsvButton 
      onPrepareCsv={onPrepareCsv}
    >
      Export
    </ExportCsvButton>
  )
}