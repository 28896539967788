import React, { useState, useMemo } from 'react'
import { Dialog, DialogContent, Grid, Button } from '@material-ui/core'
import { usePromptStyles } from 'civic-champs-shared/core/modal/hooks'
import usePost from 'civic-champs-shared/api/hooks/usePost'
import { Tester } from './ClearTesterPrompt'
import useGetOnMount from 'civic-champs-shared/api/hooks/useGetOnMount'
import { Person } from '../interface'
import Loading from 'civic-champs-shared/core/Loading'
import { SelectPerson } from './SelectPerson'
import * as _ from 'lodash'

interface Props {
  complete: (added: boolean) => void
  close: () => void
  cancel: () => void
  refresh: () => Promise<void>
  showing: boolean
  existingTesters: Tester[]
}

const useTesters = (existingTesters: Tester[]): [Person[], { loading: boolean }] => {
  const [, { loading, result: people }] = useGetOnMount<Person[]>({
    url: `/people`,
    errorMessage: 'Error fetching People',
    emptyValue: [],
  })
  return useMemo(() => {
    const existingTestersByPersonId = _.keyBy(existingTesters, 'person.id')
    const potentialTesters = people.filter(person => !existingTestersByPersonId[person.id])
    return [
      potentialTesters,
      {
        loading,
      },
    ]
  }, [people, loading, existingTesters])
}

export const AddTesterPrompt = (props: Props) => {
  const { close, showing, complete, cancel, refresh, existingTesters } = props
  const [tester, setTester] = useState<Person | null>(null)
  const [potentialTesters, { loading }] = useTesters(existingTesters)
  const [addTester, { loading: isSubmitting }] = usePost<Tester, { personId: number }>({
    errorMessage: 'Error adding tester',
    successMessage: 'Successfully added tester',
    configMapper: (body: { personId: number }) => ({ url: `/testers`, config: { body } }),
  })
  const classes = usePromptStyles()
  const handleSubmit = async () => {
    if (tester) {
      await addTester({ personId: tester.id })
      await refresh()
      complete(true)
    }
  }
  return (
    <Dialog open={showing} onClose={close} fullWidth>
      <DialogContent className={classes.contentContainer}>
        {loading && <Loading />}
        {!loading && (
          <Grid container spacing={2} direction="column">
            <Grid item>
              <SelectPerson label={'Tester'} items={potentialTesters} value={tester} onChange={setTester} />
            </Grid>
            <Grid item>
              <Button onClick={cancel} variant="text" disabled={isSubmitting}>
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                disabled={!tester || isSubmitting}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  )
}
