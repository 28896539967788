import React, { useMemo } from 'react'
import find from 'lodash/find'
import differenceBy from 'lodash/differenceBy'

import { EditCell } from 'core/table/cells'
import { useGroups } from 'group/hooks/useGroups'
import { GroupTypeCode } from 'Event/components/opportunity/GroupPicker/types'
import { timezones } from 'civic-champs-shared/helpers/timezones'
import { ProgramMatch, Tag } from '../types'
import { useTagManagement } from '../hooks'

export interface MentorshipEditCellProps {
  showAddMatch: (props: any) => void
  editMatch: (id: number, match: any) => Promise<ProgramMatch>
  fetchMatches: () => any[]
  cell: any
}

export const MentorshipMatchesEditCell = ({ showAddMatch, editMatch, fetchMatches, cell }: MentorshipEditCellProps) => {
  const {
    row: {
      original: {
        id,
        mentor,
        mentee,
        program,
        startedAt,
        stoppedAt,
        tags,
        meetingsTimezone,
        meetingCount,
        momentCount,
      },
    },
  } = cell

  const { linkTagsToMatch, unlinkTagsFromMatch } = useTagManagement()
  const { groups } = useGroups()
  const { mentorGroupId, menteeGroupId } = useMemo(
    () => ({
      mentorGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentor } })?.id,
      menteeGroupId: find(groups, { groupType: { code: GroupTypeCode.Mentee } })?.id,
    }),
    [groups],
  )

  const mentorContact = mentor.email || mentor.phoneNumber
  const menteeContact = mentee.email || mentee.phoneNumber
  return (
    <EditCell
      cell={cell}
      onEdit={() => {
        showAddMatch({
          id,
          label: 'Edit Match',
          editable: meetingCount + momentCount === 0,
          initialValues: {
            program,
            mentor: {
              id: mentor.id,
              givenName: mentor.givenName,
              familyName: mentor.familyName,
              contact: mentorContact,
              title: `${mentor.givenName} ${mentor.givenName} - ${mentorContact} `,
              groupId: mentorGroupId,
            },
            mentee: {
              id: mentee.id,
              givenName: mentee.givenName,
              familyName: mentee.familyName,
              contact: menteeContact,
              title: `${mentee.givenName} ${mentee.givenName} - ${menteeContact} `,
              groupId: menteeGroupId,
            },
            startedAt,
            stoppedAt,
            tags,
            meetingsTimezone: find(timezones, { value: meetingsTimezone }),
          },
          submit: ({ tags, ...match }: Partial<ProgramMatch> & { tags: Tag[] }) =>
            editMatch(id, match).then(async (match: ProgramMatch) => {
              const addTags = differenceBy(tags, match?.tags || [], 'id')
              const deleteTags = differenceBy(match?.tags || [], tags, 'id')
              if (addTags.length) {
                await linkTagsToMatch(
                  addTags.map(({ id }) => id),
                  match.id,
                )
              }
              if (deleteTags.length) {
                await unlinkTagsFromMatch(
                  deleteTags.map(({ id }) => id),
                  match.id,
                )
              }
              return fetchMatches()
            }),
        })
      }}
    />
  )
}
