import { useCallback } from 'react'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import { DuplicateQuestionSetPrompt } from '../components/DuplicateQuestionSetPrompt'
import { useDuplicateQuestionSet } from './useDuplicateQuestionSet'

export const useDuplicateQuestionSetPrompt = (eagerAdd: any) => {
  const showPrompt = useShowPrompt(DuplicateQuestionSetPrompt)
  const duplicateQuestionSetPrompt = useDuplicateQuestionSet(eagerAdd)

  return useCallback(
    async questionSet => {
      const updatedQuestionSet = await showPrompt({
        questionSet,
      })

      if (updatedQuestionSet) {
        await duplicateQuestionSetPrompt(updatedQuestionSet)
      }
    },
    [showPrompt, duplicateQuestionSetPrompt],
  )
}

export default useDuplicateQuestionSetPrompt
