import { useCallback } from 'react'
import { map } from 'lodash'
import { Volunteer } from 'champion/utils/interface'
import { MessagingContact } from 'messages/components/MessageRecipientsPrompt'

export const useGetSearchableContactsFromVolunteersArray = (
  volunteers: Volunteer[],
): (() => Promise<MessagingContact[]>) => {
  return useCallback(
    () =>
      Promise.resolve(
        map(volunteers, ({ user: { id, givenName, familyName } }) => ({
          id,
          firstName: givenName as string,
          lastName: familyName as string,
        })),
      ),
    [volunteers],
  )
}
