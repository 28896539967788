import React from 'react'
import moment from 'moment'
import { IconButton, makeStyles } from '@material-ui/core'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
  dayNavigation: {
    fontSize: '1.2em',
    display: 'flex',
    '& button': {
      color: theme.palette.secondary.main,
    },
    marginBottom: '5px',
  },
  currentDay: {
    width: '80px',
    textAlign: 'center',
    margin: 'auto 10px',
    color: theme.palette.secondary.main,
  },
  longFormattedDay: {
    margin: 'auto 10px',
  },
}))

export const DayNavigationBar = ({
  dayDiff,
  onPrevClick,
  onNextClick,
}: {
  dayDiff: number
  onPrevClick?: () => void
  onNextClick?: () => void
}) => {
  const classes = useStyles()
  const selectedDate = moment().clone().add(dayDiff, 'days')

  return (
    <div className={classes.dayNavigation}>
      <IconButton disabled={dayDiff === -1} onClick={onPrevClick}>
        <ChevronLeft />
      </IconButton>

      <div className={classes.currentDay}>{dayDiff === 0 ? 'Today' : dayDiff === -1 ? 'Yesterday' : 'Tomorrow'}</div>

      <IconButton disabled={dayDiff === 1} onClick={onNextClick}>
        <ChevronRight />
      </IconButton>

      <div className={classes.longFormattedDay}>{selectedDate.format('dddd, MMMM DD')}</div>
    </div>
  )
}

export default DayNavigationBar
