import React from 'react'
import { Dialog, DialogContent, Button, DialogActions } from '@material-ui/core'
import { Grid } from '@material-ui/core'

const volunteerSatisfactionDescription = value => {
  const valueForComparison = parseFloat(value)

  switch (valueForComparison) {
    case -1:
      return ' (BAD)'
    case -0.5:
      return ' (SO SO)'
    case 0.5:
      return ' (OKAY)'
    case 1:
      return ' (GREAT)'
    default:
      return null
  }
}

export default function ViewActivityReflectionsDialog(props) {
  const { activity, showing, close } = props
  const { rating, reflection, volunteerSatisfaction } = activity

  return (
    <Dialog
      open={showing}
      onClose={close}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
      fullWidth={true}
      maxWidth={'sm'}
    >
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            Reflection:
          </Grid>
          <Grid item xs={12}>
            {reflection}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
